import React from "react";

// import css
import "./HappyerpBitAboutUs.scss";

const HappyerpBitAboutUs = () => {
  return (
    <section className="happyerp-sect_padding happyerp-a_bit_about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="happyerp-about_content happyerp-about_left">
              <h6 className="happyerp-small_title_with_line">
                About Happy ERP
              </h6>
              <h2 className="happyerp-df45 happyerp-text_black happyerp-semibold">
                We Are Fully Dedicated To Support
              </h2>
              <p className="happyerp-df15 happyerp-text_grey happyerp-regular happyerp-lh26">
                There's this notion that to grow a business, you have to be
                rutless But we know there's a better way to grow
              </p>

              <div className="happyerp-ac_more">
                <div className="happyerp-icon">
                  <img
                    src="assets/img/happyerp/icon/mission.png"
                    alt="mission"
                  />
                </div>
                <h3 className="happyerp-df25 happyerp-medium happyerp-text_black">
                  Our Mission
                </h3>
                <p className="happyerp-df15 happyerp-regular happyerp-text_grey happyerp-lh26">
                  Ryun ipsum dolor sit ametconsecteturing elit sed do eiusmod
                  tempor incididunt laboret dolore magna aliqua minaquaerat.
                </p>
              </div>

              <div className="happyerp-ac_more">
                <div className="happyerp-icon">
                  <img src="assets/img/happyerp/icon/story.png" alt="story" />
                </div>
                <h3 className="happyerp-df25 happyerp-medium happyerp-text_black">
                  Our Story
                </h3>
                <p className="happyerp-df15 happyerp-regular happyerp-text_grey happyerp-lh26">
                  Ryun ipsum dolor sit ametconsecteturing elit sed do eiusmod
                  tempor incididunt laboret dolore magna aliqua minaquaerat.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="happyerp-about_right happyerp-relative">
              <img
                src="assets/img/happyerp/home/about_left.jpg"
                alt="about_left"
              />
              <div className="happyerp-exp">
                <p>
                  <span>16</span>Years Experience
                </p>
                <div className="happyerp-rotate_circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HappyerpBitAboutUs;
