import React from "react";
import "../corporateTwoCss/corporateTwoStyle.scss";
import { Routes, Route } from "react-router-dom";
import CorporateTwoHome from "../corporateTwoPages/CorporateTwoHome";
import GlobalRoute from "../../../../global route/GlobalRoute";
import Navbar from "../../../../default components/Navbar";
import BusinessThreeNavbar from "../../../business/businessThree/businessThreeComponents/BusinessThreeNavbar";
import CorporateTwoTerms from "../corporateTwoPages/CorporateTwoTerms";
import CorporateTwoPolicy from "../corporateTwoPages/CorporateTwoPolicy";
import CorporateTwoFooter from "../corporateTwoComponents/CorporateTwoFooter/CorporateTwoFooter";
// import PGProfile from "../../../../default pages/profile/PGProfile";
import { useDocument } from "../../../../firebase files/hooks/useDocument";
import { Navigate } from "react-router-dom";
import PGBusinessThreeProfile from "../../../business/businessThree/businessThreePages/PGBusinessThreeProfile";

import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import PGCorporateTwoAbout from "../corporateTwoPages/PGCorporateTwoAbout";
import Address from "../../../../default components/Address";
import Checkout from "../../../../default components/Checkout";
import OrderSummary from "../../../../default components/OrderSummary";
import OrdersPage from "../../../../default components/OrdersPage";
import Wishlist from "../../../../default components/Wishlist";
import ProductsVersion from "../../../../default components/ProductsVersion";
import PGDashboard from "../../../../allRoles/dashboards/PGDashboard";
const CorporateTwoRoute = () => {
  const { user } = useAuthContext();
  const { document: navTypeDoc } = useDocument("settings", "navbarType");

  return (
    <div className="corporate_Two_Style_main_route_div">
      {navTypeDoc && navTypeDoc.activeNav == "FixNav" ? (
        <Navbar />
      ) : (
        <BusinessThreeNavbar />
      )}

      <Routes>
        <Route path="/" element={<CorporateTwoHome />} />
        <Route path="/aboutus" element={<PGCorporateTwoAbout />} />
        <Route path="/profile" element={<PGBusinessThreeProfile />} />
        <Route path="/products-new" element={<ProductsVersion />} />
        <Route path="/dashboard" element={<PGDashboard />}></Route>
      </Routes>
      <GlobalRoute />
      <CorporateTwoFooter />
    </div>
  );
};

export default CorporateTwoRoute;
