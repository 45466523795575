import React, { useState, useEffect } from 'react'
import './MultiLocationSetting.scss'
import ReactSwitch from 'react-switch'
import { useDocument } from '../../firebase files/hooks/useDocument'
import { useAuthContext } from '../../firebase files/hooks/useAuthContext'
import { useFirestore } from '../../firebase files/hooks/useFirestore'
const MultiLocationSetting = () => {
    const { user } = useAuthContext();
    const { document, error } = useDocument('settings', 'multiLocation');
    console.log(document)
    const [value, setValue] = useState(false);

    const { updateDocument } = useFirestore('settings');

    useEffect(() => {
        if (document) {
            setValue(document.isEnabled);
        }
    }, [document])

    const handleToggleChange = (newValue) => {
        setValue(newValue);

        updateDocument('multiLocation', {
            isEnabled: newValue
        })
    }
    return (
        <div className='container'>
            <div className="section-title">
                <p>Multi Location Feature Setting</p>
                <h2>You Can Enable or Disable Multi Location Feature</h2>
                <br />
            </div>


            <div className='settings-content'>
                <h3 className='settings-title'>Toggle Multi Location Feature</h3>

                <div className="toggle-wrapper">
                    <div>
                        <p className={`toggle-status ${!value ? 'inactive' : ''}`}>
                            Inactive
                        </p>
                    </div>

                    <ReactSwitch
                        checked={value}
                        onChange={() => handleToggleChange(!value)}
                        onColor="#149AA2"
                        offColor="#F05B83"
                        className="products-status-switch"
                    />

                    <div>
                        <p className={`toggle-status ${value ? 'active' : ''}`}>
                            Active
                        </p>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default MultiLocationSetting