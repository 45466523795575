import React from "react";
import { Modal, Button } from "react-bootstrap";

// import css
import "./HappyerpSaveVariantsPopUp.scss";

const HappyerpSaveVariantsPopUp = ({ show, onClose }) => {
  return (
    <Modal
      size="md"
      centered
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={onClose}
      className="happyerp-customized-modal"
    >
      <Modal.Header className="happyerp-main_heading">
        <Modal.Title className="happyerp-main_title">Save Variants</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Variant Name:
        <input
          className="happyerp-user_inpt_field"
          type="text"
          style={{ width: "100%", marginTop: "10px" }}
        />
      </Modal.Body>
      <Modal.Footer className="happyerp-main_footer">
        {/* Close Button */}
        <Button
          variant="secondary"
          onClick={onClose}
          className="happyerp-theme_btn1"
        >
          Close
        </Button>

        {/* Save Changes Button */}
        <Button
          className="happyerp-theme_btn"
          variant="primary"
          onClick={() => {
            onClose();
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HappyerpSaveVariantsPopUp;
