import { useEffect, useState } from "react";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { useAuthContext } from "../../../firebase files/hooks/useAuthContext";

const UserDetailsRoles = ({ userProfileId }) => {
  const { document: dbRolesDocuments, error: dbRolesError } = useDocument(
    "m_roles",
    "rolesDoc"
  );

  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );

  const { user } = useAuthContext();
  // console.log(userProfileDoc);
  const { updateDocument } = useFirestore("users");

  // full code for change role and bydefault check provided roles start
  const [selectedRoles, setSelectedRoles] = useState([]);
  // const [primaryRole, setPrimaryRole] = useState("");
  const [isRoleSaving, setIsRoleSaving] = useState(false);
  const [isRoleEditing, setIsRoleEditing] = useState(false);
  const [saveRoleMessage, setSaveRoleMessage] = useState("");
  const [roleMessageType, setRoleMessageType] = useState("");
  const handleRoleEditClick = () => {
    setIsRoleEditing(!isRoleEditing);
  };
  // Update state when userProfileDoc is fetched
  useEffect(() => {
    if (userProfileDoc) {
      setSelectedRoles(userProfileDoc.roles || []);
      // setPrimaryRole(userProfileDoc.roles || "");
    }
  }, [userProfileDoc]);

  // new code of selected roel number
  const handleRoleChange = (role) => {
    setSelectedRoles((prevRoles) => {
      let updatedRoles;

      if (prevRoles.includes(role)) {
        // Remove role if already selected
        updatedRoles = prevRoles.filter((item) => item !== role);

        // Update primary role if the removed role was the primary one
        // if (primaryRole === role) {
        //   setPrimaryRole(updatedRoles.length > 0 ? updatedRoles[0] : "");
        // }
      } else {
        // Add role if not selected
        updatedRoles = [...prevRoles, role];

        // Set the first selected role as the primary role
        // if (!primaryRole) {
        //   setPrimaryRole(role);
        // }
      }

      return updatedRoles;
    });
  };

  // Handle save button click
  const handleSaveRole = async () => {
    if (selectedRoles.length === 0) {
      setSaveRoleMessage("Please select at least one role.");
      setRoleMessageType("error_msg");
      setTimeout(() => {
        setSaveRoleMessage("");
        setRoleMessageType("");
      }, 4000); // Clear message after 5 seconds
      return;
    }

    // Check if there are changes
    // if (
    //   JSON.stringify(selectedRoles) ===
    //     JSON.stringify(userProfileDoc.roles || []) &&
    //   primaryRole === (userProfileDoc.role || "")
    // ) {
    //   setSaveRoleMessage(
    //     "No changes detected. Please make updates before saving."
    //   );
    //   setRoleMessageType("error_msg"); // Use a different type if desired
    //   setTimeout(() => {
    //     setSaveRoleMessage("");
    //     setRoleMessageType("");
    //   }, 4000); // Clear message after 5 seconds
    //   return;
    // }

    // Proceed with save logic
    const dataSet = {
      //   roles: primaryRole || selectedRoles[0], // Default to first selected role if primaryRole is empty
      roles: selectedRoles,
    };

    setIsRoleSaving(true);
    setSaveRoleMessage(""); // Clear previous message

    try {
      await updateDocument(userProfileId, dataSet);
      setRoleMessageType("success_msg");
      setSaveRoleMessage("Roles updated successfully!");
      setTimeout(() => {
        setIsRoleEditing(!isRoleEditing);
      }, 4000);
    } catch (error) {
      console.error("Error updating roles:", error);
      setRoleMessageType("error_msg");
      setSaveRoleMessage("Failed to update roles. Please try again.");
    } finally {
      setIsRoleSaving(false);
      // Clear the message after a delay
      setTimeout(() => {
        setSaveRoleMessage("");
        setRoleMessageType("");
      }, 4000); // Clear message after 5 seconds
    }
  };

  return (
    <div className="pd-cards animate-slide-in">
      <div className="pd-heading">
        <h2>Roles</h2>
        {!userProfileDoc?.profileLock &&
          !isRoleEditing &&
          (user.role === "admin" ||
            user.role === "superadmin" ||
            user.role === "hr") && (
            <div
              className="pd-edit-state"
              onClick={() => {
                setIsRoleEditing((prev) => !prev);
              }}
            >
              <span className="material-symbols-outlined">edit</span>
              Edit
            </div>
          )}
      </div>
      <div className="pd-roles">
        {!isRoleEditing ? (
          <div className="theme_radio_container">
            {userProfileDoc?.roles.map((role, index) => (
              <div className="radio_single" key={index}>
                <input
                  type="checkbox"
                  id={role}
                  name="fc-role"
                  value={role}
                  disabled={!isRoleEditing}
                  checked={selectedRoles.includes(role)}
                  onChange={() => handleRoleChange(role)}
                />
                <label htmlFor={role}>{role}</label>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <div className="theme_radio_container">
              {dbRolesDocuments?.roles.map((role, index) => (
                <div className="radio_single" key={index}>
                  <input
                    type="checkbox"
                    id={role}
                    name="fc-role"
                    value={role}
                    checked={selectedRoles.includes(role)}
                    onChange={() => handleRoleChange(role)}
                  />
                  <label htmlFor={role}>{role}</label>
                </div>
              ))}
            </div>
            <div className="pd-edit-option">
              {saveRoleMessage && (
                <p className={`edit-msg_area ${roleMessageType}`}>
                  {saveRoleMessage}
                </p>
              )}

              <button
                onClick={handleRoleEditClick}
                disabled={isRoleSaving}
                className={` pd-option-btn option-btn-cancel ${
                  isRoleSaving ? "disabled" : ""
                }`}
              >
                Cancel
              </button>
              <button
                onClick={handleSaveRole}
                disabled={isRoleSaving}
                className={` pd-option-btn option-btn-save ${
                  isRoleSaving ? "disabled" : ""
                }`}
              >
                {isRoleSaving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailsRoles;
