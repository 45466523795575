import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../firebase files/hooks/useAuthContext';
import { useRazorpay } from '../firebase files/hooks/useRezorpay';
import useOrders from '../firebase files/hooks/useOrders';
import useCart from '../firebase files/hooks/useCart';
import CustomSnackbar from './CustomSnackbar';
import './OnlinePayment.scss';

const OnlinePayment = () => {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { makePayment, paymentResponse } = useRazorpay();
    const { createOrder, updatePaymentStatus } = useOrders();
    const { cartItems } = useCart();
    const [isProcessing, setIsProcessing] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info'
    });

    // Get cart details from localStorage or state management
    const cartDetails = JSON.parse(localStorage.getItem('cartDetails') || '{}');
    const { orderProducts, orderPrice, selectedAddress } = cartDetails;

    useEffect(() => {
        // Only check for user authentication
        if (!user) {
            navigate('/cart');
            return;
        }

        // Check if we have cart details in localStorage
        const cartDetails = localStorage.getItem('cartDetails');
        if (!cartDetails) {
            navigate('/cart');
            return;
        }

        // Validate cart details
        const { orderProducts, orderPrice, selectedAddress } = JSON.parse(cartDetails);
        if (!orderProducts || !orderPrice || !selectedAddress) {
            navigate('/cart');
        }
    }, [user, navigate]);

    useEffect(() => {
        if (paymentResponse) {
            handlePaymentResponse(paymentResponse);
        }
    }, [paymentResponse]);

    const handlePaymentResponse = async (response) => {
        try {
            if (response.paymentStatus === 'Success') {
                // Create order with online payment details
                const order = await createOrder(
                    orderProducts,
                    orderPrice,
                    selectedAddress,
                    'online',
                    response  // Pass full payment response
                );

                if (order) {
                    // Update payment status
                    await updatePaymentStatus(order.id, response);

                    setSnackbar({
                        open: true,
                        message: 'Payment successful! Redirecting to order summary...',
                        severity: 'success'
                    });
                    
                    localStorage.removeItem('cartDetails');
                    
                    setTimeout(() => {
                        navigate(`/ordersummary/${order.id}`);
                    }, 2000);
                }
            } else if (response.paymentStatus === 'Failed') {
                setSnackbar({
                    open: true,
                    message: `Payment failed: ${response.paymentDetails?.errorDescription || 'Please try again'}`,
                    severity: 'error'
                });
            }
        } catch (error) {
            console.error('Error handling payment response:', error);
            setSnackbar({
                open: true,
                message: 'Error processing payment. Please try again.',
                severity: 'error'
            });
        } finally {
            setIsProcessing(false);
        }
    };

    const initiatePayment = async () => {
        if (!orderProducts || !orderPrice || !selectedAddress) {
            setSnackbar({
                open: true,
                message: 'Invalid order details. Please try again.',
                severity: 'error'
            });
            return;
        }

        setIsProcessing(true);
        try {
            await makePayment(
                Date.now().toString(), // Generate unique order ID
                orderPrice,
                user.fullName || 'Customer',
                `Order payment for ${orderProducts.length} items`,
                user.email,
                user.phoneNumber
            );
        } catch (error) {
            console.error('Error initiating payment:', error);
            setSnackbar({
                open: true,
                message: 'Error initiating payment. Please try again.',
                severity: 'error'
            });
            setIsProcessing(false);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    return (
        <div className="online-payment-page">
            <div className="payment-summary">
                <h2>Payment Summary</h2>
                <div className="order-details">
                    <p>Total Items: {orderProducts?.length || 0}</p>
                    <p>Total Amount: ₹{orderPrice || 0}</p>
                </div>
                <button 
                    className="payment-button"
                    onClick={initiatePayment}
                    disabled={isProcessing}
                >
                    {isProcessing ? 'Processing...' : 'Pay Now'}
                </button>
                <button 
                    className="back-button"
                    onClick={() => navigate('/cart')}
                    disabled={isProcessing}
                >
                    Back to Cart
                </button>
            </div>

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={handleSnackbarClose}
            />
        </div>
    );
};

export default OnlinePayment;
