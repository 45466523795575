import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./HappyerpNineDots.scss";

const HappyerpNineDots = ({ options = [] }) => {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className={`ninedots ${showOptions ? "option_show" : ""}`}>
      <div className="open_div oc_icon_position" onClick={toggleOptions}>
        <div className="icon_div">
          <span className="material-symbols-outlined">apps</span>
        </div>
      </div>
      <div
        className="close_div oc_icon_position icon_with_text"
        onClick={toggleOptions}
      >
        <h6 className="happyerp-df16 happyerp-regular happyerp-text_white">Close</h6>
        <div className="icon_div">
          <span className="material-symbols-outlined">close</span>
        </div>
      </div>
      <div className="inner_options">
        <div className="io_menu">
          {options.map(({ link, text, icon }, index) => (
            <Link
              key={index}
              className="iom_single icon_with_text happyerp-df16 happyerp-text_white happyerp-regular"
              to={link}
            >
              <h6>{text}</h6>
              <div className="icon_div">
                <span className="material-symbols-outlined">{icon}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HappyerpNineDots;
