import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// import css
import "./HappyerpCard.scss";
import HappyerpMoreOptionPopup from "./HappyerpMoreOptionPopup";
import { format } from "date-fns";

const HappyerpCard = ({ id, isMoreOptionsOpen, toggleMoreOptions, order }) => {
  // add or remove class of expanded start
  const [expandedText, setExpandedText] = useState(false);

  const toggleExpanded = () => {
    setExpandedText(!expandedText);
  };
  // add or remove class of expanded end

  // status change code start
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [status, setStatus] = useState("Approved");
  const [selectedStatus, setSelectedStatus] = useState("Approved");

  const handleEditClick = () => {
    setShowEditPopup(true);
  };

  const handleCloseClick = () => {
    setShowEditPopup(false);
  };

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  const handleDoneClick = () => {
    setStatus(selectedStatus);
    setShowEditPopup(false);
  };
  // status change code end

  //More options code start------------------->

  return (
    <div className="happyerp-card_container">
      <div className="happyerp-order_edit">
        <HappyerpMoreOptionPopup
          id={id}
          isMoreOptionsOpen={isMoreOptionsOpen}
          toggleMoreOptions={toggleMoreOptions}
        />
      </div>

      <div className="happyerp-card_inner happyerp-card_shadow ">
        <div className="happyerp-card_top">
          <div className="happyerp-card_checkbox">
            <label>
              <input type="checkbox" />
            </label>
          </div>
          <div className="happyerp-card_fields">
            <div className="happyerp-cf_single happyerp-with_icon">
              {/* <div className="icon">
                <span class="material-symbols-outlined">
                  today
                </span>
              </div> */}
              <div className="happyerp-text">
                <h6>Created Date</h6>
                <h5>
                  {order?.selectedDate
                    ? format(order?.selectedDate.toDate(), "dd-MMM-yyyy")
                    : "----"}
                </h5>
              </div>
            </div>
            <div className="happyerp-cf_single">
              <h6>No Of Item</h6>
              <h5>----</h5>
            </div>
            <div className="happyerp-cf_single happyerp-with_icon">
              {/* <div className="icon">

                <span class="material-symbols-outlined">
                  event
                </span>
              </div> */}
              <div className="happyerp-text">
                <h6>Delivery Date</h6>
                <h5>
                  {order.deliveryDate
                    ? format(order.deliveryDate.toDate(), "dd-MMM-yyyy")
                    : "----"}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="happyerp-card_middle happyerp-relative happyerp-card_bg">
          <div className="happyerp-expand_more_text" onClick={toggleExpanded}>
            <span class="material-symbols-outlined">
              {expandedText ? "expand_Less" : "expand_more"}
            </span>
          </div>
          <div className="happyerp-card_fields">
            <div className="happyerp-cf_single">
              <h6>Party Name</h6>
              <h5>{order.partyName}</h5>
            </div>
            <div className="happyerp-cf_single">
              <h6>Item</h6>
              <h5>----</h5>
            </div>
            <div className="happyerp-cf_single">
              <h6>Total Quantity</h6>
              <h5>----</h5>
            </div>
            <div className="happyerp-cf_single">
              <h6>Order Number</h6>
              <h5>{order.id}</h5>
            </div>
          </div>
          {expandedText && (
            <div className="happyerp-show_on_click">
              {/* <div className="happyerp-short_info">
                {order?.orders.map((o) => (
                  <div className="happyerp-si_single">
                    <span>{o.sizeName}</span>
                    <span>|</span>
                    <span>{o.quantity}</span>
                  </div>
                ))}
                <div className="happyerp-si_single">
                  <span>Size 32</span>
                  <span>|</span>
                  <span>500</span>
                </div>
                <div className="happyerp-si_single">
                  <span>Size 34</span>
                  <span>|</span>
                  <span>500</span>
                </div>
                <div className="happyerp-si_single">
                  <span>Size 36</span>
                  <span>|</span>
                  <span>500</span>
                </div>
                <div className="happyerp-si_single">
                  <span>Size 38</span>
                  <span>|</span>
                  <span>500</span>
                </div>
                <div className="happyerp-si_single">
                  <span>Size 40</span>
                  <span>|</span>
                  <span>500</span>
                </div>
              </div> */}
              <div className="happyerp-desc">
                <h6>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Possimus, incidunt asperiores! Voluptate magnam mollitia
                  architecto nostrum fuga non, alias consectetur ad numquam
                  fugit ex aliquam libero illum quia repudiandae. A!
                </h6>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HappyerpCard;
