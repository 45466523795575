import React, { useState, useMemo, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker"; // npm install react-datepicker
import "react-datepicker/dist/react-datepicker.css";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { CSSTransition } from "react-transition-group";
import Select from "react-select";

// import css
import "./HappyerpDataEntryCard.scss";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
import { useCollection } from "../../../../firebase files/hooks/useCollection";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

const HappyerpDataEntryCard = ({ query, saleOrder, setOrders, orders }) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  //basic info starts-----

  //fetching party collection from firebase
  const { documents: allParties } = useCollection("m_parties", null, [
    "createdAt",
    "asc",
  ]);

  const { documents: allAgents } = useCollection("m_agents", null, [
    "createdAt",
    "asc",
  ]);

  const { addDocument, updateDocument } = useFirestore("sales_order");
  //pre mentioned options for item drop down in order details
  const optionsItem = [
    { value: "blazer", label: "Blazer" },
    { value: "jeans", label: "Jeans" },
    { value: "shirt", label: "Shirt" },
    { value: "t-shirt", label: "T-shirt" },
  ];

  //pre mentioned options for Design dropdown in order details
  const optionsDesign = [
    { value: "check", label: "Check" },
    { value: "circle", label: "Circle" },
    { value: "plain", label: "Plain" },
    { value: "lining", label: "Lining" },
  ];

  //pre mentioned options for subdesign dropdown in order details.
  const optionsSubDesign = [
    { value: "sub-design1", label: "Sub-Design 1" },
    { value: "sub-design2", label: "Sub-Design 2" },
    { value: "sub-design3", label: "Sub-Design 3" },
    { value: "sub-design4", label: "Sub-Design 4" },
  ];

  // state to manage the selected option

  // active tab functionaly when change with transtion start
  const [activeTab, setActiveTab] = React.useState("happyerp-step1");

  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };
  // active tab functionaly when change with transtion end

  // code for click saveand next button start
  const [formData, setFormData] = useState({
    selectedDate: new Date(Date.now()),
    partyName: null,
    agentName: null,
    transportName: null,
    deliveryDate: new Date(Date.now()),
    remark: "",
  });

  const [loading, setLoading] = useState(false);
  const [isBasicInfoEdit, setIsBasicInfoEdit] = useState(true);
  const [responseId, setResponseId] = useState(null);

  const [selectedItemOptions, setSelectedItemOptions] = useState(null);
  const [selectedDesignOptions, setSelectedDesignOptions] = useState(null);
  const [selectedSubDesignOptions, setSelectedSubDesignOptions] =
    useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState({
    blue: false,
    red: false,
    yellow: false,
    black: false,
    white: false,
    pink: false,
  });
  // select all and clear all checkbox code start

  // Function to handle the "Select All" button click event
  const handleSelectAll = (event) => {
    event.preventDefault();
    setCheckboxStates((prevState) => {
      const updatedStates = {};
      for (const key in prevState) {
        updatedStates[key] = true;
      }
      return updatedStates;
    });
  };

  // Function to handle the "Clear All" button click event
  const handleClearAll = (event) => {
    event.preventDefault();
    setCheckboxStates((prevState) => {
      const updatedStates = {};
      for (const key in prevState) {
        updatedStates[key] = false;
      }
      return updatedStates;
    });
  };

  // Function to handle individual checkbox change
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckboxStates((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  // Count the number of selected checkboxes
  const selectedCount = Object.values(checkboxStates).filter(
    (value) => value
  ).length;

  // Calculate the total count of checkboxes
  const totalCount = Object.keys(checkboxStates).length;

  // select all and clear all checkbox code end

  const [sizes, setSizes] = useState({
    "size 34": 0,
    "size 36": 0,
    "size 38": 0,
    "size 40": 0,
    "size 42": 0,
    "size 44": 0,
  });

  const handleIncrement = (sizeName) => {
    setSizes((prevSizes) => ({
      ...prevSizes,
      [sizeName]: prevSizes[sizeName] + 1,
    }));
  };

  const handleDecrement = (sizeName) => {
    setSizes((prevSizes) => ({
      ...prevSizes,
      [sizeName]: Math.max(0, prevSizes[sizeName] - 1),
    }));
  };

  const handleSizesChange = (sizeName, value) => {
    const newValue = Math.max(0, parseInt(value) || 0);
    setSizes((prevSizes) => ({
      ...prevSizes,
      [sizeName]: newValue,
    }));
  };

  // Memoized transport options
  const transportOptions = useMemo(
    () => [
      { value: "train", label: "Train" },
      { value: "truck", label: "Truck" },
      { value: "ship", label: "Ship" },
      { value: "van", label: "Van" },
    ],
    []
  );
  useEffect(() => {
    if (!query || !saleOrder) return;

    const saleOrderDate =
      saleOrder?.selectedDate?.toDate() || new Date(Date.now());
    const saleDeliveryDate =
      saleOrder?.deliveryDate?.toDate() || new Date(Date.now());
    setFormData({
      selectedDate: saleOrderDate,
      partyName: saleOrder.partyName || null,
      agentName: saleOrder.agentName || null,
      transportName: saleOrder.transportName || null,
      deliveryDate: saleDeliveryDate,
      remark: saleOrder.remark || "",
    });
    setIsBasicInfoEdit(false);
  }, [query, saleOrder]);

  const handleInputChange = useCallback(
    (field) => (value) => {
      setFormData((prev) => ({ ...prev, [field]: value }));
      setIsBasicInfoEdit(true);
    },
    []
  );
  const handleSaveAndNext = useCallback(async () => {
    const { selectedDate, partyName, transportName, deliveryDate } = formData;
    if (!selectedDate || !partyName || !transportName || !deliveryDate) {
      alert("Please fill all required fields");
      return;
    }

    setLoading(true);
    try {
      const data = {
        ...formData,
        placedBy: user?.displayName || "",
        createdBy: user?.uid || "",
        isReadOnly: false,
        orderStatus: "open",
      };
      console.log(data);

      const res =
        !query || !saleOrder
          ? await addDocument(data)
          : await updateDocument(query, data);

      setResponseId(res?.id);
      setIsBasicInfoEdit(false);
    } catch (err) {
      console.error("Save error:", err);
      alert("Failed to save. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [formData, user, query, saleOrder, addDocument, updateDocument]);

  // to handle the change in Select option
  const handleDesignDropDownChange = (item) => {
    setSelectedDesignOptions(item);
  };
  const handleItemDropDownChange = (item) => {
    console.log(item);
    setSelectedItemOptions(item);
  };
  const handleSubDesignDropDownChange = (item) => {
    setSelectedSubDesignOptions(item);
  };

  const handleSaveAndAddMore = () => {
    const colors = Object.entries(checkboxStates)
      .filter(([_, isChecked]) => isChecked)
      .map(([color]) => color);

    const filteredSizes = Object.fromEntries(
      Object.entries(sizes).filter(([_, quantity]) => quantity > 0)
    );
    console.log(
      !selectedItemOptions,
      !selectedDesignOptions,
      !selectedSubDesignOptions,
      colors.length === 0,
      !selectedStyle,
      Object.keys(filteredSizes).length === 0
    );
    if (
      !selectedItemOptions ||
      !selectedDesignOptions ||
      !selectedSubDesignOptions ||
      colors.length === 0 ||
      !selectedStyle ||
      Object.keys(filteredSizes).length === 0
    ) {
      alert("some values are pending in order details");
      return;
    }
    const value = {
      itemName: selectedItemOptions.value,
      design: selectedDesignOptions.value,
      subDesign: selectedSubDesignOptions.value,
      colors,
      style: selectedStyle,
      sizes: filteredSizes,
    };
    setOrders((order) => [...order, value]);
    setSelectedItemOptions(null);
    setSelectedDesignOptions(null);
    setSelectedSubDesignOptions(null);
    setCheckboxStates((prevState) => {
      const updatedStates = {};
      for (const key in prevState) {
        updatedStates[key] = false;
      }
      return updatedStates;
    });
    setSelectedStyle(null);
    setSizes({
      "size 34": 0,
      "size 36": 0,
      "size 38": 0,
      "size 40": 0,
      "size 42": 0,
      "size 44": 0,
    });
  };
  const handleOrderSubmit = async () => {
    try {
      await updateDocument(query, { orders });
      navigate("/sales");
    } catch (error) {
      console.log(error);
      alert("Error in done handler");
    }
  };

  return (
    <div
      className={`happyerp-data_entry_card happyerp-theme_tabs ${
        activeTab === "happyerp-step1"
          ? "happyerp-step1_active"
          : "happyerp-step2_active"
      }`}
    >
      <Tabs
        activeKey={activeTab}
        onSelect={handleSelect}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab
          eventKey="happyerp-step1"
          title={
            <div className="happyerp-step_single">
              <div className="happyerp-number">1</div>
              <h6>Basic Info</h6>
              <div className="happyerp-progress_bar">
                <div
                  className="fill"
                  style={{
                    width: "50%",
                  }}
                ></div>
              </div>
            </div>
          }
        >
          <CSSTransition
            in={activeTab === "happyerp-step1"}
            timeout={1000}
            classNames="happyerp-slide"
            unmountOnExit
          >
            <div className="happyerp-three_ff_one_row row">
              <div className="col-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Date*
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow">
                    <DatePicker
                      selected={formData.selectedDate}
                      onChange={handleInputChange("selectedDate")}
                      minDate={new Date()}
                      dateFormat="dd/MM/yy" // Date format
                      placeholderText="dd/mm/yy" // Placeholder text
                      wrapperClassName="happyerp-react-datepicker-wrapper"
                    />
                    <div className="happyerp-field_icon">
                      <span class="material-symbols-outlined">
                        calendar_month
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Party*
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow">
                    <select
                      // name=""
                      // id=""
                      onChange={(e) =>
                        handleInputChange("partyName")(e.target.value)
                      }
                      value={formData.partyName}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      {allParties?.map(
                        (party) =>
                          party.isActive && (
                            <option
                              key={party.id}
                              value={party.partyName
                                .split(" ")
                                .join("-")
                                .toLowerCase()}
                            >
                              {party.partyName}
                            </option>
                          )
                      )}
                    </select>
                    <div className="happyerp-field_icon">
                      <span class="material-symbols-outlined">
                        pan_tool_alt
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Placed by
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow">
                    <input
                      type="text"
                      placeholder="write here"
                      value={user?.displayName}
                      disabled
                    />
                    <div className="happyerp-field_icon">
                      <span class="material-symbols-outlined">draw</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Agent
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow">
                    <select
                      name=""
                      id=""
                      value={formData.agentName}
                      onChange={(e) =>
                        handleInputChange("agentName")(e.target.value)
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      {allAgents?.map(
                        ({ agentName, isActive }, i) =>
                          isActive && (
                            <option
                              key={i}
                              value={agentName
                                .split(" ")
                                .join("-")
                                .toLowerCase()}
                            >
                              {agentName}
                            </option>
                          )
                      )}
                    </select>
                    <div className="happyerp-field_icon">
                      <span class="material-symbols-outlined">
                        pan_tool_alt
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Transport
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow">
                    <select
                      value={formData.transportName}
                      onChange={(e) =>
                        handleInputChange("transportName")(e.target.value)
                      }
                    >
                      <option value="select" disabled selected>
                        Select
                      </option>
                      {transportOptions.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <div className="happyerp-field_icon">
                      <span class="material-symbols-outlined">draw</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Delivery date
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow">
                    <DatePicker
                      selected={formData.deliveryDate}
                      onChange={handleInputChange("deliveryDate")}
                      dateFormat="dd/MM/yy" // Date format
                      placeholderText="dd/mm/yy" // Placeholder text
                      minDate={formData.selectedDate}
                      wrapperClassName="happyerp-react-datepicker-wrapper"
                    />
                    <div className="happyerp-field_icon">
                      <span class="material-symbols-outlined">
                        calendar_month
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Remark
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow">
                    <textarea
                      placeholder="Remark here"
                      value={formData.remark}
                      onChange={(e) =>
                        handleInputChange("remark")(e.target.value)
                      }
                    />
                    <div className="happyerp-field_icon">
                      <span class="material-symbols-outlined">draw</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* // Save and Next button */}
              <div className="happyerp-button_container">
                {isBasicInfoEdit || (!query && !responseId) ? (
                  <button
                    className="happyerp-theme_btn"
                    onClick={handleSaveAndNext}
                    style={{ width: "100%" }}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                ) : (
                  <button
                    className="happyerp-theme_btn"
                    onClick={() => {
                      const orderId = query || responseId;
                      if (orderId) {
                        navigate(`/new-order?orderId=${orderId}`);
                      }
                      // Move to the next step order-details
                      setActiveTab("happyerp-step2");
                    }}
                    style={{ width: "100%" }}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </CSSTransition>
        </Tab>
        <Tab
          eventKey="happyerp-step2"
          disabled={isBasicInfoEdit || !query}
          title={
            <div className="happyerp-step_single">
              <div className="happyerp-number">2</div>
              <h6>Order Detail</h6>
            </div>
          }
        >
          <CSSTransition
            in={activeTab === "happyerp-step2"}
            timeout={1000}
            classNames="happyerp-slide"
            unmountOnExit
          >
            <div className="happyerp-three_ff_one_row row">
              <div className="col-md-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Item*
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow">
                    <Select
                      options={optionsItem}
                      value={selectedItemOptions}
                      onChange={handleItemDropDownChange}
                      placeholder="Select"
                      isSearchable={true}
                    />
                    {/* <div className="field_icon">
                  <span class="material-symbols-outlined">
                    pan_tool_alt
                  </span>
                </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Design*
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow">
                    <Select
                      options={optionsDesign}
                      value={selectedDesignOptions}
                      onChange={handleDesignDropDownChange}
                      placeholder="Select"
                      isSearchable={true}
                    />
                    {/* <select name="" id="">
                  <option value="" selected disabled>
                    Select
                  </option>
                  <option value="">Check</option>
                  <option value="">Circle</option>
                  <option value="">Plain</option>
                  <option value="">Lining</option>
                </select> */}
                    {/* <div className="field_icon">
                  <span class="material-symbols-outlined">
                    pan_tool_alt
                  </span>
                </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4" style={{ width: "100%" }}>
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Sub design*
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow">
                    <Select
                      options={optionsSubDesign}
                      value={selectedSubDesignOptions}
                      onChange={handleSubDesignDropDownChange}
                      placeholder="Select"
                      isSearchable={true}
                    />
                    {/* <div className="field_icon">
                  <span class="material-symbols-outlined">
                    pan_tool_alt
                  </span>
                </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Style
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow happyerp-theme_radio">
                    <div className="happyerp-theme_radio_container">
                      {["2 button", "3 Button", "Long"].map((style) => (
                        <div key={style} className="happyerp-radio_single">
                          <input
                            type="radio"
                            id={style}
                            name="style"
                            checked={style === selectedStyle}
                            onChange={(e) => setSelectedStyle(e.target.value)}
                          />
                          <label htmlFor={style}>{style}</label>
                        </div>
                      ))}
                    </div>
                    <div className="happyerp-field_icon">
                      <span class="material-symbols-outlined">
                        pan_tool_alt
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div>
                  <div className="happyerp-form_field happyerp-with_left_icon">
                    <label htmlFor="" className="happyerp-label">
                      Colors
                    </label>
                    <div className="happyerp-field_box happyerp-field_shadow happyerp-theme_checkbox">
                      <div className="happyerp-theme_checkbox_container">
                        {Object.keys(checkboxStates).map((color) => (
                          <div key={color} className="happyerp-checkbox_single">
                            <input
                              type="checkbox"
                              id={color}
                              name="colors"
                              checked={checkboxStates[color]}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor={color}>{color}</label>
                          </div>
                        ))}
                        <div className="happyerp-select_all_clear_all">
                          {" "}
                          {selectedCount < totalCount ? (
                            <span
                              className="happyerp-text_teal df14 pointer"
                              onClick={handleSelectAll}
                            >
                              Select All
                            </span>
                          ) : null}
                          {selectedCount > 0 && (
                            <span
                              className="happyerp-text_orange df14 pointer"
                              onClick={handleClearAll}
                            >
                              Clear All
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="happyerp-field_icon">
                        <span className="material-symbols-outlined">
                          pan_tool_alt
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="happyerp-form_field happyerp-with_left_icon">
                  <label htmlFor="" className="happyerp-label">
                    Sizes/Quantity
                  </label>
                  <div className="happyerp-field_box happyerp-field_shadow happyerp-theme_idinput">
                    <div className="happyerp-theme_idinput_container">
                      {Object.entries(sizes).map(([sizeName, sizeValue]) => (
                        <div key={sizeName} className="happyerp-idinput_single">
                          <span className="happyerp-idinput_label">
                            {sizeName}
                          </span>
                          <div className="happyerp-idinput_group">
                            <button
                              className="happyerp-decrement_button"
                              onClick={() => handleDecrement(sizeName)}
                            >
                              −
                            </button>
                            <input
                              type="number"
                              value={sizeValue}
                              min={0}
                              onChange={(e) =>
                                handleSizesChange(sizeName, e.target.value)
                              }
                            />
                            <button
                              className="happyerp-increment_button"
                              onClick={() => handleIncrement(sizeName)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      ))}

                      {/* <div className="happyerp-idinput_single">
                        <span className="happyerp-idinput_label">Size 36</span>
                        <div className="happyerp-idinput_group">
                          <button
                            className="happyerp-decrement_button"
                            onClick={handleDecrement}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            value={value}
                            min={1} // Set minimum value to 1
                            onChange={(e) => setValue(parseInt(e.target.value))}
                          />
                          <button
                            className="happyerp-increment_button"
                            onClick={handleIncrement}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="happyerp-idinput_single">
                        <span className="happyerp-idinput_label">Size 38</span>
                        <div className="happyerp-idinput_group">
                          <button
                            className="happyerp-decrement_button"
                            onClick={handleDecrement}
                          >
                            −
                          </button>
                          <input
                            type="number"
                            value={value}
                            min={1} // Set minimum value to 1
                            onChange={(e) => setValue(parseInt(e.target.value))}
                          />
                          <button
                            className="happyerp-increment_button"
                            onClick={handleIncrement}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="happyerp-idinput_single">
                        <span className="happyerp-idinput_label">Size 40</span>
                        <div className="happyerp-idinput_group">
                          <button
                            className="happyerp-decrement_button"
                            onClick={handleDecrement}
                          >
                            −
                          </button>
                          <input
                            type="number"
                            value={value}
                            min={1} // Set minimum value to 1
                            onChange={(e) => setValue(parseInt(e.target.value))}
                          />
                          <button
                            className="happyerp-increment_button"
                            onClick={handleIncrement}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="happyerp-idinput_single">
                        <span className="happyerp-idinput_label">Size 42</span>
                        <div className="happyerp-idinput_group">
                          <button
                            className="happyerp-decrement_button"
                            onClick={handleDecrement}
                          >
                            −
                          </button>
                          <input
                            type="number"
                            value={value}
                            min={1} // Set minimum value to 1
                            onChange={(e) => setValue(parseInt(e.target.value))}
                          />
                          <button
                            className="happyerp-increment_button"
                            onClick={handleIncrement}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="happyerp-idinput_single">
                        <span className="happyerp-idinput_label">Size 44</span>
                        <div className="happyerp-idinput_group">
                          <button
                            className="happyerp-decrement_button"
                            onClick={handleDecrement}
                          >
                            −
                          </button>
                          <input
                            type="number"
                            value={value}
                            min={1} // Set minimum value to 1
                            onChange={(e) => setValue(parseInt(e.target.value))}
                          />
                          <button
                            className="happyerp-increment_button"
                            onClick={handleIncrement}
                          >
                            +
                          </button>
                        </div>
                      </div> */}
                    </div>
                    <div className="happyerp-field_icon">
                      <span class="material-symbols-outlined">draw</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-12 text-end"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  className="happyerp-theme_btn"
                  style={{
                    marginRight: "22px",
                  }}
                  type="button"
                  onClick={handleSaveAndAddMore}
                >
                  Save & Add More
                </button>
                <button
                  className="happyerp-theme_btn"
                  type="button"
                  onClick={handleOrderSubmit}
                >
                  Done
                </button>
              </div>
            </div>
          </CSSTransition>
        </Tab>
        {/* <Tab eventKey="contact" title="Contact" disabled>
              <CSSTransition
                  in={activeTab === 'contact'}
                  timeout={1000}
                  classNames="slide"
                  unmountOnExit
              >
                  <div>Tab content for Contact</div>
              </CSSTransition>
          </Tab> */}
      </Tabs>
    </div>
  );
};

export default HappyerpDataEntryCard;
