import React from "react";

// import css
import "./HappyerpWhyUs.scss";

const HappyerpWhyUs = () => {
  return (
    <section className="happyerp-why_us happyerp-sect_padding">
      <div className="container">
        <div className="happyerp-inline_title_subtitle happyerp-title_div">
          <div className="happyerp-its_left">
            <h6 className="happyerp-small_title_with_line happyerp-text_white">
              WHY CHOOSE US
            </h6>
            <h3 className="happyerp-df45 happyerp-text_white happyerp-semibold">
              Why We're Awesome ERP System?
            </h3>
          </div>
          <div className="happyerp-its_right happyerp-text_white happyerp-regular happyerp-lh26">
            With more than 16 years of experience in this industry we know what
            it's like to be at every stage of a growing business
          </div>
        </div>
        <div className="happyerp-wu_inner">
          <div className="happyerp-wu_single">
            <div className="happyerp-div_first">
              <img src="/assets/img/happyerp/home/wu1.jpg" alt="" />
              <h4 className="happyerp-df25 happyerp-text_white happyerp-medium">
                Simple Straight Forward User Interface Ect
              </h4>
            </div>
            <div className="happyerp-div_second">
              <div>
                <h4 className="happyerp-df20 happyerp-text_black happyerp-lh26 happyerp-medium mb-2">
                  Simple Straight Forward User Interface Ect
                </h4>
                <p className="happyerp-regular happyerp-df15 happyerp-lh22 happyerp-text_black">
                  Migration dolor sit amet conseetur diisci velit sed quia non
                  nuam eius modi tempora incidunt
                </p>
              </div>
            </div>
          </div>
          <div className="happyerp-wu_single">
            <div className="happyerp-div_first">
              <img src="/assets/img/happyerp/home/wu2.jpg" alt="" />
              <h4 className="happyerp-df25 happyerp-text_white happyerp-medium">
                Developer Platform That Tighter Integration
              </h4>
            </div>
            <div className="happyerp-div_second">
              <div>
                <h4 className="happyerp-df20 happyerp-text_black happyerp-lh26 happyerp-medium mb-2">
                  Developer Platform That Tighter Integration
                </h4>
                <p className="happyerp-regular happyerp-df15 happyerp-lh22 happyerp-text_black">
                  Migration dolor sit amet conseetur diisci velit sed quia non
                  nuam eius modi tempora incidunt
                </p>
              </div>
            </div>
          </div>
          <div className="happyerp-wu_single">
            <div className="happyerp-div_first">
              <img src="/assets/img/happyerp/home/wu3.jpg" alt="" />
              <h4 className="happyerp-df25 happyerp-text_white happyerp-medium">
                Migration Wizard To Data From Spreadly
              </h4>
            </div>
            <div className="happyerp-div_second">
              <div>
                <h4 className="happyerp-df20 happyerp-text_black happyerp-lh26 happyerp-medium mb-2">
                  Migration Wizard To Data From Spreadly
                </h4>
                <p className="happyerp-regular happyerp-df15 happyerp-lh22 happyerp-text_black">
                  Migration dolor sit amet conseetur diisci velit sed quia non
                  nuam eius modi tempora incidunt
                </p>
              </div>
            </div>
          </div>
          <div className="happyerp-wu_single">
            <div className="happyerp-div_first">
              <img src="/assets/img/happyerp/home/wu4.jpg" alt="" />
              <h4 className="happyerp-df25 happyerp-text_white happyerp-medium">
                Clear Pricing Plans Gentle Monthly Contract
              </h4>
            </div>
            <div className="happyerp-div_second">
              <div>
                <h4 className="happyerp-df20 happyerp-text_black happyerp-lh26 happyerp-medium mb-2">
                  Clear Pricing Plans Gentle Monthly Contract
                </h4>
                <p className="happyerp-regular happyerp-df15 happyerp-lh22 happyerp-text_black">
                  Migration dolor sit amet conseetur diisci velit sed quia non
                  nuam eius modi tempora incidunt
                </p>
              </div>
            </div>
          </div>
          <div className="happyerp-wu_single">
            <div className="happyerp-div_first">
              <img src="/assets/img/happyerp/home/wu5.jpg" alt="" />
              <h4 className="happyerp-df25 happyerp-text_white happyerp-medium">
                Consult, Onboarding And Training Programs
              </h4>
            </div>
            <div className="happyerp-div_second">
              <div>
                <h4 className="happyerp-df20 happyerp-text_black happyerp-lh26 happyerp-medium mb-2">
                  Consult, Onboarding And Training Programs
                </h4>
                <p className="happyerp-regular happyerp-df15 happyerp-lh22 happyerp-text_black">
                  Migration dolor sit amet conseetur diisci velit sed quia non
                  nuam eius modi tempora incidunt
                </p>
              </div>
            </div>
          </div>
          <div className="happyerp-wu_single">
            <div className="happyerp-div_first">
              <img src="/assets/img/happyerp/home/wu6.jpg" alt="" />
              <h4 className="happyerp-df25 happyerp-text_white happyerp-medium">
                Sell On The With Our Awarded Crm Apps
              </h4>
            </div>
            <div className="happyerp-div_second">
              <div>
                <h4 className="happyerp-df20 happyerp-text_black happyerp-lh26 happyerp-medium mb-2">
                  Sell On The With Our Awarded Crm Apps
                </h4>
                <p className="happyerp-regular happyerp-df15 happyerp-lh22 happyerp-text_black">
                  Migration dolor sit amet conseetur diisci velit sed quia non
                  nuam eius modi tempora incidunt
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HappyerpWhyUs;
