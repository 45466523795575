import React from 'react'
import './CustomerDashboardOrders.scss'

const CustomerDashboardOrders = () => {
  return (
    <div>
        <h1>Customer Orders</h1>
        <h3>Under Process</h3>
    </div>
  )
}

export default CustomerDashboardOrders