import React from "react";
import { Link } from "react-router-dom";

// import css
import "./HappyerpServices.scss";

const HappyerpServices = () => {
  return (
    <div className="happyerp-services happyerp-relative">
      <div className="container">
        <div className="happyerp-services_card">
          <div className="happyerp-sc_single happyerp-with_top_img">
            <div className="happyerp-scs_inner happyerp-border_right">
              <div className="happyerp-images">
                <img src="/assets/img/happyerp/home/services1.png" alt="" />
              </div>
              <div className="happyerp-content">
                <h2>CMS Hub</h2>
                <p>
                  Content management software that's flexible for marketers ect
                </p>
                <ul className="happyerp-list">
                  <li>Website Themes</li>
                  <li>SEO Recommendations</li>
                  <li>Drag-and-Drop Editor</li>
                </ul>
                <Link className="happyerp-theme_btn happyerp-with_icon">
                  Know More
                  <span class="material-symbols-outlined happyerp-btn_icon">
                    arrow_forward
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="happyerp-sc_single happyerp-with_bottom_img">
            <div className="happyerp-scs_inner happyerp-border_right">
              <div className="happyerp-content">
                <h2>Free ERP</h2>
                <p>
                  Everything you need to organize track, and build better
                  relationships
                </p>
                <ul className="happyerp-list">
                  <li>Contact Insights</li>
                  <li>Deals Over Business</li>
                  <li>Tasks Regarding Test</li>
                </ul>
                <Link className="happyerp-theme_btn happyerp-with_icon">
                  Know More
                  <span class="material-symbols-outlined happyerp-btn_icon">
                    arrow_forward
                  </span>
                </Link>
              </div>
              <div className="happyerp-images">
                <img src="/assets/img/happyerp/home/services2.png" alt="" />
              </div>
            </div>
          </div>
          <div className="happyerp-sc_single happyerp-with_top_img">
            <div className="happyerp-scs_inner">
              <div className="happyerp-images">
                <img src="/assets/img/happyerp/home/services3.png" alt="" />
              </div>
              <div className="happyerp-content">
                <h2>Marketing Hub</h2>
                <p>
                  Marketing software to help you grow traffic convert more
                  visitors
                </p>
                <ul className="happyerp-list">
                  <li>Marketing Automation</li>
                  <li>Lead Generation</li>
                  <li>Analytics</li>
                </ul>
                <Link className="happyerp-theme_btn happyerp-with_icon">
                  Know More
                  <span class="material-symbols-outlined happyerp-btn_icon">
                    arrow_forward
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HappyerpServices;
