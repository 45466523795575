import { useState } from 'react';

export function useRazorpay() {
    const [paymentResponse, setPaymentResponse] = useState({
        transactionID: '',
        paymentStatus: 'Pending',
        paymentDetails: null
    });

    const razorpayKeyId = process.env.REACT_APP_RAZORPAY_KEY_ID;

    async function makePayment(orderId, amount, name, description, email, contact) {
        try {
            const razorpayOptions = {
                key: razorpayKeyId, // Replace with your test key
                amount: amount * 100, // amount in paise
                currency: "INR",
                name: 'Smart Wash',
                description: description,
                handler: async function (response) {
                    console.log('Payment success:', response);
                    setPaymentResponse({
                        transactionID: response.razorpay_payment_id,
                        paymentStatus: 'Success',
                        paymentDetails: {
                            amount: amount,
                            currency: 'INR',
                            timestamp: new Date().toISOString()
                        }
                    });
                },
                modal: {
                    ondismiss: function () {
                        setPaymentResponse({
                            transactionID: '',
                            paymentStatus: 'Cancelled',
                            paymentDetails: null
                        });
                    },
                    onfailure: function () {
                        console.log('Payment failed');
                        setPaymentResponse({
                            transactionID: '',
                            paymentStatus: 'Failed'
                        });
                    }
                },
                prefill: {
                    name: name,
                    email: email,
                    contact: contact,
                },
                notes: {
                    orderId: orderId,
                },
                theme: {
                    color: '#348DCB',
                }
            };

            const rzp1 = new window.Razorpay(razorpayOptions);
            rzp1.open();

        } catch (error) {
            console.error('Error in makePayment:', error);
            setPaymentResponse({
                transactionID: '',
                paymentStatus: 'Failed',
                paymentDetails: {
                    error: error.message,
                    timestamp: new Date().toISOString()
                }
            });
        }
    }

    return { makePayment, paymentResponse };
}