import React from "react";
import NavSettings from "../NavSettings";
import ColorSettings from "../ColorSettings";
import TemplateSettings from "../TemplateSettings";
import IconsSettings from "../IconsSettings";
import PageSettings from "../page settings/PageSettings";
import LoaderSetting from "../../admin/LoaderSetting";
import ThemePalette from "../ThemePalette";
import LoginImageSetting from "../LoginImageSetting";
import MultiLocationSetting from "../MultiLocationSetting";

const AppSettings = () => {
  return (
    <div>
      <TemplateSettings />
      <br />
      <hr /> <br />
      <LoaderSetting />
      <br />
      <hr /> <br />
      <IconsSettings />
      <br />
      <hr /> <br />
      <PageSettings />
      <br />
      <hr /> <br />
      <NavSettings />
      <br />
      <hr /> <br />
      <ColorSettings />
      <hr /> <br />
      <ThemePalette />
      <hr /> <br />
      <LoginImageSetting/>
      <hr/><br/>
      <MultiLocationSetting/>
      <br/>
    </div>
  );
};

export default AppSettings;
