import React from 'react'
import './CustomerDashboardHome.scss'

const CustomerDashboardHome = () => {
  return (
    <div>
        <h1>Customer Dashboard</h1>
        <h3>Under Process</h3>
    </div>
  )
}

export default CustomerDashboardHome