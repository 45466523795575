import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// BusinessTwo css start
// import "../businessTwoCss/BusinessTwoColors.scss";
// import "../businessTwoCss/BusinessTwoFont.scss";
// import "../businessTwoCss/BusinessTwoStyle.scss";
// BusinessTwo css end
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import { useDocument } from "../../../../firebase files/hooks/useDocument";
// components
import BusinessTwoHome from "../businessTwoPage/businessTwoHome/BusinessTwoHome";
import Navbar from "../../../../default components/Navbar";
import NavbarBottom from "../../../../default components/NavbarBottom";
import Footer from "../../../../default components/Footer";
import PartnerSlider from "../../../../default components/PartnerSlider";
import Notification from "../../../../default pages/notifications/Notification";
import ColorsComponent from "../../../../default components/ColorsComponent";
import PhoneLoginSignup from "../../../../firebase files/login/PhoneLoginSignup";

// admin
import PGAdminUser from "../../../../allRoles/admin/PGAdminUser";
import AppSettings from "../../../../allRoles/admin/PGAppSettings/AppSettings";
import PGAdminDashboard from "../../../../allRoles/admin/PGAdminDashboard";
// other pages
import PGBusinessThreeProfile from "../../businessThree/businessThreePages/PGBusinessThreeProfile";
import AddPhoto from "../../../../firebase files/AddPhoto";
import AddDocument from "../../../../firebase files/AddDocument";
import ProductDetails from "../../../../default pages/products details/ProductDetails";
import BookingList from "../../../../default pages/bookings/BookingList";
import TicketDetail from "../../../../chatboard/TicketDetail";
import CreateTicket from "../../../../chatboard/CreateTicket";
// Home Three
import AdminSettings from "../../../../allRoles/admin/AdminSettings";
// import BasicAddBooking from "../../../../default pages/add booking/BasicAddBooking";
import CustomerDashboard from "../../../../allRoles/customer/dashboard/CustomerDashboard";
import PGFaq from "../../../../default pages/faq/PGFaq";
import PGProducts from "../../../../default pages/products/PGProducts";
import PrivacyPolicy from "../../../../default pages/privacy policy/PrivacyPolicy";
import PGAboutUs from "../../../../default pages/about us/PGAboutUs";
import TermsAndCondition from "../../../../default pages/terms & condition/TermsAndCondition";
import ContactUs from "../../../../default pages/contact us/ContactUs";
import Team from "../../../../default components/Team";
import FooterUpper from "../../../../default components/FooterUpper";
import PGGallery from "../../../../default pages/gallery/PGGallery";
import FloatingSpeedDial from "../../../../default components/FloatingSpeedDial";
import PGCareer from "../../../../default pages/career/PGCareer";
import PGFeatures from "../../../../default pages/features/PGFeatures";
import BusinessThreeNavbar from "../../businessThree/businessThreeComponents/BusinessThreeNavbar";
import PGDashboard from "../../../../allRoles/dashboards/PGDashboard";

function BusinessTwoRoute() {
  const { user } = useAuthContext();
  const upcomingBookings = 6;
  const confirmedBookings = 100;
  const cancelledBookings = 2;

  const [currentModeStatus, setCurrentModeStatus] = useState("dark");

  const { document: dbTextContentDocuments, error: dbTextContentError } =
    useDocument("settings", "PWA");
  const { document: dbDisplayModeDocuments, error: dbDisplayModeError } =
    useDocument("settings", "mode");
  const { document: dbLaunchingSoonDocuments, error: dbLaunchingSoonError } =
    useDocument("settings", "launchingPage");
  const { document: dbPagesControlDocuments, error: dbPagesControlError } =
    useDocument("settings", "pagesControl");
  const { document: loginPopupDocuments, error: loginPopupError } = useDocument(
    "settings",
    "LoginPopup"
  );
  const { document: dbAppTypeDocuments, error: dbAppTypeError } = useDocument(
    "settings",
    "AppType"
  );
  const { document: dbTemplatesDocuments, error: dbTemplatesError } =
    useDocument("settings", "templates");

  const { document: navTypeDoc } = useDocument("settings", "navbarType");

  const [activeTemplate, setActiveTemplate] = useState(null);

  useEffect(() => {
    const fetchActiveTemplate = async () => {
      try {
        if (dbTemplatesDocuments) {
          setActiveTemplate(dbTemplatesDocuments.activeTemplate);
        } else {
          console.error("No active template found in the database.");
        }
      } catch (error) {
        console.error("Error fetching active template:", error);
      }
    };

    fetchActiveTemplate();
  }, [dbTemplatesDocuments]);

  useEffect(() => {
    if (
      localStorage.getItem("mode") === null ||
      localStorage.getItem("mode") === "null" ||
      localStorage.getItem("mode") === ""
    ) {
      setCurrentModeStatus(dbDisplayModeDocuments?.displayMode);
      localStorage.setItem("mode", dbDisplayModeDocuments?.displayMode);
    }

    const handleStorageChange = () => {
      const storedValue = localStorage.getItem("mode");
      setCurrentModeStatus(storedValue);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dbDisplayModeDocuments]);

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installPopupDiv, setInstallPopupDiv] = useState(true);

  const startChromeInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
    setInstallPopupDiv(false);
  };

  const closeInstallPopup = () => {
    setInstallPopupDiv(false);
  };

  return (
    <>
      {navTypeDoc && navTypeDoc.activeNav == "FixNav" ? (
        <Navbar />
      ) : (
        <BusinessThreeNavbar />
      )}

      <FloatingSpeedDial />
      <Routes>
        <Route path="/" element={<BusinessTwoHome />} />
        <Route path="/adminsettings" element={<AdminSettings />} />
        <Route
          path="/customerdashboard"
          element={
            <CustomerDashboard
              upcomingBookings={upcomingBookings}
              confirmedBookings={confirmedBookings}
              canceledBookings={cancelledBookings}
            />
          }
        />
        <Route
          path="/notifications"
          element={
            user?.status === "active" &&
            (dbAppTypeDocuments?.apptype === "liteplus" ||
              dbAppTypeDocuments?.apptype === "pro") ? (
              <Notification />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/services" element={<PGProducts />} />
        <Route
          path="/product-details/:productId"
          element={<ProductDetails />}
        />
        <Route
          path="/career"
          element={dbPagesControlDocuments?.career ? <PGCareer /> : null}
        />
        <Route path="/our-team" element={<Team />} />
        <Route
          path="/features"
          element={dbPagesControlDocuments?.features ? <PGFeatures /> : null}
        />
        <Route
          path="/admindashboard"
          element={
            user?.role === "admin" ? (
              <PGAdminDashboard />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/sitesettings"
          element={
            user?.role === "admin" ? <AppSettings /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/all-users"
          element={
            user?.role === "admin" ? <PGAdminUser /> : <Navigate to="/login" />
          }
        />

        <Route path="/dashboard" element={<PGDashboard />}></Route>

        <Route
          path="/addphoto"
          element={
            user?.role === "admin" ? <AddPhoto /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/adddocument"
          element={
            user?.role === "admin" ? <AddDocument /> : <Navigate to="/login" />
          }
        />
        <Route path="/aboutus" element={<PGAboutUs />} />
        <Route path="/profile" element={<PGBusinessThreeProfile />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/term-condition" element={<TermsAndCondition />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route
          path="/ticketdetail"
          element={
            user?.status === "active" &&
            (dbAppTypeDocuments?.apptype === "liteplus" ||
              dbAppTypeDocuments?.apptype === "pro") ? (
              <TicketDetail />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/createticket"
          element={
            user?.status === "active" ? (
              <CreateTicket />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/bookinglist"
          element={user ? <BookingList /> : <Navigate to="/login" />}
        />
        <Route path="/gallery" element={<PGGallery />} />
        <Route path="/faq" element={<PGFaq />} />
        <Route path="/partnerslider" element={<PartnerSlider />} />
        <Route
          path="/login"
          element={user ? <Navigate to="/" /> : <PhoneLoginSignup />}
        />
        {/* <Route
          path="/basicaddbooking/:productId"
          element={user ? <BasicAddBooking /> : <PhoneLoginSignup />}
        /> */}
      </Routes>
      <FooterUpper />
      <Footer />
      <NavbarBottom />
      <ColorsComponent />
    </>
  );
}

export default BusinessTwoRoute;
