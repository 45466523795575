import React from "react";
import { useState } from "react";
import GoogleLoginSignup from "./GoogleLoginSignup";
import Adcarousel from "../../default components/Ads";
import { useDocument } from "../hooks/useDocument";
import EmailPasswordLoginSignup from "./EmailPasswordLoginSignup";
import { Link } from "react-router-dom";
import PhoneLoginSignup from "./PhoneLoginSignup";

// styles
import "./PGloginsignup.css";

const PGLoginSignUp = () => {
  const [otpSliderState, setOtpSliderState] = useState(false);
  const [phoneNewUserSliderState, setPhoneNewUserSliderState] = useState(false);
  const [emailNewUserSliderState, setEmailNewUserSliderState] = useState(false);
  const [googleNewUserSliderState, setGoogleNewUserSliderState] =
    useState(false);

  const handleOtpSliderStateChange = (otpState, phoneState) => {
    setOtpSliderState(otpState);
    setPhoneNewUserSliderState(phoneState);
  };

  const handleNewUserEmailSliderState = (newState) => {
    setEmailNewUserSliderState(newState); // Update the newUserEmailSliderState
  };

  const handleNewUserGoogleSliderState = (newState) => {
    setGoogleNewUserSliderState(newState); // Update the newUserGoogleSliderState
  };

  // FIREBASE
  const { document: dbAuthTypeDocuments, error: dbAuthTypeError } = useDocument(
    "settings",
    "AuthType"
  );

  const { document: dbOrganizationDocuments, error: dbOrganizationError } =
    useDocument("settings", "organisation");

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checked state
  };

  return (
    <div className="form_page">
      <div className="container">
        <div className="fp_inner">
          <div className="form-parnt">
            <div>
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-md-6 login-adcarousel-img">
                  <Adcarousel></Adcarousel>
                </div>
                <div className="col-md-6">
                  {dbAuthTypeDocuments &&
                    !(
                      dbAuthTypeDocuments.currentAuth === "PhoneAndGoogle" ||
                      dbAuthTypeDocuments.currentAuth ===
                        "GoogleAndEmailPassword" ||
                      dbAuthTypeDocuments.currentAuth ===
                        "PhoneAndEmailPassword"
                    ) && (
                      <div className="d-flex justify-content-center align-items-center login_pge_logo_div">
                        <img
                          src={
                            dbOrganizationDocuments &&
                            dbOrganizationDocuments.logoUrl
                          }
                          alt="site logo"
                        />
                      </div>
                    )}

                  {/* START CODE FOR LOGIN/SIGNUP VIA PHONE OTP */}
                  {dbAuthTypeDocuments &&
                    (dbAuthTypeDocuments.currentAuth === "Phone" ||
                      dbAuthTypeDocuments.currentAuth === "PhoneAndGoogle" ||
                      dbAuthTypeDocuments.currentAuth ===
                        "PhoneAndEmailPassword") &&
                    !emailNewUserSliderState &&
                    !googleNewUserSliderState && (
                      <PhoneLoginSignup
                        handleOtpSliderStateChange={handleOtpSliderStateChange}
                      />
                    )}
                  {/* END CODE FOR LOGIN/SIGNUP VIA PHONE OTP */}

                  {dbAuthTypeDocuments &&
                    dbAuthTypeDocuments.currentAuth === "PhoneAndGoogle" &&
                    !emailNewUserSliderState &&
                    !googleNewUserSliderState &&
                    !otpSliderState &&
                    !phoneNewUserSliderState && (
                      <div className="login-pge-horizontal-line-with-or-parent">
                        <div className="login-pge-horizontal-line">
                          <div className="login-pge-horizontal-line-with-or">
                            OR
                          </div>
                        </div>
                      </div>
                    )}

                  {/* START CODE FOR LOGIN/SIGNUP VIA GOOGLE */}
                  {dbAuthTypeDocuments &&
                    (dbAuthTypeDocuments.currentAuth === "Google" ||
                      dbAuthTypeDocuments.currentAuth === "PhoneAndGoogle" ||
                      dbAuthTypeDocuments.currentAuth ===
                        "GoogleAndEmailPassword") &&
                    !otpSliderState &&
                    !emailNewUserSliderState &&
                    !phoneNewUserSliderState && (
                      <GoogleLoginSignup
                        handleNewUserGoogleSliderState={
                          handleNewUserGoogleSliderState
                        }
                      />
                    )}
                  {/* END CODE FOR LOGIN/SIGNUP VIA GOOGLE */}

                  {dbAuthTypeDocuments &&
                    (dbAuthTypeDocuments.currentAuth ===
                      "GoogleAndEmailPassword" ||
                      dbAuthTypeDocuments.currentAuth ===
                        "PhoneAndEmailPassword") &&
                    !emailNewUserSliderState &&
                    !googleNewUserSliderState &&
                    !otpSliderState &&
                    !phoneNewUserSliderState && (
                      <div className="login-pge-horizontal-line-with-or-parent">
                        <div className="login-pge-horizontal-line">
                          <div className="login-pge-horizontal-line-with-or">
                            OR
                          </div>
                        </div>
                      </div>
                    )}

                  {/* START CODE FOR LOGIN/SIGNUP VIA EMAIL PASSWORD */}
                  {dbAuthTypeDocuments &&
                    (dbAuthTypeDocuments.currentAuth === "EmailPassword" ||
                      dbAuthTypeDocuments.currentAuth ===
                        "GoogleAndEmailPassword" ||
                      dbAuthTypeDocuments.currentAuth ===
                        "PhoneAndEmailPassword") &&
                    !otpSliderState &&
                    !googleNewUserSliderState &&
                    !phoneNewUserSliderState && (
                      <EmailPasswordLoginSignup
                        handleNewUserEmailSliderState={
                          handleNewUserEmailSliderState
                        }
                      />
                    )}
                  {/* END CODE FOR LOGIN/SIGNUP VIA EMAIL PASSWORD */}

                  <div className="d-flex align-items-center mt-2">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    &nbsp;
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      By proceeding, I agree to&nbsp;
                      {dbOrganizationDocuments &&
                        dbOrganizationDocuments.short_name}
                      &nbsp;
                      <Link
                        to="/term-condition"
                        style={{ color: "var(--click-color)" }}
                      >
                        T&C
                      </Link>
                      &nbsp;&&nbsp;
                      <Link
                        to="/privacypolicy"
                        style={{ color: "var(--click-color)" }}
                      >
                        Privacy Policy
                      </Link>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PGLoginSignUp;
