import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import { projectFirestore } from "../../../firebase config/config";
import { BeatLoader } from "react-spinners";
import { useCollection } from "../../../firebase files/hooks/useCollection";
import { useAuthContext } from "../../../firebase files/hooks/useAuthContext";
//importing scss
import "./UserDetailsLeft.scss";
import ApplyLeave from "../../leaveManagment/ApplyLeave";

const UserDetailsLeft = ({ userProfileId }) => {
  const { user } = useAuthContext(); // Current user

  const [isOpenCoinsPopup, setIsOpenCoinsPopup] = useState(false);
  const [editCoinsFor, setEditCoinsFor] = useState("add");
  const [selectedCoins, setSelectedCoins] = useState(""); // State for date
  const [selectedRedeemCoins, setSelectedRedeemCoins] = useState("");
  const [isAddingCoins, setIsAddingCoins] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState(""); // Stores dropdown selection
  const [coinError, setCoinError] = useState(false);
  const [remarkError, setRemarkError] = useState(false);
  const [otherRemark, setOtherRemark] = useState("");

  // get and update code start
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );

  const { document: userCoinDoc, error: userCoinDocError } = useDocument(
    "cloudCoins",
    userProfileId
  );

  const { documents: CloudCoinsData, errors: cloudCoinsDataError } =
    useCollection("cloudCoins");

  const { updateDocument } = useFirestore("cloudCoins");

  const handleCoinsPopup = () => {
    setIsOpenCoinsPopup(true);
  };

  const handleConfirm = async () => {
    if (!user || !user.uid || !userProfileId) return;

    setIsAddingCoins(true);
    setCoinError(false);
    setRemarkError(false);

    let hasError = false;

    const isRedeem = editCoinsFor === "redeem";
    const coinValue = isRedeem
      ? Number(selectedRedeemCoins)
      : Number(selectedCoins);

    if (!coinValue) {
      setCoinError(true);
      hasError = true;
    }

    // Check if "Other" is selected but no input is given
    const finalRemark =
      selectedRemark === "Other" ? otherRemark : selectedRemark;

    if (!finalRemark) {
      setRemarkError(true);
      hasError = true;
    }

    if (hasError) {
      setIsAddingCoins(false);
      return;
    }

    try {
      // Check if there is a document where userId matches the current user ID
      const existingUserDoc = CloudCoinsData?.find(
        (doc) => doc.id === userProfileId
      );

      const newCoinEntry = {
        createdAt: new Date(),
        createdBy: user.uid,
        remark: finalRemark,
        ...(isRedeem
          ? { redeemedCoin: -coinValue } // Deduct coins for redemption
          : { earnedCoins: coinValue }), // Add coins normally
      };

      if (!existingUserDoc) {
        // If no document exists for the user, create a new one with userId as doc ID
        await projectFirestore
          .collection("cloudCoins")
          .doc(userProfileId)
          .set({
            userId: userProfileId,
            cloudCoinsList: [newCoinEntry], // Create array with first entry
            totalCloudCoins: isRedeem ? -coinValue : coinValue,
          });
        console.log("New cloudCoins document created");
      } else {
        // If document exists, update it
        const updatedCloudCoinsList = [
          ...(existingUserDoc.cloudCoinsList || []),
          newCoinEntry,
        ];

        // Dynamically calculate totalCloudCoins based on all earned coins
        const updatedTotalCloudCoins = updatedCloudCoinsList.reduce(
          (sum, entry) =>
            sum + (entry.earnedCoins || 0) + (entry.redeemedCoin || 0),
          0
        );

        await updateDocument(existingUserDoc.id, {
          cloudCoinsList: updatedCloudCoinsList, // Append new entry
          totalCloudCoins: updatedTotalCloudCoins, // Update total coins
        });

        console.log("cloudCoins document updated");
      }
    } catch (error) {
      console.error("Error updating cloudCoins:", error);
    }

    // Reset form and close popup
    setIsOpenCoinsPopup(false);
    setSelectedCoins("");
    setSelectedRedeemCoins("");
    setSelectedRemark(""); // Reset selected remark
    setOtherRemark(""); // Reset "Other" input
    setIsAddingCoins(false);
  };

  return (
    <div className="full-details-left-profile-card animate-slide-in">
      {/* START POPUP FOR ADD/EDIT CLOUD COINS */}
      {isOpenCoinsPopup && (
        <>
          <div className="parent-add-edit-coins-of-user">
            <Modal
              show={isOpenCoinsPopup}
              onHide={() => setIsOpenCoinsPopup(false)}
              centered
              className="pl-0"
            >
              <Modal.Header
                style={{
                  border: "1px solid grey",
                  display: "block",
                  padding: "7px 20px",
                  margin: "10px",
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className={`btn ${
                      editCoinsFor === "add" ? "btn-primary" : "btn-light"
                    }`}
                    onClick={() => setEditCoinsFor("add")}
                  >
                    Add Coins
                  </div>
                  <div
                    className={`btn ${
                      editCoinsFor === "redeem" ? "btn-primary" : "btn-light"
                    }`}
                    onClick={() => setEditCoinsFor("redeem")}
                  >
                    Redeem Coins
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body className="text-center">
                {editCoinsFor == "add" ? (
                  <div>
                    <br />
                    <div className="form_field pi_input">
                      <label htmlFor="id_tripstart">
                        Number of Coins to add*
                      </label>
                      <input
                        className="custom-input"
                        style={{ paddingRight: "10px" }}
                        type="number"
                        value={selectedCoins}
                        onChange={(e) => setSelectedCoins(e.target.value)}
                      />
                      {coinError && (
                        <div className="field_error">
                          Please enter the number of coins to add.
                        </div>
                      )}
                    </div>
                    <br />
                    <div className="form_field pi_input">
                      <label htmlFor="id_tripstart">Remark*</label>
                      <select
                        value={selectedRemark}
                        onChange={(e) => setSelectedRemark(e.target.value)}
                      >
                        <option value="">Select Remark</option>
                        <option value="For Client Appreciation">
                          For Client Appreciation
                        </option>
                        <option value="CLP Submission">CLP Submission</option>
                        <option value="Host Event">Host Event</option>
                        <option value="Do Webinar">Do Webinar</option>
                        <option value="Other">Other</option>
                      </select>

                      {selectedRemark === "Other" && (
                        <input
                          type="text"
                          placeholder="Enter custom remark"
                          value={otherRemark}
                          onChange={(e) => setOtherRemark(e.target.value)}
                        />
                      )}
                      {remarkError && (
                        <div className="field_error">
                          Please enter a remark.
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <br />
                    <div className="form_field pi_input">
                      <label htmlFor="id_tripstart">
                        Number of Coins to Redeem*
                      </label>
                      <input
                        className="custom-input"
                        style={{ paddingRight: "10px" }}
                        type="number"
                        value={selectedRedeemCoins}
                        onChange={(e) => setSelectedRedeemCoins(e.target.value)}
                      />

                      {coinError && (
                        <div className="field_error">
                          Please enter the number of coins to redeem.
                        </div>
                      )}
                    </div>
                    <br />
                    <div className="form_field pi_input">
                      <label htmlFor="id_tripstart">Remark*</label>
                      <select
                        value={selectedRemark}
                        onChange={(e) => setSelectedRemark(e.target.value)}
                      >
                        <option value="">Select Remark</option>
                        <option value="As a amazon gift voucher">
                          As a amazon gift voucher
                        </option>
                        <option value="Direct Bank Transfer">
                          Direct Bank Transfer
                        </option>
                        <option value="Buy Product">Buy Product</option>
                        <option value="Other">Other</option>
                      </select>

                      {selectedRemark === "Other" && (
                        <input
                          type="text"
                          placeholder="Enter custom remark"
                          value={otherRemark}
                          onChange={(e) => setOtherRemark(e.target.value)}
                        />
                      )}
                      {remarkError && (
                        <div className="field_error">
                          Please enter a remark.
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer
                className="d-flex justify-content-between"
                style={{
                  border: "none",
                  gap: "15px",
                }}
              >
                {!isAddingCoins ? (
                  <>
                    <button
                      className="btn_border"
                      onClick={() => setIsOpenCoinsPopup(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn_fill"
                      onClick={handleConfirm}
                      style={{ color: "white" }}
                    >
                      Confirm
                    </button>
                  </>
                ) : (
                  <BeatLoader color="grey" />
                )}
              </Modal.Footer>
            </Modal>
          </div>
        </>
      )}
      {/* END POPUP FOR ADD/EDIT CLOUD COINS */}

      <div className="profile-photo">
        <img
          src={userProfileDoc?.photoURL ?? "/assets/img/profile-option-1.png"}
          alt="profilt-photo"
        />

        <div style={{ backgroundColor: "#fff" }} className="online-indicator">
          <div
            className={`status-indicator ${
              userProfileDoc?.online ? "online" : "offline"
            }`}
          />
        </div>
      </div>
      <div className="profile-info">
        <h2>{userProfileDoc?.fullName ?? "John Doe"}</h2>
        <div className="contact-info">
          <span>
            {userProfileDoc?.phoneNumber.replace(
              /(\d{2})(\d{5})(\d{5})/,
              "+$1 $2-$3"
            ) ?? "+91 98765-43210"}
          </span>
        </div>
        <div className="contact-info">
          <span>{userProfileDoc?.email ?? "JohnDoe@gmail.com"}</span>
        </div>
        <div className="contact-info">
          <span>
            {userProfileDoc?.city ?? " "}, {userProfileDoc?.country ?? " "}{" "}
          </span>
        </div>
        <div className="contact-methods">
          <Link
            to={`https://wa.me/+${
              userProfileDoc && userProfileDoc.phoneNumber
            }`}
            className="icon right"
          >
            <img src="/assets/img/whatsappbig.png" alt="" />
          </Link>
          <Link
            to={`mailto:${userProfileDoc && userProfileDoc.email}`}
            className="icon left"
          >
            <img src="/assets/img/gmailbig.png" alt="" />
          </Link>
        </div>
        {userProfileDoc?.profileLock && (
          <div className="profile-status">
            <span class="material-symbols-outlined">lock</span>
            <p>This profile is Locked</p>
          </div>
        )}
        {user &&
          (user.role === "hr" ||
            user.role === "superAdmin" ||
            user.role === "admin") && (
            <>
              <br />
              <button className="btn_border" onClick={handleCoinsPopup}>
                Add CC 🪙
              </button>
            </>
          )}

        {user && user.uid === userProfileId && (
          <>
            <br />
            <ApplyLeave />
          </>
        )}
        {user && user.isStaff === true && (
          <>
            {/* <br /> */}
            <div
              className="holiday_coin_and_holiday_parent"
              style={{ backgroundColor: "null" }}
            >
              <div
                className="coin_and_holiday_parent"
                style={{ padding: "12px 0px", backgroundColor: "transparent" }}
              >
                <div className="coins_parent_pgattendance">
                  {user && userCoinDoc && userCoinDoc.totalCloudCoins >= 0 && (
                    <div className="attendance_page_total_coin_parent">
                      <div className="attendance_page_total_coin_box">
                        <div className="attendance_page_coin_info">
                          <div className="attendance_page_coin_icon">
                            <img src="/assets/img/app store/coins-gif.webp" />
                          </div>
                          <div className="attendance_page_coin_text align-items-start">
                            <span className="attendance_page_bonus_text">
                              YOUR CLOUD COINS
                            </span>
                            <span className="attendance_page_coin_value">
                              {user &&
                                userCoinDoc &&
                                userCoinDoc.totalCloudCoins}
                              &nbsp;COINS
                            </span>
                          </div>
                        </div>
                        {user &&
                        userCoinDoc &&
                        userCoinDoc.totalCloudCoins > 0 ? (
                          <button
                            className="attendance_page_claim_button"
                            title="Claim Feature Coming Very Soon!"
                          >
                            CLAIM
                          </button>
                        ) : (
                          <Link to="/LoY7EJFoVDEYEABPQ1eG">
                            <button
                              className="attendance_page_claim_button"
                              title="Claim Feature Coming Very Soon!"
                            >
                              EARN
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserDetailsLeft;
