import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useCollection } from "../firebase files/hooks/useCollection";
import "./HolidayAndCloudCoins.scss";

const HolidayAndCloudCoins = () => {
  const { user } = useAuthContext(); // Current user
  const { documents: dateHolidaysData, errors: holidaysDataError } =
    useCollection("date_holidays");

  const { documents: weekdayHolidaysData, errors: weekdayHolidaysDataError } =
    useCollection("settings");

  const { documents: cloudCoinsData, errors: cloudCoinsDataError } =
    useCollection("cloudCoins");

  // Ensure cloudCoinsData is available before accessing
  const userCloudCoins = cloudCoinsData?.find((doc) => doc.id === user?.uid);

  const [upcomingHoliday, setUpcomingHoliday] = useState(null);
  const [upcomingHolidayRemark, setUpcomingHolidayRemark] = useState(null);

  // START SHOW NEXT PUNCH IN TOMMOROW IN HOLIDAY AVAILIBLE
  // Get today's weekday name
  const weekDaytoday = new Date();
  const todayWeekday = weekDaytoday.toLocaleDateString("en-US", {
    weekday: "long",
  });
  const todayDate = weekDaytoday.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  // Check if today is in weekday_holiday and get the remark
  let DayHolidayRemark = "";
  const isWeekdayHoliday =
    weekdayHolidaysData &&
    weekdayHolidaysData.some((holiday) => {
      if (holiday.id === "weekday_holiday") {
        return holiday.dayName?.some((dayEntry) => {
          if (dayEntry.weekday.toLowerCase() === todayWeekday.toLowerCase()) {
            DayHolidayRemark = dayEntry.remark; // Store remark for the weekday holiday
            return true;
          }
          return false;
        });
      }
      return false;
    });

  // Check if today’s date exists in date_holiday and get the remark
  let DateHolidayRemark = "";

  // Ensure data exists before processing
  const isDateHoliday =
    dateHolidaysData &&
    dateHolidaysData.some((holiday) => {
      // Convert Firestore timestamp to JavaScript Date object
      const holidayTimestamp = new Date(holiday.date.seconds * 1000);
      const holidayFormattedDate = holidayTimestamp.toLocaleDateString(
        "en-US",
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      );

      // Check if holiday matches today's date
      if (holidayFormattedDate === todayDate) {
        DateHolidayRemark = holiday.remark; // Store the remark
        return true;
      }
      return false;
    });

  // Final check: If today is a holiday
  const isHoliday = isWeekdayHoliday || isDateHoliday;
  let finalHolidayRemark = DateHolidayRemark || DayHolidayRemark;
  // END SHOW NEXT PUNCH IN TOMMOROW IN HOLIDAY AVAILIBLE

  useEffect(() => {
    if (dateHolidaysData && dateHolidaysData.length > 0) {
      const today = new Date();

      // Filter upcoming holidays
      const upcomingHolidays = dateHolidaysData
        .map((holiday) => ({
          date: new Date(holiday.date.seconds * 1000),
          remark: holiday.remark,
        }))
        .filter((holiday) => holiday.date >= today) // Keep only future holidays
        .sort((a, b) => a.date - b.date); // Sort by date (ascending)

      if (upcomingHolidays.length > 0) {
        const nextHoliday = upcomingHolidays[0]; // First one is the soonest
        setUpcomingHoliday(
          nextHoliday.date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        );
        setUpcomingHolidayRemark(nextHoliday.remark);
      }
    }
  }, [dateHolidaysData]); // Runs when dateHolidaysData changes
  return (
    <div className="d-flex justify-content-center">
      <div className="holiday_coin_and_holiday_parent">
        <div className="coin_and_holiday_parent">
          <div className="holiday_parent_pgattendance">
            {upcomingHoliday && (
              <div className="holiday_content_pgattendance">
                🎉
                <div className="holiday_text_pgattendance">
                  <p className="holiday_title_pgattendance">
                    <strong style={{ color: "var(--logo-primary-color)" }}>
                      Upcoming Holiday
                    </strong>
                  </p>
                  <p className="holiday_remark_pgattendance">
                    {upcomingHolidayRemark}
                  </p>
                  <p className="holiday_date_pgattendance">{upcomingHoliday}</p>
                </div>
                <div className="holiday_marquee_pgattendance">
                  <marquee behavior="" direction="" scrollamount="4">
                    <span className="d-flex align-items-center">
                      <strong style={{ color: "var(--logo-primary-color)" }}>
                        Upcoming Holiday :
                      </strong>
                      &nbsp;{upcomingHolidayRemark} - {upcomingHoliday}
                    </span>
                  </marquee>
                </div>
              </div>
            )}
          </div>

          <div className="coins_parent_pgattendance">
            {user && userCloudCoins && userCloudCoins.totalCloudCoins >= 0 && (
              <div className="attendance_page_total_coin_parent">
                <div className="attendance_page_total_coin_box">
                  <div className="attendance_page_coin_info">
                    <div className="attendance_page_coin_icon">
                      <img src="/assets/img/app store/coins-gif.webp" />
                    </div>
                    <div className="attendance_page_coin_text">
                      <span className="attendance_page_bonus_text">
                        YOUR CLOUD COINS
                      </span>
                      <span className="attendance_page_coin_value">
                        {user && userCloudCoins.totalCloudCoins} COINS
                      </span>
                    </div>
                  </div>
                  {userCloudCoins.totalCloudCoins > 0 ? (
                    <button
                      className="attendance_page_claim_button"
                      title="Claim Feature Coming Very Soon!"
                    >
                      CLAIM
                    </button>
                  ) : (
                    <Link to="/LoY7EJFoVDEYEABPQ1eG">
                      <button
                        className="attendance_page_claim_button"
                        title="Claim Feature Coming Very Soon!"
                      >
                        EARN
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HolidayAndCloudCoins;
