import React from "react";

// import component
import HappyerpFooter from "../../../happyerpComponents/Footer/HappyerpFooter";
import HappyerpFooterBottom from "../../../happyerpComponents/Footer/HappyerpFooterBottom";
import HappyerpBanner from "../../../happyerpComponents/Home/HappyerpBanner";
import HappyerpServices from "../../../happyerpComponents/Home/HappyerpServices";
import HappyerpBitAboutUs from "../../../happyerpComponents/Home/HappyerpBitAboutUs";
import HappyerpWhyUs from "../../../happyerpComponents/Home/HappyerpWhyUs";
import HappyerpPartners from "../../../happyerpComponents/Home/HappyerpPartners";
import HappyerpCounter from "../../../happyerpComponents/Home/HappyerpCounter";
import HappyerpFeature from "../../../happyerpComponents/Home/HappyerpFeature";
import HappyerpTestimonial from "../../../happyerpComponents/Home/HappyerpTestimonial";

const Home = () => {
  return (
    <div className="happyerp-relative">
      <HappyerpBanner />
      <section className="happyerp-home_services">
        <HappyerpServices />
      </section>
      <HappyerpBitAboutUs />
      <HappyerpWhyUs />
      <HappyerpPartners />
      <HappyerpCounter />
      <HappyerpFeature />
      <HappyerpTestimonial />
      <HappyerpFooter />
      <HappyerpFooterBottom />
    </div>
  );
};

export default Home;
