import React, { useState, useEffect } from "react";

// importing css
import "./HappyerpAboutUs.scss";

//importing components:
// import FooterBottom from "../../../components/footer/FooterBottom";

const handleCardClick = (title) => {
  alert(`You clicked on: ${title}`);
};

const HappyerpAboutUs = () => {
  // Card Data
  const cardData = [
    {
      title: "card1",
      imgSrc: "/assets/img/happyerp/icon/sell.png",
      header: "Sell your products to various customers!",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.",
    },
    {
      title: "card2",
      imgSrc: "/assets/img/happyerp/icon/buy.png",
      header: "Purchase products from various vandors!",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.",
    },
    {
      title: "card3",
      imgSrc: "/assets/img/happyerp/icon/warehouse.png",
      header: "Store your products in our warehouse!",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.",
    },
    {
      title: "card4",
      imgSrc: "/assets/img/happyerp/icon/transport.png",
      header: "Connect with customers and buyers across the nation!",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.",
    },
  ];
  return (
    <div className="aboutus">
      <div className="aboutus_inner">
        <div className="aboutus_bg">
          <div className="aboutus_bg_img">
            <img src="/assets/img/happyerp/aboutus.jpg" alt="aboutus_bg" />
          </div>
          <div className="aboutus_content">
            <div className="aboutus_intro_section">
              <div className="card_section">
                <div className="card_section_header">
                  <div className="aboutus_intro_header">
                    <h5 className="small_title_with_line">ABOUT HAPPY ERP</h5>
                    <h2 className="df45 text_black semibold">
                      We Are Fully Dedicated to providing Excellent Services.
                    </h2>
                  </div>

                  <div className="aboutus_explore_more">
                    <div className="aboutus_explore_more_header ">
                      <h3 className="text_black semibold">
                        Want to do Business with us?
                      </h3>
                      <p className="df15 text_grey regular lh26">
                        There's this notion that to grow a business, you have to
                        be rutless but, we know there's a better way to grow.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cards">
                  {cardData.map((card, index) => (
                    <div
                      key={index}
                      className="card"
                      onClick={() => handleCardClick(card.title)}
                    >
                      <div className="card_header">
                        <img src={card.imgSrc} alt="" />
                      </div>
                      <div className="card_content">
                        <h2 className="card_title">{card.header}</h2>
                        <p className="card_description">{card.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* about us explore section  */}
            <div className="aboutus_explore_more_btn">
              <div className="exp_bg">
                <img
                  src="/assets/img/happyerp/explore_more_bg.jpg"
                  alt="aboutus_explore_more"
                />
              </div>

              <div className="em_text_overlay">
                <h2 className="df45 semibold">
                  Explore the services we provide or Get in touch with us.
                </h2>
                <div className="exp_btns">
                  <button className="theme_btn ">Explore More</button>
                  <button className="theme_btn ">Contact Us</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="aboutus_bottom">
        <FooterBottom />
      </div> */}
    </div>
  );
};

export default HappyerpAboutUs;
