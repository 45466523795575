import React from "react";
import { useState, useEffect } from "react";
import "./TodayAttendanceData.scss";
import { useNavigate } from "react-router-dom";
import { useCollection } from "../firebase files/hooks/useCollection";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import CurrentDateTime from "./CurrentDateTime";
import { format } from "date-fns";
import { projectFirestore } from "../firebase config/config";

const TodayAttendanceData = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const redirectOnAttendance = () => {
    navigate(`attendance`);
  };

  const { addDocument, updateDocument, deleteDocument, error } =
    useFirestore("attendances");

  const { documents: dateHolidaysData, errors: holidaysDataError } =
    useCollection("date_holidays");

  const { documents: weekdayHolidaysData, errors: weekdayHolidaysDataError } =
    useCollection("settings");

  const [attendanceData, setAttendanceData] = useState(null);

  // Format today's date as "02-Feb-25"
  const todayDate = format(new Date(), "dd-MMM-yy");
  const todayDay = format(new Date(), "EEEE"); // Example: Monday

  useEffect(() => {
    if (user) {
      fetchAttendance();
    }
  }, [user]); // ✅ Runs only when `user` changes

  const fetchAttendance = async () => {
    try {
      const snapshot = await projectFirestore
        .collection("attendances")
        .where("userId", "==", user.uid)
        .where("date", "==", todayDate)
        .get();

      if (snapshot.empty) {
        console.log("No document available for today.");
        setAttendanceData(null);
      } else {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAttendanceData(data[0]); // ✅ Store first document in state
        console.log("Today's Attendance Data:", data);
      }
    } catch (err) {
      console.error("Error fetching attendance:", err);
    }
  };

  // START SHOW NEXT PUNCH IN TOMMOROW IN HOLIDAY AVAILIBLE
  // Get today's weekday name
  const weekDaytoday = new Date();
  const todayWeekday = weekDaytoday.toLocaleDateString("en-US", {
    weekday: "long",
  });
  const todayCurrentDate = weekDaytoday.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // Check if today is in weekday_holiday and get the remark
  let DayHolidayRemark = "";
  const isWeekdayHoliday =
    weekdayHolidaysData &&
    weekdayHolidaysData.some((holiday) => {
      if (holiday.id === "weekday_holiday") {
        return holiday.dayName?.some((dayEntry) => {
          if (dayEntry.weekday.toLowerCase() === todayWeekday.toLowerCase()) {
            DayHolidayRemark = dayEntry.remark; // Store remark for the weekday holiday
            return true;
          }
          return false;
        });
      }
      return false;
    });

  // Check if today’s date exists in date_holiday and get the remark
  let DateHolidayRemark = "";

  // Ensure data exists before processing
  const isDateHoliday =
    dateHolidaysData &&
    dateHolidaysData.some((holiday) => {
      // Convert Firestore timestamp to JavaScript Date object
      const holidayTimestamp = new Date(holiday.date.seconds * 1000);
      const holidayFormattedDate = holidayTimestamp.toLocaleDateString(
        "en-US",
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      );

      // Check if holiday matches today's date
      if (holidayFormattedDate === todayCurrentDate) {
        DateHolidayRemark = holiday.remark; // Store the remark
        return true;
      }
      return false;
    });

  // Final check: If today is a holiday
  const isHoliday = isWeekdayHoliday || isDateHoliday;
  let finalHolidayRemark = DateHolidayRemark || DayHolidayRemark;
  // END SHOW NEXT PUNCH IN TOMMOROW IN HOLIDAY AVAILIBLE

  return (
    <div
      className="today_attendance_data_parent"
      onClick={redirectOnAttendance}
    >
      <div className="today_attendance_data_date_left">
        <h5>
          {todayDate.slice(0, 2)}
          <br />
          {todayDay.slice(0, 3)}
        </h5>
      </div>

      {/* STEPS START */}
      <div className="today_attendance_data_steps">
        {/* Step 1: Punch In */}
        <div className="today_attendance_data_step completed">
          <div className="today_attendance_data_icon check">
            <img src="/assets/img/icons/punchin.png" alt="" />
          </div>
          <div className="today_attendance_data_div">
            <div className="today_attendance_data_text">
              <p>
                Punch In &nbsp;
                {attendanceData && attendanceData.punchIn
                  ? `| ${attendanceData.punchIn}`
                  : ""}
              </p>
            </div>
            <span className="today_attendance_data_status">
              {!attendanceData || !attendanceData.punchInLocation
                ? "--:--"
                : attendanceData.punchInLocation}
            </span>
          </div>
        </div>

        {/* Step 2: Break Time */}
        <div className="today_attendance_data_step in-progress">
          <div className="today_attendance_data_icon check">
            <img src="/assets/img/icons/total_work.png" alt="" />
          </div>
          <div className="today_attendance_data_div">
            <div className="today_attendance_data_text">
              <p>Hr Worked</p>
            </div>
            <span
              className={`today_attendance_data_status ${
                !attendanceData ||
                !attendanceData.workHrs ||
                attendanceData.workHrs === "00:00"
                  ? ""
                  : (() => {
                      const [hrs, mins] = attendanceData.workHrs
                        .split(":")
                        .map(Number);
                      if (hrs < 4) return "work-hr-below-four";
                      if (hrs >= 4 && hrs < 6)
                        return "work-hr-between-four-six";
                      if (hrs >= 6 && hrs < 10)
                        return "work-hr-between-six-ten";
                      return "work-hr-between-ten-above";
                    })()
              }`}
            >
              {!attendanceData ||
              !attendanceData.workHrs ||
              attendanceData.workHrs === "00:00"
                ? "--:--"
                : `${attendanceData.workHrs.split(":")[0]} hr : ${
                    attendanceData.workHrs.split(":")[1]
                  } min`}
            </span>
          </div>
        </div>

        {/* Step 3: Punch Out */}
        <div className="today_attendance_data_step pending">
          <div className="today_attendance_data_icon in-progress">
            <img src="/assets/img/icons/punchout.png" alt="" />
          </div>
          <div className="today_attendance_data_div">
            <div className="today_attendance_data_text">
              <p>
                Punch Out &nbsp;
                {attendanceData && attendanceData.punchOut
                  ? `| ${attendanceData.punchOut}`
                  : ""}
              </p>
            </div>
            <span className="today_attendance_data_status">
              {!attendanceData || !attendanceData.punchOutLocation
                ? "--:--"
                : attendanceData.punchOutLocation}
            </span>
          </div>
        </div>
      </div>
      {/* STEPS END */}

      <div className="today_attendance_data_page_btn">
        <div className="today_attendance_data_page_status">
          {isHoliday ? (
            <p> {finalHolidayRemark}</p>
          ) : (
            <>
              {!attendanceData?.punchIn ? (
                <p>Punch In Pending!</p>
              ) : !attendanceData?.punchOut ? (
                <p>Punch Out Pending!</p>
              ) : (
                <p>Attendance Marked Successfully🎉</p>
              )}
            </>
          )}
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="currentColor"
        >
          <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
        </svg>
      </div>

      <div className="today_attendance_hover_open_card">
        {isHoliday ? (
          <p> {finalHolidayRemark}</p>
        ) : (
          <>
            {!attendanceData?.punchIn ? (
              <p>Punch In Pending!</p>
            ) : !attendanceData?.punchOut ? (
              <p>Punch Out Pending!</p>
            ) : (
              <p>Attendance Marked Successfully🎉</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TodayAttendanceData;
