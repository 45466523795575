import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "../../../../default components/Navbar";
import PGBusinessThreeHome from "../businessThreePages/PGBusinessThreeHome";
import ColorsComponent from "../../../../default components/ColorsComponent";
import FooterUpper from "../../../../default components/FooterUpper";
import Footer from "../../../../default components/Footer";
import "../businessThreeCss/BusinessThreeStyle.scss";
import GlobalRoute from "../../../../global route/GlobalRoute";
import PGBusinessThreeAboutUs from "../businessThreePages/PGBusinessThreeAboutUs";
import BusinessThreeNavbar from "../businessThreeComponents/BusinessThreeNavbar";
import PGBusinessThreeProfile from "../businessThreePages/PGBusinessThreeProfile";
import { useDocument } from "../../../../firebase files/hooks/useDocument";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import PGDashboard from "../../../../allRoles/dashboards/PGDashboard";

const BusinessThreeRoute = () => {
  const {user}=useAuthContext();
  const { document: navTypeDoc } = useDocument("settings", "navbarType");

  return (
    <div>
      <>
      {navTypeDoc && navTypeDoc.activeNav == "FixNav" ? <Navbar /> : <BusinessThreeNavbar />}

        <Routes>
          <Route path="/" element={<PGBusinessThreeHome />}></Route>
          <Route path="/aboutus" element={<PGBusinessThreeAboutUs />} />
          <Route path="/proflie" element={<PGBusinessThreeProfile />} />
          <Route path="/profile" element={<PGBusinessThreeProfile />}/>
          <Route path="/dashboard" element={<PGDashboard />}></Route>
        </Routes>
        <ColorsComponent />
        <GlobalRoute />
        <FooterUpper />
        <Footer />
      </>
    </div>
  );
};

export default BusinessThreeRoute;
