import React, { useState, useEffect } from "react";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import UserDetailsUploadDoc from "./UserDetailsUploadDoc";

const UserDetailsParents = ({ userProfileId }) => {
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );
  const { updateDocument } = useFirestore("users");

  const [isEditing, setIsEditing] = useState(false);
  const [isParentSaving, setIsParentSaving] = useState(false);
  const [saveParentMessage, setSaveParentMessage] = useState("");
  const [parentMessageType, setParentMessageType] = useState("");

  const [parentFormData, setParentFormData] = useState({
    father: { name: "", dob: "", document: null },
    mother: { name: "", dob: "", document: null },
  });

  useEffect(() => {
    if (userProfileDoc) {
      setParentFormData({
        father: {
          name: userProfileDoc?.parentFormData?.father?.name || "",
          dob: userProfileDoc?.parentFormData?.father?.dob || "",
        },
        mother: {
          name: userProfileDoc?.parentFormData?.mother?.name || "",
          dob: userProfileDoc?.parentFormData?.mother?.dob || "",
        },
      });
    }
  }, [userProfileDoc]);

  // Validate Fields
  const validateForm = () => {
    if (
      !parentFormData.father.name ||
      !parentFormData.father.dob ||
      !parentFormData.mother.name ||
      !parentFormData.mother.dob
    ) {
      setSaveParentMessage("Please fill all required fields.");
      setParentMessageType("error_msg");
      setTimeout(() => {
        setSaveParentMessage("");
        setParentMessageType("");
      }, 4000);
      return false;
    }
    return true;
  };

  // // Submit Form Data
  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsParentSaving(true);
    setSaveParentMessage("");

    try {
      const updatedData = {
        ...userProfileDoc,
        parentsDetails: parentFormData,
      };

      await updateDocument(userProfileId, updatedData);
      setParentMessageType("success_msg");
      setSaveParentMessage("Updated successfully!");

      setTimeout(() => {
        setIsEditing(false);
      }, 4000);
    } catch (error) {
      console.error("Error updating parents details:", error);
      setParentMessageType("error_msg");
      setSaveParentMessage(
        "Failed to update parents details. Please try again."
      );
    } finally {
      setIsParentSaving(false);
      setTimeout(() => {
        setSaveParentMessage("");
        setParentMessageType("");
      }, 4000);
    }
  };
  return (
    <div className="pd-cards animate-slide-in">
      <div className="pd-heading">
        <h2>Parents Information</h2>
        {!userProfileDoc?.profileLock && !isEditing && (
          <div
            className="pd-edit-state"
            onClick={() => setIsEditing((prev) => !prev)}
          >
            <span className="material-symbols-outlined">edit</span> Edit
          </div>
        )}
      </div>

      <div className="parent-information-card">
        {/* Father Info Section */}
        <div className="info-section">
          <div className="info-header">
            <h5>Father Info</h5>
          </div>
          {!isEditing ? (
            <>
              <div className="parent-data mb-4">
                <div className="parent-data-element">
                  <div className="parent-icon">
                    <img src="/assets/img/user.png" alt="" />
                  </div>
                  <div className="parent-data-value">
                    <h6>Father's Name</h6>
                    <p>
                      {userProfileDoc?.parentsDetails?.father?.name ??
                        "Not provided yet"}
                    </p>
                  </div>
                </div>

                <div className="parent-data-element">
                  <div className="parent-icon">
                    <img src="/assets/img/VisitingDays.png" alt="icon" />
                  </div>
                  <div className="parent-data-value">
                    <h6>Father's DOB</h6>
                    <p>
                      {userProfileDoc?.parentsDetails?.father?.dob ??
                        "Not provided yet"}
                    </p>
                  </div>
                </div>
              </div>
              <UserDetailsUploadDoc
                selectedDocType="Father Aadhaar Card"
                userProfileDoc={userProfileDoc}
                userProfileId={userProfileId}
                updateDocument={updateDocument}
                folderName="father-doc"
                style={{ width: "28rem" }}
                title={"Aadhaar Card"}
              />
            </>
          ) : (
            <>
              <div className="row row_gap mt-4 p-0">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-field">
                    <label className="input-label required-field">
                      Father Name
                    </label>
                    <input
                      type="text"
                      className="form-input"
                      value={parentFormData.father.name}
                      onChange={(e) => {
                        setParentFormData((prev) => ({
                          ...prev,
                          father: {
                            ...prev.father,
                            name: e.target.value,
                          },
                        }));
                      }}
                      placeholder="Enter Father's Name"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-field">
                    <label className="input-label required-field">
                      Father DOB
                    </label>
                    <input
                      type="date"
                      className="form-input"
                      value={parentFormData.father.dob}
                      onChange={(e) => {
                        setParentFormData((prev) => ({
                          ...prev,
                          father: {
                            ...prev.father,
                            dob: e.target.value,
                          },
                        }));
                      }}
                      placeholder="Enter Father's Date of Birth"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Mother Info Section */}
        <div className="info-section">
          <div className="info-header">
            <h5>Mother Info</h5>
          </div>
          {!isEditing ? (
            <>
              <div className="parent-data mb-4">
                <div className="parent-data-element">
                  <div className="parent-icon">
                    <img src="/assets/img/user.png" alt="" />
                  </div>
                  <div className="parent-data-value">
                    <h6>Mother's Name</h6>
                    <p>
                      {userProfileDoc?.parentsDetails?.mother?.name ??
                        "Not provided yet"}
                    </p>
                  </div>
                </div>

                <div className="parent-data-element">
                  <div className="parent-icon">
                    <img src="/assets/img/VisitingDays.png" alt="icon" />
                  </div>
                  <div className="parent-data-value">
                    <h6>Mother's DOB</h6>
                    <p>
                      {userProfileDoc?.parentsDetails?.mother?.dob ??
                        "Not provided yet"}
                    </p>
                  </div>
                </div>
              </div>
              <UserDetailsUploadDoc
                selectedDocType="Mother Aadhaar Card"
                userProfileDoc={userProfileDoc}
                userProfileId={userProfileId}
                updateDocument={updateDocument}
                folderName="mother-doc"
                style={{ width: "28rem" }}
                title={"Aadhaar Card"}
              />
            </>
          ) : (
            <>
              <div className="row row_gap mt-4 p-0">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-field">
                    <label className="input-label required-field">
                      Mother Name
                    </label>
                    <input
                      type="text"
                      className="form-input"
                      value={parentFormData.mother.name}
                      onChange={(e) => {
                        setParentFormData((prev) => ({
                          ...prev,
                          mother: {
                            ...prev.mother,
                            name: e.target.value,
                          },
                        }));
                      }}
                      placeholder="Enter Mother's Name"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-field">
                    <label className="input-label required-field">
                      Mother DOB
                    </label>
                    <input
                      type="date"
                      className="form-input"
                      value={parentFormData.mother.dob}
                      onChange={(e) => {
                        setParentFormData((prev) => ({
                          ...prev,
                          mother: {
                            ...prev.mother,
                            dob: e.target.value,
                          },
                        }));
                      }}
                      placeholder="Enter Mother's Date of Birth"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {isEditing && (
          <div className="pd-edit-option mt-4">
            {saveParentMessage && (
              <p className={`edit-msg_area ${parentMessageType}`}>
                {saveParentMessage}
              </p>
            )}

            <button
              onClick={() => setIsEditing((prev) => !prev)}
              disabled={isParentSaving}
              className={` pd-option-btn option-btn-cancel ${
                isParentSaving ? "disabled" : ""
              }`}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              disabled={isParentSaving}
              className={` pd-option-btn option-btn-save ${
                isParentSaving ? "disabled" : ""
              }`}
            >
              {isParentSaving ? "Saving..." : "Save"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailsParents;
