import React, { useState } from "react";
import DataEntryCard from "../../../happyerpComponents/dataEntryCard/HappyerpDataEntryCard";
import CardDetailWrapper from "../../../happyerpComponents/cardDetail/HappyerpCardDetailWrapper"; //
import { useSearchParams } from "react-router-dom";
import { useDocument } from "../../../../../firebase files/hooks/useDocument";

const HappyerpNewSalesOrder = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("orderId") || null;
  console.log(query);
  const { document: saleOrder } = useDocument(
    "sales_order",
    query ?? undefined
  );
  const [orders, setOrders] = useState([]);
  console.log(orders);
  return (
    <div className="happyerp-sales_pg happyerp-pg_margin happyerp-pg_bg happyerp-form_show">
      <div className="happyerp-add_order_pg">
        {/* <div className="card_padding card_shadow card_bg overflow-hidden relative form_area">
      <div className="close_form" onClick={handleCreateForm}>
        <span class="material-symbols-outlined">close</span>
      </div>
      <form action=""></form>
    </div> */}
        <div className="happyerp-main_area_left">
          <CardDetailWrapper saleOrder={saleOrder} orders={orders} />
        </div>

        <div className="happyerp-order_summary happyerp-card_bg happyerp-card_shadow">
          <DataEntryCard
            query={query}
            saleOrder={saleOrder}
            setOrders={setOrders}
            orders={orders}
          />
        </div>
      </div>
    </div>
  );
};

export default HappyerpNewSalesOrder;
