import { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { projectFirestore, timestamp } from "../../firebase config/config";
import { useFirestore } from "../hooks/useFirestore";
import { useSignupPhone } from "../hooks/useSignupPhone";
import { BeatLoader } from "react-spinners";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useAuthContext } from "../hooks/useAuthContext";

// FIREBASE
import { useDocument } from "../hooks/useDocument";

import { displayName } from "react-quill";

function camelCase(str) {
  return (
    str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      // .replace(/\s/g, '')
      .replace(/^(.)/, function (b) {
        return b.toUpperCase();
      })
  );
}

export default function PhoneLoginSignup({ handleOtpSliderStateChange }) {
  const handleWheel = (e) => {
    e.preventDefault();
  };

  const { loggedInUser } = useAuthContext();

  const { document: dbPageNavigationDocuments, error: dbPageNavigationError } =
    useDocument("settings", "PageNavigations");

  const navigate = useNavigate();

  // use states
  const [user, setUser] = useState();
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [country, setCountryName] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState("");
  const [otpTimer, setOtpTimer] = useState(20);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const { setUpRecapcha, resendOTP } = useSignupPhone();
  const [confirmObj, setConfirmObj] = useState("");
  const [userName, setUserName] = useState("");
  const [mobilenoSliderState, setmobilenoSliderState] = useState(true);
  const [otpSliderState, setotpSliderState] = useState(false);
  const [phoneNewUserSliderState, setPhoneNewUserSliderState] = useState(false);
  const [genderSelectionSliderState, setGenderSelectionSliderState] =
    useState(true);
  const [newUserDetailsSliderState, setNewUserDetailsSliderState] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const { updateDocument, response: responseUpdateDocument } =
    useFirestore("users");

  // Effect to detect changes in otpSliderState and pass the updated value to parent
  useEffect(() => {
    handleOtpSliderStateChange(otpSliderState, phoneNewUserSliderState);
  }, [otpSliderState, phoneNewUserSliderState, handleOtpSliderStateChange]);

  useEffect(() => {
    let interval;
    if (otpSliderState && otpTimer > 0) {
      interval = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (otpTimer === 0) {
      setIsResendDisabled(false); // Enable the Resend OTP button
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [otpSliderState, otpTimer]);

  // GENDER SELECTION START
  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setGenderSelectionSliderState(false);
    setNewUserDetailsSliderState(true);
  };
  // GENDER SELECTION END

  //   send opt
  const getOTP = async (e) => {
    e.preventDefault();
    setOtpTimer(60); // Reset Timer
    setIsResendDisabled(true);
    setError("");
    if (phone === "" || phone === undefined || phone.length < 10) {
      return setError("Please enter valid mobile number");
    }

    try {
      setIsLoading(true);
      let btnSendOTP = document.getElementById("btn_sendotp");
      btnSendOTP.style.display = "none";
      const respons = await setUpRecapcha("+" + phone);
      setConfirmObj(respons);
      setmobilenoSliderState(false);
      setotpSliderState(true);
      setIsLoading(false);
      // setPhoneNewUserSliderState(false);
    } catch (error) {
      console.log("2 error.message", error.message);
      setError(error.message);
      await resendOTP("+" + phone);
      setIsLoading(false); // Stop the loader
    }
  };

  // OTP verify
  const verifyOTP = async (e) => {
    e.preventDefault();
    setmobilenoSliderState(false);
    if (otp === "" || otp === undefined || otp === null || otp < 6) {
      return setError("Please enter six digit OTP");
    }
    setIsLoading(true); // Start the loader
    setError("");

    try {
      await confirmObj.confirm(otp).then(async (result) => {
        const user = result.user;
        setUser(user);

        // Check if the user is new
        if (result.additionalUserInfo.isNewUser) {
          setUserName(user.displayName);

          // Split the full name by space
          let splitName = userName.split(" ");

          // Extract the first name
          let firstName = splitName[0];

          let imgUrl = "/assets/img/dummy_user.png";

          await user.updateProfile({
            phoneNumber: phone,
            displayName: firstName,
            photoURL: imgUrl,
          });

          projectFirestore
            .collection("users")
            .doc(user.uid)
            .set({
              online: true,
              displayName: firstName,
              fullName: userName,
              gender: "",
              phoneNumber: phone,
              email: "",
              city,
              address,
              country,
              countryCode,
              isStaff: true,
              role: "customer",
              roles: ["customer"],
              photoURL: imgUrl,
              accessType: "country",
              accessValue: "India",
              status: "active",
              createdAt: timestamp.fromDate(new Date()),
              lastLoginTimestamp: timestamp.fromDate(new Date()),
            });

          setPhoneNewUserSliderState(true);
          setotpSliderState(false);
          setIsLoading(false);
        } else {
          console.log("Existing user signed in with phone number");
          setIsLoading(true);

          await updateDocument(user.uid, {
            online: true,
            lastLoginTimestamp: timestamp.fromDate(new Date()),
          });

          console.log("user role", user);
          console.log("logged in user", loggedInUser);

          if (dbPageNavigationDocuments) {
            if (dbPageNavigationDocuments.afterLogin === "") {
              navigate("/profile");
            } else {
              navigate(dbPageNavigationDocuments.afterLogin);
            }
          } else {
            navigate("/profile"); // Redirect to profile if the document doesn't exist
          }

          setotpSliderState(false);
          setPhoneNewUserSliderState(false);
          setGenderSelectionSliderState(false);
          setNewUserDetailsSliderState(false);
          setIsLoading(false); // Stop the loader
        }
      });
    } catch (error) {
      console.log("error.message", error.message);
      setError(
        "Given OTP is not valid, please enter the valid OTP sent to your number"
      );
      setIsLoading(false);

      setTimeout(function () {
        setError("");
      }, 30000);
    }
  };

  const handleResendOTP = async () => {
    if (isResendDisabled) return; // Prevent multiple clicks

    try {
      setError("Sending OTP...");
      await resendOTP("+" + phone);
      setError("OTP sent successfully!");
      setTimeout(() => setError(""), 60000);
      setOtpTimer(7);
    } catch (error) {
      console.log("Error resending OTP:", error.message);
      setError("Failed to resend OTP. Please try again.");
    }
  };

  // Toggle OTP visibility
  const toggleOtpVisibility = () => {
    setIsOtpVisible(!isOtpVisible);
  };

  // New User Form
  const newUserForm = async () => {
    // Basic validations
    if (!name.trim()) {
      setError("Name is required.");
      return;
    }

    // Email Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setError("No email provided.");
      return;
    } else if (!emailRegex.test(email)) {
      setError("Invalid email format.");
      return;
    }

    if (!city.trim()) {
      setError("City is required.");
      return;
    }

    setmobilenoSliderState(false);
    setotpSliderState(false);
    setGenderSelectionSliderState(false);
    setIsLoading(true);

    let errFlag = false;
    if (!errFlag) {
      let splitName = name.split(" ");

      // Extract the first name
      displayName = splitName.length > 0 ? splitName[0] : name;

      await updateDocument(user.uid, {
        displayName: camelCase(displayName.toLowerCase()),
        fullName: camelCase(name.toLowerCase()),
        email,
        gender: selectedGender,
        city: camelCase(city.toLowerCase()),
      });

      if (dbPageNavigationDocuments) {
        if (dbPageNavigationDocuments.afterLogin === "") {
          navigate("/profile");
        } else {
          navigate(dbPageNavigationDocuments.afterLogin);
        }
      } else {
        navigate("/profile"); // Redirect to profile if the document doesn't exist
      }

      setPhoneNewUserSliderState(false);
      setIsLoading(false); // Stop the loader
    }
  };

  const handlePhoneChange = (value, countryData) => {
    // setPhone(value);
    // setCountry(countryData);
    console.log(
      "value: ",
      value + " country code: ",
      countryData.countryCode + ", country name: ",
      countryData.name
    );
    setPhone(value);
    setCountryCode(countryData.countryCode);
    setCountryName(countryData.name);
  };

  return (
    <div>
      <div>
        {mobilenoSliderState && (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="mt-4 mb-2">Mobile Number</h5>
            </div>

            <div className="new_form_field with_icon phoneinput">
              <div>
                <PhoneInput
                  country={"in"}
                  // onlyCountries={['in', 'us', 'ae']}
                  value={phone}
                  onChange={handlePhoneChange}
                  international
                  keyboardType="phone-pad"
                  // countryCallingCodeEditable={false}
                  countryCodeEditable={true}
                  // disableCountryCode={true}
                  placeholder="Country code + mobile number"
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: false,
                  }}
                  inputStyle={{
                    width: "100%",
                    height: "45px",
                    paddingLeft: "45px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "1px solid var(--click-color)",
                  }}
                ></PhoneInput>
                {error && (
                  <Alert variant="danger" className="mt-2">
                    {error}
                  </Alert>
                )}
              </div>
            </div>

            <div
              id="recapcha-container"
              style={{
                marginTop: "20px",
              }}
            ></div>

            <div className="">
              {!isLoading && (
                <>
                  <div className="d-flex justify-content-center flex-column">
                    <button
                      className="btn_fill"
                      id="btn_sendotp"
                      onClick={getOTP}
                    >
                      OTP
                    </button>
                  </div>
                </>
              )}
            </div>
            {isLoading && (
              <div className="text-center">
                <h6 className="text_green mb-2">Sending OTP</h6>
                <BeatLoader color={"var(--click-color)"} loading={true} />
              </div>
            )}
          </>
        )}

        {/* start otp section */}
        {otpSliderState && (
          <div className="d-flex justify-content-center flex-column align-items-center mt-4">
            <label htmlFor="" className="d-flex align-items-center mb-2">
              Enter 6 digit OTP
              <span
                onClick={toggleOtpVisibility}
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  fontSize: "24px",
                  userSelect: "none",
                }}
                className="material-symbols-outlined"
              >
                {isOtpVisible ? "visibility" : "visibility_off"}
              </span>
            </label>

            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={
                <span style={{ margin: "10px 5px 20px 5px" }}>-</span>
              }
              renderInput={(props) => (
                <input
                  {...props}
                  type={isOtpVisible ? "text" : "password"}
                  onWheel={handleWheel}
                  inputMode="numeric"
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "1px solid gray",
                    textAlign: "center",
                    borderRadius: "5px",
                    margin: "10px 0px 20px 0px",
                  }}
                />
              )}
            />
            <p style={{ marginBottom: "10px" }}>
              {otpTimer === 0 ? (
                <>
                  Didn't get the OTP?&nbsp;
                  <span
                    onClick={handleResendOTP}
                    style={{
                      color: "var(--click-color)",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Resend
                  </span>
                </>
              ) : (
                <>
                  You can request OTP in :&nbsp;
                  <span
                    style={{ color: "var(--click-color)", fontWeight: "700" }}
                  >
                    {otpTimer}
                  </span>
                </>
              )}
            </p>
            {error && (
              <Alert variant="danger" className="mt-2">
                {error}
              </Alert>
            )}

            {!isLoading && (
              <div className="d-flex justify-content-center flex-column w-100">
                <button className="btn_fill" onClick={verifyOTP}>
                  Confirm
                </button>
              </div>
            )}
            {isLoading && (
              <div className="text-center">
                <h6 className="text_green mb-2">Sign In</h6>
                <BeatLoader color="var(--click-color)" loading={true} />
              </div>
            )}
          </div>
        )}
        {/* end otp section */}

        {/* IF USER IS NEW  */}
        {phoneNewUserSliderState && (
          <div>
            <div className="">
              {/* START GENDER SELECTION SLIDER */}
              <div>
                {genderSelectionSliderState && (
                  <div className="login-gender-selection-slider">
                    <h5 className="mb-4">Select Your Gender</h5>
                    <div className="login-gender-options">
                      <div
                        className={`login-gender-option ${
                          selectedGender === "male"
                            ? "login-gender-selected"
                            : ""
                        }`}
                        onClick={() => handleGenderSelect("male")}
                      >
                        <img src="assets/img/men-icon-login.png" alt="Male" />
                        {selectedGender === "male" && (
                          <div className="login-gender-tick">&#10003;</div>
                        )}
                        <p>Male</p>
                      </div>
                      <div
                        className={`login-gender-option ${
                          selectedGender === "female"
                            ? "login-gender-selected"
                            : ""
                        }`}
                        onClick={() => handleGenderSelect("female")}
                      >
                        <img
                          src="assets/img/women-icon-login.png"
                          alt="Female"
                        />
                        {selectedGender === "female" && (
                          <div className="login-gender-tick">&#10003;</div>
                        )}
                        <p>Female</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* END GENDER SELECTION SLIDER */}

              {newUserDetailsSliderState && (
                <>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": {
                        m: 1,
                        width: "95%",
                        textAlign: "center",
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "20px",
                      borderRadius: "8px",
                    }}
                    noValidate
                    autoComplete="on"
                  >
                    <TextField
                      label={"Full Name"}
                      id="margin-none"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      required
                    />
                    <TextField
                      label={"Email"}
                      id="margin-none"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                    <TextField
                      label={"Current City"}
                      id="margin-none"
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                      required
                    />
                  </Box>

                  {error && (
                    <Alert variant="danger" className="mt-2">
                      {error}
                    </Alert>
                  )}
                  <div className="d-flex justify-content-center mt-2">
                    <button className="btn_fill" onClick={newUserForm}>
                      Done
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
