import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useMemo } from "react";
import DatePicker from "react-datepicker"; // npm install react-datepicker
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

//import Model, Button for popup
import { Modal, Button } from "react-bootstrap";

// import component
// import ThemeTable from "../../../components/themeTable/ThemeTable";
import ReactTable from "../../../happyerpComponents/reactTable/HappyerpReactTable";
// import AntTable from "../../../components/antTableDesign/AntTable";
import SaveVariantsPopUp from "./HappyerpSaveVariantsPopUp";

// import css
import "./HappyerpOrderReport.scss";
import ReactTableGlobalFilter from "../../../happyerpComponents/reactTable/HappyerpReactTableGlobalFilter";

import ReportVariantAccordion from "./HappyerpReportVariantAccordion";

const HappyerpOrderReport = () => {
  // code for dropdown filter start
  const sections = [
    {
      title: "Order Status",
      items: ["Open", "Cancelled", "Complete"],
    },
    {
      title: "Order",
      items: ["1", "2", "3"],
    },
    {
      title: "Party",
      items: ["Avon Garments", "Minakshi Store", "Gadiya Collection"],
    },
    {
      title: "Item",
      items: ["Blazor", "Jeans", "Shirt", "Kurta Pajama", "Sherwani"],
    },
    {
      title: "Style",
      items: ["2 Button", "3 Button", "Long", "Regular", "Short"],
    },
    {
      title: "Design",
      items: ["Lining", "Plain", "Check", "Circle"],
    },
    {
      title: "Sub Design",
      items: ["Sub Design 1", "Sub Design 2", "Sub Design 3", "Sub Design 4"],
    },
    {
      title: "Color",
      items: ["Red", "Green", "Black", "Pink"],
    },
    {
      title: "Size",
      items: ["32", "34", "36", "38", "40"],
    },
    {
      title: "Agent",
      items: ["Rahul", "Mukesh", "Amit", "Sonam", "Ravi"],
    },
    {
      title: "Placed By",
      items: ["Sanskar", "kartik", "Naman", "Khushi", "Chaitali"],
    },
    {
      title: "Rows",
      items: ["Design", "subdesign", "color", "order", "item", "placedby"],
    },
    {
      title: "Coloums",
      items: ["Design", "subdesign", "color", "order", "item", "placedby"],
    },
  ];

  const [selectedItems, setSelectedItems] = useState({});
  const [searchQuery, setSearchQuery] = useState({});
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [errorMessage, setErrorMessage] = useState(""); // State to manage error messages
  const [selectedColumns, setSelectedColumns] = useState([]); //for accordion dropdown of select columns

  const handleSearch = (section, query) => {
    setSearchQuery((prev) => ({ ...prev, [section]: query.toLowerCase() }));
  };

  const handleSelectAll = (section, isChecked, items) => {
    setSelectedItems((prev) => ({
      ...prev,
      [section]: isChecked ? items : [],
    }));
  };

  const handleToggleCheckbox = (section, item) => {
    setSelectedItems((prev) => {
      const selected = prev[section] || [];
      if (selected.includes(item)) {
        return { ...prev, [section]: selected.filter((i) => i !== item) };
      } else {
        return { ...prev, [section]: [...selected, item] };
      }
    });
  };

  const handleDateChange = (field, value) => {
    if (field === "from") {
      setDateRange((prev) => ({ ...prev, from: value, to: null })); // Reset To Date when From Date is changed
      setErrorMessage("");
    } else if (field === "to") {
      if (!dateRange.from) {
        setErrorMessage("Please select 'Start Date' first");
      } else if (value <= dateRange.from) {
        setErrorMessage("The 'To Date' must be after the 'Start Date'.");
      } else {
        setDateRange((prev) => ({ ...prev, to: value }));
        setErrorMessage("");
      }
    }
  };
  const clearFilters = () => {
    setSelectedItems({});
    setSearchQuery({});
    setDateRange({ from: "", to: "" });
  };

  const isFilterActive =
    Object.keys(selectedItems).some((key) => selectedItems[key].length > 0) ||
    Object.keys(searchQuery).some((key) => searchQuery[key]) ||
    dateRange.from ||
    dateRange.to;
  // code for dropdown filter end

  // //code for saved variants pop-up start
  const [showPopUp, setShowPopUp] = useState(false);

  const handleShowPopUp = () => setShowPopUp(true);
  const handleClosePopUp = () => setShowPopUp(false);
  //code for saved variants pop-up end

  // // code for the more button in report variants section starts:
  // const [isMorePopupVisible, setMorePopupVisible] = useState(null);
  // const [currentVariant, setCurrentVariant] = useState("");
  // const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 }); //to position the popup near every filter clicked
  // // // Toggles the popup visibility
  // const toggleMorePopup = (index, filterName, event) => {
  //   setCurrentVariant(filterName);
  //   setMorePopupVisible((prev) => (prev === index ? null : index));

  //   // Geting the position of the clicked element
  //   const rect = event.target.getBoundingClientRect();
  //   const popupHeight = 200;
  //   const popupWidth = 150;
  //   const offsetTop = 150;
  //   const offsetLeft = 400;
  //   // Calculating the position
  //   const top = rect.top + window.scrollY + rect.height - offsetTop;
  //   const left =
  //     rect.left + window.scrollX + rect.width / 2 - popupWidth / 2 - offsetLeft;

  //   // Ensuring the popup doesn't go off-screen
  //   const adjustedTop =
  //     top + popupHeight > window.innerHeight
  //       ? window.innerHeight - popupHeight
  //       : top;
  //   const adjustedLeft =
  //     left + popupWidth > window.innerWidth
  //       ? window.innerWidth - popupWidth
  //       : left;

  //   setPopupPosition({
  //     top: adjustedTop,
  //     left: adjustedLeft,
  //   });
  // };

  // // Close the popup when clicking outside
  // const closeMorePopup = () => {
  //   setMorePopupVisible(null); // Close the popup
  // };

  // const [isOpenPopup, setIsOpenPopup] = useState(false);
  // const popupRef = useRef(null);

  // const handleClickOutside = (event) => {
  //   if (popupRef.current && !popupRef.current.contains(event.target)) {
  //     setIsOpenPopup(false);
  //     closeMorePopup();
  //   }
  // };

  // useEffect(() => {
  //   if (isOpenPopup) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [isOpenPopup]);

  // code for the more button in report variants section ends:

  //code for changing the title as per the selected filter starts:
  // const [reportVariantName, setReportVariantName] = useState("");
  // const renameReportVariant = (filterName) => {
  //   setReportVariantName(filterName);
  // };

  // const handleFilterChange = (index, value) => {
  //   const updatedSelectedItems = { ...selectedItems };
  //   updatedSelectedItems[`filter-${index}`] = value; // Update selected item

  //   setSelectedItems(updatedSelectedItems);
  //   // Update the active variant name
  //   setReportVariantName(`Variant ${index + 1}`);
  // };

  //code for accordion select columns start
  const options = [
    "Order",
    "Sales Agent",
    "Party",
    "Item",
    "Design",
    "Sub-design",
    "Color",
    "Style",
    "Size",
    "Placed By",
  ];

  // Handle toggle for checkbox selection/deselection
  const handleCheckboxChange = (option) => {
    setSelectedColumns((prevState) => {
      if (prevState.includes(option)) {
        return prevState.filter((item) => item !== option); // Deselect the option
      } else {
        return [...prevState, option]; // Select the option
      }
    });
  };
  //code for accordion select columns ends

  // code for table data start
  const tableData = [
    {
      id: 1,
      orderid: 2563,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black",
      size: "L",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      remark: "Decent and Comfortable",
    },
    {
      id: 2,
      orderid: 2564,
      deliveryDate: "02/12/2024",
      partyName: "Rohan Mehta",
      item: "shirt",
      order: "5",
      style: "Slim Fit",
      design: "Striped",
      subDesign: "Sub Design 2",
      color: "White",
      size: "M",
      transport: "Quick Logistics",
      placedBy: "Aman",
      agentName: "Kriti",
      createdDate: "29/11/2024",
      remark: "Elegant and Stylish",
    },
    {
      id: 3,
      orderid: 2565,
      deliveryDate: "03/12/2024",
      partyName: "Sneha Sharma",
      item: "jacket",
      order: "2",
      style: "Puffer",
      design: "Plain",
      subDesign: "Sub Design 3",
      color: "Red",
      size: "XL",
      transport: "Speedy Express",
      placedBy: "Pooja",
      agentName: "Aditya",
      createdDate: "28/11/2024",
      remark: "Warm and Comfortable",
    },
    {
      id: 4,
      orderid: 2566,
      deliveryDate: "04/12/2024",
      partyName: "Arjun Kapoor",
      item: "jeans",
      order: "4",
      style: "Skinny",
      design: "Faded",
      subDesign: "Sub Design 4",
      color: "Blue",
      size: "32",
      transport: "Fast Movers",
      placedBy: "Ravi",
      agentName: "Meera",
      createdDate: "27/11/2024",
      remark: "Trendy and Durable",
    },
    {
      id: 5,
      orderid: 2567,
      deliveryDate: "05/12/2024",
      partyName: "Priya Verma",
      item: "skirt",
      order: "3",
      style: "A-Line",
      design: "Floral",
      subDesign: "Sub Design 5",
      color: "Pink",
      size: "S",
      transport: "Swift Deliveries",
      placedBy: "Neha",
      agentName: "Karan",
      createdDate: "26/11/2024",
      remark: "Chic and Comfortable",
    },
    {
      id: 6,
      orderid: 2568,
      deliveryDate: "06/12/2024",
      partyName: "Vikram Singh",
      item: "sweater",
      order: "6",
      style: "Cardigan",
      design: "Knit",
      subDesign: "Sub Design 6",
      color: "Gray",
      size: "M",
      transport: "Turbo Transport",
      placedBy: "Rohit",
      agentName: "Simran",
      createdDate: "25/11/2024",
      remark: "Cozy and Durable",
    },
    {
      id: 7,
      orderid: 2569,
      deliveryDate: "07/12/2024",
      partyName: "Karan Patel",
      item: "shorts",
      order: "2",
      style: "Casual",
      design: "Plain",
      subDesign: "Sub Design 7",
      color: "Beige",
      size: "L",
      transport: "Rapid Couriers",
      placedBy: "Manav",
      agentName: "Anita",
      createdDate: "24/11/2024",
      remark: "Lightweight and Comfortable",
    },
    {
      id: 8,
      orderid: 2570,
      deliveryDate: "08/12/2024",
      partyName: "Riya Gupta",
      item: "cap",
      order: "5",
      style: "Snapback",
      design: "Logo",
      subDesign: "Sub Design 8",
      color: "Green",
      size: "Free Size",
      transport: "Express Services",
      placedBy: "Tina",
      agentName: "Vivek",
      createdDate: "23/11/2024",
      remark: "Stylish and Trendy",
    },
    {
      id: 9,
      orderid: 2571,
      deliveryDate: "09/12/2024",
      partyName: "Sameer Khanna",
      item: "tie",
      order: "10",
      style: "Formal",
      design: "Solid",
      subDesign: "Sub Design 9",
      color: "Navy",
      size: "Standard",
      transport: "Quick Ship",
      placedBy: "Rahul",
      agentName: "Megha",
      createdDate: "22/11/2024",
      remark: "Elegant and Professional",
    },
    {
      id: 10,
      orderid: 2572,
      deliveryDate: "10/12/2024",
      partyName: "Pooja Nair",
      item: "scarf",
      order: "7",
      style: "Infinity",
      design: "Patterned",
      subDesign: "Sub Design 10",
      color: "Purple",
      size: "One Size",
      transport: "Fast Tracks",
      placedBy: "Shweta",
      agentName: "Kabir",
      createdDate: "21/11/2024",
      remark: "Soft and Stylish",
    },
    {
      id: 11,
      orderid: 2573,
      deliveryDate: "02/12/2024",
      partyName: "Arjun Malhotra",
      item: "shirt",
      order: "2",
      style: "Slim Fit",
      design: "striped",
      subDesign: "Sub Design A",
      color: "Blue",
      size: "M",
      transport: "Speed Logistics",
      placedBy: "Vikram",
      agentName: "Aditi",
      createdDate: "01/12/2024",
      remark: "Elegant and Stylish",
    },
    {
      id: 12,
      orderid: 2574,
      deliveryDate: "03/12/2024",
      partyName: "Priya Singh",
      item: "jeans",
      order: "4",
      style: "Classic",
      design: "plain",
      subDesign: "Sub Design B",
      color: "Indigo",
      size: "32",
      transport: "Blue Dart",
      placedBy: "Sonia",
      agentName: "Ramesh",
      createdDate: "02/12/2024",
      remark: "Comfortable for all-day wear",
    },
    {
      id: 13,
      orderid: 2575,
      deliveryDate: "04/12/2024",
      partyName: "Kunal Verma",
      item: "jacket",
      order: "1",
      style: "Puffer",
      design: "solid",
      subDesign: "Sub Design C",
      color: "Red",
      size: "L",
      transport: "FastTrack Couriers",
      placedBy: "Ankit",
      agentName: "Meena",
      createdDate: "03/12/2024",
      remark: "Warm and cozy",
    },
    {
      id: 14,
      orderid: 2575,
      deliveryDate: "05/12/2024",
      partyName: "Rohit Sharma",
      item: "trousers",
      order: "3",
      style: "Formal",
      design: "plain",
      subDesign: "Sub Design D",
      color: "Gray",
      size: "34",
      transport: "Delhivery",
      placedBy: "Pooja",
      agentName: "Vivek",
      createdDate: "04/12/2024",
      remark: "Perfect for office wear",
    },
    {
      id: 15,
      orderid: 2576,
      deliveryDate: "06/12/2024",
      partyName: "Meera Nair",
      item: "kurta",
      order: "2",
      style: "Ethnic",
      design: "embroidered",
      subDesign: "Sub Design E",
      color: "Pink",
      size: "M",
      transport: "DTDC",
      placedBy: "Shweta",
      agentName: "Rajesh",
      createdDate: "05/12/2024",
      remark: "Beautiful and traditional",
    },
    {
      id: 16,
      orderid: 2577,
      deliveryDate: "07/12/2024",
      partyName: "Ajay Kumar",
      item: "hoodie",
      order: "5",
      style: "Casual",
      design: "graphic",
      subDesign: "Sub Design F",
      color: "Green",
      size: "XL",
      transport: "SpeedPost",
      placedBy: "Rohini",
      agentName: "Karan",
      createdDate: "06/12/2024",
      remark: "Trendy and comfortable",
    },
    {
      id: 17,
      orderid: 2578,
      deliveryDate: "08/12/2024",
      partyName: "Shivani Patel",
      item: "sweater",
      order: "1",
      style: "Knitted",
      design: "patterned",
      subDesign: "Sub Design G",
      color: "Purple",
      size: "S",
      transport: "Ecom Express",
      placedBy: "Vijay",
      agentName: "Simran",
      createdDate: "07/12/2024",
      remark: "Soft and warm",
    },
    {
      id: 18,
      orderid: 2579,
      deliveryDate: "09/12/2024",
      partyName: "Vivek Gupta",
      item: "shorts",
      order: "6",
      style: "Casual",
      design: "solid",
      subDesign: "Sub Design H",
      color: "Beige",
      size: "L",
      transport: "Blue Dart",
      placedBy: "Manish",
      agentName: "Kriti",
      createdDate: "08/12/2024",
      remark: "Perfect for summer",
    },
    {
      id: 19,
      orderid: 2580,
      deliveryDate: "10/12/2024",
      partyName: "Neha Roy",
      item: "skirt",
      order: "2",
      style: "Flared",
      design: "floral",
      subDesign: "Sub Design I",
      color: "Yellow",
      size: "M",
      transport: "FedEx",
      placedBy: "Sandeep",
      agentName: "Alok",
      createdDate: "09/12/2024",
      remark: "Light and breezy",
    },
    {
      id: 20,
      orderid: 2581,
      deliveryDate: "11/12/2024",
      partyName: "Pankaj Jain",
      item: "blazer",
      order: "1",
      style: "Formal",
      design: "plain",
      subDesign: "Sub Design J",
      color: "Black",
      size: "42",
      transport: "Professional Couriers",
      placedBy: "Aman",
      agentName: "Nisha",
      createdDate: "10/12/2024",
      remark: "Sophisticated and professional",
    },
  ];

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "S.No",
        accessor: (row, i) => i + 1,
        id: "serialNumber",
        Cell: ({ row }) => row.index + 1,
        disableFilters: false,
      },
      {
        Header: "Order ID",
        accessor: "orderid",
        disableFilters: false,
        Cell: ({ value }) => (
          <Link className="happyerp-text_teal" to="/card-detail">
            {value}
          </Link>
        ),
      },
      {
        Header: "Delivery Date",
        accessor: "deliveryDate",
        disableFilters: false,
      },
      {
        Header: "Party Name",
        accessor: "partyName",
        disableFilters: false,
      },
      {
        Header: "Item",
        accessor: "item",
        disableFilters: false,
      },
      {
        Header: "Order",
        accessor: "order",
        disableFilters: false,
      },
      {
        Header: "Style",
        accessor: "style",
        disableFilters: false,
      },
      {
        Header: "Design",
        accessor: "design",
        disableFilters: false,
      },
      {
        Header: "Sub Design",
        accessor: "subDesign",
        disableFilters: false,
      },
      {
        Header: "Color",
        accessor: "color",
        disableFilters: false,
      },
      {
        Header: "Size",
        accessor: "size",
        disableFilters: false,
      },
      {
        Header: "Transport",
        accessor: "transport",
        disableFilters: false,
      },
      {
        Header: "Placed By",
        accessor: "placedBy",
        disableFilters: false,
      },
      {
        Header: "Agent Name",
        accessor: "agentName",
        disableFilters: false,
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
        disableFilters: false,
      },
      // {
      //   Header: "Remark",
      //   accessor: "remark",
      //   disableFilters: false,
      // },
    ];

    return baseColumns;
  });

  // code for table data end

  // code for row and coloum filter start
  const animatedComponents = makeAnimated();
  const allFields = [
    { value: "Order", label: "Order" },
    { value: "Sales Agent", label: "Sales Agent" },
    { value: "Party", label: "Party" },
    { value: "Item", label: "Item" },
    { value: "Design", label: "Design" },
    { value: "Sub-design", label: "Sub-design" },
    { value: "Color", label: "Color" },
    { value: "Style", label: "Style" },
    { value: "Size", label: "Size" },
    { value: "Placed By", label: "Placed By" },
  ];

  const [rowFields, setRowFields] = useState([]);
  const [columnFields, setColumnFields] = useState([]);

  // Filter options to prevent overlap
  const availableFieldsForRows = allFields.filter(
    (field) => !columnFields.some((selected) => selected.value === field.value)
  );
  const availableFieldsForColumns = allFields.filter(
    (field) => !rowFields.some((selected) => selected.value === field.value)
  );

  // Custom styles for better UI
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: "40px",
    }),
    option: (base, state) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      backgroundColor: state.isSelected ? "#f0f8ff" : "white",
      ":hover": {
        backgroundColor: "#f0f8ff",
      },
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#e6f7ff",
      color: "#007bff",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#007bff",
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#007bff",
      ":hover": {
        backgroundColor: "#007bff",
        color: "white",
      },
    }),
  };
  // code for row and coloum filter end

  //code for the card_area collapsing start
  const [isCardAreaCollapsed, setIsCardAreaCollapsed] = useState(false);

  const toggleCardAreaCollapse = () =>
    setIsCardAreaCollapsed(!isCardAreaCollapsed);
  //code for the card_area collapsing end

  // code for show more start
  const filters = Array.from({ length: 24 }, (_, i) => `Filter ${i + 1}`); // Example filters
  const [visibleCount, setVisibleCount] = useState(7); // Initially show 8 filters

  // Handle "Show More" button click
  const handleShowMore = () => {
    setVisibleCount((prev) => Math.min(prev + 7, filters.length)); // Load 8 more filters or max
  };

  // Handle "Show Less" button click
  const handleShowLess = () => {
    setVisibleCount(7); // Reset to the initial 8 filters
  };
  // code for show more end

  return (
    <div
      className={`happyerp-pg_order_report happyerp-relative ${
        isCardAreaCollapsed ? "happyerp-collapsed_yes" : "happyerp-collapsed_no"
      }`}
    >
      <div className="happyerp-filter_section happyerp-left happyerp-card_shadow happyerp-card_bg">
        <div className="happyerp-left_close_icon"></div>
        <div className="happyerp-title">
          <h4>Lorem Ipsum</h4>
          <div className="happyerp-reset_btn">
            <span style={{ margin: "0px" }}>
              {isFilterActive && (
                <h6
                  onClick={clearFilters}
                  style={{
                    color: "var(--theme-teal)",
                    textDecoration: "underline",
                  }}
                >
                  Reset
                </h6>
              )}
            </span>
          </div>
        </div>
        <div className="happyerp-date_filter">
          <div className="happyerp-df_inner">
            <ReactDatePicker
              selected={dateRange.from}
              onChange={(date) => handleDateChange("from", date)}
              placeholderText="Start Date"
              maxDate={new Date()}
            />
            <span
              style={{
                fontSize: "14px",
              }}
            >
              to
            </span>
            <ReactDatePicker
              selected={dateRange.to}
              onChange={(date) => handleDateChange("to", date)}
              placeholderText="End Date"
              minDate={dateRange.from}
            />
          </div>
          {errorMessage && (
            <p className="happyerp-error_message">{errorMessage}</p>
          )}
        </div>
        {/* <div className="title">
          <h4>Filters</h4>
          {isFilterActive && <h6 onClick={clearFilters}>Reset</h6>}
        </div> */}
        <div
          className="happyerp-left_accordions_section"
          style={{
            maxHeight: "550px",
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <Accordion className="happyerp-parent_filter_accordion">
            {/* parent accordion for Filters  */}
            <Accordion.Item eventKey="0">
              <Accordion.Header>Filters</Accordion.Header>
              <Accordion.Body>
                {/* child accordion  */}
                <div className="happyerp-accordion_filter happyerp-inline">
                  <Accordion
                    className="happyerp-checkbox_accordion"
                    style={{
                      maxHeight: "400px",
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                    }}
                  >
                    {sections.map((section, index) => {
                      const filteredItems = section.items.filter((item) =>
                        searchQuery[section.title]
                          ? item
                              .toLowerCase()
                              .includes(searchQuery[section.title])
                          : true
                      );
                      const selected = selectedItems[section.title] || [];

                      return (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                          <Accordion.Header
                            className={
                              selected.length > 0
                                ? "happyerp-active_accordion_filter"
                                : ""
                            }
                          >
                            {section.title}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="happyerp-select_all_search">
                              <input
                                type="checkbox"
                                id={`select_all_${section.title}`}
                                checked={
                                  selected.length === filteredItems.length &&
                                  filteredItems.length > 0
                                }
                                onChange={(e) =>
                                  handleSelectAll(
                                    section.title,
                                    e.target.checked,
                                    filteredItems
                                  )
                                }
                              />
                              <input
                                type="text"
                                placeholder="Select All / Search"
                                className="happyerp-search_input"
                                onChange={(e) =>
                                  handleSearch(section.title, e.target.value)
                                }
                              />
                            </div>
                            <div className="happyerp-checkbox_grid">
                              {filteredItems.map((item, idx) => (
                                <div className="happyerp-c_single" key={idx}>
                                  <input
                                    type="checkbox"
                                    id={`${section.title}_${item}`}
                                    checked={selected.includes(item)}
                                    onChange={() =>
                                      handleToggleCheckbox(section.title, item)
                                    }
                                  />
                                  <label htmlFor={`${section.title}_${item}`}>
                                    {item}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="happyerp-pivot_btn_dropdown">
            <Accordion.Item eventKey="0">
              {/* Accordion Header */}
              <Accordion.Header>Pivot</Accordion.Header>

              {/* Accordion Body */}
              <Accordion.Body>
                <div className="happyerp-row_single">
                  <div className="happyerp-row_column">
                    {/* Select Rows */}
                    <div className="happyerp-rc_single">
                      <Select
                        isMulti
                        closeMenuOnSelect={false} // Keeps dropdown open
                        options={availableFieldsForRows}
                        value={rowFields}
                        onChange={setRowFields}
                        styles={customStyles}
                        placeholder="Select Rows"
                        className="react_selector"
                      />
                    </div>

                    {/* Select Columns */}
                    <div className="happyerp-rc_single">
                      <Select
                        isMulti
                        closeMenuOnSelect={false} // Keeps dropdown open
                        options={availableFieldsForColumns}
                        value={columnFields}
                        onChange={setColumnFields}
                        styles={customStyles}
                        placeholder="Select Columns"
                        className="react_selector"
                      />
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="happyerp-select_columns">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Columns</Accordion.Header>
              <Accordion.Body
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                }}
              >
                {options.map((option, index) => (
                  <div className="happyerp-cbox">
                    <div key={index} className="happyerp-checkbox_item">
                      <input
                        type="checkbox"
                        id={`column_${option}`}
                        checked={selectedColumns.includes(option)} // Check if the option is selected
                        onChange={() => handleCheckboxChange(option)} // Handles selection/deselection
                      />
                      <label htmlFor={`column_${option}`}>{option}</label>
                    </div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="happyerp-left_footer">
          <div
            className="happyerp-footer_btns"
            onClick={toggleCardAreaCollapse}
          >
            <div className="happyerp-arrow">
              <span class="material-symbols-outlined">
                {isCardAreaCollapsed ? "arrow_forward_ios" : "arrow_back_ios"}
              </span>
            </div>
          </div>
          <div className="happyerp-sr_btn">
            <div>
              <div
                className="happyerp-theme_btn happyerp-btn_midnight_blue"
                onClick={handleShowPopUp}
              >
                Save Variants
              </div>
              <SaveVariantsPopUp
                show={showPopUp} // Control modal visibility
                onClose={handleClosePopUp} // Close modal logic
              />
            </div>

            <div className="happyerp-theme_btn">Show Report</div>
          </div>
        </div>
      </div>
      <div className="happyerp-main_area happyerp-right">
        {/* removing accordion for testing  */}
        <ReportVariantAccordion filters={filters} />

        <div className="happyerp-dvg12"></div>

        <ReactTable
          tableColumns={columns}
          tableData={tableData}
          hideCheckboxes={true}
          showColumnFilter={false}
        />
        {/* <AntTable/> */}
      </div>
    </div>
  );
};

export default HappyerpOrderReport;
