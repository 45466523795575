import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

//import scss
import "./HappyerpMoreOptionPopup.scss";

const HappyerpMoreOptionPopup = ({
  id,
  isMoreOptionsOpen,
  toggleMoreOptions,
}) => {
  // console.log({
  //   id: id,
  //   isMoreOptionsOpen: isMoreOptionsOpen,
  //   // setActiveCardId: setActiveCardId,
  // });
  const moreOptionsRef = useRef(null);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [status, setStatus] = useState("Approved");
  const [selectedStatus, setSelectedStatus] = useState("Approved");

  const handleEditClick = () => {
    setShowEditPopup(true);
  };
  const handleCloseClick = () => {
    setShowEditPopup(false);
  };

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  const handleDoneClick = () => {
    setStatus(selectedStatus);
    setShowEditPopup(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMoreOptionsOpen &&
        moreOptionsRef.current &&
        !moreOptionsRef.current.contains(event.target)
      ) {
        toggleMoreOptions(id);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id, isMoreOptionsOpen, toggleMoreOptions]);
  // code end------------------->

  return (
    <>
      <div className="happyerp-order_edit">
        {/* <span class="material-symbols-outlined">border_color</span> */}

        <button
          className="happyerp-more_opt_card"
          onClick={() => toggleMoreOptions(id)}
          style={{ border: "none" }}
        >
          <span
            class="material-symbols-outlined"
            style={{
              alignItems: "center",
              fontWeight: "350",
              paddingTop: "6px",
            }}
            ref={moreOptionsRef}
          >
            more_vert
          </span>
        </button>
      </div>
      {isMoreOptionsOpen && (
        <div
          className="happyerp-more_opt_container"
          ref={moreOptionsRef}
          // style={{ zIndex: "999" }}
        >
          {/* <div className="card_bottom"> */}
          {/* <div className="moc_title">
            <h6>More Options</h6>
          </div> */}
          <ul style={{ padding: "0", textAlign: "center" }}>
            <Link
              to={`/order-detail/${id}`}
              // className="click_text ct_border"
              //
            >
              <li className="happyerp-opt_list">
                <h7
                  style={{
                    textDecoration: "none",
                    color: "var(--text-black)",
                  }}
                >
                  View More
                </h7>
                <img
                  src="/assets/img/happyerp/icon/view_more.png"
                  alt=""
                  className="happyerp-more_opt_img"
                />
              </li>
            </Link>
            <li className="happyerp-opt_list">
              <div
                className={`happyerp-editable_text ${status.toLowerCase()}`}
                onClick={handleEditClick}
                style={{ padding: "0" }}
              >
                <h7 style={{ color: "var(--text-black)" }}>Status: {status}</h7>

                {/* <button class="material-symbols-outlined">edit</button> */}
              </div>
              <img
                src="/assets/img/happyerp/icon/status.png"
                alt=""
                className="happyerp-more_opt_img"
              />
            </li>
            <li className="opt_list">
              <h7>Delete Card</h7>
              <img
                src="/assets/img/happyerp/icon/delete.png"
                alt=""
                className="happyerp-more_opt_img"
              />
            </li>
            <li className="opt_list">
              <h7>Share</h7>
              <img
                src="/assets/img/happyerp/icon/share.png"
                alt=""
                className="happyerp-more_opt_img"
              />
            </li>
          </ul>
        </div>
      )}
      {/* Edit Popup */}
      {showEditPopup && (
        <div className="happyerp-edit_status_popup">
          <div className="happyerp-close_form" onClick={handleCloseClick}>
            <span class="material-symbols-outlined">close</span>
          </div>
          <div className="happyerp-edit_content">
            <div className="happyerp-form_field">
              <label htmlFor="" className="happyerp-label text-center mb-3">
                Edit Status
              </label>
              <div className="happyerp-field_box happyerp-theme_radio">
                <div className="happyerp-theme_radio_container">
                  <div className="happyerp-radio_single">
                    <input
                      type="radio"
                      name="status"
                      value="Approved"
                      id="approved"
                      checked={selectedStatus === "Approved"}
                      onChange={() => handleStatusChange("Approved")}
                    />
                    <label htmlFor="approved">Approved</label>
                  </div>
                  <div className="happyerp-radio_single">
                    <input
                      type="radio"
                      name="status"
                      value="Pending"
                      id="pending"
                      checked={selectedStatus === "Pending"}
                      onChange={() => handleStatusChange("Pending")}
                    />
                    <label htmlFor="pending">pending</label>
                  </div>
                  <div className="happyerp-radio_single">
                    <input
                      type="radio"
                      name="status"
                      value="Status 3"
                      id="status3"
                      checked={selectedStatus === "Status 3"}
                      onChange={() => handleStatusChange("Status 3")}
                    />
                    <label htmlFor="status3">Status 3</label>
                  </div>
                  <div className="happyerp-radio_single">
                    <input
                      type="radio"
                      name="status"
                      value="Status 4"
                      id="status4"
                      checked={selectedStatus === "Status 4"}
                      onChange={() => handleStatusChange("Status 4")}
                    />
                    <label htmlFor="status4">Status 4</label>
                  </div>
                  <div className="happyerp-radio_single">
                    <input
                      type="radio"
                      name="status"
                      value="Status 5"
                      id="status5"
                      checked={selectedStatus === "Status 5"}
                      onChange={() => handleStatusChange("Status 5")}
                    />
                    <label htmlFor="status5">Status 5</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <div
                className="happyerp-card_btn happyerp-theme_btn happyerp-confirm_btn mt-3"
                onClick={handleDoneClick}
              >
                Change status
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HappyerpMoreOptionPopup;
