import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeHeroBanners from "../../../default components/HomeHeroBanners";
import OfferBanners from "../../../default components/OfferBanners";
import "./Home.css";
import Products from "../../../default components/Products";
import CorporateTwoFeatures from "../../../templates/corporate/corporateTwo/corporateTwoComponents/CorporateTwoFeatures/CorporateTwoFeatures";
import Faq from "../../../default components/Faq";
import CorporateTwoClients from "../../../templates/corporate/corporateTwo/corporateTwoComponents/CorporateTwoClients/CorporateTwoClients";
import { useCollection } from "../../../firebase files/hooks/useCollection";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import AddMedia from "../../../default components/AddMedia";
import { useAuthContext } from "../../../firebase files/hooks/useAuthContext";
import Testimonials from "../../../default components/Testimonials";
import Event from "../../../default components/Event";
import Team from "../../../default components/Team";
import Counter from "../../../default components/Counter";
import Career from "../../../default components/Career";
import TopSellingProducts from "../../../default components/TopSellingProducts";
import "aos/dist/aos.css";
import AOS from "aos";
import { Helmet } from "react-helmet";
import NewArrivals from "../../../default components/NewArrivals";
import PopularProducts from "../../../default components/PopularProducts";
import Categories from "../../../default components/Categories";
import TodayAttendanceData from "../../../default components/TodayAttendanceData";
import HolidayAndCloudCoins from "../../../default components/HolidayAndCloudCoins";

const Home = () => {
  const { user } = useAuthContext();

  const { document: organisationDocument, errors: organisationDocumentError } =
    useDocument("settings", "organisation");

  const { document: pagesControlDoc, errors: pagesControlDocError } =
    useDocument("settings", "pagesControl");

  const { documents: dbMediaDocuments, error: dbMediaError } = useCollection(
    "media",
    ["pageName", "==", "home"]
  );

  const { documents: dbMediaTeamDocuments, error: dbMediaTeamError } =
    useCollection("media", ["category", "==", "team"]);

  const { documents: dbProductsDocument, error: dbProductsDocumentError } =
    useCollection("products", ["status", "==", "active"]);

  const [faqCount, setFaqCount] = useState(0);
  const [serviceCount, setServiceCount] = useState(0);

  // START COUNT CODE FOR PRODUCTS
  useEffect(() => {
    if (dbProductsDocument) {
      setServiceCount(dbProductsDocument.length);
    }
  }, [dbProductsDocument]);
  // END COUNT CODE FOR PRODUCTS

  // START COUNT CODE FOR FAQS
  useEffect(() => {
    if (dbMediaDocuments) {
      setFaqCount(dbMediaDocuments.length);
    }
  }, [dbMediaDocuments]);
  // END COUNT CODE FOR FAQS

  const aboutusvideo =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (e) => e.section == "aboutus" && e.mediaType == "video"
    );

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <>
      <div className="home_pg">
        {/* Home Hero Banners */}
        <HomeHeroBanners
          category="banner"
          pageName="home"
          section="homeherobanners"
          addMoreVideosButton={false}
          addMoreTextContentButton={false}
        />
        {user && user.isStaff && user.isStaff === true && (
          <div
            className="today_attendance_div_for_phone"
            style={{ maxWidth: "87%", margin: "auto" }}
          >
            <br />
            <HolidayAndCloudCoins />
          </div>
        )}

        {user && user.isAttendance && user.isStaff && (
          <div className="today_attendance_div_for_phone">
            <div className="medium_padding_div"></div>
            {/* <div className="small_margin_div"></div> */}
            <TodayAttendanceData />
          </div>
        )}

        <Counter />

        {user && user.isStaff && user.isStaff === true && (
          <div
            className="today_attendance_div_for_pc"
            style={{ maxWidth: "87%", margin: "auto" }}
          >
            <div className="medium_padding_div"></div>
            <div className="small_margin_div"></div>
            <HolidayAndCloudCoins />
          </div>
        )}

        {user && user.isAttendance && user.isStaff && (
          <div className="today_attendance_div_for_pc">
            <div className="medium_padding_div"></div>
            <div className="small_margin_div"></div>
            <TodayAttendanceData />
          </div>
        )}
        <div className="medium_padding_div"></div>
        <div className="small_margin_div"></div>
        {/* Offer Banner Section */}
        <div data-aos="fade-down">
          <OfferBanners
            category="offers"
            pageName="home"
            section="offers"
            // addMoreImagesButton={false}
            addMoreVideosButton={false}
            addMoreTextContentButton={false}
          />
        </div>
        {/* products section */}
        {/* <section className="s_home_services">
          <Products maxProductsToShow={8} showInactive={true} />
          <div className="small_margin_div"></div>
          <div className="d-flex justify-content-center">
            {serviceCount >= 8 && (
              <div className="d-flex">
                <div className="home_faq_more_btnn" data-aos="fade-up">
                  <Link to="/products">
                    <button className="btn_fill home_faq_Button d-flex align-items-center ">
                      More
                      <span
                        class="material-symbols-outlined ba_animation"
                        style={{ marginLeft: "10px" }}
                      >
                        arrow_forward
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            )}
            <div className="medium_margin_div"></div>
          </div>
        </section> */}
        {/* category section */}
        <CorporateTwoFeatures />
        <div className="medium_margin_div"></div>
        {/* CATEGROIES PRODUCTS */}
        <Categories />
        <div className="medium_margin_div"></div>
        {/* TOP SELLING PRODUCTS */}
        <TopSellingProducts />
        <div className="medium_margin_div"></div>
        {/* NEW ARRIVALS PRODUCTS */}
        <NewArrivals />
        <div className="medium_margin_div"></div>
        {/* POPULAR PRODUCTS */}
        <PopularProducts />
        {/* Video Section */}
        <div className="home_video_section_parent">
          <div className="large_padding_div"></div>
          <div className="container">
            <div className="row d-flex  align-items-center justify-content-center">
              <div className="col-12 col-md-6" data-aos="fade-down">
                <div>
                  <video
                    width="100%"
                    controls
                    autoPlay
                    loop
                    muted
                    className="home_video"
                  >
                    {aboutusvideo && aboutusvideo.length > 0 && (
                      <source src={aboutusvideo[0].mediaUrl} type="video/mp4" />
                    )}
                    Your browser does not support the video tag.
                  </video>
                </div>
                <AddMedia
                  category="video"
                  pageName="home"
                  section="aboutus"
                  // addMoreImagesButton={false}
                  addMoreVideosButton={false}
                  addMoreTextContentButton={false}
                />
              </div>
              <div className="col-12 col-md-6" data-aos="fade-up">
                <div className="d-flex flex-column">
                  <div>
                    <div className="faq_services_heading">
                      <p style={{ color: "var(--black-color)" }}>About Us</p>
                      <h1 id="faq_top_heading">
                        Our Journey, Our Vision: Exploring Who We Are
                      </h1>
                      {/* <TextEditor
                      // initialDescription={editedFeaturesTitle}
                      // documentId={features.id}
                      docFieldName="aboutUsHeading"
                      collectionName="features"
                      // onCancel={() => handleCancelClick("featurestitle")}
                    /> */}
                    </div>
                    {/* <p
                      style={{ marginTop: "35px", color: "var(--black-color)" }}
                    >
                      {organisationDocument && organisationDocument.aboutUs}
                    </p> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          organisationDocument &&
                          organisationDocument.aboutUs.toString("html"),
                      }}
                    ></p>
                  </div>
                </div>
                <div className="d-flex justify-content-left mt-2">
                  <Link to="/aboutus">
                    <div className="d-flex justify-content-center">
                      <a className=" home_service_mor_btn d-flex align-items-center ">
                        Read More
                        <span
                          class="material-symbols-outlined ba_animation"
                          style={{ marginLeft: "10px" }}
                        >
                          arrow_forward
                        </span>
                      </a>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="medium_padding_div"></div>
        </div>
        <div className="large_margin_div"></div>
        {/* Event Section */}
        <Event
          category="event"
          pageName="home"
          section="event"
          // addMoreImagesButton={false}
          addMoreVideosButton={false}
          addMoreTextContentButton={false}
        />
        <div className="large_margin_div"></div>
        {/* Faq Section */}
        <div className="faq_parent_div">
          <div className="small_padding_div"></div>
          <div className="container">
            <div className="row">
              {/* Left section */}
              <div
                className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center faq-img-div"
                data-aos="fade-down"
              >
                <img src="./assets/img/faq_img.webp" />
              </div>

              {/* Right section */}
              <div
                className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center flex-column right_sec"
                data-aos="fade-up"
              >
                <div>
                  <div className="faq_services_heading">
                    <p>FAQs</p>
                    <h1 id="faq_top_heading">Common Queries Clarified</h1>
                  </div>
                  <p
                    style={{
                      fontSize: "15px",
                      marginTop: "30px",
                      color: "var(--black-color)",
                    }}
                  >
                    Our objective is to offer rapid assistance and address
                    common issues, thereby minimizing the necessity to reach out
                    to support. By doing so, we aim to elevate your overall
                    browsing encounter and ensure seamless navigation.
                  </p>
                </div>
                <Faq
                  category="faq"
                  pageName="home"
                  section="faq"
                  addMoreVideosButton={false}
                  addMoreImagesButton={false}
                  faqLimit={4}
                />
              </div>
              {/* Conditionally render "More" button */}
              <div className="small_margin_div"></div>

              {faqCount >= 4 && (
                <>
                  <div className="d-flex justify-content-end">
                    <div className="home_faq_more_btnn" data-aos="fade-up">
                      <Link to="/faq">
                        <button className="btn_fill home_faq_Button d-flex align-items-center ">
                          More
                          <span
                            class="material-symbols-outlined ba_animation"
                            style={{ marginLeft: "10px" }}
                          >
                            arrow_forward
                          </span>
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="medium_margin_div"></div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="medium_margin_div"></div>
        {/* Testimonials */}
        <Testimonials
          category="testimonial"
          pageName="home"
          section="testimonial"
          addMoreVideosButton={false}
          addMoreTextContentButton={false}
        />
        <div className="large_margin_div"></div>
        {/* CAREER */}
        {pagesControlDoc && pagesControlDoc.career == true && (
          <div>
            <div>
              <div className="container">
                <div style={{ marginBottom: "20px" }}></div>
                <div className="faq_services_heading">
                  <p>Career</p>
                  <h1 id="faq_top_heading">Get Opportunity</h1>
                </div>
              </div>
              <Career showOnlyOneOnHome={true} />
              <div className="d-flex justify-content-center">
                <Link to="/career">
                  <button className="btn_fill home_service_mor_btn d-flex align-items-center ">
                    More
                    <span
                      class="material-symbols-outlined ba_animation"
                      style={{ marginLeft: "10px" }}
                    >
                      arrow_forward
                    </span>
                  </button>
                </Link>
              </div>
            </div>
            <div className="large_margin_div"></div>
          </div>
        )}
        {/* Our Team */}
        {(dbMediaTeamDocuments && dbMediaTeamDocuments.length > 0) ||
          (user && user.role === "admin" && (
            <>
              <div style={{ backgroundColor: "var(--white-color)" }}>
                <div className="medium_padding_div"></div>
                <Team
                  category="team"
                  pageName="home"
                  section="team"
                  addMoreVideosButton={false}
                  addMoreTextContentButton={false}
                />
                <div className="small_margin_div"></div>
                <div className="d-flex justify-content-center">
                  <Link to="/gallery">
                    <button className="btn_fill home_service_mor_btn d-flex align-items-center ">
                      More
                      <span
                        class="material-symbols-outlined ba_animation"
                        style={{ marginLeft: "10px" }}
                      >
                        arrow_forward
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="medium_padding_div"></div>
              </div>
            </>
          ))}
        <div className="medium_margin_div"></div>
        <CorporateTwoClients
          category="partners"
          pageName="home"
          section="partners"
          addMoreVideosButton={false}
          addMoreImagesButton={true}
          addMoreTextContentButton={false}
        />
      </div>
    </>
  );
};

export default Home;
