import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useState } from "react";

// import component
import Back from "../ui/back/HappyerpBack";
import OrderDetailTableView from "./HappyerpOrderDetailTableView";

// import css
import "./HappyerpCardDetailWrapper.scss";
import { format } from "date-fns";

const HappyerpCardDetail = ({ saleOrder, orders }) => {
  // card view mode js start
  const [viewMode, setviewMode] = useState("happyerp-col_2"); // Initial mode is grid with 3 columns

  const handleModeChange = (newViewMode) => {
    setviewMode(newViewMode);
  };
  // card view mode js end
  return (
    <div className="happyerp-pg_margin happyerp-pg_bg happyerp-card_detail_pg happyerp-page_height">
      <div className="happyerp-card_detail_inner">
        <div className="happyerp-pg_header happyerp-pg_header2">
          <div className="happyerp-first_row">
            <div className="happyerp-left">
              <h2 className="happyerp-text_black happyerp-df20 happyerp-medium happyerp-back_with_text">
                <Back />
                Back
              </h2>
            </div>
            <div className="happyerp-right">
              <div className="happyerp-button_filter">
                {/* <div
                  className={`bf_single ${
                    viewMode === "col_3" ? "active" : ""
                  }`}
                  onClick={() => handleModeChange("col_3")}
                >
                  <span className="material-symbols-outlined">
                    calendar_view_month
                  </span>
                </div> */}
                <div
                  className={`happyerp-bf_single ${
                    viewMode === "happyerp-col_2" ? "happyerp-active" : ""
                  }`}
                  onClick={() => handleModeChange("happyerp-col_2")}
                >
                  <span className="material-symbols-outlined">grid_view</span>
                </div>
                <div
                  className={`happyerp-bf_single ${
                    viewMode === "happyerp-table_view" ? "happyerp-active" : ""
                  }`}
                  onClick={() => handleModeChange("happyerp-table_view")}
                >
                  <span className="material-symbols-outlined">view_list</span>
                </div>
              </div>
            </div>
          </div>
          <div className="happyerp-dvg12"></div>
        </div>
        <Accordion className="happyerp-order_summary_accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {/* <div className="order_summary"> */}
              <div className="happyerp-text_heading_or">
                <div className="happyerp-text">
                  <h6>Delivery Date</h6>
                  <h5>
                    {saleOrder?.deliveryDate
                      ? format(saleOrder?.deliveryDate.toDate(), "dd/MM/yyyy")
                      : "----"}
                  </h5>
                </div>
                <div className="happyerp-text">
                  <h6>No Of Items</h6>
                  <h5>----</h5>
                </div>
                <div className="happyerp-text">
                  <h6>Total Quantity</h6>
                  <h5>----</h5>
                </div>
                <div className="happyerp-text">
                  <h6>Party Name</h6>
                  <h5>{saleOrder?.partyName ? saleOrder.partyName : "----"}</h5>
                </div>

                <div className="happyerp-text">
                  <h6>Placed By</h6>
                  <h5>{saleOrder?.placedBy ? saleOrder.placedBy : "----"}</h5>
                </div>
              </div>
              {/* </div> */}
            </Accordion.Header>
            <Accordion.Body
              style={{
                padding: "0px",
              }}
            >
              <div className="happyerp-card_padding happyerp-card_bg happyerp-card_shadow happyerp-card_bottom_border">
                <div className="happyerp-first_row">
                  <div className="happyerp-card_fields">
                    <div className="happyerp-cf_single happyerp-with_icon">
                      {/* <div className="icon">
                        <span class="material-symbols-outlined">today</span>
                      </div> */}
                      <div className="happyerp-text">
                        <h6>Created Date</h6>
                        <h5>
                          {" "}
                          {saleOrder?.selectedDate
                            ? format(
                                saleOrder?.selectedDate.toDate(),
                                "dd/MM/yyyy"
                              )
                            : "----"}
                        </h5>
                      </div>
                    </div>
                    <div className="happyerp-cf_single happyerp-with_icon">
                      {/* <div className="icon">
                        <span class="material-symbols-outlined">tag</span>
                      </div> */}

                      <div className="happyerp-text">
                        <h6>Order Number</h6>
                        <h5>{saleOrder?.id ? saleOrder?.id : "----"}</h5>
                      </div>
                    </div>
                    <div className="happyerp-cf_single happyerp-with_icon">
                      {/* <div className="icon">
                                    <span class="material-symbols-outlined">
                                        category
                                    </span>
                                </div> */}

                      <div className="happyerp-text">
                        <h6>Agent</h6>
                        <h5>
                          {saleOrder?.agentName ? saleOrder?.agentName : "----"}
                        </h5>
                      </div>
                    </div>
                    <div className="happyerp-cf_single happyerp-with_icon">
                      {/* <div className="icon">

                                    <span class="material-symbols-outlined">
                                        event
                                    </span>
                                </div> */}
                      <div className="happyerp-text">
                        <div className="happyerp-text">
                          <h6>Transport</h6>
                          <h5>
                            {saleOrder?.transportName
                              ? saleOrder?.transportName
                              : "----"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="dvg22"></div>
        {viewMode !== "happyerp-table_view" && (
          <div className={`happyerp-order_item ${viewMode}`}>
            {orders && orders.length > 0 ? (
              orders.map((order, i) => (
                <div
                  key={i}
                  className="happyerp-card_bg happyerp-card_shadow happyerp-card_bottom_border happyerp-item_single"
                >
                  <div className="happyerp-card_title">{order.itemName}</div>
                  <div className="happyerp-card_fields">
                    <div
                      className="happyerp-cf_single "
                      style={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {/* <div className="icon">
                                    <span class="material-symbols-outlined">
                                        today
                                    </span>
                                </div> */}
                      <div className="happyerp-text">
                        <h6>Design</h6>
                        <h5 className="happyerp-my_badge">
                          <span>{order.design}</span>
                        </h5>
                      </div>
                      <div className="happyerp-text">
                        <h6>Sub Design</h6>
                        <h5 className="happyerp-my_badge">
                          <span>{order.subDesign}</span>
                        </h5>
                      </div>
                      <div className="happyerp-text">
                        <h6>Style</h6>
                        <h5 className="happyerp-my_badge">
                          <span>{order?.style ?? "----"}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="happyerp-card_divider"></div>
                    <div className="happyerp-cf_single happyerp-with_icon">
                      {/* <div className="icon">
                                    <span class="material-symbols-outlined">
                                        tag
                                    </span>
                                </div> */}

                      <div className="happyerp-text">
                        <h6>Color</h6>
                        <div className="happyerp-badges_group">
                          {order.colors.map((color) => (
                            <h5
                              key={color}
                              className="happyerp-my_badge happyerp-color_badge"
                            >
                              <div
                                className="happyerp-color_wrapper"
                                style={{
                                  background: color,
                                }}
                              />
                              <span>{color}</span>
                            </h5>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="happyerp-card_divider"></div>
                    <div className="happyerp-cf_single happyerp-happyerp-with_icon">
                      {/* <div className="icon">
                                    <span class="material-symbols-outlined">
                                        tag
                                    </span>
                                </div> */}

                      <div className="happyerp-text">
                        <h6>Sizes & Quantity</h6>
                        <div className="happyerp-badges_group">
                          {order.sizes &&
                            Object.entries(order.sizes).map(
                              ([sizeName, quantity], i) => (
                                <h5 key={i} className="happyerp-my_badge">
                                  <span>
                                    {sizeName} | {quantity}
                                  </span>
                                </h5>
                              )
                            )}

                          {/* <h5 className="happyerp-my_badge ">
                            <span>Size 34 | 500</span>
                          </h5>
                          <h5 className="happyerp-my_badge ">
                            <span>Size 36 | 500</span>
                          </h5>
                          <h5 className="happyerp-my_badge ">
                            <span>Size 38 | 500</span>
                          </h5>
                          <h5 className="happyerp-my_badge ">
                            <span>Size 40 | 500</span>
                          </h5> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>There is no order</p>
            )}
          </div>
        )}
        {viewMode === "happyerp-table_view" && <OrderDetailTableView />}

        <div className="happyerp-dvg22"></div>
        {/* <div className="card_padding card_bg card_shadow card_bottom_border">
          <div className="row">
            <div className="col-sm-3">
              <div className="theme_btn btn_border">Cancel</div>
            </div>
            <div
              className="col-sm-9"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: "8px",
              }}
            >
              <div className="theme_btn">Edit</div>
              <div className="theme_btn">Order Done</div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HappyerpCardDetail;
