import React from "react";
import "./CustomerPanelLayout.scss";
import CustomerPanelSideBar from "../customerPanelSideBar/CustomerPanelSideBar";
import { Outlet } from "react-router-dom";

const CustomerPanelLayout = () => {
  return (
    <div className="customer-panel-layout">
      <CustomerPanelSideBar />
      <div className="customer-panel-layout-content">
        <Outlet />
      </div>
    </div>
  );
};

export default CustomerPanelLayout;
