import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useLogout } from "../firebase files/hooks/useLogout";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDocument } from "../firebase files/hooks/useDocument";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import DarkModeToggle from "react-dark-mode-toggle";
import { useRef } from "react";
import { projectStorage } from "../firebase config/config";
import SidebarNew from "./SidebarNew";
import useCart from "../firebase files/hooks/useCart";
import { projectFirestore } from "../firebase config/config";
import { useCollection } from "../firebase files/hooks/useCollection";
// styles & images
import "./Navbar.css";
// import Temple from "../assets/img/logo.png";

export default function Navbar() {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const projectId = projectFirestore._databaseId.projectId;

  // use cart hook

  const showCart = () => {
    navigate("/cart");
  };

  const { cartItems } = useCart();

  const { document: appTypeDetails, error: appTypeDetailserror } = useDocument(
    "settings",
    "AppType"
  );

  const { document: NavMenuNames, error: NavMenuNameserror } = useDocument(
    "settings",
    "EditNavbarMenus"
  );

  const { document: displayModeDoc, error: displayModeDocError } = useDocument(
    "settings",
    "mode"
  );

  const { document: iconTypeDocument, error: iconTypeDocumentError } =
    useDocument("settings", "Icons");

  const { document: functionTypeDetails, error: functionTypeDetailserror } =
    useDocument("settings", "FunctionType");

  const { document: navTypeDoc, error: navTypeDocerror } = useDocument(
    "settings",
    "navbarType"
  );

  const { document: pageControlDoc, error: pageControlDocError } = useDocument(
    "settings",
    "pagesControl"
  );

  const {
    document: dbTemplatesPaletteDocuments,
    error: dbTemplatesPaletteError,
  } = useDocument("settings", "themePalette");

  const { updateDocument, deleteDocument } = useFirestore("settings");

  const { document, error } = useDocument("settings", "organisation");

  const { documents: vendors, error: vendorsError } = useCollection("vendors");

  // START CODE FOR LIGHT/DARK MODE
  const toggleUserDarkMode = async () => {
    // Retrieving a value from localStorage
    const currentModeStatus = localStorage.getItem("mode");

    const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";
    // Storing a value in localStorage
    localStorage.setItem("mode", newDisplayMode);

    window.dispatchEvent(new Event("storage"));
  };
  // END CODE FOR LIGHT/DARK MODEs

  const { user } = useAuthContext();
  const navigate = useNavigate();
  // Extract the current path
  const currentPath = location.pathname;

  // for show active tab with diff color
  const [activeMenu, setActiveMenu] = useState("dashboard");
  const [activeSubMenu, setActiveSubMenu] = useState("");

  // CHANGE LOGO CODE START
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false); // State to track if the Confirm button should be disabled

  const handlePlusIconClick = () => {
    fileInputRef.current.click(); // Programmatically click the file input
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleConfirmButtonClick = async () => {
    if (!selectedFile) return;
    setIsConfirmButtonDisabled(true); // Enable the Confirm button once a file is selected

    const storageRef = projectStorage.ref();
    const logoRef = storageRef.child(`master_data/logo/${selectedFile.name}`);

    try {
      const snapshot = await logoRef.put(selectedFile);
      const shortLogo = await snapshot.ref.getDownloadURL();
      await updateDocument("organisation", { shortLogo: shortLogo });
      setSelectedFile("");
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsConfirmButtonDisabled(false);
    }
  };
  const handleCancelButtonClick = () => {
    setSelectedFile("");
  };
  // CHANGE LOGO CODE END

  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const profileRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (profileRef.current) {
        profileRef.current.classList.add("vibrate");
        setTimeout(() => {
          profileRef.current.classList.remove("vibrate");
        }, 300);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleScheduleBtnClick = () => {
    if (user) {
      navigate("/products");
    } else {
      navigate("/login");
    }
  };

  // Add this state near the top with other state declarations
  const [showVendorModal, setShowVendorModal] = useState(false);
  // Add state for selected vendor
  const [selectedVendor, setSelectedVendor] = useState(null);

  const handleVendorClick = () => {
    setShowVendorModal(!showVendorModal);
  };

  const { updateDocument: usersUpdateDocument } = useFirestore("users");

  const handleVendorSelect = async (vendor) => {
    setSelectedVendor(vendor);
    setShowVendorModal(false);

    // Update user document with selected location
    if (user) {
      try {
        const updates = {
          selectedLocation: vendor.name,
        };
        await usersUpdateDocument(user.uid, updates);
      } catch (error) {
        console.error("Error updating selected location:", error);
      }
    }
  };

  // Add this near other useDocument hooks
  const { document: userDoc, error: userDocError } = useDocument(
    "users",
    user?.uid
  );

  // Multi Location Feature
  const { document: multiLocationDoc, error: multiLocationDocError } =
    useDocument("settings", "multiLocation");

  const [isMultiLocationEnabled, setIsMultiLocationEnabled] = useState(false);

  useEffect(() => {
    if (multiLocationDoc) {
      setIsMultiLocationEnabled(multiLocationDoc.isEnabled);
    }
  }, [multiLocationDoc]);

  // Add useEffect to set initial selected vendor based on user document
  useEffect(() => {
    if (userDoc?.selectedLocation) {
      const savedVendor =
        vendors && vendors.find((v) => v.name === userDoc.selectedLocation);
      if (savedVendor) {
        setSelectedVendor(savedVendor);
      }
    }
  }, [userDoc, vendors]);

  return (
    <>
      {navTypeDoc && navTypeDoc.activeNav == "FixNav" ? (
        <>
          <nav
            className="navbar-default-parent sticky-top"
            style={{
              background:
                dbTemplatesPaletteDocuments &&
                dbTemplatesPaletteDocuments.activeThemePalette ===
                  "diwaliThemePalette"
                  ? "linear-gradient(to right, #F57000, #F5BF00)"
                  : dbTemplatesPaletteDocuments &&
                    dbTemplatesPaletteDocuments.activeThemePalette ===
                      "defaultThemePalette"
                  ? "var(--page-bg)"
                  : dbTemplatesPaletteDocuments &&
                    dbTemplatesPaletteDocuments.activeThemePalette ===
                      "holiThemePalette"
                  ? `radial-gradient(circle at 10% 20%, rgba(255, 0, 0, 1), transparent 20%),
                 radial-gradient(circle at 80% 30%, rgba(0, 255, 0, 1), transparent 20%),
                 radial-gradient(circle at 40% 70%, rgba(0, 0, 255, 1), transparent 20%),
                 radial-gradient(circle at 90% 80%, rgba(255, 255, 0, 1), transparent 20%),
                 radial-gradient(circle at 30% 90%, rgba(255, 165, 0, 1), transparent 20%),
                 radial-gradient(circle at 20% 50%, rgba(255, 0, 255, 1), transparent 15%),
                 radial-gradient(circle at 70% 40%, rgba(0, 255, 255, 1), transparent 25%),
                 radial-gradient(circle at 50% 50%, rgba(255, 192, 203, 1), transparent 30%),
                 radial-gradient(circle at 60% 60%, rgba(173, 216, 230, 1), transparent 25%),
                 radial-gradient(circle at 85% 20%, rgba(255, 223, 186, 1), transparent 20%)`
                  : dbTemplatesPaletteDocuments &&
                    dbTemplatesPaletteDocuments.activeThemePalette ===
                      "womenDayThemePalette"
                  ? `linear-gradient(135deg, rgb(255, 105, 180), rgb(255 93 180), rgb(255, 192, 203), rgb(255, 182, 193))`
                  : null,
            }}
          >
            <div className="container-fluid">
              <ul>
                <li
                  className={`col-6 col-md-3 col-lg-3  ${
                    user && user.role === "admin"
                      ? "navbar-logo-only-admin"
                      : "navbar-logo-only-customer"
                  }`}
                  onClick={
                    user && user.role === "admin" ? handlePlusIconClick : null
                  }
                >
                  <div className="navbar-logo-container d-flex align-items-center">
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                    <Link to="/">
                      <img
                        src={
                          selectedFile
                            ? URL.createObjectURL(selectedFile)
                            : document && document.shortLogo
                        }
                        alt="logo"
                        className={
                          document && document.full_name === ""
                            ? "navbar-logo-only-without-title"
                            : "navbar-logo-only-with-title"
                        }
                      />
                    </Link>
                    <Link to="/">
                      <p className="nav_logo_text">
                        {document && document.full_name}
                      </p>
                    </Link>
                  </div>
                </li>
                {selectedFile && (
                  <>
                    <button
                      className="btn_fill"
                      onClick={handleConfirmButtonClick}
                      disabled={isConfirmButtonDisabled}
                    >
                      {isConfirmButtonDisabled ? "Changing..." : "confirm"}
                    </button>
                    &nbsp;
                    <button
                      className="btn_border"
                      onClick={handleCancelButtonClick}
                    >
                      cancel
                    </button>
                  </>
                )}

                <div className=""></div>
                <div className="default-navbar-large">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                    <div className="navbar-laptop-menu-links-div">
                      <div className="navbar-laptop-menu-links">
                        {/* START SHOW NEWLY CREATED ARRAY MAP MENUS HERE */}
                        {NavMenuNames &&
                        NavMenuNames.menuDetails &&
                        Array.isArray(NavMenuNames.menuDetails) &&
                        NavMenuNames.menuDetails.length > 0 ? (
                          NavMenuNames.menuDetails.map((menuItem, index) => {
                            // Check if the menu should be visible for the logged-in user
                            const isMenuVisible =
                              (menuItem.menuFor === "allRoles" ||
                                (user?.role &&
                                  menuItem.menuFor === user.role)) &&
                              menuItem.visibilityInLaptopNav !== true &&
                              menuItem.menuStatus !== "inactive";

                            // Filter submenus based on subMenuFor field and apply status and visibility checks
                            const filteredSubMenus = menuItem.subMenu?.filter(
                              (subMenuItem) => {
                                // Only include subMenus where subMenuStatus is active and visibilityInLaptopNav is true
                                return (
                                  subMenuItem.subMenuStatus !== "inactive" && // subMenuStatus is active
                                  (subMenuItem.visibilityInLaptopNav !== true ||
                                    subMenuItem.subMenuStatus === "inactive") && // If visibilityInLaptopNav is false, it shouldn't be shown unless the status is inactive
                                  (subMenuItem.subMenuFor === "allRoles" ||
                                    (user?.role &&
                                      subMenuItem.subMenuFor === user.role))
                                );
                              }
                            );
                            // Skip rendering if menu is not visible
                            if (!isMenuVisible) {
                              return null;
                            }

                            // Determine if the current menu is active based on the URL
                            const isActiveMenu =
                              currentPath === menuItem.pageUrl;

                            return (
                              <React.Fragment key={index}>
                                <Link to={menuItem.pageUrl}>
                                  <div
                                    className={`navbar-menu-item ${
                                      isActiveMenu
                                        ? "default_nav_active_menu"
                                        : ""
                                    }`}
                                    onMouseEnter={() =>
                                      setActiveSubMenu(menuItem.menuName)
                                    }
                                    onMouseLeave={() => setActiveSubMenu("")}
                                  >
                                    <span
                                      className="default_nav_active_menu_icons"
                                      dangerouslySetInnerHTML={{
                                        __html: menuItem.iconName,
                                      }}
                                    />
                                    <h1>{menuItem.menuName}</h1>

                                    {filteredSubMenus &&
                                      filteredSubMenus.length > 0 && (
                                        <svg
                                          className="default_nav_active_menu_bottm_direction"
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="24px"
                                          viewBox="0 -960 960 960"
                                          width="24px"
                                          fill="currentColor"
                                        >
                                          <path d="M480-360 280-560h400L480-360Z" />
                                        </svg>
                                      )}

                                    {/* Render Submenu */}
                                    {activeSubMenu === menuItem.menuName &&
                                      filteredSubMenus &&
                                      filteredSubMenus.length > 0 && (
                                        <div className="navbar-sub-menu-dropdown-menu">
                                          {filteredSubMenus.map(
                                            (subMenuItem, subIndex) => (
                                              <p key={subIndex}>
                                                <Link
                                                  to={
                                                    subMenuItem.subMenuPageUrl
                                                  }
                                                  className="d-flex align-items-center justify-content-between navbar-sub-menu-dropdown-name"
                                                >
                                                  <div className="d-flex align-items-center">
                                                    {/* <span className="material-symbols-outlined">
                                                      {subMenuItem.subMenuIcon}
                                                    </span> */}
                                                    <span
                                                      // style={{color:"var(--black-color)"}}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          subMenuItem.subMenuIcon,
                                                      }}
                                                    />
                                                    &nbsp;
                                                    {subMenuItem.subMenuName}
                                                  </div>
                                                </Link>
                                              </p>
                                            )
                                          )}
                                        </div>
                                      )}
                                  </div>
                                </Link>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <p>No menu items available.</p>
                        )}
                        {/* END SHOW NEWLY CREATED ARRAY MAP MENUS HERE */}

                        <div className="navbar-two-extra-menu">
                          {/* SHOPING CART START */}
                          {pageControlDoc &&
                            pageControlDoc.cart === true &&
                            pageControlDoc &&
                            pageControlDoc.login === true && (
                              <div
                                onClick={showCart}
                                className={`menu-item ${
                                  location.pathname === "/cart"
                                    ? "default_nav_active_menu"
                                    : ""
                                }`}
                                style={{
                                  cursor: "pointer",
                                  padding: "7px 15px",
                                  borderRadius: "50px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span className="material-symbols-outlined">
                                  shopping_cart
                                </span>
                                {user && user ? (
                                  <span className="navbar-cart-quantity">
                                    <span className="cart-quantity-counter">
                                      {cartItems.length}
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          {/* SHOPING CART END */}

                          {displayModeDoc &&
                            displayModeDoc.isDisplayMode === true && (
                              <DarkModeToggle
                                onChange={() => toggleUserDarkMode()}
                                checked={
                                  localStorage.getItem("mode") === "dark"
                                }
                                size={40}
                              />
                            )}

                          {iconTypeDocument &&
                            iconTypeDocument.BottomRightFixedIcons
                              .notification == true && (
                              <Link
                                to="/notifications"
                                className="ab_whatsapp_icon"
                              >
                                <img src="/assets/img/icons8-notification.gif" />
                              </Link>
                            )}
                        </div>
                      </div>

                      {/* SHOW SELECTED VENDOR NAME */}

                      {user && isMultiLocationEnabled && (
                        <div
                          className="navbar-vendor-name"
                          onClick={handleVendorClick}
                        >
                          <div className="vendor-preview">
                            <div className="vendor-info">
                              <span className="material-symbols-outlined">
                                location_on
                              </span>
                              <h5>
                                {userDoc && userDoc.selectedLocation
                                  ? userDoc?.selectedLocation
                                  : "Choose a Location"}
                              </h5>
                              {/* <small>
                                {selectedVendor ? selectedVendor.city : ""}
                              </small> */}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Add this modal JSX right before the closing tag of the main container */}
                      {showVendorModal && (
                        <div
                          className="vendor-modal-overlay"
                          onClick={() => setShowVendorModal(false)}
                        >
                          <div
                            className="vendor-modal"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="vendor-modal-header">
                              <h2>Select From Available Locations</h2>
                              <span
                                className="material-symbols-outlined close-button"
                                onClick={() => setShowVendorModal(false)}
                              >
                                close
                              </span>
                            </div>
                            <div className="vendor-list">
                              {vendors &&
                                vendors.map((vendor) => (
                                  <div
                                    key={vendor.id}
                                    className={`vendor-card ${
                                      selectedVendor?.id === vendor.id
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() => handleVendorSelect(vendor)}
                                  >
                                    <div className="vendor-card-info">
                                      <h3>{vendor.name}</h3>
                                      <p>{vendor.city}</p>
                                    </div>
                                    {selectedVendor?.id === vendor.id && (
                                      <span className="material-symbols-outlined check-icon">
                                        check_circle
                                      </span>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {user ? (
                        <div
                          className="profile-menu-container"
                          onMouseEnter={() => setShowProfileMenu(true)}
                          onMouseLeave={() => setShowProfileMenu(false)}
                        >
                          <Link
                            to="/profile"
                            className={`menu_single profile pointer ${
                              location.pathname === "/profile" ? "active" : ""
                            }`}
                            style={{ display: "flex" }}
                            ref={profileRef}
                          >
                            <span>Hi, {user.fullName?.split(" ")[0]}</span>
                            <div className="user_img">
                              {user.photoURL === "" ? (
                                <img
                                  src="https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/userThumbnails%2F1default.png?alt=media&token=38880453-e642-4fb7-950b-36d81d501fe2&_gl=1*1bbo31y*_ga*MTEyODU2MDU1MS4xNjc3ODEwNzQy*_ga_CW55HF8NVT*MTY4NjIzODcwMC42OS4xLjE2ODYyMzkwMjIuMC4wLjA."
                                  alt=""
                                  className="nav_profile_icon"
                                />
                              ) : (
                                <img src={user.photoURL} alt="" />
                              )}
                            </div>
                          </Link>

                          {showProfileMenu &&
                            functionTypeDetails &&
                            functionTypeDetails.function === "ecommerce" && (
                              <div className="profile-dropdown-menu">
                                <Link
                                  to="/profile"
                                  className="navbar-booking-dropdown-item"
                                >
                                  <span className="material-symbols-outlined">
                                    person
                                  </span>
                                  <span>My Profile</span>
                                </Link>
                                <Link
                                  to="/wishlist"
                                  className="navbar-booking-dropdown-item"
                                >
                                  <span className="material-symbols-outlined">
                                    bookmark_heart
                                  </span>
                                  <span>Wishlist</span>
                                </Link>
                                <Link
                                  to="/orders"
                                  className="navbar-booking-dropdown-item"
                                >
                                  <span className="material-symbols-outlined">
                                    shopping_bag
                                  </span>
                                  <span>My Orders</span>
                                </Link>
                                <Link
                                  to="/address"
                                  className="navbar-booking-dropdown-item"
                                >
                                  <span className="material-symbols-outlined">
                                    location_on
                                  </span>
                                  <span>My Addresses</span>
                                </Link>
                                {user && user.isAttendance && (
                                  <Link
                                    to="/attendance"
                                    className="navbar-booking-dropdown-item"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="22px"
                                      viewBox="0 -960 960 960"
                                      width="22px"
                                      fill="currentColor"
                                      style={{ marginRight: "12px" }}
                                    >
                                      <path d="M481-781q106 0 200 45.5T838-604q7 9 4.5 16t-8.5 12q-6 5-14 4.5t-14-8.5q-55-78-141.5-119.5T481-741q-97 0-182 41.5T158-580q-6 9-14 10t-14-4q-7-5-8.5-12.5T126-602q62-85 155.5-132T481-781Zm0 94q135 0 232 90t97 223q0 50-35.5 83.5T688-257q-51 0-87.5-33.5T564-374q0-33-24.5-55.5T481-452q-34 0-58.5 22.5T398-374q0 97 57.5 162T604-121q9 3 12 10t1 15q-2 7-8 12t-15 3q-104-26-170-103.5T358-374q0-50 36-84t87-34q51 0 87 34t36 84q0 33 25 55.5t59 22.5q34 0 58-22.5t24-55.5q0-116-85-195t-203-79q-118 0-203 79t-85 194q0 24 4.5 60t21.5 84q3 9-.5 16T208-205q-8 3-15.5-.5T182-217q-15-39-21.5-77.5T154-374q0-133 96.5-223T481-687Zm0-192q64 0 125 15.5T724-819q9 5 10.5 12t-1.5 14q-3 7-10 11t-17-1q-53-27-109.5-41.5T481-839q-58 0-114 13.5T260-783q-8 5-16 2.5T232-791q-4-8-2-14.5t10-11.5q56-30 117-46t124-16Zm0 289q93 0 160 62.5T708-374q0 9-5.5 14.5T688-354q-8 0-14-5.5t-6-14.5q0-75-55.5-125.5T481-550q-76 0-130.5 50.5T296-374q0 81 28 137.5T406-123q6 6 6 14t-6 14q-6 6-14 6t-14-6q-59-62-90.5-126.5T256-374q0-91 66-153.5T481-590Zm-1 196q9 0 14.5 6t5.5 14q0 75 54 123t126 48q6 0 17-1t23-3q9-2 15.5 2.5T744-191q2 8-3 14t-13 8q-18 5-31.5 5.5t-16.5.5q-89 0-154.5-60T460-374q0-8 5.5-14t14.5-6Z" />
                                    </svg>
                                    <span>Attendance</span>
                                  </Link>
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        pageControlDoc &&
                        pageControlDoc.login && (
                          <Link to="/profile">
                            <div
                              className={`menu_single login pointer ${
                                location.pathname === "/login" ? "active" : ""
                              }`}
                            >
                              <span className="material-symbols-outlined">
                                account_circle
                              </span>
                            </div>
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>

                {/* PHONE NAV START */}
                <div className="default-navbar-small">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                    {(appTypeDetails &&
                      appTypeDetails.apptype === "liteplus") ||
                      (appTypeDetails && appTypeDetails.apptype === "pro" && (
                        <div className="d-flex">
                          {user && user.isAttendance && (
                            <Link to="/attendance">
                              <li>
                                <div className="">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="27px"
                                    viewBox="0 -960 960 960"
                                    width="27px"
                                    fill="currentColor"
                                  >
                                    <path d="M481-781q106 0 200 45.5T838-604q7 9 4.5 16t-8.5 12q-6 5-14 4.5t-14-8.5q-55-78-141.5-119.5T481-741q-97 0-182 41.5T158-580q-6 9-14 10t-14-4q-7-5-8.5-12.5T126-602q62-85 155.5-132T481-781Zm0 94q135 0 232 90t97 223q0 50-35.5 83.5T688-257q-51 0-87.5-33.5T564-374q0-33-24.5-55.5T481-452q-34 0-58.5 22.5T398-374q0 97 57.5 162T604-121q9 3 12 10t1 15q-2 7-8 12t-15 3q-104-26-170-103.5T358-374q0-50 36-84t87-34q51 0 87 34t36 84q0 33 25 55.5t59 22.5q34 0 58-22.5t24-55.5q0-116-85-195t-203-79q-118 0-203 79t-85 194q0 24 4.5 60t21.5 84q3 9-.5 16T208-205q-8 3-15.5-.5T182-217q-15-39-21.5-77.5T154-374q0-133 96.5-223T481-687Zm0-192q64 0 125 15.5T724-819q9 5 10.5 12t-1.5 14q-3 7-10 11t-17-1q-53-27-109.5-41.5T481-839q-58 0-114 13.5T260-783q-8 5-16 2.5T232-791q-4-8-2-14.5t10-11.5q56-30 117-46t124-16Zm0 289q93 0 160 62.5T708-374q0 9-5.5 14.5T688-354q-8 0-14-5.5t-6-14.5q0-75-55.5-125.5T481-550q-76 0-130.5 50.5T296-374q0 81 28 137.5T406-123q6 6 6 14t-6 14q-6 6-14 6t-14-6q-59-62-90.5-126.5T256-374q0-91 66-153.5T481-590Zm-1 196q9 0 14.5 6t5.5 14q0 75 54 123t126 48q6 0 17-1t23-3q9-2 15.5 2.5T744-191q2 8-3 14t-13 8q-18 5-31.5 5.5t-16.5.5q-89 0-154.5-60T460-374q0-8 5.5-14t14.5-6Z" />
                                  </svg>
                                  <div></div>
                                </div>
                              </li>
                            </Link>
                          )}

                          {
                            <>
                              <Link to="/notifications">
                                <li>
                                  <div className="navbar-notification-div">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="28px"
                                      viewBox="0 -960 960 960"
                                      width="28px"
                                      fill="currentColor"
                                      style={{ marginLeft: "6px" }}
                                    >
                                      <path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" />
                                    </svg>
                                    <div></div>
                                  </div>
                                </li>
                              </Link>
                            </>
                          }

                          {user &&
                            functionTypeDetails &&
                            functionTypeDetails.function === "ecommerce" && (
                              <div
                                className="profile-menu-container"
                                onMouseEnter={() => setShowProfileMenu(true)}
                                onMouseLeave={() => setShowProfileMenu(false)}
                              >
                                <Link>
                                  <li>
                                    <div className="navbar-hover-menu">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="28px"
                                        viewBox="0 -960 960 960"
                                        width="28px"
                                        fill="currentColor"
                                      >
                                        <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                                      </svg>
                                      <div></div>
                                    </div>
                                  </li>
                                </Link>

                                {showProfileMenu &&
                                  functionTypeDetails &&
                                  functionTypeDetails.function ===
                                    "ecommerce" && (
                                    <div className="profile-dropdown-menu">
                                      <Link
                                        to="/cart"
                                        className="navbar-booking-dropdown-item"
                                        onClick={() =>
                                          setShowProfileMenu(false)
                                        }
                                      >
                                        <span className="material-symbols-outlined">
                                          shopping_cart
                                        </span>
                                        <span>Cart</span>
                                      </Link>
                                      <Link
                                        to="/wishlist"
                                        className="navbar-booking-dropdown-item"
                                        onClick={() =>
                                          setShowProfileMenu(false)
                                        }
                                      >
                                        <span className="material-symbols-outlined">
                                          bookmark_heart
                                        </span>
                                        <span>Wishlist</span>
                                      </Link>
                                      <Link
                                        to="/orders"
                                        className="navbar-booking-dropdown-item"
                                        onClick={() =>
                                          setShowProfileMenu(false)
                                        }
                                      >
                                        <span className="material-symbols-outlined">
                                          shopping_bag
                                        </span>
                                        <span>My Orders</span>
                                      </Link>
                                      <Link
                                        to="/address"
                                        className="navbar-booking-dropdown-item"
                                        onClick={() =>
                                          setShowProfileMenu(false)
                                        }
                                      >
                                        <span className="material-symbols-outlined">
                                          location_on
                                        </span>
                                        <span>My Addresses</span>
                                      </Link>
                                      {user && user.isAttendance && (
                                        <Link
                                          to="/attendance"
                                          className="navbar-booking-dropdown-item"
                                          onClick={() =>
                                            setShowProfileMenu(false)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="22px"
                                            viewBox="0 -960 960 960"
                                            width="22px"
                                            fill="currentColor"
                                            style={{ marginRight: "11px" }}
                                          >
                                            <path d="M481-781q106 0 200 45.5T838-604q7 9 4.5 16t-8.5 12q-6 5-14 4.5t-14-8.5q-55-78-141.5-119.5T481-741q-97 0-182 41.5T158-580q-6 9-14 10t-14-4q-7-5-8.5-12.5T126-602q62-85 155.5-132T481-781Zm0 94q135 0 232 90t97 223q0 50-35.5 83.5T688-257q-51 0-87.5-33.5T564-374q0-33-24.5-55.5T481-452q-34 0-58.5 22.5T398-374q0 97 57.5 162T604-121q9 3 12 10t1 15q-2 7-8 12t-15 3q-104-26-170-103.5T358-374q0-50 36-84t87-34q51 0 87 34t36 84q0 33 25 55.5t59 22.5q34 0 58-22.5t24-55.5q0-116-85-195t-203-79q-118 0-203 79t-85 194q0 24 4.5 60t21.5 84q3 9-.5 16T208-205q-8 3-15.5-.5T182-217q-15-39-21.5-77.5T154-374q0-133 96.5-223T481-687Zm0-192q64 0 125 15.5T724-819q9 5 10.5 12t-1.5 14q-3 7-10 11t-17-1q-53-27-109.5-41.5T481-839q-58 0-114 13.5T260-783q-8 5-16 2.5T232-791q-4-8-2-14.5t10-11.5q56-30 117-46t124-16Zm0 289q93 0 160 62.5T708-374q0 9-5.5 14.5T688-354q-8 0-14-5.5t-6-14.5q0-75-55.5-125.5T481-550q-76 0-130.5 50.5T296-374q0 81 28 137.5T406-123q6 6 6 14t-6 14q-6 6-14 6t-14-6q-59-62-90.5-126.5T256-374q0-91 66-153.5T481-590Zm-1 196q9 0 14.5 6t5.5 14q0 75 54 123t126 48q6 0 17-1t23-3q9-2 15.5 2.5T744-191q2 8-3 14t-13 8q-18 5-31.5 5.5t-16.5.5q-89 0-154.5-60T460-374q0-8 5.5-14t14.5-6Z" />
                                          </svg>
                                          <span>Attendance</span>
                                        </Link>
                                      )}
                                    </div>
                                  )}
                              </div>
                            )}
                        </div>
                      ))}
                  </div>
                </div>
                {/* PHONE NAV END */}
              </ul>
            </div>
          </nav>
        </>
      ) : (
        <SidebarNew
          navigate={navigate}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
      )}
    </>
  );
}
