import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export default function HappyerpReactTableGlobalFilter({ filter, setFilter }) {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 200);

  return (
    <div className="happyerp-rt_global_search happyerp-search_field">
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search"
        style={{ height: "38px" }}
      ></input>
      <div className="happyerp-field_icon">
        <span className="material-symbols-outlined">search</span>
      </div>
    </div>
  );
}
