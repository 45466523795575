import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDocument } from "../firebase files/hooks/useDocument";
import "./NavbarBottom.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
export default function NavbarBottom() {
  const location = useLocation(); // Get the current location
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const { document: appTypeDetails, error: appTypeDetailserror } = useDocument(
    "settings",
    "AppType"
  );
  const { document: NavMenuNames, error: NavMenuNameserror } = useDocument(
    "settings",
    "EditNavbarMenus"
  );

  const { document: pageControlDoc, error: pageControlDocError } = useDocument(
    "settings",
    "pagesControl"
  );

  const {
    document: dbTemplatesPaletteDocuments,
    error: dbTemplatesPaletteError,
  } = useDocument("settings", "themePalette");

  // Filter menus for mobile bottom based on role
  const mobileMenus =
    NavMenuNames?.menuDetails
      ?.filter(
        (menu) =>
          menu.forMobileBottom &&
          (menu.menuFor === "allRoles" ||
            (user?.role && menu.menuFor === user.role)) &&
          menu.menuStatus === "active" // Only show active menus
      )
      ?.sort((a, b) => a.orderForMobileBottom - b.orderForMobileBottom) || [];

  // Split the sorted menus into two groups
  const firstTwoMenus = mobileMenus.slice(0, 2);
  const remainingMenus = mobileMenus.slice(2);

  const isActive = (menuUrl) => location.pathname === menuUrl;

  return (
    <div
      className="small navbar-mobile-bottom"
      style={{
        background:
          dbTemplatesPaletteDocuments &&
          dbTemplatesPaletteDocuments.activeThemePalette ===
            "diwaliThemePalette"
            ? "linear-gradient(to right, #F57000, #F5BF00)"
            : dbTemplatesPaletteDocuments &&
              dbTemplatesPaletteDocuments.activeThemePalette ===
                "defaultThemePalette"
            ? "var(--page-bg)"
            : dbTemplatesPaletteDocuments &&
              dbTemplatesPaletteDocuments.activeThemePalette ===
                "holiThemePalette"
            ? `radial-gradient(circle at 10% 20%, rgba(255, 0, 0, 1), transparent 20%),
           radial-gradient(circle at 80% 30%, rgba(0, 255, 0, 1), transparent 20%),
           radial-gradient(circle at 40% 70%, rgba(0, 0, 255, 1), transparent 20%),
           radial-gradient(circle at 90% 80%, rgba(255, 255, 0, 1), transparent 20%),
           radial-gradient(circle at 30% 90%, rgba(255, 165, 0, 1), transparent 20%),
           radial-gradient(circle at 20% 50%, rgba(255, 0, 255, 1), transparent 15%),
           radial-gradient(circle at 70% 40%, rgba(0, 255, 255, 1), transparent 25%),
           radial-gradient(circle at 50% 50%, rgba(255, 192, 203, 1), transparent 30%),
           radial-gradient(circle at 60% 60%, rgba(173, 216, 230, 1), transparent 25%),
           radial-gradient(circle at 85% 20%, rgba(255, 223, 186, 1), transparent 20%)`
            : dbTemplatesPaletteDocuments &&
              dbTemplatesPaletteDocuments.activeThemePalette ===
                "womenDayThemePalette"
            ? `linear-gradient(135deg, rgb(255, 105, 180), rgb(255 93 180), rgb(255, 192, 203), rgb(255, 182, 193))`
            : null,
      }}
    >
      <div className="navbar-mobile-bottom-menu" id="divBottomNavBar">
        {/* Render the first two menus */}
        {firstTwoMenus &&
          firstTwoMenus.map((menu) => (
            <Link
              to={menu.pageUrl}
              className={`navbar-mobile-bottom-menu-a bm_single ${
                isActive(menu.pageUrl) ? "active_bottom_menu" : ""
              }`}
              key={menu.orderForMobileBottom}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* <span className="material-symbols-outlined">{menu.iconName}</span> */}
                <span dangerouslySetInnerHTML={{ __html: menu.iconName }} />
                <small>{menu.menuName}</small>
              </div>
            </Link>
          ))}

        {pageControlDoc && pageControlDoc.login && (
          <div className="bmp_single">
            <Link to="/profile" className="profile_icon bm_single">
              {user && user ? (
                <img
                  className="bottom-nav-profil-pic"
                  src={user && user.photoURL}
                  alt="user photo"
                />
              ) : (
                <span className="material-symbols-outlined">person</span>
              )}
            </Link>
          </div>
        )}

        {/* Render the remaining two menus */}
        {remainingMenus &&
          remainingMenus.map((menu) => (
            <Link
              to={menu.pageUrl}
              className={`navbar-mobile-bottom-menu-a bm_single ${
                isActive(menu.pageUrl) ? "active_bottom_menu" : ""
              }`}
              key={menu.orderForMobileBottom}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* <span className="material-symbols-outlined">{menu.iconName}</span> */}
                <span dangerouslySetInnerHTML={{ __html: menu.iconName }} />
                <small>{menu.menuName}</small>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}
