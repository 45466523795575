import React, { useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import "./HappyerpReactTable.scss";
import ReactTableGlobalFilter from "./HappyerpReactTableGlobalFilter";
import ReactTableColumnFilter from "./HappyerpReactTableColumnFilter";
import Select from "react-select";

export default function HappyerpReactTable({
  tableColumns,
  tableData,
  hideCheckboxes = false,
  showColumnFilter = true,
}) {
  // const columns = useMemo(() => tableColumns, [])
  // const data = useMemo(() => tableData, [])

  // const tableInstance = useTable({
  //     columns: tableColumns,
  //     data: tableData
  // })

  const defaultColumn = useMemo(() => {
    return {
      Filter: ReactTableColumnFilter,
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { globalFilter } = state;
  const { pageIndex, pageSize } = state;

  // export click code start
  // status change code start
  const [showEditPopup, setShowEditPopup] = useState(false);

  const handleEditClick = () => {
    setShowEditPopup(true);
  };

  const handleCloseClick = () => {
    setShowEditPopup(false);
  };

  //code for the selected rows through checkbox:
  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (rowId) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowId)
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  //code for selecting columns starts:

  const [rowTableFields, setRowTableFields] = useState([]);
  const [columnTableFields, setColumnTableFields] = useState([]);

  const allFields = [
    { value: "Order", label: "Order" },
    { value: "Sales Agent", label: "Sales Agent" },
    { value: "Party", label: "Party" },
    { value: "Item", label: "Item" },
    { value: "Design", label: "Design" },
    { value: "Sub-design", label: "Sub-design" },
    { value: "Color", label: "Color" },
    { value: "Style", label: "Style" },
    { value: "Size", label: "Size" },
    { value: "Placed By", label: "Placed By" },
  ];

  const availableFieldsForColumns = allFields.filter(
    (field) =>
      !rowTableFields.some((selected) => selected.value === field.value)
  );

  // Custom styles for better UI
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: "38px",
      minwidth: "200px",
    }),
    option: (base, state) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      backgroundColor: state.isSelected ? "#f0f8ff" : "white",
      ":hover": {
        backgroundColor: "#f0f8ff",
      },
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#e6f7ff",
      color: "#007bff",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#007bff",
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#007bff",
      ":hover": {
        backgroundColor: "#007bff",
        color: "white",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    // menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  // ends--->

  return (
    <>
      <div className="happyerp-vg22"></div>
      <div className="happyerp-filters happyerp-table_global_filter">
        <div className="happyerp-left" style={{ display: "flex", gap: "5px" }}>
          <div className="happyerp-lleft">
            <ReactTableGlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            />
          </div>
          {showColumnFilter && (
            <div className="happyerp-rleft">
              <Select
                placeholder="Select/Remove Columns"
                isMulti
                closeMenuOnSelect={false}
                options={availableFieldsForColumns}
                value={columnTableFields}
                onChange={setColumnTableFields}
                isclearable={true}
                issearchable={true}
                styles={customStyles}
              />
            </div>
          )}
        </div>

        <div className="happyerp-right happyerp-relative happyerp-export_click">
          <img
            src="/assets/img/happyerp/icon/file.png"
            alt=""
            style={{
              height: "32px",
            }}
            onClick={handleEditClick}
            className="happyerp-pointer"
          />
          {/* Edit Popup */}
          {showEditPopup && (
            <div className="happyerp-edit_status_popup">
              <div className="happyerp-close_form" onClick={handleCloseClick}>
                <span class="material-symbols-outlined">close</span>
              </div>
              <div className="happyerp-edit_content">
                <div className="happyerp-form_field">
                  <label
                    htmlFor=""
                    className="happyerp-label happyerp-text-center mb-1"
                  >
                    Export In
                  </label>
                  <div className="happyerp-export_format">
                    <div className="happyerp-ef_single">
                      <h6>Excel</h6>
                      <img src="/assets/img/happyerp/excel_logo.png" alt="" />
                    </div>
                    <div className="happyerp-ef_single">
                      <h6>CSV</h6>
                      <img src="/assets/img/happyerp/csv.png" alt="" />
                    </div>
                    <div className="happyerp-ef_single">
                      <h6>PDF</h6>
                      <img src="/assets/img/happyerp/pdf.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="happyerp-react_table">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headergroup) => (
              <tr {...headergroup.getFooterGroupProps()}>
                {headergroup.headers.map((column, index) => {
                  let leftPosition;
                  if (index === 0) {
                    leftPosition = "0px";
                  } else if (index === 1) {
                    const firstHeader = document.querySelector(
                      `[data-header-index="0"]`
                    );
                    leftPosition = firstHeader
                      ? `${firstHeader.offsetWidth}px`
                      : "0px";
                  }

                  return (
                    <th
                      {...column.getHeaderProps()}
                      data-header-index={index}
                      //code for freezing column
                      style={{
                        position: index < 2 ? "sticky" : undefined,
                        left: leftPosition,
                        zIndex: index < 2 ? 2 : undefined,
                        background: index < 2 ? "#f5f5f5" : undefined,
                      }}
                    >
                      {/* <div>
                        {index === 0 ? (
                          <>
                            <input type="checkbox" />
                            <span>{column.render("Header")}</span>
                          </>
                        ) : (
                          column.render("Header")
                        )}
                        {/* <div className="close_form">
                <span class="material-symbols-outlined">close</span>
              </div> */}
                      {/* </div> */}
                      {column.render("Header")}

                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const isSelected = selectedRows.includes(row.id);
              return (
                <tr
                  {...row.getRowProps()}
                  style={{ background: isSelected ? "#FFFFE0" : "transparent" }}
                >
                  {row.cells.map((cell, index) => {
                    let leftPosition;
                    if (index === 0) {
                      leftPosition = "0px";
                    } else if (index === 1) {
                      const firstCell = document.querySelector(
                        `[data-cell-index="0"]`
                      );
                      leftPosition = firstCell
                        ? `${firstCell.offsetWidth}px`
                        : "0px";
                    }

                    return (
                      <td
                        {...cell.getCellProps()}
                        data-cell-index={index}
                        //code for freezing column
                        style={{
                          position: index < 2 ? "sticky" : undefined,
                          left: leftPosition,
                          zIndex: index < 2 ? 2 : undefined,
                          background: index < 2 ? "#f5f5f5" : undefined,
                          // border: "1px solid black",
                        }}
                      >
                        {/* {!hideCheckboxes && ()} */}
                        <div
                          className="happyerp-table_row_checkboxes"
                          style={{
                            alignItems: "center",
                            display: "inline-flex",
                            // padding: "5px",
                            gap: "5px",
                          }}
                        >
                          {index === 0 && !hideCheckboxes && (
                            <input
                              type="checkbox"
                              style={{
                                cursor: "pointer",
                                height: "18px",
                                width: "18px",
                              }}
                              checked={isSelected}
                              onChange={() => handleCheckboxChange(row.id)}
                            />
                          )}
                          <span>{cell.render("Cell")}</span>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {tableData.length > 10 && (
        <div className="happyerp-react_pagination">
          <div className="happyerp-left">
            <div className="happyerp-items_on_page">
              <span>Items per page</span>
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[5, 10, 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="happyerp-right">
            <div className="happyerp-previous_previous_first">
              <span
                className={`material-symbols-outlined happyerp-previous_first ${
                  !canPreviousPage ? "happyerp-disabled" : "happyerp-pointer"
                }`}
                onClick={() => gotoPage(0)}
              >
                skip_previous
              </span>
              <div
                className={`happyerp-previous_click ${
                  !canPreviousPage ? "happyerp-disabled" : "happyerp-pointer"
                }`}
                onClick={() => previousPage()}
              >
                <div className="happyerp-arrow">
                  <span className="material-symbols-outlined">
                    arrow_back_ios
                  </span>{" "}
                  <span className="material-symbols-outlined">
                    arrow_back_ios
                  </span>
                </div>

                <span>Previous</span>
              </div>
            </div>
            <div className="happyerp-total_page">
              <span className="happyerp-active_page">{pageIndex + 1}</span>
              <span className="happyerp-tp_number">
                of {pageOptions.length}
              </span>
            </div>
            <div className="happyerp-next_next_last">
              <div
                className={`happyerp-next_click ${
                  !canNextPage ? "happyerp-disabled" : "happyerp-pointer"
                }`}
                onClick={() => nextPage()}
              >
                <span>Next</span>
                <div className="happyerp-arrow">
                  <span className="material-symbols-outlined">
                    arrow_forward_ios
                  </span>
                  <span className="material-symbols-outlined">
                    arrow_forward_ios
                  </span>
                </div>
              </div>
              <span
                className={`material-symbols-outlined happyerp-next_last ${
                  !canNextPage ? "happyerp-disabled" : "happyerp-pointer"
                }`}
                onClick={() => gotoPage(pageCount - 1)}
              >
                skip_next
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
