import React, { useState } from "react";
import DatePicker from "react-datepicker";
// npm install react-datepicker
import "react-datepicker/dist/react-datepicker.css";

// import component
import HappyerpNineDots from "../../../happyerpComponents/Dashboard/HappyerpNineDots";
import HappyerpFooterBottom from "../../../happyerpComponents/Footer/HappyerpFooterBottom";
// import AntTable from "../../../components/antTableDesign/AntTable";

// import charts
// import HappyerpMyLineChart from "../../../happyerpComponents/Dashboard/HappyerpMyLineChart";
// import HappyerpMyPieChart from "../../../happyerpComponents/Dashboard/HappyerpMyPieChart";
// import MyBarChart from "../../../components/charts/MyBarChart";
// import HappyerpMyColumnChart from "../../../happyerpComponents/Dashboard/HappyerpMyColumnChart";
// import Bubble from "../../../components/charts/Bubble";
// import MyAreaChart from "../../../components/charts/MyAreaChart";

// import css
import "./HappyerpDashBoard.scss";
// import EditableTable from "./editableTable/EditabeTable";

const HappyerpDashboard = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  return (
    <div className="happyerp-dashboard happyerp-pg_margin happyerp-pg_bg  happyerp-page_height">
      {/* <MyLineChart/>
      <MyPieChart/>
      <MyBarChart/>
      <MyColumnChart/>
      <Bubble/>
      <MyAreaChart/> */}
      <HappyerpNineDots
        options={[
          { link: "/sales", text: "Sales", icon: "point_of_sale" },
          { link: "/purchase", text: "Purchase", icon: "shop_two" },
          {
            link: "/production",
            text: "Production",
            icon: "production_quantity_limits",
          },
          { link: "/stock", text: "Stock", icon: "inventory_2" },
        ]}
      />

      <div className="container">
        <div className="happyerp-pg_header">
          <div className="happyerp-first_row">
            <div className="happyerp-left">
              <h2 className="happyerp-text_black happyerp-df20 happyerp-medium">
                Hi! Welcome Admin
              </h2>
            </div>
            <div className="happyerp-right">
              <div className="happyerp-button_filter">
                <div className="happyerp-bf_single happyerp-active">Today</div>
                <div className="happyerp-bf_single">Yesterday</div>
                <div className="happyerp-bf_single">Week</div>
              </div>
              <div className="happyerp-datefilter happyerp-relative">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yy" // Date format
                  placeholderText="dd/mm/yy" // Placeholder text
                />
                <span class="material-symbols-outlined">calendar_month</span>
              </div>
            </div>
          </div>
        </div>
        <div className="happyerp-dvg22"></div>
        <div className="happyerp-dashboard_inner">
          <div className="row">
            <div className="col-lg-6">
              <div className="happyerp-card_shadow happyerp-card_bg happyerp-congrats">
                <div className="happyerp-left">
                  <h3 className="happyerp-df20 happyerp-text_blue happyerp-medium">
                    Congratulations Admin! 🎉
                  </h3>
                  <br />
                  <h6 className="happyerp-text_grey happyerp-df15 happyerp-regular">
                    You have done 72% 🤩 more sales today.
                  </h6>
                </div>
                <div className="happyerp-right">
                  <img
                    src="/assets/img/happyerp/congrates_card_img.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="happyerp-line_graph">
                <div className="happyerp-dvg22"></div>
                {/* <HappyerpMyLineChart /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="happyerp-cg_1">
                <div className="happyerp-cd_1 happyerp-card_padding happyerp-card_shadow happyerp-card_bg happyerp-relative">
                  <div className="happyerp-cd1_inner">
                    <div class="happyerp-svg_icon happyerp-relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="64"
                        viewBox="0 0 48 52"
                        fill="none"
                      >
                        <path
                          d="M19.1094 2.12943C22.2034 0.343099 26.0154 0.343099 29.1094 2.12943L42.4921 9.85592C45.5861 11.6423 47.4921 14.9435 47.4921 18.5162V33.9692C47.4921 37.5418 45.5861 40.8431 42.4921 42.6294L29.1094 50.3559C26.0154 52.1423 22.2034 52.1423 19.1094 50.3559L5.72669 42.6294C2.63268 40.8431 0.726688 37.5418 0.726688 33.9692V18.5162C0.726688 14.9435 2.63268 11.6423 5.72669 9.85592L19.1094 2.12943Z"
                          fill="#0865f8"
                        ></path>
                      </svg>
                      <img src="/assets/img/happyerp/icon/sales.png" alt="" />
                    </div>
                    <div className="happyerp-content">
                      <h6>Total Sale</h6>
                      <h5>3,002</h5>
                    </div>
                  </div>
                  <div className="happyerp-trending">
                    <div className="happyerp-inner up">
                      <span class="material-symbols-outlined">trending_up</span>
                      <div className="happyerp-value">2.5%</div>
                    </div>
                    <p>last month</p>
                  </div>
                </div>
                <div className="happyerp-cd_1 happyerp-card_padding happyerp-card_shadow happyerp-card_bg happyerp-relative">
                  <div className="happyerp-cd1_inner">
                    <div class="happyerp-svg_icon happyerp-relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="64"
                        viewBox="0 0 48 52"
                        fill="none"
                      >
                        <path
                          d="M19.1094 2.12943C22.2034 0.343099 26.0154 0.343099 29.1094 2.12943L42.4921 9.85592C45.5861 11.6423 47.4921 14.9435 47.4921 18.5162V33.9692C47.4921 37.5418 45.5861 40.8431 42.4921 42.6294L29.1094 50.3559C26.0154 52.1423 22.2034 52.1423 19.1094 50.3559L5.72669 42.6294C2.63268 40.8431 0.726688 37.5418 0.726688 33.9692V18.5162C0.726688 14.9435 2.63268 11.6423 5.72669 9.85592L19.1094 2.12943Z"
                          fill="#008080"
                        ></path>
                      </svg>
                      <img
                        src="/assets/img/happyerp/icon/purchase.png"
                        alt=""
                      />
                    </div>
                    <div className="happyerp-content">
                      <h6>Total Purchase</h6>
                      <h5>4,250</h5>
                    </div>
                  </div>
                  <div className="happyerp-trending">
                    <div className="happyerp-inner happyerp-down">
                      <span class="material-symbols-outlined">
                        trending_down
                      </span>
                      <div className="happyerp-value">0.5%</div>
                    </div>
                    <p>last month</p>
                  </div>
                </div>
                <div className="happyerp-cd_1 happyerp-card_padding happyerp-card_shadow happyerp-card_bg happyerp-relative">
                  <div className="happyerp-cd1_inner">
                    <div class="happyerp-svg_icon happyerp-relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="64"
                        viewBox="0 0 48 52"
                        fill="none"
                      >
                        <path
                          d="M19.1094 2.12943C22.2034 0.343099 26.0154 0.343099 29.1094 2.12943L42.4921 9.85592C45.5861 11.6423 47.4921 14.9435 47.4921 18.5162V33.9692C47.4921 37.5418 45.5861 40.8431 42.4921 42.6294L29.1094 50.3559C26.0154 52.1423 22.2034 52.1423 19.1094 50.3559L5.72669 42.6294C2.63268 40.8431 0.726688 37.5418 0.726688 33.9692V18.5162C0.726688 14.9435 2.63268 11.6423 5.72669 9.85592L19.1094 2.12943Z"
                          fill="#800020"
                        ></path>
                      </svg>
                      <img src="/assets/img/happyerp/icon/stock.png" alt="" />
                    </div>
                    <div className="happyerp-content">
                      <h6>Total Stock</h6>
                      <h5>25</h5>
                    </div>
                  </div>
                  <div className="happyerp-trending">
                    <div className="happyerp-inner happyerp-down">
                      <span class="material-symbols-outlined">
                        trending_down
                      </span>
                      <div className="happyerp-value">9.5%</div>
                    </div>
                    <p>last month</p>
                  </div>
                </div>
                <div className="happyerp-cd_1 happyerp-card_padding happyerp-card_shadow happyerp-card_bg happyerp-relative">
                  <div className="happyerp-cd1_inner">
                    <div class="happyerp-svg_icon happyerp-relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="64"
                        viewBox="0 0 48 52"
                        fill="none"
                      >
                        <path
                          d="M19.1094 2.12943C22.2034 0.343099 26.0154 0.343099 29.1094 2.12943L42.4921 9.85592C45.5861 11.6423 47.4921 14.9435 47.4921 18.5162V33.9692C47.4921 37.5418 45.5861 40.8431 42.4921 42.6294L29.1094 50.3559C26.0154 52.1423 22.2034 52.1423 19.1094 50.3559L5.72669 42.6294C2.63268 40.8431 0.726688 37.5418 0.726688 33.9692V18.5162C0.726688 14.9435 2.63268 11.6423 5.72669 9.85592L19.1094 2.12943Z"
                          fill="#800080"
                        ></path>
                      </svg>
                      <img
                        src="/assets/img/happyerp/icon/products.png"
                        alt=""
                      />
                    </div>
                    <div className="happyerp-content">
                      <h6>Total Product</h6>
                      <h5>2,520</h5>
                    </div>
                  </div>
                  <div className="happyerp-trending">
                    <div className="happyerp-inner happyerp-up">
                      <span class="material-symbols-outlined">trending_up</span>
                      <div className="value">5.5%</div>
                    </div>
                    <p>last month</p>
                  </div>
                </div>
              </div>
              <div className="happyerp-two_graph">
                <div className="happyerp-happyerp-pie_graph">
                  <div className="happyerp-dvg22"></div>
                  {/* <HappyerpMyPieChart /> */}
                </div>
                <div className="happyerp-column_graph">
                  <div className="happyerp-dvg22"></div>
                  {/* <HappyerpMyColumnChart /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AntTable/> */}
      {/* <div style={{ marginTop: "20px" }}>
        <EditableTable />
      </div> */}

      <HappyerpFooterBottom />
    </div>
  );
};

export default HappyerpDashboard;
