import React from "react";
import { Modal, Button } from "react-bootstrap";

// import css
import "./HappyerpBulkActionPopup.scss";

const HappyerpBulkActionPopup = ({ show, onClose }) => {
  return (
    <Modal
      size="md"
      centered
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={onClose}
      className="happyerp-customized-modal"
    >
      <Modal.Header className="happyerp-main_heading">
        <Modal.Title className="happyerp-main_title">Delete</Modal.Title>
      </Modal.Header>

      <Modal.Body>Do you want to delete the selected items?</Modal.Body>
      <Modal.Footer className="happyerp-main_footer">
        {/* Close Button */}
        <Button variant="secondary" onClick={onClose} className="happyerp-heme_btn1">
          Close
        </Button>

        {/* Save Changes Button */}
        <Button
          className="happyerp-theme_btn"
          variant="primary"
          onClick={() => {
            onClose();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HappyerpBulkActionPopup;
