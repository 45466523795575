import React, { useState, useEffect, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";

// importing css
import "./HappyerpOrderReport.scss";

function HappyerpReportVariantAccordion({ filters }) {
  const [reportVariantName, setReportVariantName] = useState("");
  const [isVariantActive, setIsVariantActive] = useState(false);
  const [visibleCount, setVisibleCount] = useState(5);
  const [showReset, setShowReset] = useState(false);

  const handleVariantChange = (index, variantName) => {
    setReportVariantName(variantName);
    setIsVariantActive(true);
    setShowReset(true);
  };

  const resetVariants = () => {
    setReportVariantName("");
    setIsVariantActive(false);
    setShowReset(false);
  };

  const handleShowMore = () => setVisibleCount(visibleCount + 5);
  const handleShowLess = () => setVisibleCount(5);

  // code for the more button in report variants section starts:
  const [isMorePopupVisible, setMorePopupVisible] = useState(null);
  const [currentVariant, setCurrentVariant] = useState("");
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 }); //to position the popup near every filter clicked
  // // Toggles the popup visibility
  const toggleMorePopup = (index, filterName, event) => {
    setCurrentVariant(filterName);
    setMorePopupVisible((prev) => (prev === index ? null : index));

    // Geting the position of the clicked element
    const rect = event.target.getBoundingClientRect();
    const popupHeight = 200;
    const popupWidth = 150;
    const offsetTop = 140;
    const offsetLeft = 350;
    // Calculating the position
    const top = rect.top + window.scrollY + rect.height - offsetTop;
    const left =
      rect.left + window.scrollX + rect.width / 2 - popupWidth / 2 - offsetLeft;

    // Ensuring the popup doesn't go off-screen
    const adjustedTop =
      top + popupHeight > window.innerHeight
        ? window.innerHeight - popupHeight
        : top;
    const adjustedLeft =
      left + popupWidth > window.innerWidth
        ? window.innerWidth - popupWidth
        : left;

    setPopupPosition({
      top: adjustedTop,
      left: adjustedLeft,
    });
  };

  // Close the popup when clicking outside
  const closeMorePopup = () => {
    setMorePopupVisible(null); // Close the popup
  };

  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpenPopup(false);
      closeMorePopup();
    }
  };

  useEffect(() => {
    if (isOpenPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenPopup]);

  return (
    <Accordion className="happyerp-order_summary_accordion">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          {isVariantActive
            ? `Active Variant: ${reportVariantName}`
            : `Report Variants: ${reportVariantName}`}
        </Accordion.Header>
        <Accordion.Body style={{ padding: "0px" }}>
          <div className="happyerp-collapsible_card">
            <div className="happyerp-collapsible_card_inner happyerp-card_bg happyerp-card_shadow">
              <div className="happyerp-row_single">
                <div className="happyerp-form_field">
                  <div className="happyerp-field_box happyerp-theme_radio">
                    <div
                      className="happyerp-theme_radio_container"
                      style={{ border: "none", padding: "0px" }}
                    >
                      {filters.slice(0, visibleCount).map((filter, index) => (
                        <div
                          className="happyerp-radio_single happyerp-with_more"
                          key={index}
                        >
                          <input
                            type="radio"
                            id={`filter${index + 1}`}
                            name="style"
                            className="happyerp-radio_input"
                            checked={
                              reportVariantName === `Variant ${index + 1}`
                            }
                            onChange={() =>
                              handleVariantChange(index, `Variant ${index + 1}`)
                            }
                          />
                          <label htmlFor={`filter${index + 1}`}>
                            <div className="happyerp-label_inner">
                              <span className="happyerp-text">{`Variant ${
                                index + 1
                              }`}</span>
                            </div>
                            <div className="happyerp-filter_more_icon">
                              <span
                                className="material-symbols-outlined"
                                data-filter-index={index}
                                //using index to track the specific filter
                                onClick={(e) => {
                                  e.stopPropagation(); //sttop propagation to prevent the parent click event
                                  toggleMorePopup(
                                    index,
                                    `Variant ${index + 1}`,
                                    e
                                  );
                                  setReportVariantName(`Variant ${index + 1}`);
                                  setIsOpenPopup(true);
                                  resetVariants();
                                }}
                              >
                                <span
                                  class="material-symbols-outlined"
                                  style={{
                                    alignItems: "center",
                                    fontWeight: "350",
                                    paddingTop: "6px",
                                  }}
                                >
                                  more_vert
                                </span>
                              </span>
                              {isMorePopupVisible === index && (
                                <div
                                  className="happyerp-more_popup"
                                  onClick={(e) => e.stopPropagation()}
                                  ref={popupRef}
                                  style={{
                                    position: "absolute",
                                    top: `${popupPosition.top}px`,
                                    left: `${popupPosition.left}px`,
                                  }}
                                >
                                  <div className="happyerp-popup_container">
                                    {/* <button
                                            onClick={(e) => {
                                              closeMorePopup();
                                              e.stopPropagation(); // Ensure only the close action triggers
                                              setIsOpenPopup(false);
                                            }}
                                            className="popup_close_btn"
                                          >
                                            ✖
                                          </button> */}
                                    {/* <h4 className="popup_title">
                                            Actions
                                          </h4> */}
                                    <ul className="happyerp-popup_options">
                                      <li className="happyerp-popup_option_item">
                                        <h7>Edit Variant</h7>
                                        <img
                                          src="/assets/img/happyerp/icon/edit.png"
                                          alt=""
                                          className="happyerp-more_opt_img"
                                        />
                                      </li>
                                      <li className="happyerp-popup_option_item">
                                        <h7>Duplicate Variant</h7>
                                        <img
                                          src="/assets/img/happyerp/icon/duplicate.png"
                                          alt=""
                                          className="happyerp-more_opt_img"
                                        />
                                      </li>
                                      <li className="happyerp-popup_option_item">
                                        <h7>Share Variant</h7>
                                        <img
                                          src="/assets/img/happyerp/icon/share.png"
                                          alt=""
                                          className="happyerp-more_opt_img"
                                        />
                                      </li>
                                      <li className="happyerp-popup_option_item">
                                        <h7>Delete Variant</h7>
                                        <img
                                          src="/assets/img/happyerp/icon/delete.png"
                                          alt=""
                                          className="happyerp-more_opt_img"
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </div>
                          </label>
                        </div>
                      ))}

                      {/* show more or show less code  */}
                      {visibleCount < filters.length ? (
                        <span
                          onClick={handleShowMore}
                          className="happyerp-click_text"
                          style={{ textDecoration: "underline" }}
                        >
                          Show More
                        </span>
                      ) : (
                        <span
                          onClick={handleShowLess}
                          className="happyerp-click_text"
                          style={{ textDecoration: "underline" }}
                        >
                          Show Less
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {showReset && (
                <div
                  className="happyerp-clear_var_btn"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  <button
                    onClick={resetVariants}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      padding: "4px 8px",
                      cursor: "pointer",
                      fontSize: "14px",
                      color: "var(--theme-teal)",
                      textDecoration: "underline",

                      // marginTop: "26px",
                      // zIndex: "2",
                      marginRight: "10px",
                    }}
                  >
                    Clear
                  </button>
                </div>
              )}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default HappyerpReportVariantAccordion;
