import React from "react";
import Carousel from "react-bootstrap/Carousel";

// import component
import HappyerpBottomCurve from "../curve/HappyerpBottomCurve";
// import Login from "../../authentication/Login";

// import css
import "./HappyerpBanner.scss";

const HappyerpBanner = () => {
  return (
    <div className="happyerp-banner happyerp-relative">
      <HappyerpBottomCurve />
      <Carousel
        interval={5000000} // Set time interval (in milliseconds)
        controls={true} // Hide the control arrows
        indicators={false} // Hide the slide indicators
        pause={true} // Disable auto-pause on hover
        touch // Enable touch gestures for swipe
        // fade // Enable fade effect
        loop={true}
      >
        <Carousel.Item>
          <img
            src="./assets/img/happyerp/home/banner1.jpg"
            alt="First slide"
            className="happyerp-slide_img"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src="./assets/img/happyerp/home/banner2.jpg" // Make sure to use different images for each slide
            alt="Second slide"
            className="happyerp-slide_img"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src="./assets/img/happyerp/home/banner1.png" // Make sure to use different images for each slide
            alt="Third slide"
            className="happyerp-slide_img"
          />
        </Carousel.Item>
      </Carousel>
      <Carousel.Caption className="happyerp-carousel-caption">
        <h6 className="happyerp-banner_subtitle">ERP System</h6>
        <h1 className="happyerp-banner_title">
          Happy ERP System Management Solution
        </h1>
        {/* <Login/>              */}
      </Carousel.Caption>
    </div>
  );
};

export default HappyerpBanner;
