import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import "./BusinessThreeProfile.scss";
import DarkModeToggle from "react-dark-mode-toggle";
import Popup from "../../../../default components/Popup";
import { BeatLoader } from "react-spinners";
import { useDocument } from "../../../../firebase files/hooks/useDocument";

// IMPORT FIRESTORE
import { useLogout } from "../../../../firebase files/hooks/useLogout";
import {
  projectFirestore,
  projectStorage,
} from "../../../../firebase config/config";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
const BusinessThreeProfile = () => {
  const { user } = useAuthContext();

  const [AddRole, setAddRole] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selctedRole, setRole] = useState([]);
  const [selectedRoleIndex, setSelectedRolesIndex] = useState(null);
  const [isAddRole, setIsAddRole] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);

  //Popup Flags
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [popupReturn, setPopupReturn] = useState(false);
  const { logout, isPending } = useLogout();

  // change user details
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    city: "",
    email: "",
    phoneNumber: "",
  });

  // CHANGE PROFILE IMG CODE START
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState();
  const [selectedDummyImage, setSelectedDummyImage] = useState(null);
  const [imgUploading, setImgUploading] = useState(false);
  const [dummyImages, setDummyImages] = useState([
    "/assets/img/profile-option-1.png",
    "/assets/img/profile-option-2.png",
    "/assets/img/profile-option-3.png",
    "/assets/img/profile-option-4.jfif",
    "/assets/img/profile-option-5.jpg",
    "/assets/img/profile-option-6.jfif",
  ]);
  const defaultImageUrl =
    "https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/userThumbnails%2F1default.png?alt=media&token=38880453-e642-4fb7-950b-36d81d501fe2";

  const { updateDocument, response } = useFirestore("users");
  const { updateDocument: updateMenuDocument } = useFirestore("settings");

  const { document: pageControlDoc, error: pageControlDocError } = useDocument(
    "settings",
    "pagesControl"
  );

  const { document: dynamicNavMenuDoc, error: dynamicNavMenuDocError } =
    useDocument("settings", "EditNavbarMenus");

  const { document: displayModeDoc, error: displayModeDocError } = useDocument(
    "settings",
    "mode"
  );

  const {
    document: dbTemplatesPaletteDocuments,
    error: dbTemplatesPaletteError,
  } = useDocument("settings", "themePalette");

  //Popup Flags
  useEffect(() => {
    if (popupReturn) {
      logout();
    }
  }, [popupReturn]);

  //Popup Flags
  const showPopup = async (e) => {
    e.preventDefault();
    setShowPopupFlag(true);
    setPopupReturn(false);
  };

  // DARK\LIGHT CODE FOR CUSTOMER LOCAL STAORAGE
  const toggleUserDarkMode = async () => {
    // Retrieving a value from localStorage
    const currentModeStatus = localStorage.getItem("mode");

    const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";
    // Storing a value in localStorage
    localStorage.setItem("mode", newDisplayMode);

    window.dispatchEvent(new Event("storage"));
  };
  // END CODE FOR LIGHT/DARK MODE

  // START CODE FOR ROLE
  const handleAddRoleClick = () => {
    setIsAddRole(true);
  };
  const handleSaveAddRole = async () => {
    const lowercaseRole = AddRole.toLowerCase(); // Convert role name to lowercase

    try {
      // Get the current roles array from the document
      const currentRoles = (user && user.roles) || [];

      // Add the new option to the array
      const updatedRoles = [...currentRoles, lowercaseRole];

      // Update the roles array in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ roles: updatedRoles });

      console.log("Roles updated successfully"); // Log success message

      // Reset the AddRole state and close the input field
      setAddRole("");
      setIsAddRole(false);
    } catch (error) {
      console.error("Error adding new option:", error);
      // Handle the error as needed
    }
  };
  const handleCancelAddRole = () => {
    setIsAddRole(false);
  };

  const handleRoleChange = async (roles) => {
    setSelectedRole(roles);
    setIsRoleSelected(true);
    try {
      // Update the user's role in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ role: roles });

      console.log("User role updated successfully to:", roles); // Log success message
    } catch (error) {
      console.error("Error updating user role:", error);
      // Handle the error as needed
    }
  };

  // Fetch roles array from the document state
  const roles =
    user && user
      ? ((user && user.roles) || []).map((role) => role.toLowerCase())
      : [];
  // delete option
  const handleDeleteRole = async (index) => {
    try {
      // Get the current roles array from the document
      const currentRole = (user && user.roles) || [];

      // Remove the selected option from the array
      const updatedRole = currentRole.filter((_, i) => i !== index);

      // Update the roles array in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ roles: updatedRole });
    } catch (error) {
      console.error("Error deleting role:", error);
      // Handle the error as needed
    }
  };
  // END CODE FOR CATEGORY ROLE

  // START EDIT USER DETAILS
  useEffect(() => {
    if (user) {
      setUserDetails({
        fullName: user.fullName,
        address: user.address,
        city: user.city,
        email: user.email,
        phoneNumber: user.phoneNumber,
      });
    }
  }, [user]);

  const handleEditClick = () => {
    setShowEditPopup(true);
    setConfirmButtonDisabled(true); // Disable the confirm button initially
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setConfirmButtonDisabled(false); // Enable the confirm button when any input changes
  };

  const handleCancelClick = () => {
    setShowEditPopup(false);
  };

  // Confirm update
  const handleConfirmClick = async () => {
    try {
      await updateDocument(user.uid, userDetails);
      setShowEditPopup(false);
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };
  // END EDIT USER DETAILS

  // START CHANGE IMG CODE START
  const handleImageClick = () => {
    setShowImagePopup(true);
  };

  const handleRemoveImage = () => {
    setImagePreview(defaultImageUrl); // Set to default image URL
    setSelectedImage(null);
    setSelectedDummyImage(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedImage(file);
    }
  };

  const handleDummyImageClick = (imageUrl) => {
    setSelectedDummyImage(imageUrl);
    setImagePreview(imageUrl);
  };

  const handleCancelImgClick = () => {
    setShowImagePopup(false);
  };

  const handleConfirmImgClick = async () => {
    try {
      if (selectedImage) {
        setImgUploading(true); // Set loader on
        const uploadPath = `userThumbnails/${user.uid}/${selectedImage.name}`;
        const uploadTask = projectStorage.ref(uploadPath).put(selectedImage);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle progress
          },
          (error) => {
            console.error("Error uploading file:", error);
            setImgUploading(false); // Set loader off on error
          },
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            await updateDocument(user.uid, { photoURL: downloadURL });
            setImagePreview(downloadURL);
            setSelectedImage(null);
            setImgUploading(false); // Set loader off
            setShowImagePopup(false);
          }
        );
      } else if (selectedDummyImage) {
        setImgUploading(true);
        await updateDocument(user.uid, { photoURL: selectedDummyImage });
        setImagePreview(selectedDummyImage);
        setSelectedDummyImage(null);
        setImgUploading(false); // Set loader off
        setShowImagePopup(false);
      } else {
        setImgUploading(true);
        await updateDocument(user.uid, { photoURL: defaultImageUrl });
        setImagePreview(defaultImageUrl);
        setImgUploading(false); // Set loader off
        setShowImagePopup(false);
      }
    } catch (error) {
      console.error("Error updating image URL:", error);
      setImgUploading(false); // Set loader off on error
    }
  };

  //  START FETCH DYNAMIC MENUS FROM DB
  const handleVisibleInProfilePage = async (menuIndex, subMenuIndex = null) => {
    const updatedMenuDetails = [...dynamicNavMenuDoc.menuDetails];

    if (subMenuIndex === null) {
      // Toggle visibility for the main menu
      const currentVisibility =
        updatedMenuDetails[menuIndex]?.visibilityInProfilePage || false;
      updatedMenuDetails[menuIndex].visibilityInProfilePage =
        !currentVisibility;
    } else {
      // Toggle visibility for the submenu
      const currentVisibility =
        updatedMenuDetails[menuIndex]?.subMenu[subMenuIndex]
          ?.visibilityInProfilePage || false;
      updatedMenuDetails[menuIndex].subMenu[
        subMenuIndex
      ].visibilityInProfilePage = !currentVisibility;
    }

    try {
      // Update Firestore document
      await updateMenuDocument("EditNavbarMenus", {
        menuDetails: updatedMenuDetails,
      });
      console.log("Visibility updated successfully");
    } catch (error) {
      console.error("Error updating visibility:", error);
      alert("There was an error updating the visibility. Please try again.");
    }
  };
  //  START FETCH DYNAMIC MENUS FROM DB

  return (
    <>
      <Popup
        showPopupFlag={showPopupFlag}
        setShowPopupFlag={setShowPopupFlag}
        setPopupReturn={setPopupReturn}
        msg={"Are you sure you want to logout?"}
      />

      <div className="business-three-profile">
        {(user || (pageControlDoc && pageControlDoc.login === true)) && (
          <div className="business-three-profile-sidebar">
            <div className="business-three-profile-image">
              {user && user.status === "active" ? (
                <div className="business-three-profile-img-camera">
                  <div style={{ position: "relative" }}>
                    <img
                      src={user.photoURL}
                      alt="Profile"
                      className="business-three-profile-img-camera-img"
                    />

                    {dbTemplatesPaletteDocuments &&
                    dbTemplatesPaletteDocuments.activeThemePalette ===
                      "defaultThemePalette" ? (
                      <div className="business-three-profile-img-camera-occation-img"></div>
                    ) : dbTemplatesPaletteDocuments &&
                      dbTemplatesPaletteDocuments.activeThemePalette ===
                        "diwaliThemePalette" ? (
                      <img
                        src="/assets/img/themes/diwali-rocket.gif"
                        className="business-three-profile-img-camera-occation-img"
                        alt="Diwali Theme"
                      />
                    ) : dbTemplatesPaletteDocuments &&
                      dbTemplatesPaletteDocuments.activeThemePalette ===
                        "holiThemePalette" ? (
                      <img
                        src="/assets/img/themes/holi-rangoli.webp"
                        className="business-three-profile-img-holi-occation-img"
                        alt="Holi Theme"
                      />
                    ) : dbTemplatesPaletteDocuments &&
                      dbTemplatesPaletteDocuments.activeThemePalette ===
                        "womenDayThemePalette" ? (
                      user && user.gender && user.gender === "female" ? (
                        <img
                          src="/assets/img/themes/women-day-transprent-flower.png"
                          alt="Women's Day Flower"
                          className="business-three-profile-img-holi-occation-img"
                        />
                      ) : (
                        <div className="business-three-profile-img-camera-occation-img"></div>
                      )
                    ) : null}
                  </div>
                  <span
                    className="material-symbols-outlined business-three-profile-camera-icon"
                    onClick={handleImageClick}
                  >
                    photo_camera
                  </span>
                </div>
              ) : (
                <>
                  <Link to="/login">
                    <img
                      src="assets/img/login-auth.gif"
                      alt="login gif img"
                      style={{ width: "100%" }}
                    />
                    {/* <div className="business-three-profile-logout-span">
                    <span className="material-symbols-outlined ba_animation">
                      login
                    </span>
                  </div> */}
                    <br />
                    <div className="d-flex justify-content-center">
                      <button className="btn_fill d-flex align-items-center">
                        <span class="material-symbols-outlined ba_animation">
                          logout
                        </span>
                        &nbsp; Login / Sign-Up
                      </button>
                    </div>
                  </Link>
                </>
              )}
              {user && user && (
                <span
                  className="material-symbols-outlined business-three-profile-edit-icon"
                  onClick={handleEditClick}
                >
                  edit
                </span>
              )}
            </div>

            {user && user && (
              <>
                <div className="business-three-profile-info">
                  <h2>{user && user.fullName}</h2>
                  <p>
                    {user && (
                      <>
                        {user.city && `${user.city}, `} {user.country}
                      </>
                    )}
                  </p>
                  <div className="business-three-profile-details">
                    <p>
                      <strong>Email:</strong> {user && user.email}
                    </p>
                    <p>
                      <strong>Phone:</strong> +{" "}
                      {user &&
                        `${user.phoneNumber.slice(
                          0,
                          2
                        )} ${user.phoneNumber.slice(
                          2,
                          6
                        )} ${user.phoneNumber.slice(
                          6,
                          8
                        )} ${user.phoneNumber.slice(8)}`}
                    </p>
                    {user && user.address != "" && (
                      <p>
                        <strong>Address:</strong> {user && user.address}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  {/* <br /> */}
                  {user && user.roles.includes("admin") && (
                    <div className="profile-page-content role">
                      <p>
                        <strong>Role</strong>
                      </p>
                      <div className="radio-container">
                        {roles &&
                          roles.map((roles, index) => (
                            <label
                              key={index}
                              className={`radio-container ${
                                selectedRoleIndex === index
                                  ? "radio_btn_checked"
                                  : "service_details_radion_btn"
                              }`}
                              style={{
                                color:
                                  user && user.role === roles
                                    ? "var(--white-color)"
                                    : "inherit",
                                backgroundColor:
                                  user && user.role === roles
                                    ? "var(--click-color)"
                                    : "inherit",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <input
                                  type="radio"
                                  name="role"
                                  value={roles}
                                  onChange={() => handleRoleChange(roles)}
                                  className="radio-button"
                                />
                                <span
                                  className="material-symbols-outlined"
                                  style={{ fontSize: "21px" }}
                                >
                                  {user && user.role === roles ? "done" : "add"}
                                </span>
                                {roles}
                                {user && user.role === "admin" ? (
                                  <span
                                    class="material-symbols-outlined"
                                    onClick={() => handleDeleteRole(index)}
                                  >
                                    close
                                  </span>
                                ) : null}
                              </div>
                            </label>
                          ))}
                        {isAddRole ? (
                          <div>
                            <input
                              type="text"
                              value={AddRole}
                              onChange={(e) => setAddRole(e.target.value)}
                            />
                            <div className="d-flex">
                              <button
                                className="product_edit_save_btn"
                                onClick={handleSaveAddRole}
                              >
                                Save
                              </button>
                              <button
                                className="product_edit_save_btn cancel-btn"
                                onClick={handleCancelAddRole}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            {/* {user && user.role == "admin" && (
                            <span
                              class="material-symbols-outlined"
                              onClick={handleAddRoleClick}
                              style={{ fontSize: "24px" }}
                            >
                              add_box
                            </span>
                          )} */}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="business-three-profile-tags">
              <div className="business-three-profile-button-container">
                {user && user ? (
                  <>
                    <button
                      className="btn_border"
                      style={{ minWidth: "100%" }}
                      onClick={showPopup}
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
        {/* RIGHT SECTION START */}
        <div className="business-three-profile-main container">
          <div className="business-three-profile-more-expand-div">
            <div className="business-three-profile-more-div-big-icon-div">
              {pageControlDoc && pageControlDoc.dashboard === true && (
                <div className="business-three-profile-more-div-big-icon-div-inner pointer">
                  <Link
                    to="/dashboard"
                    className="d-flex flex-column align-items-center"
                  >
                    <div>
                      <span className="material-symbols-outlined">
                        insert_chart
                      </span>
                    </div>
                    <h1 style={{ color: "var(--black-color)" }}>Dashboard</h1>
                  </Link>
                </div>
              )}

              {pageControlDoc && pageControlDoc.adminPanel === true && (
                <div className="business-three-profile-more-div-big-icon-div-inner pointer">
                  <Link
                    to={
                      user && user.role === "admin"
                        ? `/admin-panel`
                        : `/dashboard`
                    }
                    className="d-flex flex-column align-items-center"
                  >
                    <div>
                      <span className="material-symbols-outlined">
                        insert_chart
                      </span>
                    </div>
                    <h1 style={{ color: "var(--black-color)" }}>Dashboard</h1>
                  </Link>
                </div>
              )}

              {pageControlDoc && pageControlDoc.orders === true && (
                <div className="business-three-profile-more-div-big-icon-div-inner pointer">
                  <Link
                    to="/orders"
                    className="d-flex flex-column align-items-center"
                  >
                    <div>
                      <span className="material-symbols-outlined">
                        list_alt
                      </span>
                    </div>
                    <h1 style={{ color: "var(--black-color)" }}>My Orders</h1>
                  </Link>
                </div>
              )}

              <div className="business-three-profile-more-div-big-icon-div-inner pointer">
                <Link
                  to="/notifications"
                  className="d-flex flex-column align-items-center"
                >
                  <div>
                    <span className="material-symbols-outlined">
                      notifications
                    </span>
                  </div>
                  <h1 style={{ color: "var(--black-color)" }}>Notifications</h1>
                </Link>
              </div>
            </div>
          </div>

          {/* ADMIN MENUS START */}
          {user && user.role == "admin" && (
            <>
              <div className="business-three-profile-menus-list container">
                <div className="row">
                  <h3 style={{ fontWeight: "700" }}>Admin</h3>
                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/all-users">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="currentColor"
                              >
                                <path d="M500-482q29-32 44.5-73t15.5-85q0-44-15.5-85T500-798q60 8 100 53t40 105q0 60-40 105t-100 53Zm220 322v-120q0-36-16-68.5T662-406q51 18 94.5 46.5T800-280v120h-80Zm80-280v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80Zm-480-40q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM0-160v-112q0-34 17.5-62.5T64-378q62-31 126-46.5T320-440q66 0 130 15.5T576-378q29 15 46.5 43.5T640-272v112H0Zm320-400q33 0 56.5-23.5T400-640q0-33-23.5-56.5T320-720q-33 0-56.5 23.5T240-640q0 33 23.5 56.5T320-560ZM80-240h480v-32q0-11-5.5-20T540-306q-54-27-109-40.5T320-360q-56 0-111 13.5T100-306q-9 5-14.5 14T80-272v32Zm240-400Zm0 400Z" />
                              </svg>
                            </div>
                            <h6>All Users</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>

                    <div className="business-three-profile-menu">
                      <Link to="/sitesettings">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="currentColor"
                              >
                                <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z" />
                              </svg>
                            </div>
                            <h6>Site Settings</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/aboutus">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="currentColor"
                              >
                                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                              </svg>
                            </div>
                            <h6>About Us</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>

                    {displayModeDoc &&
                      displayModeDoc.isDisplayMode === true && (
                        <div className="business-three-profile-menu">
                          <div className="business-three-profile-content">
                            <div className="business-three-profile-icon">
                              <div className="business-three-profile-icon-bg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="24px"
                                  viewBox="0 -960 960 960"
                                  width="24px"
                                  fill="currentColor"
                                >
                                  <path d="M300-360h60v-160h-60v50h-60v60h60v50Zm100-50h320v-60H400v60Zm200-110h60v-50h60v-60h-60v-50h-60v160Zm-360-50h320v-60H240v60Zm80 450v-80H160q-33 0-56.5-23.5T80-280v-480q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v480q0 33-23.5 56.5T800-200H640v80H320ZM160-280h640v-480H160v480Zm0 0v-480 480Z" />
                                </svg>
                              </div>
                              <div>
                                <h6 style={{ color: "var(--black-color)" }}>
                                  Display Mode
                                </h6>
                              </div>
                            </div>
                            <div>
                              <DarkModeToggle
                                onChange={() => toggleUserDarkMode()}
                                checked={
                                  localStorage.getItem("mode") === "dark"
                                }
                                size={40}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/enquries-list">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="currentColor"
                              >
                                <path d="M360-240h440v-107H360v107ZM160-613h120v-107H160v107Zm0 187h120v-107H160v107Zm0 186h120v-107H160v107Zm200-186h440v-107H360v107Zm0-187h440v-107H360v107ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Z" />
                              </svg>
                            </div>
                            <h6>Enquiries List</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* MASTER DATA CARD START */}
              <div className="business-three-profile-menus-list container">
                <div className="row">
                  <h3 style={{ fontWeight: "700" }}>Master Data</h3>
                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/add-categories">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="currentColor"
                              >
                                <path d="M280-280h160v-160H280v160Zm240 0h160v-160H520v160ZM280-520h160v-160H280v160Zm240 0h160v-160H520v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z" />
                              </svg>
                            </div>
                            <h6>Add Categories</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  {/* <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/address">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span class="material-symbols-outlined">
                                place
                              </span>
                            </div>
                            <h6>Manage Address</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </>
          )}
          {/* ADMIN MENUS END */}

          <div className="business-three-profile-menus-list">
            <div className="row">
              <div className="col-12 col-sm-6">
                <h3 style={{ fontWeight: "700" }}>Quick Links</h3>
                <div className="business-three-profile-menu">
                  <Link to="/">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="currentColor"
                          >
                            <path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" />
                          </svg>
                        </div>
                        <h6>Home</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>

                <div className="business-three-profile-menu">
                  <Link to="/contact">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="currentColor"
                          >
                            <path d="M640-80v-90q-56-18-94-64t-44-106h80q8 43 40.5 71.5T700-240h120q25 0 42.5 17.5T880-180v100H640Zm120-200q-33 0-56.5-23.5T680-360q0-33 23.5-56.5T760-440q33 0 56.5 23.5T840-360q0 33-23.5 56.5T760-280ZM360-400q0-150 105-255t255-105v80q-117 0-198.5 81.5T440-400h-80Zm160 0q0-83 58.5-141.5T720-600v80q-50 0-85 35t-35 85h-80ZM80-520v-100q0-25 17.5-42.5T140-680h120q45 0 77.5-28.5T378-780h80q-6 60-44 106t-94 64v90H80Zm120-200q-33 0-56.5-23.5T120-800q0-33 23.5-56.5T200-880q33 0 56.5 23.5T280-800q0 33-23.5 56.5T200-720Z" />
                          </svg>
                        </div>
                        <h6>Contact</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>

                <div className="business-three-profile-menu">
                  <Link to="/aboutus">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="currentColor"
                          >
                            <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                          </svg>
                        </div>
                        <h6>About Us</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>

                {pageControlDoc && pageControlDoc.dashboard === true && (
                  <div className="business-three-profile-menu">
                    <Link to="/customerdashboard">
                      <div className="business-three-profile-content">
                        <div className="business-three-profile-icon">
                          <div className="business-three-profile-icon-bg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="currentColor"
                            >
                              <path d="M280-280h80v-280h-80v280Zm160 0h80v-400h-80v400Zm160 0h80v-160h-80v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z" />
                            </svg>
                          </div>
                          <h6>Dashboard</h6>
                        </div>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </div>
                    </Link>
                  </div>
                )}

                {/* <div className="business-three-profile-menu">
                  <Link to="/services">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <span className="material-symbols-outlined">
                            add_shopping_cart
                          </span>
                        </div>
                        <h6>Add Booking</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div> */}

                {/* <div className="business-three-profile-menu">
                  <Link to="/bookinglist">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <span className="material-symbols-outlined">
                            add_shopping_cart
                          </span>
                        </div>
                        <h6>My Booking</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div> */}
              </div>

              <div className="col-12 col-sm-6">
                <h3 style={{ fontWeight: "700" }}>More Links</h3>

                <div className="business-three-profile-menu">
                  <Link to="/faq">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="currentColor"
                          >
                            <path d="M560-360q17 0 29.5-12.5T602-402q0-17-12.5-29.5T560-444q-17 0-29.5 12.5T518-402q0 17 12.5 29.5T560-360Zm-30-128h60q0-29 6-42.5t28-35.5q30-30 40-48.5t10-43.5q0-45-31.5-73.5T560-760q-41 0-71.5 23T446-676l54 22q9-25 24.5-37.5T560-704q24 0 39 13.5t15 36.5q0 14-8 26.5T578-596q-33 29-40.5 45.5T530-488ZM320-240q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z" />
                          </svg>
                        </div>
                        <h6>FAQ</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>

                {pageControlDoc && pageControlDoc.gallery === true && (
                  <div className="business-three-profile-menu">
                    <Link to="/gallery">
                      <div className="business-three-profile-content">
                        <div className="business-three-profile-icon">
                          <div className="business-three-profile-icon-bg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="currentColor"
                            >
                              <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm40-80h480L570-480 450-320l-90-120-120 160Zm-40 80v-560 560Z" />
                            </svg>
                          </div>
                          <h6>Gallery</h6>
                        </div>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </div>
                    </Link>
                  </div>
                )}

                <div className="business-three-profile-menu">
                  <Link to="/privacy-policy">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="currentColor"
                          >
                            <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                          </svg>
                        </div>
                        <h6>Privacy Policy</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>

                <div className="business-three-profile-menu">
                  <Link to="/term-condition">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="currentColor"
                          >
                            <path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z" />
                          </svg>
                        </div>
                        <h6>Terms & Conditions</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>

                {user && user.isAttendance && (
                  <div className="business-three-profile-menu">
                    <Link to="/attendance">
                      <div className="business-three-profile-content">
                        <div className="business-three-profile-icon">
                          <div className="business-three-profile-icon-bg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="currentColor"
                              style={{ color: "var(--logo-primary-color)" }}
                            >
                              <path d="M481-781q106 0 200 45.5T838-604q7 9 4.5 16t-8.5 12q-6 5-14 4.5t-14-8.5q-55-78-141.5-119.5T481-741q-97 0-182 41.5T158-580q-6 9-14 10t-14-4q-7-5-8.5-12.5T126-602q62-85 155.5-132T481-781Zm0 94q135 0 232 90t97 223q0 50-35.5 83.5T688-257q-51 0-87.5-33.5T564-374q0-33-24.5-55.5T481-452q-34 0-58.5 22.5T398-374q0 97 57.5 162T604-121q9 3 12 10t1 15q-2 7-8 12t-15 3q-104-26-170-103.5T358-374q0-50 36-84t87-34q51 0 87 34t36 84q0 33 25 55.5t59 22.5q34 0 58-22.5t24-55.5q0-116-85-195t-203-79q-118 0-203 79t-85 194q0 24 4.5 60t21.5 84q3 9-.5 16T208-205q-8 3-15.5-.5T182-217q-15-39-21.5-77.5T154-374q0-133 96.5-223T481-687Zm0-192q64 0 125 15.5T724-819q9 5 10.5 12t-1.5 14q-3 7-10 11t-17-1q-53-27-109.5-41.5T481-839q-58 0-114 13.5T260-783q-8 5-16 2.5T232-791q-4-8-2-14.5t10-11.5q56-30 117-46t124-16Zm0 289q93 0 160 62.5T708-374q0 9-5.5 14.5T688-354q-8 0-14-5.5t-6-14.5q0-75-55.5-125.5T481-550q-76 0-130.5 50.5T296-374q0 81 28 137.5T406-123q6 6 6 14t-6 14q-6 6-14 6t-14-6q-59-62-90.5-126.5T256-374q0-91 66-153.5T481-590Zm-1 196q9 0 14.5 6t5.5 14q0 75 54 123t126 48q6 0 17-1t23-3q9-2 15.5 2.5T744-191q2 8-3 14t-13 8q-18 5-31.5 5.5t-16.5.5q-89 0-154.5-60T460-374q0-8 5.5-14t14.5-6Z" />
                            </svg>
                          </div>
                          <h6>Attendance</h6>
                        </div>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </div>
                    </Link>
                  </div>
                )}

                <div className="business-three-profile-menu business-three-profile-logout-login-pc">
                  {user && user ? (
                    <div onClick={showPopup}>
                      <div className="business-three-profile-content">
                        <div className="business-three-profile-icon">
                          <div className="business-three-profile-icon-bg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="currentColor"
                            >
                              <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" />
                            </svg>
                          </div>
                          <h6>Logout</h6>
                        </div>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </div>
                    </div>
                  ) : (
                    <Link to="/login">
                      <div className="business-three-profile-content">
                        <div className="business-three-profile-icon">
                          <div className="business-three-profile-icon-bg">
                            <span className="material-symbols-outlined">
                              logout
                            </span>
                          </div>
                          <h6>Login</h6>
                        </div>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* DYNAMIC MENUS START */}
          <>
            <div className="business-three-profile-menus-list container">
              <div className="row">
                <h3 style={{ fontWeight: "700" }}>More Pages</h3>
                {dynamicNavMenuDoc &&
                  dynamicNavMenuDoc.menuDetails &&
                  dynamicNavMenuDoc.menuDetails.map((menu, index) => {
                    // Skip menu if menuStatus is inactive
                    if (menu.menuStatus === "inactive") {
                      return null; // Don't render this menu
                    }

                    // For admin, show all menus and submenus (ignore visibility status)
                    if (user && user.role === "admin") {
                      return (
                        <React.Fragment key={`menu-${index}`}>
                          {/* Main Menu */}
                          <div className="col-12 col-sm-6">
                            <div className="business-three-profile-menu">
                              <Link to={menu.pageUrl}>
                                <div className="business-three-profile-content">
                                  <div className="business-three-profile-icon">
                                    <div className="business-three-profile-icon-bg">
                                      {/* <span className="material-symbols-outlined">
                                        {menu.iconName}
                                      </span> */}
                                      <span
                                        style={{
                                          color: "var(--logo-primary-color)",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: menu.iconName,
                                        }}
                                      />
                                    </div>
                                    <h6>{menu.menuName}</h6>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p style={{ fontSize: "12px" }}>Hide</p>
                                    &nbsp;
                                    <DarkModeToggle
                                      onChange={() =>
                                        handleVisibleInProfilePage(index)
                                      } // Pass the correct indices for the main menu
                                      checked={
                                        menu.visibilityInProfilePage || false
                                      }
                                      size={30}
                                    />
                                    &nbsp;
                                    <p style={{ fontSize: "12px" }}>Unhide</p>
                                    <span className="material-symbols-outlined">
                                      chevron_right
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>

                          {/* Submenus */}
                          {menu.subMenu &&
                            menu.subMenu.map((subMenuItem, subIndex) => {
                              // Skip submenu if subMenuStatus is inactive
                              if (subMenuItem.subMenuStatus === "inactive") {
                                return null; // Don't render this submenu
                              }

                              return (
                                <div
                                  className="col-12 col-sm-6"
                                  key={`submenu-${index}-${subIndex}`}
                                >
                                  <div className="business-three-profile-menu">
                                    <Link to={subMenuItem.subMenuPageUrl}>
                                      <div className="business-three-profile-content">
                                        <div className="business-three-profile-icon">
                                          <div className="business-three-profile-icon-bg">
                                            {/* <span className="material-symbols-outlined">
                                              {subMenuItem.subMenuIcon}
                                            </span> */}
                                            <span
                                              style={{
                                                color:
                                                  "var(--logo-primary-color)",
                                              }}
                                              dangerouslySetInnerHTML={{
                                                __html: subMenuItem.subMenuIcon,
                                              }}
                                            />
                                          </div>
                                          <h6>{subMenuItem.subMenuName}</h6>
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <p style={{ fontSize: "12px" }}>
                                            Hide
                                          </p>
                                          &nbsp;
                                          <DarkModeToggle
                                            onChange={() =>
                                              handleVisibleInProfilePage(
                                                index,
                                                subIndex
                                              )
                                            } // Pass indices for submenu
                                            checked={
                                              subMenuItem.visibilityInProfilePage ||
                                              false
                                            }
                                            size={30}
                                          />
                                          &nbsp;
                                          <p style={{ fontSize: "12px" }}>
                                            Unhide
                                          </p>
                                          <span className="material-symbols-outlined">
                                            chevron_right
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                        </React.Fragment>
                      );
                    }

                    // For other users, show only menus and submenus with visibilityInProfilePage = true
                    const showMainMenu = menu.visibilityInProfilePage;
                    const subMenusToShow =
                      menu.subMenu &&
                      menu.subMenu.filter(
                        (subMenuItem) =>
                          subMenuItem.visibilityInProfilePage &&
                          subMenuItem.subMenuStatus !== "inactive"
                      );

                    if (
                      !showMainMenu &&
                      (!subMenusToShow || subMenusToShow.length === 0)
                    ) {
                      return null; // Skip this menu entirely if nothing to show
                    }

                    return (
                      <React.Fragment key={`menu-${index}`}>
                        {/* Main Menu */}
                        {showMainMenu && (
                          <div className="col-12 col-sm-6">
                            <div className="business-three-profile-menu">
                              <Link to={menu.pageUrl}>
                                <div className="business-three-profile-content">
                                  <div className="business-three-profile-icon">
                                    <div className="business-three-profile-icon-bg">
                                      {/* <span className="material-symbols-outlined">
                                        {menu.iconName}
                                      </span> */}
                                      <span
                                        style={{
                                          color: "var(--logo-primary-color)",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: menu.iconName,
                                        }}
                                      />
                                    </div>
                                    <h6>{menu.menuName}</h6>
                                  </div>
                                  <span className="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </div>
                              </Link>
                            </div>
                          </div>
                        )}

                        {/* Submenus */}
                        {subMenusToShow &&
                          subMenusToShow.map((subMenuItem, subIndex) => (
                            <div
                              className="col-12 col-sm-6"
                              key={`submenu-${index}-${subIndex}`}
                            >
                              <div className="business-three-profile-menu">
                                <Link to={subMenuItem.subMenuPageUrl}>
                                  <div className="business-three-profile-content">
                                    <div className="business-three-profile-icon">
                                      <div className="business-three-profile-icon-bg">
                                        {/* <span className="material-symbols-outlined">
                                          {subMenuItem.subMenuIcon}
                                        </span> */}
                                        <span
                                          style={{
                                            color: "var(--logo-primary-color)",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: subMenuItem.subMenuIcon,
                                          }}
                                        />
                                      </div>
                                      <h6>{subMenuItem.subMenuName}</h6>
                                    </div>
                                    <span className="material-symbols-outlined">
                                      chevron_right
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </>
          {/* DYNAMIC MENUS END */}

          {/* FRONT OFFICE MENUS START */}
          {user && user.role == "frontoffice" && (
            <>
              <div className="business-three-profile-menus-list container">
                <div className="row">
                  <h3 style={{ fontWeight: "700" }}>Front Office</h3>
                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/enquries-list">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span className="material-symbols-outlined">
                                view_list
                              </span>
                            </div>
                            <h6>Enquiries List</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/aboutus">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span className="material-symbols-outlined">
                                info
                              </span>
                            </div>
                            <h6>About Us</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* FRONT OFFICE MENUS END */}
        </div>
      </div>

      {/* EDIT USER DETAILS START */}
      {showEditPopup && (
        <>
          <div
            className={`business-three-profile-edit-overlay ${
              showEditPopup
                ? "business-three-profile-overlay-open"
                : "business-three-profile-overlay-close"
            }`}
          />
          <div
            className={`business-three-profile-edit-popup ${
              showEditPopup
                ? "business-three-profile-open"
                : "business-three-profile-close"
            }`}
          >
            <div className="business-three-profile-edit-popup-content">
              <div className="business-three-profile-edit-popup-header">
                <h2>Edit User Details</h2>
                <span
                  className="business-three-profile-edit-popup-close"
                  onClick={handleCancelClick}
                >
                  &times;
                </span>
              </div>
              <div className="business-three-profile-edit-popup-body">
                <input
                  type="text"
                  name="fullName"
                  value={userDetails.fullName}
                  onChange={handleInputChange}
                  placeholder="Full Name"
                />
                <input
                  type="textarea"
                  name="address"
                  value={userDetails.address}
                  onChange={handleInputChange}
                  placeholder="Address"
                />
                <input
                  type="textarea"
                  name="city"
                  value={userDetails.city}
                  onChange={handleInputChange}
                  placeholder="City"
                />
                <input
                  type="email"
                  name="email"
                  value={userDetails.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                />
              </div>
              <div className="business-three-profile-edit-popup-footer">
                <Link to={`/user-detail/${user.uid}`}>
                  <button className="btn_fill">More Details</button>
                </Link>

                <button
                  onClick={handleConfirmClick}
                  disabled={confirmButtonDisabled}
                  className="btn_fill"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* END EDIT USER DETAILS  */}

      {/* Image Change Popup */}
      {showImagePopup && (
        <>
          <div
            className={`business-three-profile-edit-overlay ${
              showImagePopup
                ? "business-three-profile-overlay-open"
                : "business-three-profile-overlay-close"
            }`}
          />
          <div
            className={`business-three-profile-edit-popup ${
              showImagePopup
                ? "business-three-profile-open"
                : "business-three-profile-close"
            }`}
          >
            <div className="business-three-profile-edit-popup-header">
              <h2>Change Picture</h2>
              {!imgUploading && (
                <span
                  className="business-three-profile-edit-popup-close"
                  onClick={handleCancelImgClick}
                >
                  &times;
                </span>
              )}
            </div>
            <div className="row">
              <div className="business-three-profile-popup-body col-12 col-md-4">
                <div className="row">
                  <div className="business-three-profile-image-preview col-6 col-md-12">
                    <img
                      src={imagePreview || (user && user.photoURL)}
                      alt="Preview"
                    />
                    {!imgUploading && (
                      <div style={{ cursor: "pointer" }}>
                        <span
                          className="material-symbols-outlined business-three-profile-delete-icon"
                          onClick={handleRemoveImage}
                        >
                          delete
                        </span>
                      </div>
                    )}
                  </div>
                  {imgUploading ? (
                    <div className="d-flex align-items-center justify-content-center col-6 col-md-12">
                      <BeatLoader color={"var(--click-color)"} loading={true} />
                    </div>
                  ) : (
                    <div className="business-three-profile-button-group col-6 col-md-12">
                      {/* start file and camera for mobile */}
                      <div className="d-flex justify-content-between business-three-profile-icon-group">
                        {!imgUploading && (
                          <div
                            style={{
                              cursor: "pointer",
                              backgroundColor: "var(--grey-light)",
                              padding: "5px",
                              borderRadius: "6px",
                            }}
                            className="d-flex align-items-center"
                          >
                            <input
                              type="file"
                              accept="image/*"
                              id="file-input"
                              capture="environment" // Opens camera by default on mobile devices
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                            <span
                              className="material-symbols-outlined business-three-profile-delete-icon"
                              onClick={() =>
                                document.getElementById("file-input").click()
                              }
                            >
                              photo_camera
                            </span>
                          </div>
                        )}

                        {!imgUploading && (
                          <div
                            style={{
                              cursor: "pointer",
                              backgroundColor: "var(--grey-light)",
                              padding: "5px",
                              borderRadius: "6px",
                            }}
                            className="d-flex align-items-center"
                          >
                            <input
                              type="file"
                              accept="image/*"
                              id="file-input"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                            <span
                              className="material-symbols-outlined business-three-profile-delete-icon"
                              onClick={() =>
                                document.getElementById("file-input").click()
                              }
                            >
                              folder_open
                            </span>
                          </div>
                        )}
                      </div>
                      {/* end file and camera for mobile */}

                      {/* start file for pc */}
                      <input
                        type="file"
                        accept="image/*"
                        id="file-input"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                      <button
                        onClick={() =>
                          document.getElementById("file-input").click()
                        }
                        className="btn_fill pc_profile_browse_btn"
                      >
                        Browse
                      </button>
                      {/* end file for pc */}
                      {imagePreview && (
                        <button
                          onClick={handleConfirmImgClick}
                          className="btn_fill"
                        >
                          Confirm
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="business-three-profile-popup-footer col-12 col-md-8">
                <div className="business-three-profile-line-container">
                  <div className="business-three-profile-line"></div>
                  <div className="business-three-profile-or-text">or</div>
                  <div className="business-three-profile-line"></div>
                </div>
                <div className="business-three-profile-dummy-images">
                  {dummyImages.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Dummy ${index + 1}`}
                      onClick={() => handleDummyImageClick(img)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BusinessThreeProfile;
