import React, { useState, useEffect } from "react";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";

import "./UserDetailsAddress.scss";

const UserDetailsAddress = ({ title, type, userProfileId }) => {
  // get and update code start
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );
  const { updateDocument } = useFirestore("users");

  const [isEditing, setIsEditing] = useState(false);
  const [addressDetails, setAddressDetails] = useState({
    houseNo: "",
    street: "",
    area: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  });
  useEffect(() => {
    if (userProfileDoc && userProfileDoc[type]) {
      setAddressDetails(userProfileDoc[type]);
    }
  }, [userProfileDoc, type]);
  const [isAddressSaving, setIsAddressSaving] = useState(false);
  const [saveAddressMessage, setSaveAddressMessage] = useState("");
  const [addressMessageType, setAddressMessageType] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setAddressDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCancelClick = () => {
    if (userProfileDoc && userProfileDoc[type]) {
      setAddressDetails(userProfileDoc[type]);
    }
    setIsEditing(!isEditing);
  };

  const validateForm = () => {
    if (
      !addressDetails.houseNo ||
      !addressDetails.street ||
      !addressDetails.area ||
      !addressDetails.city ||
      !addressDetails.pincode ||
      !addressDetails.state ||
      !addressDetails.country
    ) {
      setSaveAddressMessage("Please fill all required fields.");
      setAddressMessageType("error_msg");
      setTimeout(() => {
        setSaveAddressMessage("");
        setAddressMessageType("");
      }, 4000);
      return false;
    }
    return true;
  };

  const addressSubmit = async () => {
    if (!validateForm()) return;

    setIsAddressSaving(true);
    setSaveAddressMessage("");

    try {
      const updatedData = {
        ...userProfileDoc,
        [type]: addressDetails,
      };

      await updateDocument(userProfileId, updatedData);
      setAddressMessageType("success_msg");
      setSaveAddressMessage("Updated successfully!");

      setTimeout(() => {
        setIsEditing(false);
      }, 4000);
    } catch (error) {
      console.error("Error updating residence address details:", error);
      setAddressMessageType("error_msg");
      setSaveAddressMessage(
        "Failed to update residence address details. Please try again."
      );
    } finally {
      setIsAddressSaving(false);
      setTimeout(() => {
        setSaveAddressMessage("");
        setAddressMessageType("");
      }, 4000);
    }
  };
  return (
    <div className="pd-cards animate-slide-in">
      <div className="pd-heading">
        <h2>{title}</h2>
        {!userProfileDoc?.profileLock && !isEditing && (
          <div
            className="pd-edit-state"
            onClick={() => {
              setIsEditing((prev) => !prev);
            }}
          >
            <span className="material-symbols-outlined">edit</span>
            Edit
          </div>
        )}
      </div>
      <div className="pd-data">
        {!isEditing ? (
          <>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/flat.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>House/Flat No.</h6>
                <p>{userProfileDoc?.[type]?.houseNo ?? "Not provided yet"}</p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/streets.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Locality/Street</h6>
                <p>{userProfileDoc?.[type]?.street ?? "Not provided yet"}</p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/landmark.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Area/Landmark</h6>
                <p>{userProfileDoc?.[type]?.area ?? "Not provided yet"}</p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/location.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Pincode</h6>
                <p>{userProfileDoc?.[type]?.pincode ?? "Not provided yet"}</p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/placeholder.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>City</h6>
                <p>{userProfileDoc?.[type]?.city ?? "Not provided yet"}</p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/placeholder.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>State</h6>
                <p>{userProfileDoc?.[type]?.state ?? "Not provided yet"}</p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/placeholder.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Country</h6>
                <p>{userProfileDoc?.[type]?.country ?? "Not provided yet"}</p>
              </div>
            </div>
          </>
        ) : (
          <div className="col mb-4">
            <div className="row row_gap">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    House/Flate No.
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    name="houseNo"
                    value={addressDetails.houseNo}
                    onChange={handleInputChange}
                    placeholder="Enter House/Flat Number"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Locality/Street
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    name="street"
                    value={addressDetails.street}
                    onChange={handleInputChange}
                    placeholder="Enter your Locality/Street"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Area/Landmark
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    name="area"
                    value={addressDetails.area}
                    onChange={handleInputChange}
                    placeholder="Enter your Area/Landmark"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">Pincode</label>
                  <input
                    type="text"
                    className="form-input"
                    maxLength="6"
                    name="pincode"
                    value={addressDetails.pincode}
                    onChange={handleInputChange}
                    placeholder="Enter your Pincode"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">City</label>
                  <input
                    type="text"
                    className="form-input"
                    name="city"
                    value={addressDetails.city}
                    onChange={handleInputChange}
                    placeholder="Enter your City"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">State</label>
                  <input
                    type="text"
                    className="form-input"
                    name="state"
                    value={addressDetails.state}
                    onChange={handleInputChange}
                    placeholder="Enter your State"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">Country</label>
                  <input
                    type="text"
                    className="form-input"
                    name="country"
                    value={addressDetails.country}
                    onChange={handleInputChange}
                    placeholder="Enter your Country"
                  />
                </div>
              </div>
            </div>
            <div className="pd-edit-option">
              {saveAddressMessage && (
                <p className={`edit-msg_area ${addressMessageType}`}>
                  {saveAddressMessage}
                </p>
              )}

              <button
                onClick={handleCancelClick}
                disabled={isAddressSaving}
                className={` pd-option-btn option-btn-cancel ${
                  isAddressSaving ? "disabled" : ""
                }`}
              >
                Cancel
              </button>
              <button
                onClick={addressSubmit}
                disabled={isAddressSaving}
                className={` pd-option-btn option-btn-save ${
                  isAddressSaving ? "disabled" : ""
                }`}
              >
                {isAddressSaving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailsAddress;
