import React from 'react'

// import css 
import './HappyerpCurveDivider.css'

const HappyerpCurveTop = () => {
  return (
    <div className='happyerp-curve_divider happyerp-curve_top'>
      <img src="/assets/img/happyerp/curve_top.png" alt="" />
    </div>
  )
}

export default HappyerpCurveTop;
