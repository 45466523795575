import React, { useState, useMemo, useRef, useEffect } from "react";
import DatePicker from "react-datepicker"; // npm install react-datepicker
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

// import tabs start
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
// import { CSSTransition } from "react-transition-group";

// import tabs end

// import component
// import Select from "react-select";
import Back from "../../../happyerpComponents/ui/back/HappyerpBack";
import HappyerpCard from "../../../happyerpComponents/card/HappyerpCard"; //
import ActionSelect from "./HappyerpActionSelect";
import BulkActionPopup from "./HappyerpBulkActionPopup";
// import SummaryCard from "../../../components/card/SummaryCard";
import DataEntryCard from "../../../happyerpComponents/dataEntryCard/HappyerpDataEntryCard"; //
import CardDetailWrapper from "../../../happyerpComponents/cardDetail/HappyerpCardDetailWrapper"; //
// import ThemeTable from "../../../components/themeTable/ThemeTable";
import ReactTable from "../../../happyerpComponents/reactTable/HappyerpReactTable"; //
// import BottomAddButton from "../../../components/bottomAddButton/BottomAddButton";
// import NineDots from "../../../components/nineDots/NineDots";
// import FooterBottom from "../../../components/footer/FooterBottom";

// import css
import "./HappyerpSales.scss";
import { useCollection } from "../../../../../firebase files/hooks/useCollection";
import { useAuthContext } from "../../../../../firebase files/hooks/useAuthContext";
// import HappyerpMoreOptionPopup from "../../../components/card/MoreOptionPopup";

const HappyerpSales = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  // add or remove class  start
  const [createFormShow, setCreateFormShow] = useState(false);
  const handleCreateForm = () => {
    setCreateFormShow(!createFormShow);
  };
  // add or remove class  end

  // custom idinput js start
  const [value, setValue] = useState(1); // Initial value is 1

  const handleIncrement = (e) => {
    e.preventDefault();
    setValue(value + 1); // Increment the value
  };

  const handleDecrement = (e) => {
    e.preventDefault();
    if (value > 1) {
      setValue(value - 1); // Decrement the value, but not below 1
    }
  };
  // custom idinput js end

  // card view mode js start
  const [viewMode, setviewMode] = useState("happyerp-col_3"); // Initial mode is grid with 3 columns

  const handleModeChange = (newViewMode) => {
    setviewMode(newViewMode);
  };
  // card view mode js end

  //-----------code for closing the popup when clicked anywhere starts

  const [activeCardId, setActiveCardId] = useState(null); //to handle the active card
  const toggleMoreOptions = (id) => {
    console.log(id, "toggle is clicked");

    //to change the id and set it in the state variable wrt to the card clicked upon
    setActiveCardId(activeCardId === id ? null : id);
  };
  //-------------------code for closing the popup ends

  // code for click saveand next button end
  const tableData = [
    {
      id: 1,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 2,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 3,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 4,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 5,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 6,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 7,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 8,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 9,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 10,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 11,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 12,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 13,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 14,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 15,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 16,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 17,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 18,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 19,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
    {
      id: 20,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size: "L",
      numberOfItem: "5",
      quantity: "500",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      orderNumber: "#_34245",
      remark: "Decent and Comfortable",
    },
  ];

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "S.No",
        accessor: (row, i) => i + 1,
        id: "serialNumber",
        Cell: ({ row }) => row.index + 1,
        disableFilters: false,
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
        disableFilters: false,
      },

      {
        Header: "Order Number",
        accessor: "orderNumber",
        disableFilters: false,
      },
      {
        Header: "Delivery Date",
        accessor: "deliveryDate",
        disableFilters: false,
      },

      // {
      //   Header: "Order",
      //   accessor: "order",
      //   disableFilters: false,
      // },

      {
        Header: "Party Name",
        accessor: "partyName",
        disableFilters: false,
      },
      {
        Header: "Item",
        accessor: "item",
        disableFilters: false,
      },
      {
        Header: "No. of Item",
        accessor: "numberOfItem",
        disableFilters: false,
      },
      {
        Header: "Total Quantity",
        accessor: "quantity",
        disableFilters: false,
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   disableFilters: false,
      // },
      // {
      //   Header: "View",
      //   accessor: "View",
      //   disableFilters: false,
      // },

      {
        Header: "Size",
        accessor: "size",
        disableFilters: false,
      },
      {
        Header: "Color",
        accessor: "color",
        disableFilters: false,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: false,
        Cell: ({ row }) => {
          console.log("activeCardId ", activeCardId);
          // return (
          //   <MoreOptionPopup
          //     id={row.original.id}
          //     isMoreOptionsOpen={activeCardId === row.original.id}
          //     toggleMoreOptions={toggleMoreOptions}
          //   />
          // );
        },
      },

      // {
      //   Header: "Remark",
      //   accessor: "remark",
      //   disableFilters: false,
      // },
    ];

    return baseColumns;
  }, []);
  //rows and columns data for table view starts here

  //code for the Select button for the bulk action starts here:
  // const [selectedBulkAction, setSelectedBulkAction] = useState(null);

  // const optionsBulkActions = [
  //   { value: "share", label: "Share" },
  //   { value: "export", label: "Export" },
  //   { value: "delete", label: "Delete" },
  // ];

  //code for Action select in Bulk Action starts here
  const [selectedOption, setSelectedOption] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const options = [
    { value: "share", label: "Share" },
    { value: "export", label: "Export" },
    { value: "delete", label: "Delete" },
  ];

  const handleMenuOpen = () => {
    setMenuIsOpen(true); // Keep the dropdown open
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false); // Close the dropdown after applying
  };

  //code for delete popup starts here
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);

  const handleShowDeletePopUp = () => setShowDeletePopUp(true);
  const handleCloseDeletePopUp = () => setShowDeletePopUp(false);
  //code for delete popup ends

  const handleApply = () => {
    if (selectedOption.value === "delete") {
      setShowDeletePopUp(true);
    }
    setMenuIsOpen(false);
  };
  //code for Action select in Bulk Action ends here

  // code for sales card  start here
  const { documents: dbSalesOrders } = useCollection("sales_order", null, [
    "createdAt",
    "desc",
  ]);
  console.log(dbSalesOrders);

  // code for sales card  end here

  return (
    <div
      className={`happyerp-sales_pg happyerp-pg_margin happyerp-pg_bg ${
        createFormShow ? "happyerp-form_show" : ""
      }`}
    >
      {/* {!createFormShow && (
        <NineDots
          options={[
            { link: "/dashboard", text: "Dashboard", icon: "dashboard" },
            { link: "/purchase", text: "Purchase", icon: "shop_two" },
            {
              link: "/production",
              text: "Production",
              icon: "production_quantity_limits",
            },
            { link: "/stock", text: "Stock", icon: "inventory_2" },
          ]}
        />
      )} */}

      <Link className="happyerp-add_button" to="/new-order">
        <span class="material-symbols-outlined">add</span>
      </Link>

      {/* <BottomAddButton onClick={handleCreateForm} /> */}

      <div className="happyerp-pg_header happyerp-pg_header2">
        <div className="happyerp-first_row">
          <div className="happyerp-left">
            <h2 className="happyerp-text_black happyerp-df20 happyerp-medium happyerp-back_with_text">
              <Back />
              Manage Sales Orders
            </h2>
          </div>
          <div className="happyerp-right"> </div>
        </div>
        <div className="happyerp-dvg12"></div>
        <div className="happyerp-second_row">
          <div className="happyerp-left">
            <div className="happyerp-form_field happyerp-with_right_icon">
              {/* <label htmlFor="" className='label'></label> */}
              <div className="happyerp-field_box happyerp-search_bar">
                <input type="text" placeholder="Search" />
                <div className="happyerp-field_icon">
                  <span class="material-symbols-outlined">search</span>
                </div>
              </div>
            </div>
            <div className="happyerp-button_filter">
              <div className="happyerp-bf_single happyerp-active">Today</div>
              <div className="happyerp-bf_single">Yesterday</div>
              <div className="happyerp-bf_single">Week</div>
            </div>
            <div className="happyerp-date_filter_range d-flex align-items-center">
              <div className="happyerp-datefilter happyerp-relative">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yy" // Date format
                  placeholderText="From" // Placeholder text
                />
                <span class="material-symbols-outlined">calendar_month</span>
              </div>
              <div className="happyerp-datefilter happyerp-relative">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yy" // Date format
                  placeholderText="To" // Placeholder text
                />
                <span class="material-symbols-outlined">calendar_month</span>
              </div>
            </div>
          </div>
          <div className="happyerp-center_ba">
            {/* <Select
                placeholder="Bulk Action"
                options={optionsBulkActions}
                value={selectedBulkAction}
                onChange={(action) => setSelectedBulkAction(action)}
                isSearchable
                isClearable
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 35,
                    minHeight: 34,
                    width: 200,
                    minWidth: 200,
                    borderColor: "#ccc",
                    boxShadow: "none",
                    borderRadius: 0,
                    cursor: "pointer",
                    alignItems: "center",
                    fontSize: 14,
                  }),
                }}
              /> */}
            <div style={{ width: "200px" }}>
              <ActionSelect
                options={options}
                selectedOption={selectedOption}
                onChange={setSelectedOption}
                onApply={handleApply}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                menuIsOpen={menuIsOpen}
              />
            </div>
            {/* Bulk Action Delete popup  */}
            {showDeletePopUp && (
              <BulkActionPopup
                show={showDeletePopUp}
                onClose={handleCloseDeletePopUp}
              />
            )}
          </div>
          <div className="happyerp-right" style={{ alignItems: "center" }}>
            <div className="happyerp-button_filter" style={{ height: "34px" }}>
              <div
                className={`happyerp-bf_single ${
                  viewMode === "happyerp-col_3" ? "happyerp-active" : ""
                }`}
                onClick={() => handleModeChange("happyerp-col_3")}
              >
                <span className="material-symbols-outlined">
                  calendar_view_month
                </span>
              </div>
              <div
                className={`happyerp-bf_single ${
                  viewMode === "happyerp-col_2" ? "happyerp-active" : ""
                }`}
                onClick={() => handleModeChange("happyerp-col_2")}
              >
                <span className="material-symbols-outlined">grid_view</span>
              </div>
              <div
                className={`happyerp-bf_single ${
                  viewMode === "happyerp-table_view" ? "happyerp-active" : ""
                }`}
                onClick={() => handleModeChange("happyerp-table_view")}
              >
                <span className="material-symbols-outlined">view_list</span>
              </div>
            </div>
            {/* <div className="theme_btn inline_btn with_icon" onClick={handleCreateForm}>
                       {!createFormShow ? "Create New" : "Close"}
                       <span class="material-symbols-outlined btn_icon">
                           {!createFormShow ? "arrow_drop_down" : "close"}

                       </span>

                   </div> */}
            <Link
              to="/order-report"
              className="happyerp-theme_btn happyerp-inline_btn"
            >
              Order Report
            </Link>
          </div>
        </div>
      </div>
      <div className="happyerp-page_inner">
        <div className="happyerp-dvg22"></div>
        {viewMode !== "happyerp-table_view" && (
          <div className={`happyerp-card_view ${viewMode}`}>
            {dbSalesOrders?.map((order) => (
              <HappyerpCard
                key={order.id}
                id={order.id}
                order={order}
                isMoreOptionsOpen={activeCardId === order.id}
                toggleMoreOptions={toggleMoreOptions}
              />
            ))}
          </div>
        )}
        {viewMode === "happyerp-table_view" && (
          <div className={`happyerp-card_view ${viewMode}`}>
            {/* <ThemeTable headers={tableHeaders} data={tableData} /> */}
            {/* <ThemeTable /> */}
            <ReactTable tableColumns={columns} tableData={tableData} />
          </div>
        )}
      </div>

      {/* <FooterBottom /> */}
    </div>
  );
};

export default HappyerpSales;
