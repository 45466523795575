import React from 'react'
import { Route,Routes } from 'react-router-dom';
import CustomerPanelLayout from './customerPanelLayout/CustomerPanelLayout';
import CustomerDashboardHome from './customerPanelPages/customerHome/CustomerDashboardHome';
import CustomerDashboardOrders from './customerPanelPages/customerOrders/CustomerDashboardOrders';
import CustomerDashboardOrderDetails from './customerPanelPages/customerOrdersDetails/CustomerDashboardOrderDetails';

const CustomerRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<CustomerPanelLayout/>}>
                <Route index element={<CustomerDashboardHome/>}/> 
                <Route path="home" element={<CustomerDashboardHome />}/>
                <Route path="orders" element={<CustomerDashboardOrders />}/>
                <Route path="orders/:orderId" element={<CustomerDashboardOrderDetails/>}/>
            </Route>
        </Routes>
    )
}

export default CustomerRoutes;