import React, { useState, useEffect } from "react";
import { projectFirestore, timestamp } from "../../firebase config/config";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import "./ContactUs.css";
import CorporateTwoClients from "../../templates/corporate/corporateTwo/corporateTwoComponents/CorporateTwoClients/CorporateTwoClients";
import { BeatLoader } from "react-spinners";
import { useSignupPhone } from "../../firebase files/hooks/useSignupPhone";
import { useNavigate } from "react-router-dom";
import { useCollection } from "../../firebase files/hooks/useCollection";
import { projectStorage } from "../../firebase config/config";
import PhoneInput from "react-phone-input-2";
import { Alert, Dropdown } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useRef } from "react";

// Simple email validation regex
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

const ContactUs = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const { document: contactDetails } = useDocument("settings", "contactUs");
  const { document: contactPageData } = useDocument("pages-data", "contactUs");

  const { document } = useDocument("settings", "organisation");
  const { addDocument, response } = useFirestore("enquiries");

  const { updateDocument: updateContactUsDocument } =
    useFirestore("pages-data");

  // START LOGIN USING CONTACT FORM
  const { loggedInUser } = useAuthContext();

  const [isUser, setUser] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [country, setCountryName] = useState("");
  const [address, setAddress] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const { setUpRecapcha, resendOTP } = useSignupPhone();
  const [confirmObj, setConfirmObj] = useState("");
  const [userName, setUserName] = useState("");
  const [mobilenoSliderState, setmobilenoSliderState] = useState(true);
  const [otpSliderState, setotpSliderState] = useState(false);
  const [newUserSliderState, setnewUserSliderState] = useState(false);
  const [genderSelectionSliderState, setGenderSelectionSliderState] =
    useState(true);
  const [newUserDetailsSliderState, setNewUserDetailsSliderState] =
    useState(false);
  const navigate = useNavigate();
  const [resendOTPFlag, setResendOTPFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [isOtpVisible, setIsOtpVisible] = useState(false);

  const { updateDocument, response: responseUpdateDocument } =
    useFirestore("users");

  const { documents: allCategories, error: allCategoriesError } =
    useCollection("m_categories");

  const handleWheel = (e) => {
    // Prevent scrolling changes
    e.preventDefault();
  };

  //   send opt
  const getOTP = async (e) => {
    e.preventDefault();
    setIsResendDisabled(true);
    setError("");

    // Check if name, email, and message are required
    if (!name) {
      return setError("Full Name is required.");
    }
    // Check if email is required
    if (!email) {
      return setError("Email is required.");
    }

    // Validate email structure
    if (!validateEmail(email)) {
      return setError("Please enter a valid email address.");
    }

    // Check if message is required
    if (!formData.message) {
      return setError("Message is required.");
    }

    // Validate phone number
    if (phone === "" || phone === undefined || phone.length < 10) {
      return setError("Please enter a valid mobile number");
    }

    try {
      setIsLoading(true);
      // let btnSendOTP = document.getElementById("btn_sendotp");
      // btnSendOTP.style.display = "none";
      const respons = await setUpRecapcha("+" + phone);
      setConfirmObj(respons);
      setmobilenoSliderState(false);
      setotpSliderState(true);
      setIsLoading(false);
      // setnewUserSliderState(false);
    } catch (error) {
      console.log("error.message", error.message);
      setError(error.message);
      await resendOTP("+" + phone);
      // let obj_maintenance = document.getElementById("btn_sendotp");
      // obj_maintenance.style.display = "block";
      setIsLoading(false); // Stop the loader
    }
  };

  // OTP verify
  const verifyOTP = async (e) => {
    e.preventDefault();
    setmobilenoSliderState(false);
    if (otp === "" || otp === undefined || otp === null || otp < 6) {
      return setError("Please enter six digit OTP");
    }
    setIsLoading(true); // Start the loader
    setError("");

    try {
      await confirmObj.confirm(otp).then(async (result) => {
        const user = result.user;
        setUser(user);

        // Check if the user is new
        if (result.additionalUserInfo.isNewUser) {
          setUserName(user.displayName);

          // Split the full name by space
          let splitName = userName.split(" ");

          // Extract the first name
          let firstName = splitName[0];

          let imgUrl = "/assets/img/dummy_user.png";

          await user.updateProfile({
            phoneNumber: phone,
            displayName: firstName,
            photoURL: imgUrl,
          });

          projectFirestore
            .collection("users")
            .doc(user.uid)
            .set({
              online: true,
              displayName: firstName,
              fullName: name,
              gender: "",
              phoneNumber: phone,
              email: email,
              city,
              address,
              country,
              countryCode,
              role: "customer",
              roles: ["customer"],
              photoURL: imgUrl,
              accessType: "country",
              accessValue: "India",
              status: "active",
              createdAt: timestamp.fromDate(new Date()),
              lastLoginTimestamp: timestamp.fromDate(new Date()),
            });

          // Add user data to 'enquiries' collection
          const contactData = {
            fullName: name,
            email: email,
            phoneNumber: phone,
            message: formData.message,
            contactFor: formData.contactFor,
            createdBy: user.uid,
            createdAt: timestamp.fromDate(new Date()), // Optionally add a created timestamp
          };

          // Use 'set' to add the document with the user.uid as the document ID
          await projectFirestore.collection("enquiries").doc().set(contactData);

          setSuccessMessage(
            "We appreciate your message and will be in touch with you shortly. Thank you for reaching out to us!"
          );

          setnewUserSliderState(true);
          setotpSliderState(false);
          setIsLoading(false);
        } else {
          console.log("Existing user signed in with phone number");
          setIsLoading(true);

          await updateDocument(user.uid, {
            online: true,
            lastLoginTimestamp: timestamp.fromDate(new Date()),
          });

          // Add user data to 'enquiries' collection
          const contactData = {
            fullName: name,
            email: email,
            phoneNumber: phone,
            message: formData.message,
            contactFor: formData.contactFor,
            createdBy: user.uid,
            createdAt: timestamp.fromDate(new Date()), // Optionally add a created timestamp
          };

          // Use 'set' to add the document with the user.uid as the document ID
          await projectFirestore.collection("enquiries").doc().set(contactData);

          setSuccessMessage(
            "We appreciate your message and will be in touch with you shortly. Thank you for reaching out to us!"
          );

          setotpSliderState(false);
          setnewUserSliderState(false);
          setGenderSelectionSliderState(false);
          setNewUserDetailsSliderState(false);
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.log("error.message", error.message);
      setError(
        "Given OTP is not valid, please enter the valid OTP sent to your number"
      );
      setIsLoading(false);

      setTimeout(function () {
        setError("");
        setResendOTPFlag(true);
      }, 30000);
    }
  };

  // Toggle OTP visibility
  const toggleOtpVisibility = () => {
    setIsOtpVisible(!isOtpVisible);
  };

  const handlePhoneChange = (value, countryData) => {
    setPhone(value);
    setCountryCode(countryData.countryCode);
    setCountryName(countryData.name);
  };
  // END LOGIN USING CONTACT FORM

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userRef = projectFirestore.collection("users").doc(user.uid);
        const doc = await userRef.get();
        if (doc.exists) {
          const userData = doc.data();
          setFormData({
            fullName: userData.fullName || "",
            email: userData.email || "",
            phoneNumber: userData.phoneNumber || "",
            message: "",
          });
        }
      };
      fetchUserData();
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsPending(true);

    const contactconfirm = {
      ...formData,
      createdAt: timestamp.fromDate(new Date()),
    };

    await addDocument(contactconfirm);
    setIsPending(false);
    setSuccessMessage(
      "We appreciate your message and will be in touch with you shortly. Thank you for reaching out to us!"
    );

    setFormData({
      fullName: "",
      email: "",
      phoneNumber: "",
      message: "",
      contactFor: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // START CHANGE CONTACT FORM RIGHT IMAGE
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null); // To store the file for upload
  const [isUploading, setIsUploading] = useState(false); // New state to track upload status
  const [uploadError, setUploadError] = useState(null); // State for upload error message
  const fileInputRef = useRef(null);
  const handleAddPhotoClick = () => {
    setIsPopupOpen(true);
  };

  // Handle file selection
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(URL.createObjectURL(e.target.files[0]));
      setImageFile(e.target.files[0]); // Store the file
    }
  };

  const handleConfirm = async () => {
    if (!imageFile) return;
    setIsUploading(true); // Start uploading
    setUploadError(null);

    const storageRef = projectStorage.ref();
    const fileRef = storageRef.child(`contactUs/formImage/${imageFile.name}`);

    try {
      // Upload image to Firebase Storage
      await fileRef.put(imageFile);
      const downloadURL = await fileRef.getDownloadURL();

      // Update Firestore document with the image URL
      await updateContactUsDocument("contactUs", { formImage: downloadURL });

      setIsPopupOpen(false);
    } catch (error) {
      setUploadError("Failed to upload image. Please try again.");
    } finally {
      setIsUploading(false); // Stop uploading
      setSelectedImage(null); // Reset image preview
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  // END CHANGE CONTACT FORM RIGHT IMAGE

  return (
    <>
      <div className="pg_contact_us">
        {/* START CHANGE CONTACT FORM RIGHT IMAGE */}
        {isPopupOpen && (
          <div className="pg_contact_us_popup-overlay">
            <div className="pg_contact_us_popup-content">
              <button
                className="pg_contact_us_close-btn"
                onClick={handleClosePopup}
              >
                <span class="material-symbols-outlined">close</span>
              </button>
              <div className="pg_contact_us_selected-image-wrapper">
                {/* {selectedImage && (
                  <> */}
                <div
                  className={`pg_contact_us_selected-image-preview ${
                    isUploading ? "dull-background" : ""
                  }`}
                >
                  <img
                    src={
                      selectedImage ||
                      (contactPageData && contactPageData.formImage) ||
                      "/assets/img/contact-us.jfif"
                    }
                    alt="Selected"
                  />
                  {isUploading && (
                    <div className="pg_contact_us_uploading-gif-container">
                      <img
                        src="/assets/img/cloud-upload.gif"
                        alt="Uploading"
                        className="contact-section-uploading-gif"
                      />
                    </div>
                  )}
                </div>
                {/* </>
                )} */}
              </div>

              <div style={{ position: "relative" }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
              </div>
              <div className="d-flex justify-content-between mt-3">
                {!isUploading && (
                  <button
                    className="btn_border"
                    onClick={() => fileInputRef.current.click()}
                  >
                    Replace Image
                  </button>
                )}

                <button
                  className="btn_fill"
                  onClick={handleConfirm}
                  disabled={isUploading}
                >
                  {isUploading ? "Uploading..." : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        )}
        {/* END CHANGE CONTACT FORM RIGHT IMAGE */}

        <section className="loc_em_ph">
          <div className="d-flex align-items-center flex-column pvcy_polcy_head_div">
            <h2
              className="map_heading"
              style={{ textAlign: "center", color: "white" }}
            >
              {document && document.short_name}
            </h2>
          </div>
          <div className="container">
            <div
              className="loc_em_ph_inner"
              style={{
                color: "var(--black-color)",
              }}
            >
              <div className="lep_single">
                <div className="icon_div ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30px"
                    viewBox="0 -960 960 960"
                    width="30px"
                    fill="currentColor"
                  >
                    <path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" />
                  </svg>
                </div>
                <h4>Address</h4>
                <h6 className="lep_single_address">
                  {document && document.address}
                </h6>
              </div>

              <div className="lep_single">
                <a
                  href={` mailto:${
                    contactDetails && contactDetails.contactEmail
                  }`}
                  style={{ color: "var(--black-color)" }}
                >
                  <div>
                    <div className="icon_div">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="30px"
                        viewBox="0 -960 960 960"
                        width="30px"
                        fill="currentColor"
                      >
                        <path d="m720-160-56-56 63-64H560v-80h167l-63-64 56-56 160 160-160 160ZM160-280q-33 0-56.5-23.5T80-360v-400q0-33 23.5-56.5T160-840h520q33 0 56.5 23.5T760-760v204q-10-2-20-3t-20-1q-10 0-20 .5t-20 2.5v-147L416-520 160-703v343h323q-2 10-2.5 20t-.5 20q0 10 1 20t3 20H160Zm58-480 198 142 204-142H218Zm-58 400v-400 400Z" />
                      </svg>
                    </div>
                    <h4>Email</h4>
                    <h6>{contactDetails && contactDetails.contactEmail}</h6>
                  </div>
                </a>
              </div>

              <div className="lep_single">
                <a
                  href={
                    "tel:" + (contactDetails && contactDetails.contactNumber)
                  }
                  style={{ color: "var(--black-color)" }}
                >
                  <div>
                    <div className="icon_div">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="30px"
                        viewBox="0 -960 960 960"
                        width="30px"
                        fill="currentColor"
                      >
                        <path d="M680-200h80v-560h-80v560ZM280-400q17 0 28.5-11.5T320-440q0-17-11.5-28.5T280-480q-17 0-28.5 11.5T240-440q0 17 11.5 28.5T280-400Zm0 120q17 0 28.5-11.5T320-320q0-17-11.5-28.5T280-360q-17 0-28.5 11.5T240-320q0 17 11.5 28.5T280-280Zm-40-240h320v-160H240v160Zm160 120q17 0 28.5-11.5T440-440q0-17-11.5-28.5T400-480q-17 0-28.5 11.5T360-440q0 17 11.5 28.5T400-400Zm0 120q17 0 28.5-11.5T440-320q0-17-11.5-28.5T400-360q-17 0-28.5 11.5T360-320q0 17 11.5 28.5T400-280Zm120-120q17 0 28.5-11.5T560-440q0-17-11.5-28.5T520-480q-17 0-28.5 11.5T480-440q0 17 11.5 28.5T520-400Zm0 120q17 0 28.5-11.5T560-320q0-17-11.5-28.5T520-360q-17 0-28.5 11.5T480-320q0 17 11.5 28.5T520-280Zm80 40v-480H200v480h400Zm80 120q-23 0-40.5-11T611-160H200q-33 0-56.5-23.5T120-240v-480q0-33 23.5-56.5T200-800h411q11-18 28.5-29t40.5-11h80q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120h-80ZM200-240v-480 480Z" />
                      </svg>
                    </div>
                    <h4>Phone</h4>
                    <h6>{contactDetails && contactDetails.contactNumber}</h6>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="contact-container d-flex justify-content-center align-items-center">
            <div className="contact-image">
              <div className="pg_contact_us_img_and_icon_div">
                <img
                  src={
                    contactPageData?.formImage
                      ? contactPageData.formImage.trim() !== ""
                        ? contactPageData.formImage // Use the database image if available
                        : "/assets/img/contact-us.jfif" // Default image if value is empty
                      : "/assets/img/contact-us.jfif" // Default image if field is not available
                  }
                  alt="Contact"
                  className="pg_contact_us_img_div"
                />

                {user && user.role === "admin" && (
                  <span
                    class="material-symbols-outlined pg_contact_us_img_icon"
                    onClick={handleAddPhotoClick}
                  >
                    add_a_photo
                  </span>
                )}
              </div>
            </div>

            {user ? (
              <div className="contact-form">
                {successMessage ? (
                  <div className="thank-you-message">
                    <h2>Thank You!</h2>
                    <p>{successMessage}</p>
                  </div>
                ) : (
                  <>
                    <h2>Contact Us</h2>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        name="fullName"
                        placeholder="Name"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        disabled={!!user}
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled={!!user}
                      />
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Mobile"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        disabled={!!user}
                      />
                      <textarea
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                      />
                      <select
                        name="contactFor"
                        value={formData.category}
                        onChange={handleInputChange}
                        required
                        className="cat-dropdown-contact-contact-form"
                      >
                        <option value="">Select a category</option>
                        {allCategories &&
                          allCategories.map((category) => (
                            <option key={category.id} value={category.name}>
                              {category.category}
                            </option>
                          ))}
                      </select>
                      <div className="d-flex justify-content-center align-items-center">
                        {isPending ? (
                          <BeatLoader color="var(--logo-primary-color)" />
                        ) : (
                          <button type="submit" className="btn_fill">
                            Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </>
                )}
              </div>
            ) : (
              <div className="col-md-6">
                <div>
                  {mobilenoSliderState && (
                    <>
                      <div className="contact-form">
                        {successMessage ? (
                          <div className="thank-you-message">
                            <h2>Thank You!</h2>
                            <p>{successMessage}</p>
                          </div>
                        ) : (
                          <>
                            <h2>Contact Us</h2>
                            <form onSubmit={handleSubmit}>
                              <input
                                type="text"
                                name="fullName"
                                placeholder="Name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                required
                              />
                              <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required
                              />
                              <div className="new_form_field with_icon phoneinput">
                                <div>
                                  <PhoneInput
                                    country={"in"}
                                    // onlyCountries={['in', 'us', 'ae']}
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    international
                                    keyboardType="phone-pad"
                                    // countryCallingCodeEditable={false}
                                    countryCodeEditable={true}
                                    // disableCountryCode={true}
                                    placeholder="Country code + mobile number"
                                    inputProps={{
                                      name: "phone",
                                      required: true,
                                      autoFocus: false,
                                    }}
                                    inputStyle={{
                                      width: "100%",
                                      height: "55px",
                                      paddingLeft: "45px",
                                      border: "1px solid #ddd",
                                    }}
                                  ></PhoneInput>
                                </div>
                              </div>

                              <div
                                id="recapcha-container"
                                style={{
                                  marginTop: "-20px",
                                }}
                              ></div>

                              <textarea
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                              />
                              <select
                                name="contactFor"
                                value={formData.category}
                                onChange={handleInputChange}
                                required
                                className="cat-dropdown-contact-contact-form"
                              >
                                <option value="">Select a category</option>
                                {allCategories &&
                                  allCategories.map((category) => (
                                    <option
                                      key={category.id}
                                      value={category.name}
                                    >
                                      {category.category}
                                    </option>
                                  ))}
                              </select>
                              {error && (
                                <Alert variant="danger" className="mt-2">
                                  {error}
                                </Alert>
                              )}
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="">
                                  {!isLoading && (
                                    <>
                                      <div className="d-flex justify-content-center flex-column">
                                        <button
                                          className="btn_fill"
                                          style={{ width: "100%" }}
                                          onClick={getOTP}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                                {isLoading && (
                                  <div className="text-center">
                                    <h6 className="text_green mb-2">
                                      Sending OTP
                                    </h6>
                                    <BeatLoader
                                      color={"var(--click-color)"}
                                      loading={true}
                                    />
                                  </div>
                                )}
                              </div>
                            </form>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* start otp section */}
                  {otpSliderState && (
                    <div className="d-flex justify-content-center flex-column align-items-center">
                      <label
                        htmlFor=""
                        className="d-flex align-items-center mb-2"
                      >
                        Enter 6 digit OTP
                        <span
                          onClick={toggleOtpVisibility}
                          style={{
                            cursor: "pointer",
                            marginLeft: "10px",
                            fontSize: "24px",
                            userSelect: "none",
                          }}
                          className="material-symbols-outlined"
                        >
                          {isOtpVisible ? "visibility" : "visibility_off"}
                        </span>
                      </label>

                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={
                          <span style={{ margin: "10px 5px 20px 5px" }}>-</span>
                        }
                        renderInput={(props) => (
                          <input
                            {...props}
                            type={isOtpVisible ? "text" : "password"}
                            onWheel={handleWheel}
                            inputMode="numeric"
                            style={{
                              width: "40px",
                              height: "40px",
                              border: "1px solid gray",
                              textAlign: "center",
                              borderRadius: "5px",
                              margin: "10px 0px 20px 0px",
                            }}
                          />
                        )}
                      />

                      {error && (
                        <Alert variant="danger" className="mt-2">
                          {error}
                        </Alert>
                      )}

                      {!isLoading && (
                        <div className="d-flex justify-content-center flex-column">
                          <button className="btn_fill" onClick={verifyOTP}>
                            Confirm
                          </button>
                        </div>
                      )}
                      {isLoading && (
                        <div className="text-center">
                          <h6 className="text_green mb-2">Sending</h6>
                          <BeatLoader
                            color="var(--click-color)"
                            loading={true}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {/* end otp section */}

                  {/* IF USER IS NEW  */}
                  {/* <div>
                      {newUserSliderState && (
                        <div>
                          <div className="">
                            <div>
                              {genderSelectionSliderState && (
                                <div className="login-gender-selection-slider">
                                  <h5 className="mb-4">Select Your Gender</h5>
                                  <div className="login-gender-options">
                                    <div
                                      className={`login-gender-option ${selectedGender === "male"
                                        ? "login-gender-selected"
                                        : ""
                                        }`}
                                      onClick={() => handleGenderSelect("male")}
                                    >
                                      <img
                                        src="assets/img/men-icon-login.png"
                                        alt="Male"
                                      />
                                      {selectedGender === "male" && (
                                        <div className="login-gender-tick">
                                          &#10003;
                                        </div>
                                      )}
                                      <p>Male</p>
                                    </div>
                                    <div
                                      className={`login-gender-option ${selectedGender === "female"
                                        ? "login-gender-selected"
                                        : ""
                                        }`}
                                      onClick={() =>
                                        handleGenderSelect("female")
                                      }
                                    >
                                      <img
                                        src="assets/img/women-icon-login.png"
                                        alt="Female"
                                      />
                                      {selectedGender === "female" && (
                                        <div className="login-gender-tick">
                                          &#10003;
                                        </div>
                                      )}
                                      <p>Female</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            {newUserDetailsSliderState && (
                              <>
                                <Box
                                  component="form"
                                  sx={{
                                    "& .MuiTextField-root": {
                                      m: 1,
                                      width: "95%",
                                      textAlign: "center",
                                    },
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "20px",
                                    borderRadius: "8px",
                                  }}
                                  noValidate
                                  autoComplete="on"
                                >
                                  <TextField
                                    label={"Full Name"}
                                    id="margin-none"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    required
                                  />
                                  <TextField
                                    label={"Email"}
                                    id="margin-none"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                  />
                                  <TextField
                                    label={"Current City"}
                                    id="margin-none"
                                    onChange={(e) => setCity(e.target.value)}
                                    value={city}
                                    required
                                  />
                                </Box>
                                {error && (
                                  <div className="field_error">{error}</div>
                                )}
                                <div className="d-flex justify-content-center mt-2">
                                  <button
                                    className="btn_fill"
                                    onClick={newUserForm}
                                  >
                                    Done
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div> */}
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="form_sec">
          <h2 className="map_top_heading">{document && document.short_name}</h2>
          <iframe
            src={contactDetails && contactDetails.contactmap}
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </section>
        <CorporateTwoClients
          category="partners"
          pageName="contact"
          section="partners"
          addMoreVideosButton={false}
          addMoreImagesButton={true}
          addMoreTextContentButton={false}
        />
        {/* <CorporateTwoDownloadApp/> */}
      </div>
    </>
  );
};

export default ContactUs;
