import React from "react";
import Carousel from "react-bootstrap/Carousel";

// import css
import "./HappyerpTestimonial.scss";

const HappyerpTestimonial = () => {
  return (
    <div className="happyerp-tesimonial happyerp-sect_padding">
      <div className="container">
        <div className="happyerp-testimonial_inner">
          <Carousel
            interval={5000} // Set time interval (in milliseconds)
            controls={false} // Hide the control arrows
            indicators={false} // Hide the slide indicators
            pause={false} // Disable auto-pause on hover
            touch // Enable touch gestures for swipe
            loop={true}
          >
            <Carousel.Item>
              <div className="happyerp-top">
                <div className="happyerp-star">
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                </div>
                <div className="happyerp-name_pic">
                  <div className="happyerp-left">
                    <h5 className="happyerp-df22 happyerp-text_black happyerp-medium">
                      Sanskar Solanki
                    </h5>
                    <h6 className="happyerp-df15 happyerp-text_grey">
                      Developer
                    </h6>
                  </div>
                  <div className="happyerp-right">
                    <img
                      src="/assets/img/happyerp/home/demo_customer.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <h4 className="happyerp-say happyerp-df20 happyerp-regular happyerp-text_black">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id,
                recusandae! Optio velit expedita corporis obcaecati, suscipit
                quis libero dolores minima quam itaque fugit? Esse, maiores
                minima repudiandae praesentium nobis consectetur.
              </h4>
            </Carousel.Item>
            <Carousel.Item>
              <div className="happyerp-top">
                <div className="happyerp-star">
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                </div>
                <div className="happyerp-name_pic">
                  <div className="happyerp-left">
                    <h5 className="happyerp-df22 happyerp-text_black happyerp-medium">
                      Naman Gaur
                    </h5>
                    <h6 className="happyerp-df15 happyerp-text_grey">
                      Developer
                    </h6>
                  </div>
                  <div className="happyerp-right">
                    <img
                      src="/assets/img/happyerp/home/demo_customer.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <h4 className="happyerp-say happyerp-df20 happyerp-regular happyerp-text_black">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id,
                recusandae! Optio velit expedita corporis obcaecati, suscipit
                quis libero dolores minima quam itaque fugit? Esse, maiores
                minima repudiandae praesentium nobis consectetur.
              </h4>
            </Carousel.Item>
            <Carousel.Item>
              <div className="happyerp-top">
                <div className="happyerp-star">
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                </div>
                <div className="happyerp-name_pic">
                  <div className="happyerp-left">
                    <h5 className="happyerp-df22 happyerp-text_black happyerp-medium">
                      Rahul Kumar
                    </h5>
                    <h6 className="happyerp-df15 happyerp-text_grey">
                      Developer
                    </h6>
                  </div>
                  <div className="happyerp-right">
                    <img
                      src="/assets/img/happyerp/home/demo_customer.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <h4 className="happyerp-say happyerp-df20 happyerp-regular happyerp-text_black">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id,
                recusandae! Optio velit expedita corporis obcaecati, suscipit
                quis libero dolores minima quam itaque fugit? Esse, maiores
                minima repudiandae praesentium nobis consectetur.
              </h4>
            </Carousel.Item>
            <Carousel.Item>
              <div className="happyerp-top">
                <div className="happyerp-star">
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                </div>
                <div className="happyerp-name_pic">
                  <div className="happyerp-left">
                    <h5 className="happyerp-df22 happyerp-text_black happyerp-medium">
                      Mohan Sharma
                    </h5>
                    <h6 className="happyerp-df15 happyerp-text_grey">
                      Developer
                    </h6>
                  </div>
                  <div className="happyerp-right">
                    <img
                      src="/assets/img/happyerp/home/demo_customer.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <h4 className="happyerp-say happyerp-df20 happyerp-regular happyerp-text_black">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id,
                recusandae! Optio velit expedita corporis obcaecati, suscipit
                quis libero dolores minima quam itaque fugit? Esse, maiores
                minima repudiandae praesentium nobis consectetur.
              </h4>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div className="happyerp-band"></div>
    </div>
  );
};

export default HappyerpTestimonial;
