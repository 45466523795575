import React from "react";
import { useMemo } from "react";

// import component
import ReactTable from "../reactTable/HappyerpReactTable";

const HappyerpOrderDetailTableView = () => {
  const tableData = [
    {
      id: 1,
      deliveryDate: "01/12/2024",
      partyName: "Naman Gaur",
      item: "t-shirt",
      order: "3",
      style: "Regular",
      design: "Check",
      subDesign: "Sub Design 1",
      color: "Black, Yellow, Red, Pink",
      size32_quantity: "500000",
      size34_quantity: "500000",
      size36_quantity: "500000",
      size38_quantity: "500000",
      size40_quantity: "500000",
      transport: "DC Mechanics",
      placedBy: "Rahul",
      agentName: "Khushi",
      createdDate: "30/11/2024",
      remark: "Decent and Comfortable",
    },
    {
      id: 2,
      deliveryDate: "02/12/2024",
      partyName: "Rohan Mehta",
      item: "shirt",
      order: "5",
      style: "Slim Fit",
      design: "Striped",
      subDesign: "Sub Design 2",
      color: "White, Black, Yellow, Red",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "M",
      quantity: "50000",
      transport: "Quick Logistics",
      placedBy: "Aman",
      agentName: "Kriti",
      createdDate: "29/11/2024",
      remark: "Elegant and Stylish",
    },
    {
      id: 3,
      deliveryDate: "03/12/2024",
      partyName: "Sneha Sharma",
      item: "jacket",
      order: "2",
      style: "Puffer",
      design: "Plain",
      subDesign: "Sub Design 3",
      color: "Red, Green, Violet",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "XL",
      transport: "Speedy Express",
      placedBy: "Pooja",
      agentName: "Aditya",
      createdDate: "28/11/2024",
      remark: "Warm and Comfortable",
    },
    {
      id: 4,
      deliveryDate: "04/12/2024",
      partyName: "Arjun Kapoor",
      item: "jeans",
      order: "4",
      style: "Skinny",
      design: "Faded",
      subDesign: "Sub Design 4",
      color: "Blue, Black, Yellow, Red, Pink",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "32",
      transport: "Fast Movers",
      placedBy: "Ravi",
      agentName: "Meera",
      createdDate: "27/11/2024",
      remark: "Trendy and Durable",
    },
    {
      id: 5,
      deliveryDate: "05/12/2024",
      partyName: "Priya Verma",
      item: "skirt",
      order: "3",
      style: "A-Line",
      design: "Floral",
      subDesign: "Sub Design 5",
      color: "Pink, Black, Yellow, Red",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "S",
      transport: "Swift Deliveries",
      placedBy: "Neha",
      agentName: "Karan",
      createdDate: "26/11/2024",
      remark: "Chic and Comfortable",
    },
    {
      id: 6,
      deliveryDate: "06/12/2024",
      partyName: "Vikram Singh",
      item: "sweater",
      order: "6",
      style: "Cardigan",
      design: "Knit",
      subDesign: "Sub Design 6",
      color: "Gray",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "M",
      transport: "Turbo Transport",
      placedBy: "Rohit",
      agentName: "Simran",
      createdDate: "25/11/2024",
      remark: "Cozy and Durable",
    },
    {
      id: 7,
      deliveryDate: "07/12/2024",
      partyName: "Karan Patel",
      item: "shorts",
      order: "2",
      style: "Casual",
      design: "Plain",
      subDesign: "Sub Design 7",
      color: "Beige, Pink, Red, Gray",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "L",
      transport: "Rapid Couriers",
      placedBy: "Manav",
      agentName: "Anita",
      createdDate: "24/11/2024",
      remark: "Lightweight and Comfortable",
    },
    {
      id: 8,
      deliveryDate: "08/12/2024",
      partyName: "Riya Gupta",
      item: "cap",
      order: "5",
      style: "Snapback",
      design: "Logo",
      subDesign: "Sub Design 8",
      color: "Green, Black, Maroon",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "Free Size",
      transport: "Express Services",
      placedBy: "Tina",
      agentName: "Vivek",
      createdDate: "23/11/2024",
      remark: "Stylish and Trendy",
    },
    {
      id: 9,
      deliveryDate: "09/12/2024",
      partyName: "Sameer Khanna",
      item: "tie",
      order: "10",
      style: "Formal",
      design: "Solid",
      subDesign: "Sub Design 9",
      color: "Navy",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "Standard",
      transport: "Quick Ship",
      placedBy: "Rahul",
      agentName: "Megha",
      createdDate: "22/11/2024",
      remark: "Elegant and Professional",
    },
    {
      id: 10,
      deliveryDate: "10/12/2024",
      partyName: "Pooja Nair",
      item: "scarf",
      order: "7",
      style: "Infinity",
      design: "Patterned",
      subDesign: "Sub Design 10",
      color: "Purple",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "One Size",
      transport: "Fast Tracks",
      placedBy: "Shweta",
      agentName: "Kabir",
      createdDate: "21/11/2024",
      remark: "Soft and Stylish",
    },
    {
      id: 11,
      deliveryDate: "02/12/2024",
      partyName: "Arjun Malhotra",
      item: "shirt",
      order: "2",
      style: "Slim Fit",
      design: "striped",
      subDesign: "Sub Design A",
      color: "Blue",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "M",
      transport: "Speed Logistics",
      placedBy: "Vikram",
      agentName: "Aditi",
      createdDate: "01/12/2024",
      remark: "Elegant and Stylish",
    },
    {
      id: 12,
      deliveryDate: "03/12/2024",
      partyName: "Priya Singh",
      item: "jeans",
      order: "4",
      style: "Classic",
      design: "plain",
      subDesign: "Sub Design B",
      color: "Indigo",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "32",
      transport: "Blue Dart",
      placedBy: "Sonia",
      agentName: "Ramesh",
      createdDate: "02/12/2024",
      remark: "Comfortable for all-day wear",
    },
    {
      id: 13,
      deliveryDate: "04/12/2024",
      partyName: "Kunal Verma",
      item: "jacket",
      order: "1",
      style: "Puffer",
      design: "solid",
      subDesign: "Sub Design C",
      color: "Red",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "L",
      transport: "FastTrack Couriers",
      placedBy: "Ankit",
      agentName: "Meena",
      createdDate: "03/12/2024",
      remark: "Warm and cozy",
    },
    {
      id: 14,
      deliveryDate: "05/12/2024",
      partyName: "Rohit Sharma",
      item: "trousers",
      order: "3",
      style: "Formal",
      design: "plain",
      subDesign: "Sub Design D",
      color: "Gray",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "34",
      transport: "Delhivery",
      placedBy: "Pooja",
      agentName: "Vivek",
      createdDate: "04/12/2024",
      remark: "Perfect for office wear",
    },
    {
      id: 15,
      deliveryDate: "06/12/2024",
      partyName: "Meera Nair",
      item: "kurta",
      order: "2",
      style: "Ethnic",
      design: "embroidered",
      subDesign: "Sub Design E",
      color: "Pink",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "M",
      transport: "DTDC",
      placedBy: "Shweta",
      agentName: "Rajesh",
      createdDate: "05/12/2024",
      remark: "Beautiful and traditional",
    },
    {
      id: 16,
      deliveryDate: "07/12/2024",
      partyName: "Ajay Kumar",
      item: "hoodie",
      order: "5",
      style: "Casual",
      design: "graphic",
      subDesign: "Sub Design F",
      color: "Green",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "XL",
      transport: "SpeedPost",
      placedBy: "Rohini",
      agentName: "Karan",
      createdDate: "06/12/2024",
      remark: "Trendy and comfortable",
    },
    {
      id: 17,
      deliveryDate: "08/12/2024",
      partyName: "Shivani Patel",
      item: "sweater",
      order: "1",
      style: "Knitted",
      design: "patterned",
      subDesign: "Sub Design G",
      color: "Purple",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "S",
      transport: "Ecom Express",
      placedBy: "Vijay",
      agentName: "Simran",
      createdDate: "07/12/2024",
      remark: "Soft and warm",
    },
    {
      id: 18,
      deliveryDate: "09/12/2024",
      partyName: "Vivek Gupta",
      item: "shorts",
      order: "6",
      style: "Casual",
      design: "solid",
      subDesign: "Sub Design H",
      color: "Beige",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "L",
      transport: "Blue Dart",
      placedBy: "Manish",
      agentName: "Kriti",
      createdDate: "08/12/2024",
      remark: "Perfect for summer",
    },
    {
      id: 19,
      deliveryDate: "10/12/2024",
      partyName: "Neha Roy",
      item: "skirt",
      order: "2",
      style: "Flared",
      design: "floral",
      subDesign: "Sub Design I",
      color: "Yellow",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "M",
      transport: "FedEx",
      placedBy: "Sandeep",
      agentName: "Alok",
      createdDate: "09/12/2024",
      remark: "Light and breezy",
    },
    {
      id: 20,
      deliveryDate: "11/12/2024",
      partyName: "Pankaj Jain",
      item: "blazer",
      order: "1",
      style: "Formal",
      design: "plain",
      subDesign: "Sub Design J",
      color: "Black",
      size32_quantity: "50000",
      size34_quantity: "50000",
      size36_quantity: "50000",
      size38_quantity: "50000",
      size40_quantity: "50000",
      size: "42",
      transport: "Professional Couriers",
      placedBy: "Aman",
      agentName: "Nisha",
      createdDate: "10/12/2024",
      remark: "Sophisticated and professional",
    },
  ];

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "S.No",
        accessor: (row, i) => i + 1,
        id: "serialNumber",
        Cell: ({ row }) => row.index + 1,
        disableFilters: false,
      },
      {
        Header: "Item",
        accessor: "item",
        disableFilters: false,
      },

      {
        Header: "Design",
        accessor: "design",
        disableFilters: false,
      },
      {
        Header: "Sub Design",
        accessor: "subDesign",
        disableFilters: false,
      },
      {
        Header: "Style",
        accessor: "style",
        disableFilters: false,
      },
      {
        Header: "Color",
        accessor: "color",
        disableFilters: false,
      },
      {
        Header: "Size: 32 | Qty",
        accessor: "size32_quantity",
        disableFilters: false,
        Cell: ({ value }) =>
          new Intl.NumberFormat("en-IN", {
            maximumFractionDigits: 0,
          }).format(value),
      },
      {
        Header: "Size: 34 | Qty",
        accessor: "size34_quantity",
        disableFilters: false,
        Cell: ({ value }) =>
          new Intl.NumberFormat("en-IN", {
            maximumFractionDigits: 0,
          }).format(value),
      },
      {
        Header: "Size: 36 | Qty",
        accessor: "size36_quantity",
        disableFilters: false,
        Cell: ({ value }) =>
          new Intl.NumberFormat("en-IN", {
            maximumFractionDigits: 0,
          }).format(value),
      },
      {
        Header: "Size: 38 | Qty",
        accessor: "size38_quantity",
        disableFilters: false,
        Cell: ({ value }) =>
          new Intl.NumberFormat("en-IN", {
            maximumFractionDigits: 0,
          }).format(value),
      },
      {
        Header: "Size: 40 | Qty",
        accessor: "size40_quantity",
        disableFilters: false,
        Cell: ({ value }) =>
          new Intl.NumberFormat("en-IN", {
            maximumFractionDigits: 0,
          }).format(value),
      },
      // {
      //   Header: "Quantity",
      //   accessor: "quantity",
      //   disableFilters: false,
      // },

      // {
      //   Header: "Remark",
      //   accessor: "remark",
      //   disableFilters: false,
      // },
    ];

    return baseColumns;
  });
  return (
    <div>
      <ReactTable tableColumns={columns} tableData={tableData} />
    </div>
  );
};

export default HappyerpOrderDetailTableView;
