import React from "react";
import { Link } from "react-router-dom";

// import css
import "./HappyerpFooter.scss";

const HapperpFooterBottom = () => {
  return (
    <div className="happyerp-copyright">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <p className="happyerp-df16 happyerp-text_white">
              Copyright @2024{" "}
              <span className="happyerp-text_orange">Happy ERP</span>. All
              Rights Reserved by{" "}
              <span className="happyerp-text_orange">Happy ERP</span>
            </p>
          </div>
          <div className="col-6">
            <p className="happyerp-text_white text-end">
              Design & Developed By{" "}
              <Link
                to="https://hyperclouddigital.com/"
                className="text_orange"
                target="_blank"
              >
                Hyper Cloud Digital Solution
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HapperpFooterBottom;
