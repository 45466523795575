import React from "react";
import { Link } from "react-router-dom";

// import component
import HappyerpCurveTop from "../curve/HappyerpCurveTop";

// import css
import "./HappyerpFooter.scss";

const HappyerpFooter = () => {
  return (
    <div className="happyerp-footer">
      <div className="happyerp-footer_top">
        <div className="container">
          <div
            className="happyerp-ft_inner"
            style={{
              backgroundImage:
                "url('/assets/img/happyerp/home/footer_top_bg.png')",
            }}
          >
            <div className="row align-items-center">
              <div className="col-6">
                <h3 className="happyerp-text_white happyerp-semibold happyerp-df36">
                  1500+ Businesses Who Sell Smarter With Happy ERP
                </h3>
              </div>
              <div className="col-6 text-end">
                <div
                  className="d-flex align-items-center justify-content-end"
                  style={{
                    gap: "5px",
                  }}
                >
                  <div className="happyerp-theme_btn happyerp-btn_white happyerp-with_icon">
                    Sign Up
                    <span class="material-symbols-outlined happyerp-btn_icon">
                      arrow_forward
                    </span>
                  </div>
                  <div className="happyerp-theme_btn happyerp-with_icon happyerp-btn_white_border">
                    See All Features
                    <span class="material-symbols-outlined happyerp-btn_icon">
                      arrow_forward
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="happyerp-footer_inner happyerp-relative">
        <HappyerpCurveTop />
        <div className="container">
          <div className="row">
            <div className="col-3">
              <div className="happyerp-footer_links">
                <h6 className="happyerp-df20 happyerp-semibold happyerp-text_white">
                  Useful Links
                </h6>
                <div className="happyerp-fl_inner">
                  <Link className="happyerp-fl_single" to="">
                    <span class="happyerp--symbols-outlined">add</span>
                    Lorem Ipsum
                  </Link>
                  <Link className="happyerp-fl_single" to="">
                    <span class="material-symbols-outlined">add</span>
                    Lorem Ipsum
                  </Link>
                  <Link className="happyerp-fl_single" to="">
                    <span class="material-symbols-outlined">add</span>
                    Lorem Ipsum
                  </Link>
                  <Link className="happyerp-fl_single" to="">
                    <span class="material-symbols-outlined">add</span>
                    Lorem Ipsum
                  </Link>
                  <Link className="happyerp-fl_single" to="">
                    <span class="material-symbols-outlined">add</span>
                    Lorem Ipsum
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="happyerp-footer_links">
                <h6 className="happyerp-df20 happyerp-semibold happyerp-text_white">
                  Quick Links
                </h6>
                <div className="happyerp-fl_inner">
                  <Link className="happyerp-fl_single" to="">
                    <span class="material-symbols-outlined">add</span>
                    Lorem Ipsum
                  </Link>
                  <Link className="happyerp-fl_single" to="">
                    <span class="material-symbols-outlined">add</span>
                    Lorem Ipsum
                  </Link>
                  <Link className="happyerp-fl_single" to="">
                    <span class="material-symbols-outlined">add</span>
                    Lorem Ipsum
                  </Link>
                  <Link className="happyerp-fl_single" to="">
                    <span class="material-symbols-outlined">add</span>
                    Lorem Ipsum
                  </Link>
                  <Link className="happyerp-fl_single" to="">
                    <span class="material-symbols-outlined">add</span>
                    Lorem Ipsum
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="happyerp-footer_links">
                <h6 className="happyerp-df20 happyerp-semibold happyerp-text_white">
                  Social Media
                </h6>
              </div>

              <div className="happyerp-footer_social_media">
                <Link to="">
                  <img
                    src="/assets/img/happyerp/icon/footer_google.png"
                    alt=""
                  />
                </Link>
                <Link to="">
                  <img
                    src="/assets/img/happyerp/icon/footer_linkedin.png"
                    alt=""
                  />
                </Link>
                <Link to="">
                  <img
                    src="/assets/img/happyerp/icon/footer_youtube.png"
                    alt=""
                  />
                </Link>
                <Link to="">
                  <img
                    src="/assets/img/happyerp/icon/footer_whatsapp.png"
                    alt=""
                  />
                </Link>
                <Link to="">
                  <img
                    src="/assets/img/happyerp/icon/footer_snapchat.png"
                    alt=""
                  />
                </Link>
                <Link to="">
                  <img
                    src="/assets/img/happyerp/icon/footer_twitter.png"
                    alt=""
                  />
                </Link>
                <Link to="">
                  <img
                    src="/assets/img/happyerp/icon/footer_skype.png"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HappyerpFooter;
