import React from "react";
import Select, { components } from "react-select";

// Custom Dropdown Menu Component
const CustomMenu = (props) => {
  const { children, selectProps } = props;

  return (
    <div>
      <components.Menu {...props}>
        {children}
        <div
          style={{
            padding: "10px",
            textAlign: "center",
            borderTop: "1px solid #ddd",
          }}
        >
          <button
            style={{
              padding: "5px 15px",
              backgroundColor: "var(--theme-teal)",
              color: "white",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              width: "100px",
              height: "40px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              selectProps.onApply();
            }}
          >
            Apply
          </button>
        </div>
      </components.Menu>
    </div>
  );
};

// ActionSelect Component
const ActionSelect = ({
  options,
  selectedOption,
  onApply,
  onChange,
  onMenuOpen,
  onMenuClose,
  menuIsOpen,
}) => {
  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={onChange}
      components={{ Menu: CustomMenu }}
      onApply={onApply}
      placeholder="Bulk Actions"
      onMenuOpen={onMenuOpen} // Triggering parent's onMenuOpen function
      onMenuClose={onMenuClose} // Triggering parent's onMenuClose function
      menuIsOpen={menuIsOpen}
      closeMenuOnSelect={false}
      isClearable={true}
      isSearchable={true}
    />
  );
};

export default ActionSelect;
