import { useEffect, useState } from "react";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";

const UserDetailsStaffDetails = ({ userProfileId }) => {
  const { document: userProfileDoc } = useDocument("users", userProfileId);
  const { updateDocument } = useFirestore("users");

  const [staffCategory, setStaffCategory] = useState("");
  const [isAttendance, setIsAttendance] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (userProfileDoc) {
      setStaffCategory(userProfileDoc.staffCategory || "");
      setIsAttendance(userProfileDoc.isAttendance ?? true);
    }
  }, [userProfileDoc]);

  const handleCategoryChange = (category) => {
    setStaffCategory(category);
  };

  const handleCancelEdit = () => {
    if (userProfileDoc) {
      setStaffCategory(userProfileDoc.staffCategory || "");
      setIsAttendance(userProfileDoc.isAttendance ?? true);
    }
    setIsEditing(false);
    setSaveMessage("");
  };

  const handleSaveCategory = async () => {
    if (!staffCategory) {
      setSaveMessage("Please select a staff category.");
      setMessageType("error_msg");
      setTimeout(() => setSaveMessage(""), 4000);
      return;
    }

    if (
      staffCategory === (userProfileDoc.staffCategory || "") &&
      isAttendance === (userProfileDoc.isAttendance ?? true)
    ) {
      setSaveMessage("No changes detected. Please make updates before saving.");
      setMessageType("error_msg");
      setTimeout(() => setSaveMessage(""), 4000);
      return;
    }

    const dataSet = {
      staffCategory,
      isAttendance,
    };

    setIsSaving(true);
    setSaveMessage("");

    try {
      await updateDocument(userProfileId, dataSet);
      setMessageType("success_msg");
      setSaveMessage("Staff category and attendance updated successfully!");
      setTimeout(() => setIsEditing(false), 4000);
    } catch (error) {
      console.error("Error updating details:", error);
      setMessageType("error_msg");
      setSaveMessage("Failed to update. Please try again.");
    } finally {
      setIsSaving(false);
      setTimeout(() => setSaveMessage(""), 4000);
    }
  };

  return (
    <div className="pd-cards animate-slide-in">
      <div className="pd-heading">
        <h2>Staff Category</h2>
        {!userProfileDoc?.profileLock && !isEditing && (
          <div className="pd-edit-state" onClick={() => setIsEditing(true)}>
            <span className="material-symbols-outlined">edit</span>
            Edit
          </div>
        )}
      </div>

      <div className="pd-roles">
        {!isEditing ? (
          staffCategory ? (
            <div className="theme_radio_container">
              <div className="radio_single">
                <input
                  type="radio"
                  id={staffCategory}
                  name="fc-staffcategory"
                  value={staffCategory}
                  checked={true}
                  readOnly
                />
                <label htmlFor={staffCategory}>
                  {staffCategory.replace("is", "").trim()}
                </label>
              </div>
            </div>
          ) : (
            <p>Please click on edit to update the information.</p>
          )
        ) : (
          <div className="edit-container" style={{ display: "" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="theme_radio_container">
                {["is Employee", "is Contractor", "is Intern"].map(
                  (category) => (
                    <div className="radio_single" key={category}>
                      <input
                        type="radio"
                        id={category}
                        name="staff-category"
                        value={category}
                        checked={staffCategory === category}
                        onChange={() => handleCategoryChange(category)}
                      />
                      <label htmlFor={category}>
                        {category.replace("is", "")}
                      </label>
                    </div>
                  )
                )}
              </div>

              {/* Attendance Section */}
              <div className="theme_radio_container">
                <h6 style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Is Attendance? -{" "}
                </h6>
                <div className="radio_single">
                  <input
                    type="radio"
                    id="attendance-yes"
                    name="attendance"
                    value="Yes"
                    checked={isAttendance === true}
                    onChange={() => setIsAttendance(true)}
                  />
                  <label htmlFor="attendance-yes">Yes</label>
                </div>
                <div className="radio_single">
                  <input
                    type="radio"
                    id="attendance-no"
                    name="attendance"
                    value="No"
                    checked={isAttendance === false}
                    onChange={() => setIsAttendance(false)}
                  />
                  <label htmlFor="attendance-no">No</label>
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div className="pd-edit-option">
              {saveMessage && (
                <p className={`edit-msg_area ${messageType}`}>{saveMessage}</p>
              )}
              <button
                onClick={handleCancelEdit}
                disabled={isSaving}
                className={`pd-option-btn option-btn-cancel ${
                  isSaving ? "disabled" : ""
                }`}
              >
                Cancel
              </button>
              <button
                onClick={handleSaveCategory}
                disabled={isSaving}
                className={`pd-option-btn option-btn-save ${
                  isSaving ? "disabled" : ""
                }`}
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailsStaffDetails;
