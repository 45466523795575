import React from 'react'
import { Route, Routes } from "react-router-dom";
import AppStoreOneHome from '../appStoreOnePages/appStoreOneHome/AppStoreOneHome'
import Navbar from '../../../../default components/Navbar'
import PGBusinessThreeProfile from '../../../business/businessThree/businessThreePages/PGBusinessThreeProfile'
import GlobalRoute from '../../../../global route/GlobalRoute'
import ColorsComponent from '../../../../default components/ColorsComponent'
import FooterUpper from '../../../../default components/FooterUpper'
import Footer from '../../../../default components/Footer'
import PGAboutUs from '../../../../default pages/about us/PGAboutUs';
import PGDashboard from '../../../../allRoles/dashboards/PGDashboard';
const AppStoreOneRoute = () => {
    return (
        <div>
            <Navbar />
            <Routes>
                <Route path="/" element={<AppStoreOneHome />} />
                <Route path="/profile" element={<PGBusinessThreeProfile />} />
                <Route path="/aboutus" element={<PGAboutUs/>}/>
                <Route path="/dashboard" element={<PGDashboard />}></Route>
            </Routes>
            <GlobalRoute />
            <ColorsComponent />
            <FooterUpper />
            <Footer />
        </div>
    )
}

export default AppStoreOneRoute
