import React, { useEffect, useState } from "react";
//importing scss
import "./UserDetail.scss";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { format } from "date-fns";
import { Modal } from "react-bootstrap";
import { useCollection } from "../../firebase files/hooks/useCollection";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";

// importing components
import UserDetailsLeft from "./components/UserDetailsLeft";
import UserDetailsAddress from "./components/UserDetailsAddress";
import UserDetailsReferences from "./components/UserDetailsReferences";
import UserDetailsDependents from "./components/UserDetailsDependents";
import UserDetailsParents from "./components/UserDetailsParents";
import UserDetailsBankDetails from "./components/UserDetailsBankDetails";
import UserDetailsEmpDetails from "./components/UserDetailsEmpDetails";
import UserDetailsVehicleDetails from "./components/UserDetailsVehicleDetails";
import UserDetailsEmployeeDoc from "./components/UserDetailsEmployeeDoc";
import UserDetailsStaffDetails from "./components/UserDetailsStaffDetails";

import { useParams } from "react-router-dom";
import UserDetailsRoles from "./components/UserDetailsRoles";

const UserDetail = () => {
  const { userProfileId } = useParams();
  const { user } = useAuthContext();

  // get and update code start
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );

  const { updateDocument, response: responseUpdateDocument } =
    useFirestore("users");
  // get and update code end

  // get user
  const { documents: dbUsers, error: dbuserserror } = useCollection("users");
  const [dbUserState, setdbUserState] = useState(dbUsers);

  // code for active inactive start
  // Make sure that userProfileDoc is not null before using it
  const [status, setStatus] = useState(userProfileDoc?.status || "active");
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [reason, setReason] = useState("");
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to handle status changes (active/inactive)
  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setShowPopup(true);
    setPopupData({ status: newStatus });
  };

  // Add a state to track the error message
  const [errorForNoSelectReasonMessage, setErrorForNoSelectReasonMessage] =
    useState("");

  // Function to handle the submission of the status change
  const handlePopupSubmit = async () => {
    // Check if a reason is selected when the status is inactive
    if (popupData.status === "inactive" && !reason) {
      setErrorForNoSelectReasonMessage(
        "Please select a reason before updating the status."
      );
      return; // Don't proceed if no reason is selected
    } else {
      setErrorForNoSelectReasonMessage(""); // Clear error message when a reason is selected
    }

    setLoading(true);
    try {
      const currentDate = new Date();

      const updateData = {
        status: popupData.status,
        activeBy: popupData.status === "active" ? user.uid : null, // replace with your logic
        activeAt: popupData.status === "active" ? currentDate : null, // replace with your logic
        inactiveBy: popupData.status === "inactive" ? user.uid : null,
        inactiveAt: popupData.status === "inactive" ? currentDate : null,
        inactiveReason: popupData.status === "inactive" ? reason : null, // Add reason here
        inactiveRemark: popupData.status === "inactive" ? remark : null, // Add remark here
      };

      // Add to the 'inactiveByAt' array to track status changes
      if (popupData.status === "inactive") {
        //   // Append inactive status details to the map
        updateData.inactiveByAt = userProfileDoc.inactiveByAt || [];
        updateData.inactiveByAt.push({
          inactiveBy: user.uid, // Replace with actual user who is marking inactive
          inactiveAt: currentDate, // Store the current timestamp
          inactiveReason: reason, // Add the selected reason here
          inactiveRemark: remark, // Add the remark here
        });
      }

      if (popupData.status === "active") {
        // Append active status details to the map
        updateData.activeByAt = userProfileDoc.activeByAt || [];
        updateData.activeByAt.push({
          activeBy: user.uid, // Replace with actual user who is marking active
          activeAt: currentDate, // Store the current timestamp
        });
      }

      // Call function to update the document in the database (replace this with your update logic)
      await updateDocument(userProfileId, updateData);
      setShowPopup(false);
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setLoading(false);
    }
  };
  // code for active inactive end

  // code for isemployee start
  const [isStaff, setIsStaff] = useState(userProfileDoc?.isStaff ?? false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    setIsStaff(userProfileDoc?.isStaff ?? false);
  }, [setIsStaff, userProfileDoc]);
  const handleRadioChange = (value) => {
    setIsStaff(value);
    setShowConfirmationPopup(true); // Show the confirmation popup
  };
  const handleUpdateIsStaff = async () => {
    try {
      // Update in the database (assuming you have a function to do this)
      if (isStaff)
        await updateDocument(userProfileId, { isStaff, isAttendance: true });
      else
        await updateDocument(userProfileId, { isStaff, isAttendance: false });
      // Update the state to reflect the change
      setShowConfirmationPopup(false); // Close the popup
    } catch (error) {
      console.error("Error updating employee status:", error);
    }
  };
  // code for isemployee end

  //c0de for fetching roles and setting them starts----
  // useEffect(() => {
  //   const fetchRoles = async () => {
  //     if (!userProfileId) return;

  //     try {
  //       const userDoc = await getDocument("users", userProfileId);

  //       if (userDoc?.roles) {
  //         console.log("Fetched roles:", userDoc.roles);
  //         setRoles(userDoc.roles);
  //       } else {
  //         console.log("No roles found");
  //         setRoles([]);
  //       }
  //     } catch (error) {
  //       console.log("error  fetching roles:", error);
  //       setRoles([]);
  //     }
  //   };
  //   fetchRoles();
  // }, [userProfileId]);
  //
  //code for profile status change starts----
  const [profileStatus, setProfileStatus] = useState(false);
  useEffect(() => {
    setProfileStatus(userProfileDoc?.profileLock ?? false);
  }, [setProfileStatus, userProfileDoc]);
  // const [showProfileStatusPopup, setShowProfileStatusPopup] = useState(false);
  const handleProfileStatusChange = async (newStatus) => {
    setProfileStatus(newStatus); // Update state first
    try {
      await updateDocument(userProfileId, { profileLock: newStatus }); // Use newStatus instead of profileStatus
    } catch (error) {
      console.error("Error updating profile status:", error);
    }
  };

  // const handlePopupSubmitProfileStatus = async () => {
  //   setShowProfileStatusPopup(false);
  //   console.log(profileStatus);
  // };
  //code for profile status change ends----
  return (
    <div className="user-details">
      <div className="user-details-container">
        <div className="user-details-layout">
          {/* left column */}
          <UserDetailsLeft userProfileId={userProfileId} />
          {/* right column */}
          <div className="profile-detail">
            <div className="pd-cards animate-slide-in">
              <div className="pd-info">
                <div style={{ width: "100%" }} className="info-heading">
                  <div className="info-heading">
                    <div>
                      <label>On-boarded</label>
                      <p>
                        {userProfileDoc?.createdAt
                          ? format(
                              userProfileDoc.createdAt.toDate(),
                              "dd-MMM-yyyy"
                            )
                          : ""}
                      </p>
                    </div>
                    <div>
                      <label>Last Login</label>
                      <p>
                        {userProfileDoc && userProfileDoc.lastLoginTimestamp
                          ? format(
                              userProfileDoc.lastLoginTimestamp.toDate(),
                              "dd-MMM-yyyy hh:mm a"
                            )
                          : ""}
                      </p>
                    </div>
                  </div>
                  {user &&
                    ["admin", "superadmin", "hr"].includes(user.role) && (
                      <div className="status">
                        <div className="theme_radio_container">
                          <div className="radio_single">
                            <input
                              type="radio"
                              id="Locked"
                              name="profile_status"
                              value="Locked"
                              checked={profileStatus}
                              onChange={() => {
                                handleProfileStatusChange(true);
                              }}
                            />
                            <label htmlFor="Locked">
                              <div className="label_inner">Locked</div>
                            </label>
                          </div>
                          <div className="radio_single">
                            <input
                              type="radio"
                              id="unlocked"
                              name="profile_status"
                              checked={!profileStatus}
                              onChange={() => {
                                handleProfileStatusChange(false);
                              }}
                            />
                            <label htmlFor="unlocked">
                              <div className="label_inner">Unlocked</div>
                            </label>
                          </div>
                        </div>
                        {/* <Modal
                            show={showProfileStatusPopup}
                            onHide={() => setShowProfileStatusPopup(false)}
                            centered
                          >
                            <Modal.Header
                              className="justify-content-center"
                              style={{
                                border: "none",
                                backgroundColor: "#f0f5ff",
                              }}
                            >
                              <h5>
                                Make This Profile{" "}
                                {profileStatus ? "Locked" : "Unlocked"}
                              </h5>
                            </Modal.Header>
                            <Modal.Body
                              className="text-center"
                              style={{ fontSize: "18px" }}
                            >
                              <span style={{ color: "rgb(250, 98, 98)" }}>
                                Are you sure you want to make this profile{" "}
                                {profileStatus ? "Locked" : "Unlocked"}?
                              </span>
                            </Modal.Body>
                            <Modal.Footer
                              className="d-flex justify-content-between"
                              style={{
                                border: "none",
                                gap: "15px",
                              }}
                            >
                           
                              <div
                                className="cancel_btn"
                                onClick={handlePopupSubmitProfileStatus}
                                style={{ cursor: "pointer" }}
                              >
                                <button className="theme_btn">
                                  {loading ? "Saving..." : "Yes, Update"}
                                </button>
                              </div>
                              <div
                                className="done_btn"
                                onClick={() => setShowProfileStatusPopup(false)}
                                style={{ cursor: "pointer" }}
                              >
                                <button className="theme_btn">No</button>
                              </div>
                            </Modal.Footer>
                          </Modal> */}
                      </div>
                    )}
                </div>
                <div className="info-detail">
                  <div className="status">
                    {user &&
                    ["admin", "superadmin", "hr"].includes(user.role) ? (
                      <>
                        <div className="theme_radio_container">
                          <div className="radio_single">
                            <input
                              type="radio"
                              id="active"
                              name="user_status"
                              value="active"
                              checked={status === "active"}
                              onChange={() => handleStatusChange("active")}
                            />
                            <label htmlFor="active">
                              <div className="label_inner">
                                {userProfileDoc && userProfileDoc.activeAt
                                  ? "Active"
                                  : "Make Active"}

                                {userProfileDoc &&
                                  userProfileDoc.activeBy &&
                                  userProfileDoc.activeAt && (
                                    <div className="info_icon">
                                      <span className="material-symbols-outlined">
                                        info
                                      </span>
                                      <div className="info_icon_inner">
                                        <b className="text_green">Active</b> by
                                        <b>
                                          {userProfileDoc &&
                                            dbUserState &&
                                            dbUserState.find(
                                              (user) =>
                                                user.id ===
                                                userProfileDoc.activeBy
                                            )?.fullName}
                                        </b>
                                        on
                                        <b>
                                          {userProfileDoc &&
                                            format(
                                              userProfileDoc.activeAt.toDate(),
                                              "dd-MMM-yyyy hh:mm a"
                                            )}
                                        </b>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </label>
                          </div>
                          <div className="radio_single">
                            <input
                              type="radio"
                              id="inactive"
                              name="user_status"
                              checked={status === "inactive"}
                              onChange={() => handleStatusChange("inactive")}
                            />
                            <label htmlFor="inactive">
                              <div className="label_inner">
                                {userProfileDoc && userProfileDoc.inactiveAt
                                  ? "Inactive"
                                  : "Make Inactive"}

                                {userProfileDoc &&
                                  userProfileDoc.inactiveAt &&
                                  userProfileDoc.inactiveBy && (
                                    <div className="info_icon">
                                      <span className="material-symbols-outlined">
                                        info
                                      </span>
                                      <div className="info_icon_inner">
                                        <b className="text_red">Inactive</b> by
                                        <b>
                                          {userProfileDoc &&
                                            dbUserState &&
                                            dbUserState.find(
                                              (user) =>
                                                user.id ===
                                                userProfileDoc.inactiveBy
                                            )?.fullName}
                                        </b>
                                        on
                                        {userProfileDoc &&
                                          format(
                                            userProfileDoc.inactiveAt.toDate(),
                                            "dd-MMM-yyyy hh:mm a"
                                          )}
                                        , Reason
                                        {userProfileDoc &&
                                          userProfileDoc.inactiveReason &&
                                          userProfileDoc.inactiveReason}
                                        {/* {userProfileDoc &&
                                      userProfileDoc.inactiveRemark && (
                                        <>
                                          
                                          Remark
                                          <b>{userProfileDoc.inactiveRemark}</b>
                                        </>
                                      )} */}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </label>
                          </div>
                        </div>
                        <Modal
                          show={showPopup}
                          onHide={() => setShowPopup(false)}
                          centered
                        >
                          <Modal.Header
                            className="justify-content-center"
                            style={{
                              border: "none",
                              backgroundColor: "#f0f5ff",
                            }}
                          >
                            <h5>
                              {popupData.status === "inactive"
                                ? "Reason For Inactivity"
                                : "Confirmation"}
                            </h5>
                          </Modal.Header>
                          <Modal.Body
                            className="text-center"
                            style={{ fontSize: "18px" }}
                          >
                            {popupData.status === "inactive" && (
                              <div
                                className="form_container"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "20px",
                                  marginBottom: "20px",
                                }}
                              >
                                <div
                                  className="radio_group"
                                  style={{
                                    display: "grid",
                                    alignItems: "center",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className="radio_single">
                                    <input
                                      type="radio"
                                      name="reason"
                                      value="Security Concerns"
                                      checked={reason === "Security Concerns"}
                                      onChange={() =>
                                        setReason("Security Concerns")
                                      }
                                      id="SecurityConcerns"
                                    />
                                    <label
                                      htmlFor="SecurityConcerns"
                                      style={{ padding: "2px" }}
                                    >
                                      Security Concerns
                                    </label>
                                  </div>
                                  <div className="radio_single">
                                    <input
                                      type="radio"
                                      name="reason"
                                      value="Policy Violations"
                                      checked={reason === "Policy Violations"}
                                      onChange={() =>
                                        setReason("Policy Violations")
                                      }
                                      id="PolicyViolations"
                                    />
                                    <label
                                      htmlFor="PolicyViolations"
                                      style={{ padding: "2px" }}
                                    >
                                      Policy Violations
                                    </label>
                                  </div>
                                  {userProfileDoc?.isStaff && (
                                    <div className="radio_single">
                                      <input
                                        type="radio"
                                        name="reason"
                                        value="Resigned"
                                        checked={reason === "Resigned"}
                                        onChange={() => setReason("Resigned")}
                                        id="Resigned"
                                      />
                                      <label
                                        htmlFor="Resigned"
                                        style={{ padding: "2px" }}
                                      >
                                        Resigned
                                      </label>
                                    </div>
                                  )}
                                  <div className="radio_single">
                                    <input
                                      type="radio"
                                      name="reason"
                                      value="Other"
                                      checked={reason === "Other"}
                                      onChange={() => setReason("Other")}
                                      id="Other"
                                    />
                                    <label
                                      htmlFor="Other"
                                      style={{ padding: "2px" }}
                                    >
                                      Other
                                    </label>
                                  </div>
                                </div>

                                <div
                                  className="textarea_container"
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                  }}
                                >
                                  <textarea
                                    value={remark}
                                    onChange={(e) => setRemark(e.target.value)}
                                    placeholder=" Enter any remark..."
                                    style={{ width: "250px" }}
                                  ></textarea>
                                </div>
                              </div>
                            )}
                            <span style={{ color: "rgb(250, 98, 98)" }}>
                              Are you sure you want to mark this user as
                              {popupData.status}?
                            </span>
                          </Modal.Body>
                          <Modal.Footer
                            className="d-flex justify-content-between"
                            style={{
                              border: "none",
                              gap: "15px",
                            }}
                          >
                            {errorForNoSelectReasonMessage && (
                              <div
                                style={{
                                  fontSize: "15px",
                                  padding: "4px 15px",
                                  borderRadius: "8px",
                                  background: "#ffe9e9",
                                  color: "red",
                                  width: "fit-content",
                                  margin: "auto",
                                }}
                              >
                                {errorForNoSelectReasonMessage}
                              </div>
                            )}
                            <div
                              className="cancel_btn"
                              onClick={handlePopupSubmit}
                              disabled={loading}
                              style={{ cursor: "pointer" }}
                            >
                              <button className="theme_btn">
                                {loading ? "Saving..." : "Yes, Update"}
                              </button>
                            </div>
                            <div
                              className="done_btn"
                              onClick={() => setShowPopup(false)}
                              style={{ cursor: "pointer" }}
                            >
                              <button className="theme_btn">No</button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h6 style={{ fontSize: "0.875rem", fontStyle: "bold" }}>
                          Current status -{" "}
                        </h6>
                        <p style={{ fontSize: "1rem", color: "#3f5e98" }}>
                          {userProfileDoc?.status ?? "No status"}
                        </p>
                      </div>
                    )}
                  </div>

                  {user &&
                    ["admin", "superadmin", "hr"].includes(user.role) && (
                      <div className="status">
                        <h6>Is Staff? - </h6>
                        <div className="theme_radio_container">
                          <div className="radio_single">
                            <input
                              type="radio"
                              id="yes"
                              name="emp_status"
                              checked={isStaff === true}
                              onChange={() => handleRadioChange(true)}
                            />
                            <label htmlFor="yes">Yes</label>
                          </div>
                          <div className="radio_single">
                            <input
                              type="radio"
                              id="no"
                              name="emp_status"
                              checked={isStaff === false}
                              onChange={() => handleRadioChange(false)}
                            />
                            <label htmlFor="no">No</label>
                          </div>
                        </div>
                        <Modal
                          show={showConfirmationPopup}
                          onHide={() => setShowConfirmationPopup(false)}
                          centered
                        >
                          <Modal.Header
                            className="justify-content-center"
                            style={{
                              backgroundColor: "#f0f5ff",
                              border: "none",
                            }}
                          >
                            <h5>Confirmation</h5>
                          </Modal.Header>
                          <Modal.Body
                            className="text-center"
                            style={{
                              color: "#FA6262",
                              fontSize: "20px",
                              border: "none",
                            }}
                          >
                            Are you sure you want to mark this user as
                            {isStaff === "yes" ? "a staff" : "not a staff"}?
                          </Modal.Body>
                          <Modal.Footer
                            className="d-flex justify-content-between"
                            style={{
                              border: "none",
                              gap: "15px",
                            }}
                          >
                            <div
                              className="done_btn theme_btn"
                              onClick={() => setShowConfirmationPopup(false)}
                            >
                              No
                            </div>
                            <div
                              className="cancel_btn theme_btn"
                              onClick={handleUpdateIsStaff}
                              disabled={loading}
                            >
                              {loading ? "Saving..." : "Yes, Update"}
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    )}
                  <div className="current-status">
                    <div className="current-role">
                      <h6>Current Role - </h6>
                      <p>{userProfileDoc?.role ?? "No role"}</p>
                    </div>
                    {user &&
                      (userProfileDoc?.online ? (
                        <div className="current-mode">
                          <p
                            className="online-status"
                            style={{ backgroundColor: "#22c55e" }}
                          />
                          <p style={{ color: "#22c55e" }}>online</p>
                        </div>
                      ) : (
                        <div className="current-mode">
                          <p
                            className="online-status"
                            style={{ backgroundColor: "#e74747" }}
                          />
                          <p style={{ color: "#e74747" }}>offline</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Staff Card  */}
            {userProfileDoc &&
              userProfileDoc.isStaff &&
              (user.role === "admin" ||
                user.role === "superadmin" ||
                user.role === "hr") && (
                <UserDetailsStaffDetails userProfileId={userProfileId} />
              )}

            {/* Roles card */}
            <UserDetailsRoles userProfileId={userProfileId} />

            {/* Access management card */}
            <div className="pd-cards animate-slide-in">
              <div className="pd-heading">
                <h2>Access management</h2>
              </div>
              <div className="pd-data">
                <p style={{ paddingLeft: "8px" }}>No access yet provided</p>
              </div>
            </div>

            {/* Employee Details card */}
            <UserDetailsEmpDetails userProfileId={userProfileId} />

            {/* Vehicle Details card */}
            <UserDetailsVehicleDetails userProfileId={userProfileId} />

            {/* Employee Document card */}
            <UserDetailsEmployeeDoc userProfileId={userProfileId} />
            {/* Bank Detail card */}
            <UserDetailsBankDetails userProfileId={userProfileId} />

            {/* Reference 1 card */}
            <UserDetailsReferences
              title="Reference 1"
              type="ref1"
              userProfileId={userProfileId}
            />

            {/* Reference 2 card */}
            <UserDetailsReferences
              title="Reference 2"
              type="ref2"
              userProfileId={userProfileId}
            />

            {/* Dependents card  */}
            <UserDetailsDependents userProfileId={userProfileId} />

            {/* Parents card  */}
            <UserDetailsParents userProfileId={userProfileId} />

            {/* Residence Address  */}
            <UserDetailsAddress
              title="Communication/Residence Address"
              type="residenceDetails"
              userProfileId={userProfileId}
            />

            {/* Permanent Address  */}
            <UserDetailsAddress
              title="Permanent Address"
              type="permanentDetails"
              userProfileId={userProfileId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
