import React from "react";
import "./ApplyLeave.scss";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useCollection } from "../../firebase files/hooks/useCollection";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { projectFirestore } from "../../firebase config/config";
import { BeatLoader } from "react-spinners";
import { DateRange, DefinedRange } from "react-date-range";
import { addDays, format } from "date-fns"; // Added format for date formatting
import { enUS } from "date-fns/locale"; // Import the locale from date-fns

const ApplyLeave = () => {
  const { user } = useAuthContext(); // Current user
  const today = new Date();

  const [isOpenLeavePopup, setIsOpenLeavePopup] = useState(false);
  const [isAddingLeave, setIsAddingLeave] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState(""); // Stores dropdown selection
  const [remarkLeaveTypeError, setLeaveTypeError] = useState(false);
  const [remarkLeaveRemarksError, setLeaveRemarksError] = useState(false);
  const [isLeaveRemark, setLeaveRemark] = useState("");
  const [direction, setDirection] = useState("horizontal");
  const [detailOpenPopup, setDetailOpenPopup] = useState(false);
  const [mobileFilterOpenPopup, setMobileFilterOpenPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    startDate: today,
    endDate: addDays(today, 1),
  });
  const [state, setState] = useState([
    {
      startDate: today,
      endDate: addDays(today, 1),
      key: "selection",
    },
  ]);

  const userId = user && user.uid;

  // get and update code start
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userId
  );

  const { document: userLeaveDoc, error: userLeaveDocError } = useDocument(
    "applied_leaves",
    userId
  );

  const { document: allotedLeaveDoc, error: allotedLeaveDocError } =
    useDocument("m_leaves", "total_alloted_leaves");

  const {
    documents: appliedLeavesCollection,
    errors: appliedLeavesCollectionError,
  } = useCollection("applied_leaves");

  const { updateDocument } = useFirestore("applied_leaves");

  const handleLeavePopup = () => {
    setIsOpenLeavePopup(true);
  };

  const handleDateChange = (ranges) => {
    const { selection } = ranges;
    const { startDate, endDate } = selection; // ✅ Extract values correctly

    setState([selection]); // Update state with new selection
    setSelectedDate({ startDate, endDate });

    // Calculate total selected days
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const totalSelectedDays =
      Math.ceil(differenceInTime / (1000 * 3600 * 24)) + 1;

    setSelectedDate({ startDate, endDate, totalSelectedDays }); // ✅ Store all values correctly
  };

  const handleConfirm = async () => {
    setIsAddingLeave(true);
    setLeaveRemarksError(false);
    setLeaveTypeError(false);

    let hasError = false;

    if (!selectedLeaveType) {
      setLeaveTypeError(true);
      hasError = true;
    }

    if (!isLeaveRemark.trim()) {
      setLeaveRemarksError(true);
      hasError = true;
    }

    if (hasError) {
      setIsAddingLeave(false);
      return;
    }

    try {
      const userLeaveRef = projectFirestore
        .collection("applied_leaves")
        .doc(userId);
      const userLeaveSnap = await userLeaveRef.get();

      const newLeaveEntry = {
        createdAt: new Date(),
        createdBy: user.uid,
        leaveType: selectedLeaveType,
        leaveRemark: isLeaveRemark,
        startDate: selectedDate.startDate, // ✅ Corrected reference
        endDate: selectedDate.endDate, // ✅ Corrected reference
        status: "pending",
        totalLeaveDays: selectedDate.totalSelectedDays,
      };

      if (!userLeaveSnap.exists) {
        // Create a new document if not exists
        await userLeaveRef.set({
          cloudLeaveList: [newLeaveEntry],
          remainingSickLeaves:
            selectedLeaveType === "Sick Leave"
              ? (allotedLeaveDoc?.sick_leave || 0) -
                selectedDate.totalSelectedDays
              : allotedLeaveDoc?.sick_leave || 0,

          remainingCompensatoryLeaves:
            selectedLeaveType === "Compensatory Leave"
              ? (allotedLeaveDoc?.compensatory_leave || 0) -
                selectedDate.totalSelectedDays
              : allotedLeaveDoc?.compensatory_leave || 0,

          remainingMaternityLeaves:
            selectedLeaveType === "Maternity Leave"
              ? (allotedLeaveDoc?.maternity_leave || 0) -
                selectedDate.totalSelectedDays
              : allotedLeaveDoc?.maternity_leave || 0,

          remainingLWOPLeaves:
            selectedLeaveType === "Leave Without Pay"
              ? (allotedLeaveDoc?.leave_without_pay || 0) -
                selectedDate.totalSelectedDays
              : allotedLeaveDoc?.leave_without_pay || 0,

          remainingPTOLeaves:
            selectedLeaveType === "Paid Time Off"
              ? (allotedLeaveDoc?.paid_time_off || 0) -
                selectedDate.totalSelectedDays
              : allotedLeaveDoc?.paid_time_off || 0,

          createdBy: userId,
        });
        console.log("New leave document created");
      } else {
        // Update existing document
        const existingData = userLeaveSnap.data();
        const updatedLeaveList = [
          ...(existingData.cloudLeaveList || []),
          newLeaveEntry,
        ];

        let updatedSickLeaves =
          existingData.remainingSickLeaves ?? allotedLeaveDoc?.sick_leave;
        let updatedCompensatoryLeaves =
          existingData.remainingCompensatoryLeaves ??
          allotedLeaveDoc?.compensatory_leave;
        let updatedLWOPLeaves =
          existingData.remainingLWOPLeaves ??
          allotedLeaveDoc?.leave_without_pay;
        let updatedMaternityLeaves =
          existingData.remainingMaternityLeaves ??
          allotedLeaveDoc?.maternity_leave;
        let updatedPTOLeaves =
          existingData.remainingPTOLeaves ?? allotedLeaveDoc?.paid_time_off;

        if (selectedLeaveType === "Sick Leave" && updatedSickLeaves > 0) {
          updatedSickLeaves -= 1; // Reduce sick leave count
        }

        if (selectedLeaveType === "Sick Leave" && updatedSickLeaves > 0) {
          updatedSickLeaves -= selectedDate.totalSelectedDays;
        } else if (
          selectedLeaveType === "Compensatory Leave" &&
          updatedCompensatoryLeaves > 0
        ) {
          updatedCompensatoryLeaves -= selectedDate.totalSelectedDays;
        } else if (
          selectedLeaveType === "Leave Without Pay" &&
          updatedLWOPLeaves > 0
        ) {
          updatedLWOPLeaves -= selectedDate.totalSelectedDays;
        } else if (
          selectedLeaveType === "Maternity Leave" &&
          updatedMaternityLeaves > 0
        ) {
          updatedMaternityLeaves -= selectedDate.totalSelectedDays;
        } else if (
          selectedLeaveType === "Paid Time Off" &&
          updatedPTOLeaves > 0
        ) {
          updatedPTOLeaves -= selectedDate.totalSelectedDays;
        }

        await userLeaveRef.update({
          cloudLeaveList: updatedLeaveList,
          remainingSickLeaves: updatedSickLeaves,
          remainingCompensatoryLeaves: updatedCompensatoryLeaves,
          remainingMaternityLeaves: updatedMaternityLeaves,
          remainingLWOPLeaves: updatedLWOPLeaves,
          remainingPTOLeaves: updatedPTOLeaves,
        });
      }
    } catch (error) {
      console.error("Error updating leaves:", error);
    }

    // Reset form and close popup
    setIsOpenLeavePopup(false);
    setSelectedDate("");
    setSelectedLeaveType("");
    setLeaveTypeError("");
    setLeaveRemark("");
    setLeaveRemarksError("");
    setIsAddingLeave(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const isPC = window.innerWidth >= 992;
      setDetailOpenPopup(isPC);
      setMobileFilterOpenPopup(isPC);
    };

    // Check screen size on initial render
    handleResize();

    // Add event listener to update state on resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updateDirection = () => {
      setDirection(window.innerWidth < 768 ? "vertical" : "horizontal");
    };

    // Set initial direction
    updateDirection();

    // Update on resize
    window.addEventListener("resize", updateDirection);
    return () => window.removeEventListener("resize", updateDirection);
  }, []);

  return (
    <div className="parent-add-leave-of-user">
      {/* START POPUP FOR APPLY LEAVE */}
      {isOpenLeavePopup && (
        <div>
          <Modal
            show={isOpenLeavePopup}
            onHide={() => setIsOpenLeavePopup(false)}
            centered
          >
            <Modal.Header
              style={{
                border: "1px solid grey",
                display: "block",
                padding: "3px",
                margin: "5px",
              }}
            >
              <div className="d-flex align-items-center justify-content-center">
                Apply Leave
              </div>
            </Modal.Header>
            <Modal.Body className="text-center">
              <div>
                <div
                  className="form_field pi_input"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="date-picker-container">
                    <DateRange
                      onChange={handleDateChange}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={state}
                      direction={direction}
                      locale={enUS}
                      minDate={today} // Disable past dates
                    />
                  </div>
                </div>
                <div className="form_field pi_input">
                  <div className="d-flex">
                    <select
                      value={selectedLeaveType}
                      onChange={(e) => setSelectedLeaveType(e.target.value)}
                    >
                      <option value="">Select Leave Type</option>
                      {!userLeaveDoc ||
                      (userLeaveDoc.remainingSickLeaves &&
                        userLeaveDoc.remainingSickLeaves > 0) ? (
                        <option value="Sick Leave">Sick Leave</option>
                      ) : (
                        <option
                          value="Sick Leave"
                          disabled
                          title="You have already used all your sick leaves"
                        >
                          Sick Leave (Unavailable)
                        </option>
                      )}

                      {!userLeaveDoc ||
                      (userLeaveDoc.remainingPTOLeaves &&
                        userLeaveDoc.remainingPTOLeaves > 0) ? (
                        <option value="Paid Time Off">
                          Paid Time Off (PTO)
                        </option>
                      ) : (
                        <option
                          disabled
                          title="You have already used all your paid time off"
                        >
                          Paid Time Off (PTO) (Unavailable)
                        </option>
                      )}

                      {!userLeaveDoc ||
                      (userLeaveDoc.remainingMaternityLeaves &&
                        userLeaveDoc.remainingMaternityLeaves > 0) ? (
                        <option value="Maternity Leave">Maternity Leave</option>
                      ) : (
                        <option
                          disabled
                          title="You have already used all your maternity leave"
                        >
                          Maternity Leave
                        </option>
                      )}

                      {!userLeaveDoc ||
                      (userLeaveDoc.remainingLWOPLeaves &&
                        userLeaveDoc.remainingLWOPLeaves > 0) ? (
                        <option value="Leave Without Pay">
                          Leave Without Pay (LWOP)
                        </option>
                      ) : (
                        <option
                          disabled
                          title="You have already used all your leave without pay"
                        >
                          Leave Without Pay (LWOP)
                        </option>
                      )}

                      {!userLeaveDoc ||
                      (userLeaveDoc.remainingCompensatoryLeaves &&
                        userLeaveDoc.remainingCompensatoryLeaves > 0) ? (
                        <option value="Compensatory Leave">
                          Compensatory leave (Comp-Off)
                        </option>
                      ) : (
                        <option
                          disabled
                          title="You have already used all your  compensatory leave (Comp-Off)"
                        >
                          Compensatory leave (Comp-Off)
                        </option>
                      )}
                    </select>
                    &nbsp;
                    <input
                      type="text"
                      placeholder="Enter custom remark"
                      value={isLeaveRemark}
                      onChange={(e) => setLeaveRemark(e.target.value)}
                    />
                  </div>
                  {remarkLeaveTypeError && (
                    <div className="field_error">Please Select Leave Type.</div>
                  )}
                  {remarkLeaveRemarksError && (
                    <div className="field_error">Please enter a remark.</div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer
              className="d-flex justify-content-between"
              style={{
                border: "none",
                gap: "15px",
              }}
            >
              {!isAddingLeave ? (
                <>
                  <button
                    className="btn_border"
                    onClick={() => setIsOpenLeavePopup(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn_fill"
                    onClick={handleConfirm}
                    style={{ color: "white" }}
                  >
                    Confirm
                  </button>
                </>
              ) : (
                <BeatLoader color="grey" />
              )}
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {/* END POPUP FOR APPLY LEAVE */}

      {user && user && (
        <>
          <button className="btn_border" onClick={handleLeavePopup}>
            Apply Leave
          </button>
        </>
      )}
    </div>
  );
};

export default ApplyLeave;
