import React from "react";
import "./LeaveHistory.scss";
import { useCollection } from "../../firebase files/hooks/useCollection";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import ApplyLeave from "./ApplyLeave";

const LeaveHistory = () => {
  // Function to format timestamp into '11-Mar-25' format
  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = timestamp.toDate(); // Convert Firestore timestamp to JS Date
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      })
      .replace(",", "");
  };

  const { user } = useAuthContext();
  const { documents: appliedLeavesCollection } =
    useCollection("applied_leaves");

  return (
    <div className="leave-history-parent-div">
      <h2 className="leave-history-header">Leave Managment System(LMS)</h2>
      <ApplyLeave />
      <div>pending sick leaves: 10/12, </div>
      <div>pending paid leaves: 7/10, </div>
      <div>pending personal leaves: 13, </div>
      <div className="leave-history-tabs">
        <span className="leave-history-tab leave-history-active">PENDING</span>
        <span className="leave-history-tab">REJECTED</span>

        <span className="leave-history-tab">HISTORY</span>
      </div>

      <input
        type="text"
        placeholder="Search employee"
        className="leave-history-search"
      />

      <div className="leave-history-container">
        {appliedLeavesCollection &&
          appliedLeavesCollection.map((doc) =>
            doc.cloudLeaveList?.map((leave, index) => (
              <div key={`${doc.id}-${index}`} className="leave-history-card">
                <div className="leave-history-card-header">
                  <div className="leave-history-initial">{leave.leaveType}</div>
                  <div>
                    <h3 className="leave-history-name">{leave.name}</h3>
                    <span className="leave-history-type">{leave.type}</span>
                  </div>
                  <span className="leave-history-applied">
                    Applied On {formatDate(leave.createdAt)}
                  </span>
                </div>
                <div className="leave-history-details">
                  <p>
                    <strong>Leave Date:</strong> {formatDate(leave.startDate)} -{" "}
                    {formatDate(leave.endDate)}
                  </p>
                  <p>
                    <strong>Status:</strong> {leave.status}
                  </p>
                  <p>
                    <strong>Duration:</strong> {leave.duration}
                  </p>
                  <p>
                    <strong>Reason:</strong> {leave.leaveRemark}
                  </p>
                </div>
                <div className="leave-history-actions">
                  <button className="leave-history-approve">APPROVE</button>
                  <button className="leave-history-reject">REJECT</button>
                </div>
              </div>
            ))
          )}
      </div>
    </div>
  );
};

export default LeaveHistory;
