import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import css 
import './BusinessOneTestimonial.scss'

const BusinessOneTestimonial = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 8000, // Your desired speed after the initial delay
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0, // Initial delay for the autoplay (in milliseconds)
        cssEase: 'linear',
        variableWidth: true,
        pauseOnHover: false,
        loop: true,
    };
    return (
        <section className="business_one_testimonial" >
            <div className="word_slider">
                <Slider {...settings}>
                    <div className='word_single'>
                        Amazing
                    </div>
                    <div className='word_single text-stroke'>
                        Exceptional
                    </div>

                    <div className='word_single'>
                        Professional
                    </div>
                    <div className='word_single text-stroke'>
                        Reliable
                    </div>

                </Slider>
            </div>
            <div className="container">
                <div className="testimonial_inner">
                    <h4 className="line_title justify-content-center"><span></span>Testimonial</h4>
                    <div className="testimonial_card">
                        <div className="tc_single">
                            <div className="back_layer"></div>
                            <div className="top">
                                <div className="left">
                                    <img src="/assets/img/business/businessone/home/testimonial1.jpeg" alt="" />
                                </div>
                                <div className="right">
                                    <h4>shraddha kapoor</h4>
                                    <h5>Bollywood Actress</h5>
                                </div>
                            </div>
                            <div className="content">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi illum nulla ipsa sint culpa ipsum error! Nulla, ea architecto error repellendus fuga asperiores? Perferendis corporis officia, ullam mollitia magni cum?
                            </div>
                        </div>
                        <div className="tc_single">
                            <div className="back_layer"></div>
                            <div className="top">
                                <div className="left">
                                    <img src="/assets/img/business/businessone/home/testimonial2.jpeg" alt="" />
                                </div>
                                <div className="right">
                                    <h4>jubin nautiyal</h4>
                                    <h5>Superstar singer</h5>
                                </div>
                            </div>
                            <div className="content">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi illum nulla ipsa sint culpa ipsum error! Nulla, ea architecto error repellendus fuga asperiores? Perferendis corporis officia, ullam mollitia magni cum?
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BusinessOneTestimonial
