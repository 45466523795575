import React, { useState, useEffect } from "react";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import Select from "react-select";
import UserDetailsUploadDoc from "./UserDetailsUploadDoc";
import "./UserDetailsDependents.scss";
const UserDetailsDependents = ({ userProfileId }) => {
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );
  const { updateDocument } = useFirestore("users");

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Other" },
  ];

  const [isEditing, setIsEditing] = useState(false);
  const [isDependentSaving, setIsDependentSaving] = useState(false);
  const [saveDependentMessage, setSaveDependentMessage] = useState("");
  const [messageDependentType, setMessageDependentType] = useState("");
  const [dependentsData, setDependentsData] = useState({
    spouse: {
      name: "",
      gender: "",
      dob: "",
      document: null,
    },
    children: [
      {
        name: "",
        gender: "",
        dob: "",
        document: null,
      },
    ],
  });
  const addChildren = () => {
    setDependentsData((prev) => ({
      ...prev,
      children: [
        ...prev.children,
        {
          name: "",
          gender: "",
          dob: "",
          document: null,
        },
      ],
    }));
  };

  useEffect(() => {
    if (userProfileDoc) {
      console.log("children data:", userProfileDoc?.dependentsData?.children);

      setDependentsData({
        spouse: {
          name: userProfileDoc.dependentsData?.spouse?.name || "",
          gender: userProfileDoc.dependentsData?.spouse?.gender || "",
          dob: userProfileDoc.dependentsData?.spouse?.dob || "",
        },
        children:
          userProfileDoc?.dependentsData?.children?.map((child) => ({
            name: child.name || "",
            gender: child.gender || "",
            dob: child.dob || "",
            document: child.document || null,
          })) || [],
      });
    }
  }, [userProfileDoc]);

  const validateForm = () => {
    if (
      !dependentsData.spouse.name ||
      !dependentsData.spouse.dob ||
      !dependentsData.spouse.gender
    ) {
      setSaveDependentMessage("Please fill all the details.");
      setMessageDependentType("error_msg");
      setTimeout(() => {
        setSaveDependentMessage("");
        setMessageDependentType("");
      }, 4000);
      return false;
    }
    for (let i = 0; i < dependentsData.children.length; i++) {
      const child = dependentsData.children[i];
      if (!child.name || !child.dob || !child.gender) {
        setSaveDependentMessage("Please fill all the details.");
        setMessageDependentType("error_msg");
        setTimeout(() => {
          setSaveDependentMessage("");
          setMessageDependentType("");
        }, 4000);
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setIsDependentSaving(true);
    setSaveDependentMessage("");
    try {
      console.log(dependentsData);
      const updatedData = {
        ...userProfileDoc,
        dependentsData,
      };
      await updateDocument(userProfileId, updatedData);
      setMessageDependentType("success_msg");
      setSaveDependentMessage("Updated successfully!");
      setTimeout(() => {
        setIsEditing(false);
      }, 4000);
    } catch (error) {
      console.error("Error updating dependents details:", error);
      setMessageDependentType("error_msg");
      setSaveDependentMessage(
        "Failed to update dependents details. Please try again."
      );
    } finally {
      setIsDependentSaving(false);
      setTimeout(() => {
        setSaveDependentMessage("");
        setMessageDependentType("");
      }, 4000);
    }
  };
  return (
    <div className="pd-cards animate-slide-in">
      <div className="pd-heading">
        <h2>Dependents Information</h2>
        {!userProfileDoc?.profileLock && !isEditing && (
          <div
            className="pd-edit-state"
            onClick={() => {
              setIsEditing((prev) => !prev);
            }}
          >
            <span className="material-symbols-outlined">edit</span>
            Edit
          </div>
        )}
      </div>
      <div className="parent-information-card">
        {/* Spouse Info Section */}
        <div className="info-section">
          <div className="info-header">
            <h5>Spouse Info</h5>
          </div>
          {!isEditing ? (
            <div className="">
              <div className="parent-data mb-4">
                <div className="parent-data-element">
                  <div className="parent-icon">
                    <img src="/assets/img/user.png" alt="icon" />
                  </div>
                  <div className="parent-data-value">
                    <h6>Spouse's Name</h6>
                    <p>
                      {userProfileDoc?.dependentsData?.spouse?.name ||
                        "Not provided yet"}
                    </p>
                  </div>
                </div>
                <div className="parent-data-element">
                  <div className="parent-icon">
                    <img src="/assets/img/gender.png" alt="icon" />
                  </div>
                  <div className="parent-data-value">
                    <h6>Spouse's Gender</h6>
                    <p>
                      {userProfileDoc?.dependentsData?.spouse?.gender ||
                        "Not provided yet"}
                    </p>
                  </div>
                </div>
                <div className="parent-data-element">
                  <div className="parent-icon">
                    <img src="/assets/img/VisitingDays.png" alt="icon" />
                  </div>
                  <div className="parent-data-value">
                    <h6>Spouse's DOB</h6>
                    <p>
                      {userProfileDoc?.dependentsData?.spouse?.dob ||
                        "Not provided yet"}
                    </p>
                  </div>
                </div>
              </div>

              <UserDetailsUploadDoc
                selectedDocType="Spouse Aadhaar Card"
                userProfileDoc={userProfileDoc}
                userProfileId={userProfileId}
                updateDocument={updateDocument}
                folderName="spouse-doc"
                title={"Aadhaar Card"}
                className="dependents-doc"
              />
            </div>
          ) : (
            <>
              <div className="row row_gap mt-4 p-0">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-field">
                    <label className="input-label required-field">
                      Spouse Name
                    </label>
                    <input
                      type="text"
                      className="form-input"
                      value={dependentsData.spouse.name}
                      onChange={(e) => {
                        setDependentsData((prev) => ({
                          ...prev,
                          spouse: {
                            ...prev.spouse,
                            name: e.target.value,
                          },
                        }));
                      }}
                      placeholder="Enter the name"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-field">
                    <label className="input-label required-field">
                      Spouse Gender
                    </label>
                    <Select
                      value={genderOptions.find(
                        (option) =>
                          option.value === dependentsData.spouse.gender
                      )}
                      onChange={(e) => {
                        setDependentsData((prev) => ({
                          ...prev,
                          spouse: {
                            ...prev.spouse,
                            gender: e.value,
                          },
                        }));
                      }}
                      options={genderOptions}
                      placeholder="Select Gender"
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-field">
                    <label className="input-label required-field">
                      Spouse DOB
                    </label>
                    <input
                      type="date"
                      className="form-input"
                      value={dependentsData.spouse.dob}
                      onChange={(e) => {
                        setDependentsData((prev) => ({
                          ...prev,
                          spouse: {
                            ...prev.spouse,
                            dob: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Children Info Section */}
        {/* {dependentsData.children.length === 0 ? (
          <p className="info-section">
            Please click on edit to add children details
          </p>
        ) : ( */}
        <div className="info-section">
          {dependentsData?.children.map((child, index) => (
            <div className="mb-4" key={index}>
              <div className="info-header">
                <h5>Children Info</h5>
                {isEditing && (
                  <button
                    style={{
                      fontSize: "14px",
                      fontWeight: "800",
                      border: "none",
                      backgroundColor: "transparent",
                      color: "red",
                    }}
                    onClick={() =>
                      setDependentsData((prev) => ({
                        ...prev,
                        children: prev.children.filter((_, i) => i !== index),
                      }))
                    }
                    className="d-flex justify-content-center align-items-center"
                  >
                    <span
                      class="material-symbols-outlined"
                      style={{
                        marginRight: "6px",
                      }}
                    >
                      delete
                    </span>
                    Remove
                  </button>
                )}
              </div>
              {!isEditing ? (
                <>
                  <div className="parent-data mb-4">
                    <div className="parent-data-element">
                      <div className="parent-icon">
                        <img src="/assets/img/user.png" alt="icon" />
                      </div>
                      <div className="parent-data-value">
                        <h6>Children's Name</h6>
                        <p>{child.name ?? "Not provided yet"}</p>
                      </div>
                    </div>
                    <div className="parent-data-element">
                      <div className="parent-icon">
                        <img src="/assets/img/gender.png" alt="icon" />
                      </div>
                      <div className="parent-data-value">
                        <h6>Children's Gender</h6>
                        <p>{child.gender ?? "Not provided yet"}</p>
                      </div>
                    </div>
                    <div className="parent-data-element">
                      <div className="parent-icon">
                        <img src="/assets/img/VisitingDays.png" alt="icon" />
                      </div>
                      <div className="parent-data-value">
                        <h6>Children's DOB</h6>
                        <p>{child.dob ?? "Not provided yet"}</p>
                      </div>
                    </div>
                  </div>
                  <UserDetailsUploadDoc
                    selectedDocType="Children Aadhaar Card"
                    userProfileDoc={userProfileDoc}
                    userProfileId={userProfileId}
                    updateDocument={updateDocument}
                    folderName="children-doc"
                    title={"Aadhaar Card"}
                    childIndex={index + 1}
                  />
                </>
              ) : (
                <>
                  <div className="row row_gap mt-4 p-0">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="form-field">
                        <label className="input-label required-field">
                          Children Name
                        </label>
                        <input
                          type="text"
                          className="form-input"
                          value={child.name}
                          onChange={(e) => {
                            setDependentsData((prev) => ({
                              ...prev,
                              children: prev.children.map((c, i) =>
                                i === index ? { ...c, name: e.target.value } : c
                              ),
                            }));
                          }}
                          placeholder="Enter the name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="form-field">
                        <label className="input-label required-field">
                          Children Gender
                        </label>
                        <Select
                          value={
                            genderOptions.find(
                              (option) => option.value === child.gender
                            ) || null
                          }
                          onChange={(selectedOption) => {
                            setDependentsData((prev) => ({
                              ...prev,
                              children: prev.children.map((c, i) =>
                                i === index
                                  ? {
                                      ...c,
                                      gender: selectedOption.value || "",
                                    }
                                  : c
                              ),
                            }));
                          }}
                          options={genderOptions}
                          placeholder="Select Gender"
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="form-field">
                        <label className="input-label required-field">
                          Children DOB
                        </label>
                        <input
                          type="date"
                          className="form-input"
                          value={child.dob}
                          onChange={(e) => {
                            setDependentsData((prev) => ({
                              ...prev,
                              children: prev.children.map((c, i) =>
                                i === index ? { ...c, dob: e.target.value } : c
                              ),
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
          {isEditing && (
            <button
              onClick={addChildren}
              style={{
                width: "100%",
                padding: "6px",
                borderRadius: "5px",
                border: "1px solid",
                backgroundColor: "rgb(238 238 238)",
              }}
            >
              Add children
            </button>
          )}
        </div>
        {/* )} */}

        {isEditing && (
          <div className="pd-edit-option mt-4">
            {saveDependentMessage && (
              <p className={`edit-msg_area ${messageDependentType}`}>
                {saveDependentMessage}
              </p>
            )}

            <button
              onClick={() => {
                if (userProfileDoc) {
                  setDependentsData({
                    spouse: {
                      name: userProfileDoc.dependentsData?.spouse?.name || "",
                      gender:
                        userProfileDoc.dependentsData?.spouse?.gender || "",
                      dob: userProfileDoc.dependentsData?.spouse?.dob || "",
                    },
                    children:
                      userProfileDoc.dependentsData?.children?.map((child) => ({
                        name: child.name || "",
                        gender: child.gender || "",
                        dob: child.dob || "",
                        document: child.document || "",
                      })) || [],
                  });
                }
                setIsEditing(false); // Exit edit mode
              }}
              disabled={isDependentSaving}
              className={` pd-option-btn option-btn-cancel ${
                isDependentSaving ? "disabled" : ""
              }`}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              disabled={isDependentSaving}
              className={` pd-option-btn option-btn-save ${
                isDependentSaving ? "disabled" : ""
              }`}
            >
              {isDependentSaving ? "Saving..." : "Save"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailsDependents;
