import React from 'react'

// import css 
import './HappyerpPartners.scss'

const HappyerpPartners = () => {
  return (
    <div className='happyerp-partners'>
      <div className="happyerp-partner_single">
        <img src="/assets/img/happyerp/home/partner1.png" alt="" />
      </div>
      <div className="happyerp-partner_single">
        <img src="/assets/img/happyerp/home/partner2.png" alt="" />
      </div>
      <div className="happyerp-partner_single">
        <img src="/assets/img/happyerp/home/partner3.png" alt="" />
      </div>
      <div className="happyerp-partner_single">
        <img src="/assets/img/happyerp/home/partner4.png" alt="" />
      </div>
      <div className="happyerp-partner_single">
        <img src="/assets/img/happyerp/home/partner5.png" alt="" />
      </div>
      <div className="happyerp-partner_single">
        <img src="/assets/img/happyerp/home/partner6.png" alt="" />
      </div>
    </div>
  )
}

export default HappyerpPartners
