import { useState, useEffect } from "react";
import Adcarousel from "../../default components/Ads";
import { useCollection } from "../hooks/useCollection";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { projectFirestore, timestamp } from "../../firebase config/config";
import { useFirestore } from "../hooks/useFirestore";
import { useSignupPhone } from "../hooks/useSignupPhone";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useAuthContext } from "../hooks/useAuthContext";
import { projectAuthObj, projectAuth } from "../../firebase config/config";
import { useDocument } from "../hooks/useDocument";

function camelCase(str) {
  return (
    str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      // .replace(/\s/g, '')
      .replace(/^(.)/, function (b) {
        return b.toUpperCase();
      })
  );
}

const GoogleLoginSignup = ({ handleNewUserGoogleSliderState }) => {
  const navigate = useNavigate();

  // FIREBASE
  const { updateDocument, response: responseUpdateDocument } =
    useFirestore("users");

  const { document: dbPageNavigationDocuments, error: dbPageNavigationError } =
    useDocument("settings", "PageNavigations");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [googleNewUserSliderState, setGoogleNewUserSliderState] =
    useState(false);
  const [genderSelectionSliderState, setGenderSelectionSliderState] =
    useState(true);
  const [newUserDetailsSliderState, setNewUserDetailsSliderState] =
    useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [googleSelectSliderState, setGoogleSelectSliderState] = useState(true);
  const [phoneNumber, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [newUserDocId, setNewUserDocId] = useState(null);

  // Effect to detect changes in emailSliderState and pass the updated value to parent
  useEffect(() => {
    handleNewUserGoogleSliderState(googleNewUserSliderState);
  }, [googleNewUserSliderState, handleNewUserGoogleSliderState]);

  // Google authentication
  const signInWithGoogle = async () => {
    const provider = new projectAuthObj.GoogleAuthProvider();

    // Force account selection prompt
    provider.setCustomParameters({
      prompt: "select_account",
    });

    try {
      const result = await projectAuth.signInWithPopup(provider);
      const user = result.user;

      if (!user) {
        console.error("No user returned from Google sign-in");
        return;
      }

      // Get user document reference
      const userRef = projectFirestore.collection("users").doc(user.uid);
      const userSnap = await userRef.get();

      if (userSnap.exists) {
        console.log("Existing user signed in with Google");

        await updateDocument(user.uid, {
          online: true,
          email: user.email,
          lastLoginTimestamp: timestamp.fromDate(new Date()),
        });

        navigate("/profile");
      } else {
        console.log(
          "New user detected, storing document ID and showing gender selection"
        );

        let firstName = user.displayName?.split(" ")[0] || "";
        let imgUrl = user.photoURL || "/assets/img/dummy_user.png";

        // Store the new document ID in state
        setNewUserDocId(user.uid);

        // Create new user document in Firestore
        await userRef.set({
          online: true,
          displayName: firstName,
          fullName: user.displayName,
          gender: "",
          phoneNumber: user.phoneNumber || "",
          email: user.email,
          city: "",
          address: "",
          country: "",
          isStaff: true,
          countryCode: "",
          role: "customer",
          roles: ["customer"],
          photoURL: imgUrl,
          status: "active",
          createdAt: timestamp.fromDate(new Date()),
          lastLoginTimestamp: timestamp.fromDate(new Date()),
        });

        setGoogleSelectSliderState(false);
        setGoogleNewUserSliderState(true);
        setGenderSelectionSliderState(true);
      }
    } catch (error) {
      console.error("Google Sign-in Error:", error);
    }
  };

  // IF USER IS NEW
  // GENDER SELECTION START
  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setGenderSelectionSliderState(false);
    setNewUserDetailsSliderState(true);
  };
  // GENDER SELECTION END

  const newUserForm = async () => {
    if (!newUserDocId) {
      console.error("No document ID found. Cannot update user.");
      return;
    }

    // Basic validations
    if (!phoneNumber.trim()) {
      setError("Phone number is required.");
      return;
    }

    // Phone validation (Example: Must start with + and have 10-15 digits)
    const phoneRegex = /^(?:\+91|0|91)?[6-9]\d{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setError("Invalid phone number format.");
      return;
    }

    if (!city.trim()) {
      setError("City is required.");
      return;
    }

    setGoogleSelectSliderState(false);
    setGenderSelectionSliderState(false);
    setIsLoading(true);

    await updateDocument(newUserDocId, {
      phoneNumber,
      gender: selectedGender,
      city: camelCase(city.toLowerCase()),
    });

    if (dbPageNavigationDocuments) {
      if (dbPageNavigationDocuments.afterLogin === "") {
        navigate("/profile");
      } else {
        navigate(dbPageNavigationDocuments.afterLogin);
      }
    } else {
      navigate("/profile");
    }

    setGoogleNewUserSliderState(false);
    setIsLoading(false);
  };

  return (
    <div>
      {googleSelectSliderState && (
        <>
          <div className="d-flex justify-content-center align-items-center">
            <h5 className="mt-4 mb-2">Google</h5>
          </div>

          <div className="new_form_field with_icon phoneinput">
            <div>
              <button
                className="signup-login-google-inpt-btn"
                onClick={signInWithGoogle}
              >
                <img src="/assets/img/google-icon.png" />
                <div className="d-flex justify-content-center w-100">
                  Continue with Google
                </div>
              </button>
            </div>
          </div>
        </>
      )}

      {/* IF USER IS NEW  */}
      <div>
        {googleNewUserSliderState && (
          <div>
            <div className="">
              {/* START GENDER SELECTION SLIDER */}
              <div>
                {genderSelectionSliderState && (
                  <div className="login-gender-selection-slider">
                    <h5 className="mb-4">Select Your Gender</h5>
                    <div className="login-gender-options">
                      <div
                        className={`login-gender-option ${
                          selectedGender === "male"
                            ? "login-gender-selected"
                            : ""
                        }`}
                        onClick={() => handleGenderSelect("male")}
                      >
                        <img src="assets/img/men-icon-login.png" alt="Male" />
                        {selectedGender === "male" && (
                          <div className="login-gender-tick">&#10003;</div>
                        )}
                        <p>Male</p>
                      </div>
                      <div
                        className={`login-gender-option ${
                          selectedGender === "female"
                            ? "login-gender-selected"
                            : ""
                        }`}
                        onClick={() => handleGenderSelect("female")}
                      >
                        <img
                          src="assets/img/women-icon-login.png"
                          alt="Female"
                        />
                        {selectedGender === "female" && (
                          <div className="login-gender-tick">&#10003;</div>
                        )}
                        <p>Female</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* END GENDER SELECTION SLIDER */}

              {newUserDetailsSliderState && (
                <>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": {
                        m: 1,
                        width: "95%",
                        textAlign: "center",
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "20px",
                      borderRadius: "8px",
                    }}
                    noValidate
                    autoComplete="on"
                  >
                    <TextField
                      label={"Phone"}
                      id="margin-none"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phoneNumber}
                      required
                    />
                    <TextField
                      label={"Current City"}
                      id="margin-none"
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                      required
                    />
                  </Box>

                  {error && (
                    <Alert variant="danger" className="mt-2">
                      {error}
                    </Alert>
                  )}
                  {isLoading ? (
                    <div className="text-center">
                      <BeatLoader color={"var(--click-color)"} loading={true} />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center mt-2">
                      <button className="btn_fill" onClick={newUserForm}>
                        Done
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GoogleLoginSignup;
