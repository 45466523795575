import React, { useState, useEffect } from "react";
import "./WomenDayTheme.scss";

import { useDocument } from "../firebase files/hooks/useDocument";

const WomenDayTheme = () => {
  const [isDeepakClicked, setIsDeepakClicked] = useState(false);
  const { document: dbSettingsDocuments, error: dbSettingsError } = useDocument(
    "settings",
    "organisation"
  );

  // Effect to reset the state after 8 seconds when the component mounts
  useEffect(() => {
    setIsDeepakClicked(true);

    const timer = setTimeout(() => {
      setIsDeepakClicked(false);
    }, 8000);

    return () => clearTimeout(timer);
  }, []);

  const handleDeepakClick = () => {
    setIsDeepakClicked((prevState) => !prevState);
  };

  return (
    <div
      className={`women-day-theme ${isDeepakClicked ? "deepak-active" : ""}`}
    >
      <img
        src="/assets/img/themes/tree-drop.gif"
        className="diwali-theme-lantern-gif-left"
      />
      <img
        src="/assets/img/themes/tree-drop.gif"
        className="diwali-theme-lantern-gif-right"
      />

      {isDeepakClicked && (
        <>
          <div className="d-flex diwali-theme-fire-crackers-gif">
            <img
              src="/assets/img/themes/women-day-pc.gif"
              alt="Diwali Cracker"
              className="women-day-first"
            />
            <img
              src="/assets/img/themes/women-day-phone.png"
              alt="Diwali Cracker"
              className="women-day-second"
            />
          </div>
        </>
      )}

      <div>
        <img
          src="/assets/img/themes/heart-icon.png"
          className="diwali-theme-deepak-gif"
          onClick={handleDeepakClick}
          alt="Deepak"
        />
      </div>

      {isDeepakClicked && <div className="diwali-theme-dark-overlay"></div>}
    </div>
  );
};

export default WomenDayTheme;
