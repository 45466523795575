import React from "react";

// import css
import "./HappyerpCurveDivider.css";

const HappyerpBottomCurve = () => {
  return (
    <div className="happyerp-curve_divider happyerp-curve_bottom">
      <img src="/assets/img/happyerp/curve_bottom.png" alt="" />
    </div>
  );
};

export default HappyerpBottomCurve;
