import React, { useState, useEffect } from "react";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";

const UserDetailsReferences = ({ title, type, userProfileId }) => {
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );
  const { updateDocument } = useFirestore("users");

  const relationOptions = [
    { value: "friend", label: "Friend" },
    { value: "colleague", label: "Colleague" },
    { value: "manager", label: "Manager" },
    { value: "mentor", label: "Mentor" },
    { value: "teacher", label: "Teacher" },
    { value: "neighbor", label: "Neighbor" },
    { value: "guardian", label: "Guardian" },
    { value: "other", label: "Other" },
  ];
  //code for RelationOptions for Ref1 and Ref2 ends------------------

  // full code for ref start
  const [isEditing, setIsEditing] = useState(false);
  const [refFormData, setRefFormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    relation: "",
  });
  // Update refFormData when userProfileDoc changes
  useEffect(() => {
    if (userProfileDoc && userProfileDoc[type]) {
      setRefFormData(userProfileDoc[type]);
    }
  }, [userProfileDoc, type]);

  const [isRefSaving, setIsRefSaving] = useState(false);
  const [saveRefMessage, setSaveRefMessage] = useState("");
  const [refMessageType, setRefMessageType] = useState("");

  const handleCancelClick = () => {
    if (userProfileDoc && userProfileDoc[type]) {
      setRefFormData(userProfileDoc[type]);
    }
    setIsEditing(!isEditing);
  };

  // Handle Input Changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRefFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangePhone = (value) => {
    setRefFormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
  };

  // Validate Fields
  const validateForm = () => {
    if (!refFormData.name || !refFormData.phone || !refFormData.address) {
      setSaveRefMessage("Please fill all required fields.");
      setRefMessageType("error_msg");
      setTimeout(() => {
        setSaveRefMessage("");
        setRefMessageType("");
      }, 4000);
      return false;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (refFormData.email && !emailRegex.test(refFormData.email)) {
      setSaveRefMessage("Please enter a valid email address.");
      setRefMessageType("error_msg");
      setTimeout(() => {
        setSaveRefMessage("");
        setRefMessageType("");
      }, 4000);
      return false;
    }

    return true;
  };

  // Submit Form Data
  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsRefSaving(true);
    setSaveRefMessage("");

    try {
      const updatedData = {
        ...userProfileDoc,
        [type]: refFormData,
      };

      await updateDocument(userProfileId, updatedData);
      setRefMessageType("success_msg");
      setSaveRefMessage("Updated successfully!");

      setTimeout(() => {
        setIsEditing(false);
      }, 4000);
    } catch (error) {
      console.error("Error updating reference 1 details:", error);
      setRefMessageType("error_msg");
      setSaveRefMessage(
        "Failed to update Reference 1 details. Please try again."
      );
    } finally {
      setIsRefSaving(false);
      setTimeout(() => {
        setSaveRefMessage("");
        setRefMessageType("");
      }, 4000);
    }
  };

  return (
    <div className="pd-cards animate-slide-in">
      <div className="pd-heading">
        <h2>{title}</h2>
        {!userProfileDoc?.profileLock && !isEditing && (
          <div
            className="pd-edit-state"
            onClick={() => {
              setIsEditing((prev) => !prev);
            }}
          >
            <span className="material-symbols-outlined">edit</span>
            Edit
          </div>
        )}
      </div>
      <div className="pd-data">
        {!isEditing && (
          <>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/user.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Name</h6>
                <p>{userProfileDoc?.[type]?.name ?? "Not provided yet"}</p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/mobile-phone.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Phone</h6>
                <p>{userProfileDoc?.[type]?.Phone ?? "Not provided yet"}</p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/mail.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Email</h6>
                <p>{userProfileDoc?.[type]?.email ?? "Not provided yet"}</p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/icons/public-relation.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Relation</h6>
                <p>{userProfileDoc?.[type]?.relation ?? "Not provided yet"}</p>
              </div>
            </div>
            <div className="pd-data-element">
              <div className="pd-icon">
                <img src="/assets/img/pin.png" alt="" />
              </div>
              <div className="pd-data-value">
                <h6>Address</h6>
                <p>{userProfileDoc?.[type]?.address ?? "Not provided yet"}</p>
              </div>
            </div>
          </>
        )}
        {isEditing && (
          <div className="col mb-4">
            <div className="row row_gap">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={refFormData.name}
                    onChange={handleChange}
                    required
                    placeholder="Type name here"
                    onKeyPress={(e) => {
                      const regex = /^[a-zA-Z\s]*$/; // Only letters and spaces allowed
                      if (!regex.test(e.key)) {
                        e.preventDefault(); // Prevent invalid input
                      }
                    }}
                    className="form-input"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={refFormData.email}
                    onChange={handleChange}
                    required
                    placeholder="Type email here"
                    className="form-input"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">Relation</label>
                  <Select
                    name="relation"
                    value={relationOptions.find(
                      (option) => option.value === refFormData.relation
                    )}
                    options={relationOptions}
                    onChange={(e) =>
                      setRefFormData((prev) => ({
                        ...prev,
                        relation: e.value,
                      }))
                    }
                    placeholder="Select"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    required
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">
                    Phone Number
                  </label>
                  <div className="phone-input-container">
                    <PhoneInput
                      country={"in"}
                      id="contact"
                      onlyCountries={["in", "us", "ae"]}
                      value={refFormData.phone}
                      onChange={handleChangePhone}
                      international
                      countryCodeEditable={false}
                      placeholder="Country code + mobile number"
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: false,
                      }}
                      className="form-input"
                      containerClass="react-tel-input"
                      inputClass="phone-input"
                      buttonClass="flag-dropdown"
                      inputStyle={{ border: "none", background: "transparent" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-field">
                  <label className="input-label required-field">Address</label>
                  <textarea
                    name="address"
                    value={refFormData.address}
                    onChange={handleChange}
                    required
                    placeholder="Type full address here"
                    className="form-input"
                  />
                </div>
              </div>
            </div>
            <div className="pd-edit-option">
              {saveRefMessage && (
                <p className={`edit-msg_area ${refMessageType}`}>
                  {saveRefMessage}
                </p>
              )}

              <button
                onClick={handleCancelClick}
                disabled={isRefSaving}
                className={` pd-option-btn option-btn-cancel ${
                  isRefSaving ? "disabled" : ""
                }`}
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={isRefSaving}
                className={` pd-option-btn option-btn-save ${
                  isRefSaving ? "disabled" : ""
                }`}
              >
                {isRefSaving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailsReferences;
