import React from 'react'

// import css 
import './BusinessOneOffer.scss'

const BusinessOneOffer = () => {
    return (
      <section className="business_one_offer_banner">
        <img src="/assets/img/business/businessone/home/offer1.jpg" alt="" />
        <img src="/assets/img/business/businessone/home/offer2.jpg" alt="" />
        <img src="/assets/img/business/businessone/home/offer3.jpg" alt="" />
      </section>
    );
}

export default BusinessOneOffer
