import React from "react";
import { useState } from "react";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { Modal } from "react-bootstrap";
import { useDocument } from "../firebase files/hooks/useDocument";
import { BeatLoader } from "react-spinners";

const AddHoliday = () => {
  const [showHolidayPopup, setShowHolidayPopup] = useState(false);
  const [holidayType, setHolidayType] = useState("date"); // "date" or "weekday"
  const [selectedDate, setSelectedDate] = useState(""); // State for date
  const [remark, setRemark] = useState(""); // State for remark
  const [selectedWeekday, setSelectedWeekday] = useState(""); // State for weekday holiday
  const [isAddingHoliday, setIsAddingHoliday] = useState(false);
  // Separate error states for each field
  const [dateError, setDateError] = useState(false);
  const [weekdayError, setWeekdayError] = useState(false);
  const [remarkError, setRemarkError] = useState(false);

  const { addDocument } = useFirestore("date_holidays");

  const { updateDocument } = useFirestore("settings");

  const { document: weekdayHolidayDocuments, error: weekdayHolidayError } =
    useDocument("settings", "weekday_holiday");

  const handleHoliday = async () => {
    setIsAddingHoliday(true);
    // Reset all errors before validation
    setDateError(false);
    setWeekdayError(false);
    setRemarkError(false);

    let hasError = false;

    if (holidayType === "date") {
      if (!selectedDate) {
        setDateError(true);
        hasError = true;
      }
      if (!remark) {
        setRemarkError(true);
        hasError = true;
      }

      if (hasError) return; // Stop execution if there are errors

      // Add the holiday document to Firestore
      await addDocument({
        date: new Date(selectedDate), // Convert to Firestore Timestamp
        remark,
      });
    } else {
      if (!selectedWeekday) {
        setWeekdayError(true);
        hasError = true;
      }
      if (!remark) {
        setRemarkError(true);
        hasError = true;
      }

      if (hasError) return; // Stop execution if there are errors

      try {
        // Use the document state instead of calling `useDocument` again
        if (!weekdayHolidayDocuments) {
          console.error("Weekday holiday document not found.");
          return;
        }

        // Ensure dayName is an array
        let updatedDayName = weekdayHolidayDocuments.dayName || [];

        // Add new holiday entry
        updatedDayName.push({
          weekday: selectedWeekday,
          remark: remark,
        });

        // Update Firestore document
        await updateDocument("weekday_holiday", {
          dayName: updatedDayName,
        });

        console.log("Weekday holiday added successfully");
      } catch (error) {
        console.error("Error updating weekday holiday:", error);
      }
    }

    // Reset the form and close popup
    setShowHolidayPopup(false);
    setSelectedDate("");
    setSelectedWeekday("");
    setRemark("");
    setIsAddingHoliday(false);
  };

  return (
    <div>
      <button className="btn_fill" onClick={() => setShowHolidayPopup(true)}>
        Add Holiday
      </button>
      {/* START POPUP FOR ADD HOLIDAY */}
      <Modal
        show={showHolidayPopup}
        onHide={() => setShowHolidayPopup(false)}
        centered
        className="pl-0"
      >
        <Modal.Header
          style={{
            border: "1px solid grey",
            display: "block",
            padding: "7px 20px",
            margin: "10px",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div
              className={`btn ${
                holidayType === "date" ? "btn-primary" : "btn-light"
              }`}
              onClick={() => setHolidayType("date")}
            >
              Holiday Setup
            </div>
            <div
              className={`btn ${
                holidayType === "weekday" ? "btn-primary" : "btn-light"
              }`}
              onClick={() => setHolidayType("weekday")}
            >
              Weekly Off
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          {holidayType == "date" ? (
            <div>
              <br />
              <div className="form_field pi_input">
                <label htmlFor="id_tripstart">Select Date For Holiday*</label>
                <input
                  className="custom-input"
                  style={{ paddingRight: "10px" }}
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
                {dateError && (
                  <div className="field_error">Please select a date.</div>
                )}
              </div>
              <br />
              <div className="form_field pi_input">
                <label htmlFor="id_tripstart">Remark For Date Holiday*</label>
                <input
                  className="custom-input"
                  style={{ paddingRight: "10px" }}
                  type="text"
                  placeholder="Ex : Today's Diwali, Enjoy Your Day!"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
                {remarkError && (
                  <div className="field_error">Please enter a remark.</div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <br />
              <div className="form_field pi_input">
                <label htmlFor="id_tripstart">
                  Select Weekday For Holiday*
                </label>
                <select
                  id="id_tripstart"
                  className="custom-input"
                  style={{ paddingRight: "10px" }}
                  value={selectedWeekday}
                  onChange={(e) => setSelectedWeekday(e.target.value)}
                >
                  <option value="">Select Weekday</option>
                  <option value="Sunday">Sunday</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                </select>
                {weekdayError && (
                  <div className="field_error">Please select a weekday.</div>
                )}
              </div>
              <br />
              <div className="form_field pi_input">
                <label htmlFor="id_tripstart">
                  Remark For Weekday Holiday*
                </label>
                <input
                  id="id_tripstart"
                  className="custom-input"
                  style={{ paddingRight: "10px" }}
                  type="text"
                  placeholder="Ex : Today's Sunday, Enjoy Your Day!"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
                {remarkError && (
                  <div className="field_error">Please enter a remark.</div>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-between"
          style={{
            border: "none",
            gap: "15px",
          }}
        >
          {!isAddingHoliday ? (
            <>
              <button
                className="btn_border"
                onClick={() => setShowHolidayPopup(false)}
              >
                Cancel
              </button>
              <button
                className="btn_fill"
                onClick={handleHoliday}
                style={{ color: "white" }}
              >
                Confirm
              </button>
            </>
          ) : (
            <BeatLoader color="grey" />
          )}
        </Modal.Footer>
      </Modal>
      {/* END POPUP FOR ADD HOLIDAY */}
    </div>
  );
};

export default AddHoliday;
