import React from "react";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import UserDetailsUploadDoc from "./UserDetailsUploadDoc";

// importing scss
import "./UserDetailsEmployeeDoc.scss";

const documentTypes = [
  "Aadhaar Card",
  "PAN Card",
  "Driving Licence",
  "Voter ID",
];

const UserDetailsEmployeeDoc = ({ userProfileId }) => {
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );
  const { updateDocument } = useFirestore("users");
  // New code for upload document start

  return (
    <div className="pd-cards animate-slide-in">
      <div className="pd-heading">
        <h2>Employee Document</h2>
      </div>
      <div className="pd-emp_doc">
        <div className="pd-document">
          {documentTypes.map((docType) => {
            return (
              <UserDetailsUploadDoc
                selectedDocType={docType}
                userProfileDoc={userProfileDoc}
                userProfileId={userProfileId}
                updateDocument={updateDocument}
                key={docType}
                folderName="user-docs"
                title={docType}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UserDetailsEmployeeDoc;
