import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./HappyerpBack.scss"; // Import css
const HappyerpBack = () => {
  // goback code
  const navigate = useNavigate(); // Use useNavigate hoo
  const handleGoBack = () => {
    navigate(-1); // Navigate back one step
  };
  // goback code
  return (
    <button onClick={handleGoBack} className="back_button">
      <span class="material-symbols-outlined pointer">arrow_back</span>
    </button>
  );
};

export default HappyerpBack;
