import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";

import DataEntryCard from "../dataEntryCard/HappyerpDataEntryCard";
import CardDetailWrapper from "./HappyerpCardDetailWrapper"; //
import { useDocument } from "../../../../firebase files/hooks/useDocument";

// import css
import "./HappyerpOrderDetail.scss";
import { useCollection } from "../../../../firebase files/hooks/useCollection";
import { format } from "date-fns";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import { color } from "@mui/system";
import { Modal } from "react-bootstrap";
import HappyerpBack from "../ui/back/HappyerpBack";

const HappyerpOrderDetail = () => {
  const { orderId } = useParams();
  const { user } = useAuthContext();

  const { document: saleOrder } = useDocument("sales_order", orderId);
  const { updateDocument } = useFirestore("sales_order");

  const { documents: partiesDoc } = useCollection("m_parties", null, [
    "createdAt",
    "asc",
  ]);
  const { documents: agentsDoc } = useCollection("m_agents", null, [
    "createdAt",
    "asc",
  ]);

  const allParties = useMemo(
    () =>
      partiesDoc
        ?.filter((party) => party.isActive)
        .map((party) => ({
          label: party.partyName,
          value: party.partyName.toLowerCase().split(" ").join("-"),
        })),
    [partiesDoc]
  );
  const allAgents = useMemo(
    () =>
      agentsDoc
        ?.filter((agent) => agent.isActive)
        .map((agent) => ({
          label: agent.agentName,
          value: agent.agentName.toLowerCase().split(" ").join("-"),
        })),
    [agentsDoc]
  );

  //pre mentioned options for item drop down in order details
  const optionsItem = [
    { value: "blazer", label: "Blazer" },
    { value: "jeans", label: "Jeans" },
    { value: "shirt", label: "Shirt" },
    { value: "t-shirt", label: "T-shirt" },
  ];

  //pre mentioned options for Design dropdown in order details
  const optionsDesign = [
    { value: "check", label: "Check" },
    { value: "circle", label: "Circle" },
    { value: "plain", label: "Plain" },
    { value: "lining", label: "Lining" },
  ];

  //pre mentioned options for subdesign dropdown in order details.
  const optionsSubDesign = [
    { value: "sub-design-1", label: "Sub-Design 1" },
    { value: "sub-design-2", label: "Sub-Design 2" },
    { value: "sub-design-3", label: "Sub-Design 3" },
    { value: "sub-design-4", label: "Sub-Design 4" },
  ];

  //pre mentioned options for style dropdown in order details.
  const optionsStyle = [
    { value: "style-1", label: "Style 1" },
    { value: "style-2", label: "Style 2" },
    { value: "style-3", label: "Style 3" },
    { value: "style-4", label: "Style 4" },
  ];

  //pre mentioned options for transport dropdown in order details.

  const transportOptions = useMemo(
    () => [
      { value: "train", label: "Train" },
      { value: "truck", label: "Truck" },
      { value: "ship", label: "Ship" },
      { value: "van", label: "Van" },
    ],
    []
  );

  const [activeForm, setActiveForm] = useState({
    editing: false,
    type: null,
  });

  // ---------------------------------------------------------------------------------------------------------------------
  //code for order Detail start here
  const [orderDetails, setOrderDetails] = useState({
    createdDate: null,
    agentName: null,
    transport: null,
    numberOfItems: "",
    deliveryDate: null,
    partyName: null,
    totalQuantity: "",
    placedBy: "",
  });

  useEffect(() => {
    if (!orderId || !saleOrder) return;
    setOrderDetails({
      deliveryDate: saleOrder?.deliveryDate.toDate() ?? null,
      partyName: saleOrder?.partyName,
      agentName: saleOrder?.agentName,
      transport: saleOrder?.transportName,
    });
  }, [saleOrder, orderId]);

  const handleOrderFormChange = (field, value) => {
    console.log(field, value);
    setOrderDetails((prev) => ({ ...prev, [field]: value }));
  };

  const handleOrderDetailsSubmit = async () => {
    try {
      if (!orderId) {
        throw new Error("Order ID is missing.");
      }

      if (!orderDetails || typeof orderDetails !== "object") {
        throw new Error("Order details are invalid.");
      }

      // Validate required fields
      const requiredFields = [
        "agentName",
        "partyName",
        "deliveryDate",
        "transport",
      ];
      for (const field of requiredFields) {
        if (!orderDetails[field]) {
          throw new Error(`${field.replace(/([A-Z])/g, " $1")} is required.`);
        }
      }

      const value = {
        agentName: orderDetails.agentName,
        partyName: orderDetails.partyName,
        deliveryDate: orderDetails.deliveryDate,
        transportName: orderDetails.transport,
      };

      console.log("Submitting order details:", value);

      await updateDocument(orderId, value);

      // Reset state after successful update
      setOrderDetails({
        deliveryDate: null,
        partyName: null,
        agentName: null,
        transport: null,
      });

      setActiveForm({
        editing: false,
        type: null,
      });

      alert("Order details submitted successfully!");
    } catch (error) {
      console.error("Error in handleOrderDetailsSubmit:", error);

      if (error.message) {
        alert(`Error: ${error.message}`);
      } else {
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

  //code for order Detail end here
  // ---------------------------------------------------------------------------------------------------------------------
  const [products, setProducts] = useState(null);
  useEffect(() => {
    if (!saleOrder || !saleOrder.orders) return;

    const updatedProducts = saleOrder.orders.map((product) => ({
      ...product,
      sizes: Object.fromEntries(
        Object.entries(product.sizes || {}).filter(
          ([_, quantity]) => quantity > 0
        )
      ),
    }));

    setProducts(updatedProducts);
  }, [saleOrder]);

  // ---------------------------------------------------------------------------------------------------------------------
  //code for add new product start here
  const [newProduct, setNewProduct] = useState({
    itemName: null,
    design: null,
    subDesign: null,
    style: null,
    colors: [],
    sizes: {
      "size 34": 0,
      "size 36": 0,
      "size 38": 0,
      "size 40": 0,
      "size 42": 0,
      "size 44": 0,
    },
  });
  const handleProductFormChange = (field, value) => {
    setNewProduct((prev) => ({ ...prev, [field]: value }));
  };

  const handleColorChange = (color) => {
    setNewProduct((prev) => ({
      ...prev,
      colors: prev.colors.includes(color)
        ? prev.colors.filter((c) => c !== color)
        : [...prev.colors, color],
    }));
  };

  const handleSizeChange = (sizeName, quantity) => {
    setNewProduct((prev) => ({
      ...prev,
      sizes: {
        ...prev.sizes,
        [sizeName]: Math.max(0, quantity),
      },
    }));
  };
  const handleAddProductCancelClick = () => {
    setActiveForm({ editing: false, type: null });
    setNewProduct({
      itemName: null,
      design: null,
      subDesign: null,
      style: null,
      colors: [],
      sizes: {
        "size 34": 0,
        "size 36": 0,
        "size 38": 0,
        "size 40": 0,
        "size 42": 0,
        "size 44": 0,
      },
    });
  };

  const handleProductSubmit = async () => {
    try {
      if (!newProduct || typeof newProduct !== "object") {
        throw new Error("Product details are invalid.");
      }

      const requiredFields = [
        "itemName",
        "design",
        "subDesign",
        "style",
        "colors",
        "sizes",
      ];
      for (const field of requiredFields) {
        if (
          !newProduct[field] ||
          (Array.isArray(newProduct[field]) && newProduct[field].length === 0)
        ) {
          throw new Error(`${field.replace(/([A-Z])/g, " $1")} is required.`);
        }
      }

      const value =
        saleOrder && !saleOrder?.orders
          ? [
              {
                itemName: newProduct.itemName,
                design: newProduct.design,
                subDesign: newProduct.subDesign,
                style: newProduct.style,
                colors: newProduct.colors,
                sizes: newProduct.sizes,
              },
            ]
          : [
              ...saleOrder.orders,
              {
                itemName: newProduct.itemName,
                design: newProduct.design,
                subDesign: newProduct.subDesign,
                style: newProduct.style,
                colors: newProduct.colors,
                sizes: newProduct.sizes,
              },
            ];

      console.log("Submitting product:", value);

      await updateDocument(orderId, { orders: value });
      handleAddProductCancelClick();

      alert("Product added successfully!");
    } catch (error) {
      console.error("Error in handleProductSubmit:", error);

      if (error.message) {
        alert(`Error: ${error.message}`);
      } else {
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

  //code for add new product end here
  // ---------------------------------------------------------------------------------------------------------------------
  //code for add multiple new product start here
  const [multipleProduct, setMultipleProduct] = useState([
    {
      itemName: null,
      design: null,
      subDesign: null,
      style: null,
      colors: [],
      sizes: {
        "size 34": 0,
        "size 36": 0,
        "size 38": 0,
        "size 40": 0,
        "size 42": 0,
        "size 44": 0,
      },
    },
  ]);

  const handleAddAnotherProduct = () => {
    setMultipleProduct((prev) => [
      ...prev,
      {
        itemName: null,
        design: null,
        subDesign: null,
        style: null,
        colors: [],
        sizes: {
          "size 34": 0,
          "size 36": 0,
          "size 38": 0,
          "size 40": 0,
          "size 42": 0,
          "size 44": 0,
        },
      },
    ]);
  };

  const handleMultipleProductChange = (index, field, value) => {
    setMultipleProduct((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        [field]: value,
      };
      return updated;
    });
  };

  const handleMultipleColorChange = (index, color) => {
    setMultipleProduct((prev) => {
      const updated = [...prev];
      const currentColors = updated[index].colors;

      updated[index] = {
        ...updated[index],
        colors: currentColors.includes(color)
          ? currentColors.filter((c) => c !== color)
          : [...currentColors, color],
      };
      return updated;
    });
  };
  const handleMultipleSizeChange = (index, sizeName, quantity) => {
    setMultipleProduct((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        sizes: {
          ...updated[index].sizes,
          [sizeName]: Math.max(0, quantity),
        },
      };
      return updated;
    });
  };

  const handleMultipleDelete = (index) => {
    const filteredProduct = multipleProduct.filter((_, i) => index !== i);
    setMultipleProduct(filteredProduct);
  };
  const handleMultipleAddProductCancelClick = () => {
    setActiveForm({ editing: false, type: null });
    setMultipleProduct([
      {
        itemName: null,
        design: null,
        subDesign: null,
        style: null,
        colors: [],
        sizes: {
          "size 34": 0,
          "size 36": 0,
          "size 38": 0,
          "size 40": 0,
          "size 42": 0,
          "size 44": 0,
        },
      },
    ]);
  };

  const handleMultipleSubmitChange = async () => {
    try {
      if (!orderId) {
        throw new Error("Order ID is missing.");
      }

      if (!Array.isArray(multipleProduct) || multipleProduct.length === 0) {
        throw new Error("No products selected to add.");
      }

      for (const [index, product] of multipleProduct.entries()) {
        if (!product.itemName) {
          throw new Error(`Product ${index + 1}: Item Name is required.`);
        }
        if (!product.design) {
          throw new Error(`Product ${index + 1}: Design is required.`);
        }
        if (!product.subDesign) {
          throw new Error(`Product ${index + 1}: Sub Design is required.`);
        }
        if (!product.style) {
          throw new Error(`Product ${index + 1}: Style is required.`);
        }
        if (!Array.isArray(product.colors) || product.colors.length === 0) {
          throw new Error(
            `Product ${index + 1}: At least one color must be selected.`
          );
        }
      }

      const updatedOrders =
        saleOrder && !saleOrder?.orders
          ? multipleProduct
          : [...saleOrder.orders, ...multipleProduct];

      await updateDocument(orderId, { orders: updatedOrders });
      handleMultipleAddProductCancelClick();
      alert("Products added successfully!");
    } catch (err) {
      console.error(err);
      alert("handleMultipleSubmitChange", err);
    }
  };

  //code for add multiple new product end here
  // ---------------------------------------------------------------------------------------------------------------------
  //code for edit product start here
  const [editProduct, setEditProduct] = useState(null);
  const [editingProductId, setEditingProductId] = useState(null);

  useEffect(() => {
    if (!editProduct) return;

    const hasMissingSizes = Object.keys(newProduct.sizes).some(
      (size) => !(editProduct.sizes && size in editProduct.sizes)
    );

    if (hasMissingSizes) {
      setEditProduct((prev) => ({
        ...prev,
        sizes: Object.fromEntries(
          Object.entries({
            ...newProduct.sizes, // Default sizes
            ...prev.sizes, // Existing values
          })
        ),
      }));
    }
  }, [editProduct]);

  const handleEditProductFormChange = (field, value) => {
    setEditProduct((prev) => ({ ...prev, [field]: value }));
  };
  const handleEditColorChange = (color) => {
    setEditProduct((prev) => {
      const colors = prev.colors || [];
      return {
        ...prev,
        colors: colors.includes(color)
          ? colors.filter((c) => c !== color)
          : [...colors, color],
      };
    });
  };
  const handleProductSizeChange = (sizeName, quantity) => {
    console.log(sizeName, quantity);
    console.log(editProduct);
    setEditProduct((prev) => ({
      ...prev,
      sizes: {
        ...prev.sizes,
        [sizeName]: Math.max(0, quantity),
      },
    }));
  };
  const handleEditProductCancelClick = () => {
    setActiveForm({ editing: false, type: null });
  };
  const handleUpdateOrderProduct = async () => {
    try {
      console.log(editProduct);
      const updatedOrder = saleOrder.orders.map((order, idx) =>
        idx === editingProductId
          ? {
              ...order,
              itemName: editProduct.itemName,
              design: editProduct.design,
              subDesign: editProduct.subDesign,
              colors: editProduct.colors,
              sizes: editProduct.sizes,
            }
          : order
      );
      await updateDocument(orderId, { orders: updatedOrder });
      handleEditProductCancelClick();
    } catch (error) {
      console.log(error);
      alert("handleUpdateOrderProduct", error);
    }
  };
  //code for edit product end here
  // ---------------------------------------------------------------------------------------------------------------------
  //code for delete product end here
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const deleteProduct = async (id) => {
    try {
      const updatedProducts = products.filter((_, idx) => idx !== id);
      setProducts(updatedProducts);

      await updateDocument(orderId, { orders: [...updatedProducts] });

      setOpenDeleteModal(false);
      if (editingProductId === id)
        setActiveForm({ editing: false, type: null });
    } catch (err) {
      console.log(err);
      alert("deleteProduct", err.message);
    }
  };
  //code for delete product end here

  return (
    <div className="happyerp-edit-page">
      <div
        className="happyerp-edit-page-left-content"
        style={activeForm.editing ? { maxWidth: "60%" } : { maxWidth: "100%" }}
      >
        <div className="happyerp-edit-back">
          <HappyerpBack />
          <span>Back</span>
        </div>
        <div>
          <div className="happyerp-order-detail">
            <div className="happyerp-order-heading">
              <h3>Order Details</h3>
              <button
                className="happyerp-edit-btn"
                onClick={() =>
                  setActiveForm((prev) => ({
                    editing: prev.editing ? prev.editing : !prev.editing,
                    type: "order-detail",
                  }))
                }
              >
                <span class="material-symbols-outlined">edit</span>Edit
              </button>
            </div>
            <div className="happyerp-order-info">
              <div>
                <div className="happyerp-order-content">
                  <div>
                    <p className="happyerp-text-label">Created Date</p>
                    <p className="happyerp-text-value">
                      {saleOrder?.createdAt
                        ? format(saleOrder.createdAt.toDate(), "dd/MM/yyyy")
                        : "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="happyerp-text-label">Order Number</p>
                    <p
                      className="happyerp-text-value"
                      style={{ wordBreak: "break-all" }}
                    >
                      {orderId}
                    </p>
                  </div>
                  <div>
                    <p className="happyerp-text-label">Agent</p>
                    <p className="happyerp-text-value">
                      {saleOrder?.agentName ?? "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="happyerp-text-label">Transport</p>
                    <p className="happyerp-text-value">
                      {saleOrder?.transportName ?? "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="happyerp-text-label">No.of Items</p>
                    <p className="happyerp-text-value">
                      {saleOrder?.orders?.length ?? "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="happyerp-text-label">Delivery Date</p>
                    <p className="happyerp-text-value">
                      {orderDetails.deliveryDate
                        ? format(orderDetails.deliveryDate, "dd/MM/yyyy")
                        : "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="happyerp-text-label">Party Name</p>
                    <p className="happyerp-text-value">
                      {saleOrder?.partyName ?? "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="happyerp-text-label">Total Quantity</p>
                    <p className="happyerp-text-value">{"N/A"}</p>
                  </div>
                </div>
                <div style={{ marginTop: "1.5rem" }}>
                  <div>
                    <p className="happyerp-text-label">Placed By</p>
                    <p
                      className="happyerp-text-value"
                      style={{ fontWeight: "bold" }}
                    >
                      {user?.fullName ?? "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="happyerp-product-details">
            <div className="happyerp-product-details-heading">
              <h3>Products</h3>
              <div className="happyerp-add-product">
                <button
                  className="happyerp-add-product-btn"
                  onClick={() =>
                    setActiveForm((prev) => ({
                      editing: prev.editing ? prev.editing : !prev.editing,
                      type: "multiple-product",
                    }))
                  }
                >
                  <span class="material-symbols-outlined">add</span> Add
                  Multiple
                </button>
                <button
                  className="happyerp-add-product-btn"
                  style={{
                    backgroundColor: "var(--theme-teal)",
                    color: "white",
                  }}
                  onClick={() =>
                    setActiveForm((prev) => ({
                      editing: prev.editing ? prev.editing : !prev.editing,
                      type: "new-product",
                    }))
                  }
                >
                  <span class="material-symbols-outlined">add</span>
                  Add Product
                </button>
              </div>
            </div>
            <div
              className="happyerp-product-content"
              style={
                activeForm.editing
                  ? { gridTemplateColumns: "repeat(2,1fr)" }
                  : { gridTemplateColumns: "repeat(3,1fr)" }
              }
            >
              {saleOrder?.orders && saleOrder?.orders?.length > 0 ? (
                <>
                  {products?.map((order, index) => (
                    <div className="happyerp-product-item">
                      <div className="happyerp-product-item-heading">
                        <h5>{order.itemName}</h5>
                        <div className="happyerp-product-edit">
                          <button>
                            <span
                              class="material-symbols-outlined"
                              onClick={() => {
                                setActiveForm((prev) => ({
                                  editing: prev.editing
                                    ? prev.editing
                                    : !prev.editing,
                                  type: "product",
                                }));
                                setEditProduct({ ...order });
                                setEditingProductId(index);
                              }}
                            >
                              edit
                            </span>
                          </button>
                          <button
                            onClick={() => {
                              setOpenDeleteModal(true);
                              setEditingProductId(index);
                            }}
                          >
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "#dc2626" }}
                            >
                              delete
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="happyerp-product-item-detail">
                        <div className="happyerp-product-item-description">
                          <div>
                            <p className="happyerp-text-label">Design</p>
                            <p className="happyerp-item-value">
                              {order.design}
                            </p>
                          </div>
                          <div>
                            <p className="happyerp-text-label">Sub Design</p>
                            <p className="happyerp-item-value">
                              {order.subDesign}
                            </p>
                          </div>
                          <div>
                            <p className="happyerp-text-label">Style</p>
                            <p className="happyerp-item-value">{order.style}</p>
                          </div>
                        </div>
                        <div className="happyerp-separator" />
                        <div className="happyerp-product-item-color">
                          <h5
                            className="happyerp-text-label"
                            style={{ fontSize: "1rem" }}
                          >
                            Color
                          </h5>
                          <div className="happyerp-product-item-color-content">
                            {order.colors.map((color) => (
                              <div
                                key={color}
                                className="happyerp-product-item-color-text"
                              >
                                <span style={{ backgroundColor: color }} />{" "}
                                {color}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="happyerp-separator" />
                        <div className="happyerp-product-item-quantity">
                          <h5
                            className="happyerp-text-label"
                            style={{ fontSize: "1rem" }}
                          >
                            Sizes/Quantity
                          </h5>
                          <div className="happyerp-product-item-quantity-content">
                            {Object.entries(order.sizes).map(
                              ([sizeName, quantity], i) => (
                                <div key={sizeName + i + quantity}>
                                  <p className="happyerp-text-label ">
                                    {sizeName}
                                  </p>
                                  <p
                                    className="happyerp-text-value"
                                    style={{ textAlign: "center" }}
                                  >
                                    {quantity}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <Modal
                    centered
                    show={openDeleteModal}
                    onHide={() => setOpenDeleteModal(false)}
                  >
                    <Modal.Header closeButton>Delete Product</Modal.Header>
                    <Modal.Body style={{ color: "var(--theme-red)" }}>
                      Do you want to delete this product from order?
                    </Modal.Body>
                    <Modal.Footer>
                      <button onClick={() => deleteProduct(editingProductId)}>
                        Yes
                      </button>
                      <button onClick={() => setOpenDeleteModal(false)}>
                        No
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>
              ) : (
                <p>There is no order yet</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {activeForm.editing && (
        <div className="happyerp-edit-page-right-content">
          {activeForm.type === "order-detail" && (
            <div className="happyerp-right-edit-form">
              <div className="happyerp-right-edit-form-heading">
                <h3>Edit Order Details</h3>
                <button
                  onClick={() =>
                    setActiveForm({
                      editing: false,
                      type: null,
                    })
                  }
                  className="happyerp-right-edit-btn"
                >
                  <span class="material-symbols-outlined">close</span>
                </button>
              </div>
              <div className="happyerp-right-edit-form-content">
                {/* <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Created Date
                  </label>
                  <DatePicker
                    disabled
                    selected={saleOrder?.createdAt.toDate()}
                    dateFormat="dd/MM/yyyy"
                  />
                </div> */}
                <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Party
                  </label>
                  <Select
                    options={allParties}
                    value={
                      allParties.find(
                        (option) => option.value === orderDetails.partyName
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleOrderFormChange("partyName", selectedOption.value)
                    }
                  />
                </div>
                <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Agent
                  </label>
                  <Select
                    options={allAgents}
                    value={
                      allAgents.find(
                        (option) => option.value === orderDetails.agentName
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleOrderFormChange("agentName", selectedOption.value)
                    }
                  />
                </div>
                <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Transport
                  </label>
                  <Select
                    options={transportOptions}
                    value={
                      transportOptions.find(
                        (option) => option.value === orderDetails.transport
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleOrderFormChange("transport", selectedOption.value)
                    }
                  />
                </div>
                {/* <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Order Number
                  </label>
                  <input type="text" disabled value={orderId} />
                </div> */}
                <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Delivery Date
                  </label>
                  <DatePicker
                    minDate={new Date()}
                    selected={orderDetails.deliveryDate}
                    onChange={(date) =>
                      handleOrderFormChange("deliveryDate", date)
                    }
                    dateFormat="dd/MM/yyyy"
                  />
                </div>

                <div className="happyerp-right-edit-form-element-btn">
                  <button style={{ border: "1px solid #e4e4e4" }}>
                    Cancel
                  </button>
                  <button
                    style={{
                      backgroundColor: "var(--theme-teal)",
                      color: "white",
                    }}
                    onClick={handleOrderDetailsSubmit}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeForm?.type?.startsWith("product") && (
            <div className="happyerp-right-edit-form">
              <div className="happyerp-right-edit-form-heading">
                <h3>Edit product {editProduct?.itemName}</h3>
                <button
                  onClick={handleEditProductCancelClick}
                  className="happyerp-right-edit-btn"
                >
                  <span class="material-symbols-outlined">close</span>
                </button>
              </div>
              <div className="happyerp-right-edit-form-content">
                <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Items
                  </label>
                  <Select
                    options={optionsItem}
                    value={
                      optionsItem.find(
                        (option) => option.value === editProduct.itemName
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleEditProductFormChange(
                        "itemName",
                        selectedOption.value
                      )
                    }
                  />
                </div>
                <div style={{ display: "flex", gap: "4px" }}>
                  <div className="happyerp-right-edit-form-element">
                    <label
                      htmlFor=""
                      className="happyerp-text-label"
                      style={{ color: "black" }}
                    >
                      Design
                    </label>
                    <Select
                      options={optionsDesign}
                      value={
                        optionsDesign.find(
                          (option) => option.value === editProduct.design
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        handleEditProductFormChange(
                          "design",
                          selectedOption.value
                        )
                      }
                    />
                  </div>
                  <div className="happyerp-right-edit-form-element">
                    <label
                      htmlFor=""
                      className="happyerp-text-label"
                      style={{ color: "black" }}
                    >
                      Sub Design
                    </label>
                    <Select
                      options={optionsSubDesign}
                      value={
                        optionsSubDesign.find(
                          (option) => option.value === editProduct.subDesign
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        handleEditProductFormChange(
                          "subDesign",
                          selectedOption.value
                        )
                      }
                    />
                  </div>
                  <div className="happyerp-right-edit-form-element">
                    <label
                      htmlFor=""
                      className="happyerp-text-label"
                      style={{ color: "black" }}
                    >
                      Style
                    </label>
                    <Select
                      options={optionsStyle}
                      value={
                        optionsStyle.find(
                          (option) => option.value === editProduct.style
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        handleEditProductFormChange(
                          "style",
                          selectedOption.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Colors
                  </label>
                  <div className="happyerp-right-edit-form-element-color">
                    {["red", "blue", "yellow", "green"].map((color) => (
                      <div
                        key={color}
                        className="happyerp-right-edit-form-element-checkbox"
                      >
                        <input
                          type="checkbox"
                          id={color}
                          checked={editProduct.colors.includes(color)}
                          onChange={() => handleEditColorChange(color)}
                        />
                        <label htmlFor={color}>
                          <span style={{ backgroundColor: color }} />
                          {color.charAt(0).toUpperCase() + color.slice(1)}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Sizes/Quantity
                  </label>
                  <div className="happyerp-right-edit-form-element-size">
                    {Object.entries(editProduct.sizes)
                      .sort(([a], [b]) => {
                        const numA = parseInt(a.replace("size ", ""), 10);
                        const numB = parseInt(b.replace("size ", ""), 10);
                        return numA - numB;
                      })
                      .map(([sizeName, quantity]) => {
                        return (
                          <div className="happyerp-size-div">
                            <p>{sizeName}</p>
                            <div className="happyerp-size-quantity">
                              <button
                                className="happyerp-size-quantity-btn"
                                onClick={() =>
                                  handleProductSizeChange(
                                    sizeName,
                                    quantity - 1
                                  )
                                }
                              >
                                -
                              </button>
                              <input
                                type="text"
                                name=""
                                id=""
                                value={quantity}
                                onChange={(e) =>
                                  handleProductSizeChange(
                                    sizeName,
                                    Number.parseInt(e.target.value || 0)
                                  )
                                }
                              />
                              <button
                                className="happyerp-size-quantity-btn"
                                onClick={() =>
                                  handleProductSizeChange(
                                    sizeName,
                                    quantity + 1
                                  )
                                }
                              >
                                +
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="happyerp-right-edit-form-element-btn">
                  <button
                    style={{ border: "1px solid #e4e4e4" }}
                    onClick={handleEditProductCancelClick}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      backgroundColor: "var(--theme-teal)",
                      color: "white",
                    }}
                    onClick={handleUpdateOrderProduct}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeForm.type === "new-product" && (
            <div className="happyerp-right-edit-form">
              <div className="happyerp-right-edit-form-heading">
                <h3>Add new product</h3>
                <button
                  onClick={handleAddProductCancelClick}
                  className="happyerp-right-edit-btn"
                >
                  <span class="material-symbols-outlined">close</span>
                </button>
              </div>
              <div className="happyerp-right-edit-form-content">
                <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Items
                  </label>
                  <Select
                    options={optionsItem}
                    value={
                      optionsItem.find(
                        (option) => option.value === newProduct.itemName
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleProductFormChange("itemName", selectedOption.value)
                    }
                  />
                </div>
                <div style={{ display: "flex", gap: "4px" }}>
                  <div className="happyerp-right-edit-form-element">
                    <label
                      htmlFor=""
                      className="happyerp-text-label"
                      style={{ color: "black" }}
                    >
                      Design
                    </label>
                    <Select
                      options={optionsDesign}
                      value={
                        optionsDesign.find(
                          (option) => option.value === newProduct.design
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        handleProductFormChange("design", selectedOption.value)
                      }
                    />
                  </div>
                  <div className="happyerp-right-edit-form-element">
                    <label
                      htmlFor=""
                      className="happyerp-text-label"
                      style={{ color: "black" }}
                    >
                      Sub Design
                    </label>
                    <Select
                      options={optionsSubDesign}
                      value={
                        optionsSubDesign.find(
                          (option) => option.value === newProduct.subDesign
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        handleProductFormChange(
                          "subDesign",
                          selectedOption.value
                        )
                      }
                    />
                  </div>
                  <div className="happyerp-right-edit-form-element">
                    <label
                      htmlFor=""
                      className="happyerp-text-label"
                      style={{ color: "black" }}
                    >
                      Style
                    </label>
                    <Select
                      options={optionsStyle}
                      value={
                        optionsStyle.find(
                          (option) => option.value === newProduct.style
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        handleProductFormChange("style", selectedOption.value)
                      }
                    />
                  </div>
                </div>
                <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Colors
                  </label>
                  <div className="happyerp-right-edit-form-element-color">
                    {["red", "green", "blue", "yellow"].map((color, index) => (
                      <div
                        key={index}
                        className="happyerp-right-edit-form-element-checkbox"
                      >
                        <input
                          type="checkbox"
                          id={color}
                          checked={newProduct?.colors?.includes(color)}
                          onChange={() => handleColorChange(color)}
                        />
                        <label htmlFor={color}>
                          <span style={{ backgroundColor: color }} />
                          {color}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="happyerp-right-edit-form-element">
                  <label
                    htmlFor=""
                    className="happyerp-text-label"
                    style={{ color: "black" }}
                  >
                    Sizes/Quantity
                  </label>
                  <div className="happyerp-right-edit-form-element-size">
                    {Object.entries(newProduct.sizes).map(
                      ([sizeName, quantity], index) => (
                        <div key={index} className="happyerp-size-div">
                          <p>{sizeName}</p>
                          <div className="happyerp-size-quantity">
                            <button
                              className="happyerp-size-quantity-btn"
                              onClick={() =>
                                handleSizeChange(sizeName, quantity - 1)
                              }
                            >
                              -
                            </button>
                            <input
                              type="text"
                              name=""
                              id=""
                              value={quantity || 0}
                              onChange={(e) =>
                                handleSizeChange(
                                  sizeName,
                                  Number.parseInt(e.target.value || 0)
                                )
                              }
                            />
                            <button
                              className="happyerp-size-quantity-btn"
                              onClick={() =>
                                handleSizeChange(sizeName, quantity + 1)
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="happyerp-right-edit-form-element-btn">
                  <button
                    style={{ border: "1px solid #e4e4e4" }}
                    onClick={handleAddProductCancelClick}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      backgroundColor: "var(--theme-teal)",
                      color: "white",
                    }}
                    onClick={handleProductSubmit}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeForm.type === "multiple-product" && (
            <div className="happyerp-right-edit-form">
              <div className="happyerp-right-edit-form-heading">
                <h3>Add multiple product</h3>
                <button
                  onClick={handleMultipleAddProductCancelClick}
                  className="happyerp-right-edit-btn"
                >
                  <span class="material-symbols-outlined">close</span>
                </button>
              </div>
              <div className="happyerp-right-edit-form-content">
                {multipleProduct.map((product, index) => (
                  <div
                    key={index}
                    className="happyerp-right-edit-form-multiple-add"
                  >
                    <div className="happyerp-right-multiple-add-heading">
                      <p>Product {index + 1}</p>

                      {multipleProduct.length > 1 && (
                        <button
                          className="happyerp-right-multiple-add-heading-btn"
                          onClick={() => handleMultipleDelete(index)}
                        >
                          <span className="material-symbols-outlined">
                            delete
                          </span>
                        </button>
                      )}
                    </div>
                    <div className="happyerp-right-edit-form-content">
                      <div className="happyerp-right-edit-form-element">
                        <label
                          htmlFor=""
                          className="happyerp-text-label"
                          style={{ color: "black" }}
                        >
                          Items
                        </label>
                        <Select
                          options={optionsItem}
                          value={
                            optionsItem.find(
                              (option) =>
                                option.value === multipleProduct[index].itemName
                            ) || null
                          }
                          onChange={(selectedOption) =>
                            handleMultipleProductChange(
                              index,
                              "itemName",
                              selectedOption.value
                            )
                          }
                        />
                      </div>
                      <div style={{ display: "flex", gap: "4px" }}>
                        <div className="happyerp-right-edit-form-element">
                          <label
                            htmlFor=""
                            className="happyerp-text-label"
                            style={{ color: "black" }}
                          >
                            Design
                          </label>
                          <Select
                            options={optionsDesign}
                            value={
                              optionsDesign.find(
                                (option) => option.value === product.design
                              ) || null
                            }
                            onChange={(selectedOption) =>
                              handleMultipleProductChange(
                                index,
                                "design",
                                selectedOption.value
                              )
                            }
                          />
                        </div>
                        <div className="happyerp-right-edit-form-element">
                          <label
                            htmlFor=""
                            className="happyerp-text-label"
                            style={{ color: "black" }}
                          >
                            Sub Design
                          </label>
                          <Select
                            options={optionsSubDesign}
                            value={
                              optionsSubDesign.find(
                                (option) =>
                                  option.value ===
                                  multipleProduct[index].subDesign
                              ) || null
                            }
                            onChange={(selectedOption) =>
                              handleMultipleProductChange(
                                index,
                                "subDesign",
                                selectedOption.value
                              )
                            }
                          />
                        </div>
                        <div className="happyerp-right-edit-form-element">
                          <label
                            htmlFor=""
                            className="happyerp-text-label"
                            style={{ color: "black" }}
                          >
                            Style
                          </label>
                          <Select
                            options={optionsStyle}
                            value={
                              optionsStyle.find(
                                (option) =>
                                  option.value === multipleProduct[index].style
                              ) || null
                            }
                            onChange={(selectedOption) =>
                              handleMultipleProductChange(
                                index,
                                "style",
                                selectedOption.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="happyerp-right-edit-form-element">
                        <label
                          htmlFor=""
                          className="happyerp-text-label"
                          style={{ color: "black" }}
                        >
                          Colors
                        </label>
                        <div
                          className="happyerp-right-edit-form-element-color"
                          style={{ padding: "0px" }}
                        >
                          {["red", "blue", "yellow", "green"].map(
                            (color, i) => {
                              return (
                                <div
                                  key={color + i}
                                  className="happyerp-right-edit-form-element-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    id={color + index}
                                    checked={multipleProduct[
                                      index
                                    ]?.colors?.includes(color)}
                                    onChange={() =>
                                      handleMultipleColorChange(index, color)
                                    }
                                  />
                                  <label htmlFor={color + index}>
                                    <span style={{ backgroundColor: color }} />
                                    {color}
                                  </label>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="happyerp-right-edit-form-element">
                        <label
                          htmlFor=""
                          className="happyerp-text-label"
                          style={{ color: "black" }}
                        >
                          Sizes/Quantity
                        </label>
                        <div className="happyerp-right-edit-form-element-size">
                          {Object.entries(multipleProduct[index].sizes).map(
                            ([sizeName, quantity], i) => (
                              <div
                                key={sizeName + quantity + i}
                                className="happyerp-size-div"
                              >
                                <p>{sizeName}</p>
                                <div className="happyerp-size-quantity">
                                  <button
                                    className="happyerp-size-quantity-btn"
                                    onClick={() =>
                                      handleMultipleSizeChange(
                                        index,
                                        sizeName,
                                        quantity - 1
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    value={Number.parseInt(quantity) || 0}
                                    onChange={(e) =>
                                      handleSizeChange(
                                        "size 34",
                                        Number.parseInt(e.target.value || 0)
                                      )
                                    }
                                  />
                                  <button
                                    className="happyerp-size-quantity-btn"
                                    onClick={() =>
                                      handleMultipleSizeChange(
                                        index,
                                        sizeName,
                                        quantity + 1
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className="happyerp-add-another-btn"
                  onClick={handleAddAnotherProduct}
                >
                  <button>
                    <span className="material-symbols-outlined">add</span>Add
                    another product
                  </button>
                </div>
                <div className="happyerp-right-edit-form-element-btn">
                  <button
                    className="happyerp-edit-cancel-btn"
                    onClick={handleMultipleAddProductCancelClick}
                  >
                    Cancel
                  </button>
                  <button
                    className="happyerp-edit-save-btn"
                    onClick={handleMultipleSubmitChange}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HappyerpOrderDetail;
