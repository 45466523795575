import React from "react";
import { Route, Routes } from "react-router-dom";
// BusinessOne css start
import "../businessOneCss/BusinessOneColors.scss";
import "../businessOneCss/BusinessOneFont.scss";
import "../businessOneCss/BusinessOneStyle.scss";
// BusinessOne css end

// import pages
// import BusinessOneHeader from "../businessOneComponents/BusinessOneHeader";
import BusinessOneHome from "../businessOnePages/businessOneHome/BusinessOneHome";
import GlobalRoute from "../../../../global route/GlobalRoute";
import Navbar from "../../../../default components/Navbar";
import ColorsComponent from "../../../../default components/ColorsComponent";
import Footer from "../../../../default components/Footer";
import FooterUpper from "../../../../default components/FooterUpper";
import PGDashboard from "../../../../allRoles/dashboards/PGDashboard";

const BusinessOneRoute = () => {
  return (
    <div className="business_one_route_parent_div">
      {/* <BusinessOneHeader /> */}
      <Navbar />
      <Routes>
        <Route path="/" element={<BusinessOneHome />}></Route>
        <Route path="/dashboard" element={<PGDashboard />}></Route>
      </Routes>
      <GlobalRoute />
      <ColorsComponent />
      <FooterUpper />
      <Footer />
    </div>
  );
};

export default BusinessOneRoute;
